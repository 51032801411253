import React, { useEffect, useState } from "react";

import Home from "../home/Home";
import GroupList from "../group-list/GroupList";
import BudgetPartPhase from "../budgetpart/BudgetPartPhase";
import Notification from "../notification/Notification";
import ConfirmEmail from "../confirm-email/ConfirmEmail";
import history from "../../utils/history";

import Profile from "../profile/Profile";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePreviousProps } from "../../hooks-custom/usePreviousProps";
import GroupPage from "../group-page/GroupPage";
import "./styles.css";
import { css } from "emotion";


const Flux = (props) => {
  const { location } = props;
  const previousLocation = usePreviousProps(location);
  const [pageDeFond, setPageDeFond] = useState(null);
  const infoCity = useSelector((state) => state.city.infoCity);


  useEffect(() => {
  
    // SI PAS DE PAGE DE FOND :
    if (typeof previousLocation === "undefined") {

      // SI JE DEMANDE UNE PAGE DE PARTICIPATIONS
      if (
        location.pathname === "/" ||
        location.pathname === "/participation" ||
        location.pathname === "/evenement" ||
        location.pathname === "/alerte" ||
        location.pathname === "/actu" ||
        location.pathname === "/sondage" ||
        location.pathname === "/participez"
      ) {

        // SI CETTE VILLE A L'OPTION PARTICIPATIONS
        if (infoCity.options.participations === 1) {
          setPageDeFond({ pathname: "/" });
        } else {
        // SI CETTE VILLE N'A PAS L'OPTION PARTICIPATIONS :
          if (infoCity.options.budgetpart === 1) {
            history.push({ pathname: "/budget-participatif" });
          } else if (infoCity.options.groups === 1) {
            history.push({ pathname: "/communautes" });
          }

        }
      } else if (
        location.pathname === "/connexion" ||
        location.pathname === "/signinstep"
      ) {

        // SI JE DEMANDE UNE PAGE DE POPUP NEUTRE :

        if (infoCity.options.participations === 1) {
          setPageDeFond({ pathname: "/" });
        } else if (infoCity.options.budgetpart === 1) {
          setPageDeFond({ pathname: "/budget-participatif" });
        } else if (infoCity.options.groups === 1) {
          setPageDeFond({ pathname: "/communautes" });
        }


      } else if (location.pathname === "/budget-participatif") {

        // SI JE DEMANDE LA PAGE DES BUDGETS PARTICIPATIFS
        if (infoCity.options.budgetpart === 1) {
          // SI CETTE VILLE A L'OPTION  BUDGETS PARTICIPATIFS
          setPageDeFond(location);
        } else {
          // SI CETTE VILLE N'A PAS L'OPTION BUDGETS PARTICIPATIFS
          if (infoCity.options.participations === 1) {
            history.push({ pathname: "/" });
          } else if (infoCity.options.groups === 1) {
            history.push({ pathname: "/communautes" });
          }

        }
      } else if (
        location.pathname === "/communautes" ||
        location.pathname === "/communaute"
      ) {
        // SI JE DEMANDE LA PAGE DES COMMUNAUTES
        if (infoCity.options.groups === 1) {
          // SI CETTE VILLE A L'OPTION COMMUNAUTES
          setPageDeFond(location);
        } else {
          // SI CETTE VILLE N'A PAS L'OPTION COMMUNAUTES
          if (infoCity.options.participations === 1) {
            history.push({ pathname: "/" });
          } else if (infoCity.options.budgetpart === 1) {
            history.push({ pathname: "/budget-participatif" });
          }
        }
      } else {

        //POUR TOUTE AUTRE PAGE DEMANDEE, JE LA POUSSE TELLE QU'ELLE :
        setPageDeFond(location);
      }
      
    } else {


      if(location.pathname === "/profile" ||
      location.pathname === "/notification" ||
      location.pathname === "/confirm_email") {
        setPageDeFond(location);
      } else if (
        location.pathname === "/"
      ) {
        if (infoCity.options.participations === 1) {
          setPageDeFond(location);
        } else {
          if (infoCity.options.budgetpart === 1) {
            history.push({ pathname: "/budget-participatif" });
          } else if (infoCity.options.groups === 1) {
            history.push({ pathname: "/communautes" });
          }
        }

      } else if (location.pathname === "/budget-participatif") {
        if (infoCity.options.budgetpart === 1) {
          setPageDeFond(location);
        } else {
          if (infoCity.options.participations === 1) {
            history.push({ pathname: "/" });
          } else if (infoCity.options.groups === 1) {
            history.push({ pathname: "/communautes" });
          }
        }
      } else if (
        location.pathname === "/communautes" ||
        location.pathname === "/communaute"
      ) {
        if (infoCity.options.groups === 1) {
          setPageDeFond(location);
        } else {
          if (infoCity.options.participations === 1) {
            history.push({ pathname: "/" });
          } else if (infoCity.options.budgetpart === 1) {
            history.push({ pathname: "/budget-participatif" });
          }
        }
      }
    }

  }, [location]);

  if (infoCity !== null) {
    return (
      <div
        className={css`
          box-sizing: border-box;
          height: 100%;
          width: 100%;
        `}>
        <Switch location={pageDeFond}>
          <Route exact path="/" component={Home} />
          <Route path="/confirm_email" component={ConfirmEmail} />
          <Route path="/communautes" component={GroupList} />
          <Route path="/notification" component={Notification} />
          <Route path="/profile" component={Profile} />
          <Route path="/communaute" component={GroupPage} />
          <Route path="/budget-participatif" component={BudgetPartPhase} />
        </Switch>
      </div>
    );
  } else {
    return false;
  }
};

export default Flux;
