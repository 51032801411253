import React from 'react';
import {css} from 'emotion';
import {Marker} from 'react-mapbox-gl';
import IconEasyImagine from '../../../../common/icons/IconEasyImagine';

const MarkerMap = props => {
  return (
    <Marker
      onClick={() => {
        props.showPopup(props.id);
      }}
      coordinates={props.coord}
      anchor="center">
      <div
        className={css`
          background-color: rgb(242, 101, 35);
          border-radius: 50%;
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}>
        <IconEasyImagine color={'white'} size={20} />
      </div>
    </Marker>
  );
};
export default MarkerMap;
