import React, { useState } from "react";
import { css } from "emotion";
import IconEasyLike from "../../../common/icons/IconEasyLike";
import IconEasyComment from "../../../common/icons/IconEasyComment";
import TokenTitle from "../../../common/material-easy-ui/material/TokenTitle";

import { useSelector, useDispatch } from "react-redux";
import {
  likeCitizenpart,
  unlikeCitizenpart,
} from "../../../../redux/actions/participationActions";

import MessageAlerte from "../../../common/material-easy-ui/modal/MessageAlerte";
import AccountNotConfirmedAlert from "../../../common/alert/AccountNotConfirmedAlert";
import Connexion from "../../../connexion/Connexion";

const FooterCard = (props) => {
  const dispatch = useDispatch();

  const popkey = useSelector((state) => state.user.popkey);
  const info_user = useSelector((state) => state.user.info_user);
  const [connect, setConnect] = useState(false);
  const [forbidenUserModal, setForbidenUserModal] = useState(false);
  const [notConfirmedUserModal, setNotConfirmedUserModal] = useState(false);

  const toggleLike = (event) => {
    event.stopPropagation();

    if (popkey) {
      if (info_user.account_blocked === 0) {
        if (!props.has_liked) {
          dispatch(likeCitizenpart(popkey, props.item.id_citizenpart));
        } else {
          dispatch(unlikeCitizenpart(popkey, props.item.id_citizenpart));
        }
      } else if (info_user.account_blocked === 1) {
        setForbidenUserModal(true);
      } else {
        setNotConfirmedUserModal(true);
      }
    } else {
      setConnect(true);
    }
  };

  return (
    <>
      <div
        className={css`
          display: flex;
          padding-left: 15px;
          padding-right: 15px;
          margin-top: 20px;
          justify-content: flex-end;
          flex: 1;
        `}>
        
        {/*props.status !== undefined && (
          <TokenTitle
            style={{ marginLeft: 10, whiteSpace: "nowrap", }}
            background={"#fff"}
            colorText={"rgba(41, 53, 74,1)"}
            text={
              participationStatusFr[props.status]
                ? participationStatusFr[props.status]
                : "Reçu"
            }
          />
          )*/}

        {props.private_card && (
          <TokenTitle
            style={{ marginLeft: 10, whiteSpace: "nowrap", }}
            text={"Mode privé"}
            background="#fff"
            colorText="#454545"
          />
        )}

        {!props.community && !props.private_card && (
          <TokenTitle
            style={{
              marginLeft: 10,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "block"
            }}
            text="Discussion publique"
            background="#fff"
            colorText="#454545"
          />
        )}

        {props.community && (
          <TokenTitle
            style={{
              marginLeft: 10,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "block"
            }}
            text={props.community}
            background="#fff"
            colorText="#454545"
          />
        )}

      </div>

      <div
        className={css`
          display: flex;
          flex-direction: row;
          margin-left: 30px;
          margin-right: 30px;
          padding-top: 10px;
          padding-bottom: 20px;
        `}>
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
          `}
          onClick={(e) => toggleLike(e)}>
          <IconEasyLike
            color={props.has_liked ? "#ff006a" : "#29354a"}
            size={25}
          />
          <div
            className={css`
              color: ${props.has_liked ? "#ff006a" : "#29354a"};
              font-size: 13px;
            `}>
            {props.likes}
          </div>
        </div>

        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
          `}>
          <div>
            {" "}
            <IconEasyComment color={"#29354a"} size={25} />
          </div>
          <div
            className={css`
              color: #29354a;
              font-size: 13px;
            `}>
            {props.comments}
          </div>
        </div>

        {forbidenUserModal && (
          <MessageAlerte
            close={() => setForbidenUserModal(false)}
            text={
              "Votre compte a été bloqué par la ville ou l'équipe popvox car vous ne respectiez pas les CGU du service. Pour plus d'informations, vous pouvez contacter les équipes popvox (contact@popvox.fr)."
            }
            textHeader={"Compte bloqué"}
          />
        )}

        {notConfirmedUserModal && (
          <AccountNotConfirmedAlert
            close={() => setNotConfirmedUserModal(false)}
          />
        )}

        {connect && <Connexion onBack={() => setConnect(false)} />}
      </div>
    </>
  );
};

export default FooterCard;
