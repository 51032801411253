import React from 'react';
import {css} from 'emotion';
import IconEasyGroup from '../../../../common/icons/IconEasyGroup';
import TokenMembers from './TokenMembers';
const FooterCardGroup = props => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        align-items: center;
        margin-top: 20px;
      `}>
      <div
        className={css`
          flex: 1;
          text-align: left;
          justify-content: flex-start;
        `}>
        <TokenMembers
          subscribed={props.subscribed}
          requested={props.requested}
        />
      </div>
      <div
        className={css`
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        `}>
        <IconEasyGroup color={'grey'} size={30} />
        <div
          className={css`
            color: grey;
          `}>
          {props.members}
        </div>
      </div>
    </div>
  );
};

export default FooterCardGroup;
