import React from 'react';
import Avatar from './Avatar';
import './styles.css';

const AvatarPicker = props => {
  //const tabsAvatar = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const tabsAvatar = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39];
  const chooseAvatar = item => {
    props.chooseAvatar(item);
  };

  return (
    <div className="avatar-picker">
      <div
        onClick={props.closeAvatarPicker}
        style={{
          textAlign: 'center',
          color: 'grey',
          height: '30px',
          lineHeight: '30px',
          borderBottom: '1px solid #f7f7f7',
          display: 'flex',
          
          flex: 1
        }}>
        <span style={{flex: 1}}>
          Choisir un Avatar
        </span>
        <div style={{width: 40, textAlign: 'center', backgroundColor: '#f0f0f0', cursor:'pointer'}}>x</div>
      </div>

      <div className="avatar-picker-contains">
        {tabsAvatar.map((item, index) => {
          return (
            <div
              key={"AVATAR" + index}
              className={'avatar-picker-item'}
              onClick={() => {
                chooseAvatar(item);
              }}>
              <Avatar size={50} id_avatar={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AvatarPicker;
