import React, {useEffect, useRef, useState} from 'react';
import {css} from 'emotion';
import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import CloseButton from './components/CloseButton';
import SelectInput from '../post/components/SelectInput';
import Button from '../../../common/material-easy-ui/button/ButtonEX';

const Filtrage = props => {


  const targetRef = useRef(null);
  const [showList, setShowList] = useState(1);

  useEffect(() => {
    targetRef.current && disableBodyScroll(targetRef.current);
    return () => clearAllBodyScrollLocks();
  }, []);

  const sortArray = (current_phase) => {

    let listSort = [
      {id: 0, name: 'Ordre aléatoire'},
      {id: 1, name: 'Ordre chronologique'},
      {id: 2, name: 'Nombre de likes'},
      {id: 3, name: 'Nombre de votes'},
    ];

    if ((current_phase == 1) || (current_phase == 1.5)) {
      return [listSort[0], listSort[1], listSort[2]];
    } else if ((current_phase == 2) || (current_phase == 2.5) ) {
      return [listSort[0], listSort[1], listSort[3]];
    } else if(current_phase == 3) {
      return [listSort[0], listSort[1]];
    } else {
      return [listSort[0], listSort[1], listSort[2], listSort[3]];
    }
  };

  const onClose = () => {
    props.onClose();
  };

  const onUseListOne = () => {
    setShowList(1);
  };

  const onUseListTwo = () => {
    setShowList(2);
  };

  const onSend = () => {
    props.onfiltre();
    props.onClose();
  };

  return (
    <div
      ref={targetRef}
      className={css`
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 101%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1000;
      `}>
      <div
        className={css`
          width: 600px;
          background-color: white;
          border-radius: 10px;
          padding: 20px;
        `}>
        <div
          className={css`
            display: flex;
            justify-content: flex-end;
          `}>
          <CloseButton onPress={onClose} />
        </div>
        <div
          className={css`
            text-align: center;
            font-weight: 600;
            font-size: 15px;
          `}>
          Appliquer un Filtre
        </div>
        <div>
          <div
            className={css`
              padding: 20px;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              margin-top: 20px;
            `}>
            <SelectInput
              id={1}
              showList={showList}
              onUse={onUseListOne}
              width={'300px'}
              onChoose={props.onChooseRubric}
              selected={props.rubric}
              defaultText={'Thématique'}
              options={[
                {id: 0, name: 'Toutes les thematiques'},
                ...props.session_rubrics
              ]}
            />
          </div>
          <div
            className={css`
              padding: 20px;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
            `}>
            <SelectInput
              id={2}
              showList={showList}
              onUse={onUseListTwo}
              width={'300px'}
              onChoose={props.onChooseSort}
              selected={props.sort}
              defaultText={'Ordre'}
              options={sortArray(props.current_phase)}
            />
          </div>

          <div
            className={css`
              box-sizing: border-box;
              display: flex;
              justify-content: center;
            `}>
            <Button
              onPress={onSend}
              text="Envoyer"
              textColor="white"
              background={'rgb(242,101,35)'}
              hoverBackground={'rgba(245, 93, 66,0.8)'}
              width="300"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filtrage;
