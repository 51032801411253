const initialState = {
  popkey: null,
  info_user: null,
  communities: [],
  messages: null,
  signupError: null,
  coord_user: false,
  new_pwd_sent: false,
  error_new_pwd_sent: 0,
};

export function userReducers(state = initialState, action) {
  switch (action.type) {
    case "NEW_PWD_SENT":
      return {
        ...state,
        new_pwd_sent: action.error === 0 ? true : false,
        error_new_pwd_sent: action.error,
      };
    case "RESET_NEW_PWD_SENT":
      return {
        ...state,
        new_pwd_sent: false,
        error_new_pwd_sent: 0,
      };
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        popkey: null,
        info_user: null,
        communities: [],
        messages: 0,
        signupError: null,
      };
    case "SET_COORD_USER":
      return {
        ...state,
        coord_user: {
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
        },
      };
    case "SET_POPKEY":
      return {
        ...state,
        popkey: action.payload,
      };

    case "LOG_IN":
      var error = null;
      if (action.payload.info === null) {
        error = "Mauvais identifiant ou mot de passe !";
      }

      return {
        ...state,
        info_user: action.payload.info,
        popkey: action.payload.info ? action.payload.info.auth_token : null,
        communities: action.payload.communities,
        messages: action.payload.messages,
        signupError: error,
      };

    case "GET_USER_INFO":
      if (action.payload.info && action.payload.info !== null) {
        return {
          ...state,
          info_user: action.payload.info,
          communities: action.payload.communities,
          messages: action.payload.messages,
          popkey: action.payload.info ? action.payload.info.auth_token : null,
        };
      } else {
        localStorage.removeItem("popvox@popkey");
      }

      return {
        ...state,
        popkey: null,
      };

    case "REGISTER_USER":
      return {
        ...state,
        signupError: action.payload.signupError,
        info_user: action.payload.info,
        popkey: action.payload.info ? action.payload.info.auth_token : null,
      };
    case "REGISTER_USER_ERROR":
      return {
        ...state,
        signupError: action.payload.signupError,
      };

    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        signupError: null,
      };

    case "LOG_IN_LOADING":
      return {
        ...state,
        signupError: null,
      };

    case "UPDATE_PASSWORD_SUCCESS":
      return {
        ...state,
        signupError: action.payload.signupError,
      };
    case "DISCONNECT":
      return {
        ...state,
        popkey: null,
        info_user: null,
        communites: [],
        messages: 0,
      };
    case "GET_CONNECT_BY_FACEBOOK":
      return {
        ...state,
        info_user: action.payload.info,
        communites: action.payload.communites,
        messages: action.payload.messages,
        popkey: action.payload.info ? action.payload.info.auth_token : null,
      };
    case "MESSAGE_READ":
      return {
        ...state,
        messages: 0,
      };

    case "ADD_USER_COMMUNITY":
      let newcommunities = [];
      if (state.communities) {
        newcommunities = [...state.communities, action.community];
      } else {
        newcommunities = [action.community];
      }
      return {
        ...state,
        communities: newcommunities,
      };

    case "REMOVE_USER_COMMUNITY":
      let new_communities = [...state.communities];
      const index = new_communities.findIndex((i) => {
        return i.id === action.community.id;
      });
      new_communities.splice(index, 1);
      return {
        ...state,
        communities: new_communities,
      };

    default:
      return state;
  }
}
