import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyAlert = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
          version="1.1" viewBox="0 0 32 32">
          <path
          fill={props.color}
          d="M5.364,22.12l0,-8.494c0.013,-4.372 2.932,-8.564 7.102,-10.033c1.155,-0.407 2.384,-0.605 3.603,-0.602c4.333,0.042 8.538,2.966 9.987,7.164c0.385,1.115 0.577,2.297 0.581,3.471l0,4.729c-0.032,0.803 -0.953,1.443 -1.719,1.053c-0.332,-0.169 -0.573,-0.502 -0.631,-0.868c-0.012,-0.082 -0.011,-0.103 -0.014,-0.185l0,-4.725c-0.007,-2.232 -0.944,-4.445 -2.565,-5.991c-2.095,-1.997 -5.287,-2.77 -8.071,-1.942c-3.376,1.004 -5.898,4.349 -5.91,7.933l0,8.264c1.576,-0.009 3.152,-0.015 4.728,-0.019l0,-5.887c0.011,-1.794 1.491,-3.443 3.364,-3.538c0.064,-0.003 0.072,-0.005 0.227,-0.004c1.797,0.034 3.427,1.573 3.497,3.409c0.002,0.044 0.002,0.088 0.003,0.133l0,5.887c1.984,0.005 3.968,0.013 5.953,0.026c1.893,0.035 3.57,1.763 3.499,3.677c-0.065,1.744 -1.58,3.321 -3.368,3.409c-0.043,0.002 -0.087,0.003 -0.131,0.004c-6.319,0.039 -12.638,0.001 -18.957,0.001c-1.88,-0.012 -3.589,-1.71 -3.541,-3.634c0.035,-1.422 1.02,-2.732 2.363,-3.238Zm1.185,2.144c-0.946,0.006 -1.633,1.411 -0.73,2.114c0.202,0.157 0.454,0.245 0.712,0.25c6.312,0.038 12.626,0.038 18.938,0c0.888,-0.017 1.532,-1.253 0.852,-1.986c-0.217,-0.234 -0.529,-0.372 -0.852,-0.378c-6.306,-0.039 -12.613,0 -18.92,0Zm10.634,-2.392c0.003,-1.966 0.012,-3.931 -0.001,-5.896c-0.015,-0.764 -0.887,-1.382 -1.628,-1.079c-0.432,0.176 -0.733,0.618 -0.736,1.098l0,5.877c0.789,-0.001 1.577,-0.001 2.365,0Z"/>
        </svg>
    </IconPattern>
    )
}

export default IconEasyAlert;
