import axios from 'axios';
import {server_api_url} from '../config/constants';

class CityAPI {

  static getCitiesByName(searchStr, longitude, latitude, popkey) {
    return axios.get(server_api_url + '/city/getcitiesbyname', {
      params: {
        searchstr: searchStr,
        longitude: longitude,
        latitude: latitude,
        popkey: popkey,
      },
    });
  }

  static getCityInformation(popkey, insee_com) {
    return axios.get(server_api_url + '/city/getcitybyinsee', {
      params: {
        popkey: popkey,
        code_insee: insee_com,
      },
    });
  }

  static getCityInfoByCoords(popkey, lng, lat) {
    return axios.get(server_api_url + '/city/getcitiesbyname', {
      params: {
        popkey: popkey,
        longitude: lng,
        latitude: lat,
        searchstr: ''
      },
    });
  }


  static getInseecomByPublicpart(id_publicpart) {
    return axios.get(server_api_url + '/city/getinseecombypublicpart', {
      params: {
        id_publicpart: id_publicpart,
      },
    });
  }

  static getInseecomByParticipation(id_citizenpart) {
    return axios.get(server_api_url + '/city/getinseecombycitizenpart', {
      params: {
        id_citizenpart: id_citizenpart,
      },
    });
  }

  static getInseecomByBpProject(id_budget_part_project) {
    return axios.get(server_api_url + '/city/getinseecombybpproject', {
      params: {
        id_budget_part_project: id_budget_part_project,
      },
    });
  }

  static addfavoritecity(popkey, insee_com) {
    return axios.post(server_api_url + '/users/addfavoritecity', {
      popkey: popkey,
      codeinsee: insee_com,
    });
  }

  static removefavoritecity(popkey, insee_com) {
    return axios.post(server_api_url + '/users/removefavoritecity', {
      popkey: popkey,
      codeinsee: insee_com,
    });
  }

}

export default CityAPI;
