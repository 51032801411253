import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyHome = (props) =>{
    return(
      <IconPattern size={props.size}>
      <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
          `}
       viewBox="0 0 32 32">
      <path
      fill={props.color}
      d="M25.038,12.623c-2.776,-2.477 -5.541,-4.968 -8.373,-7.382c-0.367,-0.306 -0.939,-0.307 -1.31,0.017l-10.695,9.447c-0.066,0.053 -0.081,0.069 -0.155,0.112c-0.693,0.409 -1.777,-0.367 -1.442,-1.227c0.057,-0.147 0.154,-0.275 0.27,-0.387l10.7,-9.45c0.554,-0.485 1.281,-0.755 2.021,-0.751c0.712,0.011 1.415,0.28 1.954,0.751l10.7,9.45c0.56,0.544 0.278,1.635 -0.531,1.744c-0.221,0.03 -0.45,-0.017 -0.642,-0.13c-0.073,-0.043 -0.088,-0.059 -0.154,-0.112c-0.113,-0.1 -0.226,-0.2 -0.339,-0.3l0,11.576c-0.009,1.56 -1.353,2.973 -2.968,3.003c-5.358,0.033 -10.715,0 -16.073,0c-1.567,-0.01 -2.993,-1.366 -3.003,-3.003l0,-8.619c0.004,-0.078 0.002,-0.098 0.015,-0.174c0.138,-0.78 1.392,-1.129 1.855,-0.327c0.08,0.138 0.122,0.216 0.134,0.501c0,2.876 -0.017,5.752 0,8.628c0.01,0.518 0.466,0.98 0.99,0.99c3.678,0.023 7.356,0.03 11.034,0.021l0,-10.041l-6.012,0l0,7.014c-0.018,0.531 -0.202,0.641 -0.369,0.777c-0.543,0.441 -1.524,0.098 -1.625,-0.641c-0.009,-0.06 -0.008,-0.075 -0.01,-0.136l0,-8.016c0.007,-0.21 0.026,-0.245 0.058,-0.336c0.122,-0.344 0.443,-0.607 0.808,-0.657c0.06,-0.008 0.076,-0.007 0.136,-0.009l8.016,0c0.023,0.001 0.046,0.001 0.069,0.002c0.463,0.048 0.861,0.405 0.924,0.863c0.008,0.061 0.007,0.076 0.009,0.137l0,11.037c1.006,-0.004 2.013,-0.009 3.019,-0.015c0.521,-0.01 0.986,-0.459 0.989,-1.005l0,-13.352Z"/>
      </svg>
        </IconPattern>
    )
}

export default IconEasyHome;
