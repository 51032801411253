import React, {useEffect, useRef, useState} from 'react';
import {css} from 'emotion';
import Portal from './components/Portal';

import ReactMapboxGl, {Marker, Popup} from 'react-mapbox-gl';
import HeaderModalMap from './components/HeaderModalMap';

import {useSelector} from 'react-redux';
import ControlMap from './components/ControlMap';
import ActionMap from './components/ActionMap';
import IconEasyPin from '../../icons/IconEasyPin';
import axios from 'axios';
const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoianVzcHJpZXQiLCJhIjoiY2o5cHRzMjB1MnVxMTJ4czJoaTdhZjVjbCJ9.3LZxp2Lf97TcCYWPAgsyyA',
});

const ModalMap = (props) => {
  const targetRef = useRef(null);

  const [openMap, setOpenMap] = useState(false);
  const infoCity = useSelector((state) => state.city.infoCity);
  const [center, setCenter] = useState([infoCity.lon, infoCity.lat]);
  const [zoom, setZoom] = useState([props.zoomLevel]);
  const [marker, setMarker] = useState(props.marker);
  const [adress, setAdress] = useState(props.adress);

  const timer = useRef(null);
  const clicks = useRef(0);

  useEffect(() => {
    setOpenMap(true);
  }, []);

  const clickModal = () => {
    props.onBack();
  };

  const onValid = () => {
    props.onValid(marker, adress);
  };

  const clickChildren = (e) => {
    e.stopPropagation();
  };

  const onSelectCity = (item) => {
    setMarker([item.center[0], item.center[1]]);
    setCenter([item.center[0], item.center[1]]);
    setAdress(item.place_name);
    setZoom([15]);
  };

  const getPosition = () => {
    // Simple wrapper
    return new Promise((res, rej) => {
      navigator.geolocation.getCurrentPosition(res, rej);
    });
  };
  const onGeoLocUser = () => {
    getPosition()
      .then((data) => {
        setCenter([data.coords.longitude, data.coords.latitude]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const zoomIn = () => {
    setZoom([zoom[0] + 1]);
  };

  const zoomOut = () => {
    setZoom([zoom[0] - 1]);
  };

  const placeMarker = (map, evt) => {
    clicks.current = clicks.current + 1;
    if (clicks.current === 1) {
      timer.current = setTimeout(() => {
        setMarker([evt.lngLat.lng, evt.lngLat.lat]);
        setAdress(null);
        axios
          .get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${evt.lngLat.lng},${evt.lngLat.lat}.json?access_token=pk.eyJ1IjoianVzcHJpZXQiLCJhIjoiY2o5cHRzMjB1MnVxMTJ4czJoaTdhZjVjbCJ9.3LZxp2Lf97TcCYWPAgsyyA`,
          )
          .then((data) => {
            setAdress(data.data.features[0].place_name);
          })
          .catch(() => {
            console.log('une erreur est survenue');
            setAdress(null);
          });
        clicks.current = 0;
      }, 600);
    } else {
      clearTimeout(timer.current);
      clicks.current = 0;
    }
  };

  return (
    <Portal id={props.id}>
      <div
        onClick={clickModal}
        ref={targetRef}
        className={css`
          width: 100%;
          height: 100%;
          position: fixed;
          z-index: 2000;
          top: 0px;
          left: 0px;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        `}>
        <div
          onClick={clickChildren}
          className={css`
            animation-duration: 0.2s;
            animation-fill-mode: both;
            animation-name: fadeInUp;
            background: ${props.color ? props.color : 'white'};
            height: ${props.height};
            width: 778px;
            border-radius: 5px;
            overflow: hidden;
            @media screen and (max-width: 678px) {
              width: 100%;
              height: 100%;
              border-radius: 0px;
              overflow: auto;
            }
          `}>
          <div
            className={css`
              height: 100%;
              width: 100%;
              position: relative;
            `}>
            <HeaderModalMap onSelectCity={onSelectCity} />
            <ActionMap
              onValid={onValid}
              onClose={clickModal}
              selectCity={marker}
            />
            <ControlMap
              zoomOut={zoomOut}
              zoomIn={zoomIn}
              onGeoLocUser={onGeoLocUser}
            />

            {openMap && (
              <Map
                center={center}
                // eslint-disable-next-line
                style="mapbox://styles/mapbox/streets-v9"
                containerStyle={{
                  height: '100%',
                  width: '100%',
                }}
                onClick={placeMarker}
                zoom={zoom}>
                {marker && (
                  <Marker coordinates={marker} anchor="bottom">
                    <IconEasyPin size={40} color={'red'} />
                  </Marker>
                )}

                {adress && (
                  <Popup
                    coordinates={marker}
                    offset={{
                      'bottom-left': [-200, -50],
                      bottom: [0, -50],
                      'bottom-right': [-200, -50],
                    }}>
                    <div
                      className={css`
                        width: 100%;
                        aling-text: center;
                      `}>
                      {adress}
                    </div>
                  </Popup>
                )}
              </Map>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default ModalMap;
