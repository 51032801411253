import React from 'react';
import {css} from 'emotion';
import HeaderCardNotification from './HeaderCardNotification';
import ContentCardNotification from './ContentCardNotification';

const CardNotification = props => {

  //console.log(props.item)
  return (
    <div
      onClick={() => {
        props.goToPage(props.item);
      }}
      className={css`border-top:1px solid rgba(24, 62, 100,0.1);width:100%;
      background-color:${props.item.click ? 'white' : '#F5F8FA'};
      cursor:pointer;
      padding-top:10px;
      padding-bottom:20px;
    };
      &:first-child{border-top:none}
      `}>
      <HeaderCardNotification
        time={props.item.min_creation}
      />
      <ContentCardNotification
        pseudo={props.item.pseudo}
        type={props.item.type_message}
        avatar={props.item.avatar}
        urlimg={props.item.urlimg}
        type_user={props.item.type_user}
        nom_comm={props.item.nom_comm}
        deleteNotification={() => props.deleteNotification(props.item.id_users_message)}
      />
    </div>
  );
};

export default CardNotification;
