import React from "react";
import { css } from "emotion";
import IconEasyInfo from "../../../common/icons/IconEasyInfo";
import { useSelector } from "react-redux";

const WelcomeBlock = () => {
  const infoCity = useSelector((state) => state.city.infoCity);

  if (
    infoCity &&
    infoCity.welcome_msg &&
    infoCity.welcome_msg !== null &&
    infoCity.welcome_msg !== ""
  ) {
    return (

      <div
        className={css`
          width: 100%;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 15px;
          margin-top: 30px;
          margin-bottom: 20px;
          background: #fff;
          border: 1px solid rgba(24, 62, 100, 0.05);
        `}>
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          `}>
          <div
            className={css`
              color: #29354a;
              font-size: 17px;
              text-align: left;
              flex: 1;
              font-weight: 600;
              line-height: 1;
            `}>
            Bienvenue !!
          </div>
          <div>
            <IconEasyInfo color={"#29354a"} size={20} />
          </div>
        </div>
        <div
          className={css`
            border-top: 1px solid #ebebeb;
            margin-top: 10px;
            padding: 10px;
            white-space: pre-wrap;
          `}>
          {infoCity.welcome_msg}
        </div>
      </div>
      
    );
  } else if(infoCity && infoCity.nom_comm) {
    return (
      
      <div
        className={css`
          width: 100%;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 15px;
          margin-top: 30px;
          margin-bottom: 20px;
          background: #fff;
          border: 1px solid rgba(24, 62, 100, 0.05);
        `}>
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          `}>
          <div
            className={css`
              color: #29354a;
              font-size: 17px;
              text-align: left;
              flex: 1;
              font-weight: 600;
              line-height: 1;
            `}>
            Bienvenue !!
          </div>
          <div>
            <IconEasyInfo color={"#29354a"} size={20} />
          </div>
        </div>
        <div
          className={css`
            border-top: 1px solid #ebebeb;
            margin-top: 10px;
            padding: 10px;
            white-space: pre-wrap;
          `}>
          {"Bienvenue dans l'espace de dialogue de " +
            infoCity.nom_comm +
            ".\nCela vous permet de contacter facilement et rapidement vos élus afin d'améliorer et de co-construire votre ville."}
        </div>
      </div>
    );
  }


  return false;
};

export default WelcomeBlock;
