import React, {useEffect, useState} from 'react';
import {css} from 'emotion';
import {server_img_api} from '../../config/constants';

import {useDispatch, useSelector} from 'react-redux';
import SubHeader from './components/sub-header/SubHeader';
import EmptySubHeader from './components/sub-header/EmptySubHeader';
import WarningMessage from './components/warning/WarningMessage';
import ListProject from './components/list-project/ListProject';
import {
  getBudgetPart,
  getMoreProjects,
} from '../../redux/actions/budgetPartActions';

import Filtrage from './components/filtrage/Filtrage';
import MapProject from './components/map-project/MapProject';
import BudgetpartButton from './components/budgetpartButton/BudgetpartButton';

// import MenuParticipation from '../participations/components/MenuParticipation';

import history from '../../utils/history';

const BudgetPartPhase = () => {

  const dispatch = useDispatch();

  const info_city = useSelector(state => state.city.infoCity);
  const zoomLevel = info_city.zoom_level ? info_city.zoom_level : 8;

  const popkey = useSelector(state => state.user.popkey);
  const budget_part_session = useSelector(state => state.budgetpart.budget_part_session);
  const isLoading = useSelector(state => state.budgetpart.isLoading);

  const list_project = useSelector(state => state.budgetpart.list_project);
  const list_map = useSelector(state => state.budgetpart.list_map);
  const nb_votes_user = useSelector(state => state.budgetpart.nb_votes_user);
  const endReachedProject = useSelector(state => state.budgetpart.endReachedProject);

  const [typeList, setTypeList] = useState(0);
  const [map, setMap] = useState(0);
  const [rubric, setRubric] = useState(0);
  const [sort, setSort] = useState(0);
  const [filtre, setFiltre] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const goToPostPage = () => {
    history.push({pathname: '/budget-participatif/poster'});
  };

  useEffect(() => {
    const scrollEndBottom = () => {
      if (window.scrollY >= document.body.scrollHeight - window.innerHeight) {
        setIsFetching(true);
      }
    };
    window.addEventListener('scroll', scrollEndBottom);
    return () => {
      window.removeEventListener('scroll', scrollEndBottom);
    };
  }, []);


  useEffect(() => {
    dispatch(getBudgetPart(info_city.insee_com, popkey, rubric, sort));
    setIsFetching(false);
  }, [info_city.insee_com, popkey, rubric, sort]);

  useEffect(() => {
    if(isFetching && !endReachedProject) {
      let all_ids_budget_part_project = list_project.map((theproj) => {
        return theproj.id_budget_part_project;
      })
      dispatch(getMoreProjects(budget_part_session.id_budget_part_session, popkey, rubric, sort, budget_part_session.current_phase, all_ids_budget_part_project));
      setIsFetching(false);
    }
  }, [isFetching]);

  const onFiltre = () => {
    dispatch(getBudgetPart(info_city.insee_com, popkey, rubric, sort));
    setIsFetching(false);
  };

  if (budget_part_session && budget_part_session.current_phase != 0) {
    return (
      <>
        <div
          className={css`
            position: relative;
            width: 100%;
            height: 220px;
            background-image: url(${server_img_api +
              budget_part_session.img_url});
            background-repeat: no-repeat;
            background-origin: content-box;
            background-position: center;
            background-size: cover;
          `}></div>

        {budget_part_session.current_phase == 1 && (
          <BudgetpartButton
            onPress={goToPostPage}
            name="Déposer un nouveau projet"
          />
        )}

        {budget_part_session.current_phase == 2 && (
          <div className={css`
            position: fixed;
            left: 65%;
            bottom: 80px;

            @media (min-width: 680px) {
              bottom: 30px;
            }
            z-index: 100;
          `}
          >
            <div
              className={css`
                background-color: rgba(245,93,66,1);
                color: #fff;
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 7px;
                padding-bottom: 7px;
                border-radius: 15px;
                font-weight: bold;
                -moz-box-shadow: 3px 3px 14px 0px rgba(0, 0, 0,0.5);
                -webkit-box-shadow: 3px 3px 14px 0px rgba(0, 0, 0,0.5);
                -o-box-shadow: 3px 3px 14px 0px rgba(0, 0, 0,0.5);
                box-shadow: 3px 3px 14px 0px rgba(0, 0, 0,0.5);
                font-size: 13px;`

              }>
              Crédits de votes :{' '}
              {budget_part_session.nbvote - nb_votes_user}
            </div>
          </div>
        )}

        <div
          className={css`
            box-sizing: border-box;
          `}>

          <SubHeader
            nb_votes_user={nb_votes_user}
            budget_part_session={budget_part_session}
            map={map}
            onShowList={() => setMap(0)}
            onShowMap={() => setMap(1)}
            typeList={typeList}
            chooseTypeList={setTypeList}
            showFilter={() => setFiltre(true)}
          />

          {budget_part_session.current_phase == 1.5 && (
            <WarningMessage
              title='Information importante !'
              message={`Les projets sont actuellement en cours d'analyse. Vous ne pouvez plus déposer de projet, mais vous pourrez bientôt voter!`}
              />
          )}


          {budget_part_session.current_phase == 2.5 && (
            <WarningMessage
              title='Information importante !'
              message={`Les projets sont actuellement en cours d'analyse. Vous ne pouvez plus voter. Vous allez bientôt connaître les lauréats !`}
              />
          )}

          {map === 0 ? (
            <div style={{marginTop: 25}}>
              <ListProject
                list_project={list_project}
                session_rubrics={budget_part_session.rubric.rubric}
                current_phase={budget_part_session.current_phase}
              />
            </div>
          ) : (
            <MapProject zoomLevel={zoomLevel} lon={info_city.lon} lat={info_city.lat} list_map={list_map} />
          )}
          
        </div>

        {filtre && (
          <Filtrage
            onfiltre={onFiltre}
            onChooseRubric={setRubric}
            onChooseSort={setSort}
            onClose={() => setFiltre(false)}
            rubric={rubric}
            sort={sort}
            current_phase={budget_part_session.current_phase}
            session_rubrics={budget_part_session.rubric.rubric}
          />
        )}


      </>
    );
  } else if (isLoading ) {
    return (
      <div style={{textAlign: 'center'}}>
        <img
          className={css`
            margin-top: 100px;
            width: 70px;
            height: 70px;
          `}
          src={require('../../image/loading.gif')}
          alt="loading"
        />
      </div>
    );
  } else if (budget_part_session && budget_part_session.current_phase == 0) {

    return (
      <>
        <div
          className={css`
            position: relative;
            width: 100%;
            height: 220px;
            background-image: url(${server_img_api +
              budget_part_session.img_url});
            background-repeat: no-repeat;
            background-origin: content-box;
            background-position: center;
            background-size: cover;
          `}></div>

          <EmptySubHeader budget_part_session={budget_part_session} />

          </>
    );

  }

  return (
    <div style={{textAlign: 'center', marginTop: 100, color: '#cacaca'}}>
      Pas de Budget participatif en cours...
    </div>
  );


};

export default BudgetPartPhase;
