import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyArrowBack = (props) =>{
    return(
      <IconPattern size={props.size}>
      <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
          `}
       version="1.1" viewBox="0 0 32 32">
        <path
         fill={props.color}
         d="M6.407,15.011l4.3,-4.3l0.046,-0.05c0.039,-0.047 0.05,-0.058 0.083,-0.109c0.311,-0.474 0.112,-1.206 -0.407,-1.453c-0.103,-0.049 -0.215,-0.081 -0.328,-0.092c-0.131,-0.014 -0.163,-0.003 -0.205,0c-0.182,0.025 -0.358,0.088 -0.503,0.198c-0.048,0.037 -0.058,0.048 -0.103,0.09l-6.009,6.009c-0.042,0.045 -0.053,0.055 -0.09,0.103c-0.261,0.344 -0.267,0.859 0,1.211c0.037,0.048 0.048,0.058 0.09,0.103l6.009,6.009c0.045,0.042 0.055,0.053 0.103,0.09c0.535,0.405 1.467,0.097 1.592,-0.628c0.047,-0.269 -0.023,-0.557 -0.188,-0.775c-0.037,-0.049 -0.049,-0.059 -0.09,-0.103l-4.3,-4.3l21.62,0c0.08,-0.01 0.161,-0.01 0.239,-0.029c0.692,-0.171 1.033,-1.183 0.425,-1.722c-0.122,-0.108 -0.267,-0.184 -0.425,-0.223c-0.078,-0.019 -0.159,-0.019 -0.239,-0.029l-21.62,0Z"/>
      </svg>
        </IconPattern>
    )
}

export default IconEasyArrowBack;
