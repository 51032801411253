import produce from 'immer';

const intitalState = {
  listConsultation: [],
  loading: false,
  endReached: false,
};

export const publicationReducers = (state = intitalState, action) => {
  switch (action.type) {

    case 'CLEAR_PUBLICATIONS':
      return {
        ...state,
        listConsultation: ['loading', 'loading', 'loading'],
        loading: false,
        endReached: false,
      };

    case 'GET_ON_THE_FRONT_LOADING':
      return {
        ...state,
        listConsultation: ['loading', 'loading', 'loading'],
        loading: true,
      };

    case 'GET_ON_THE_FRONT_SUCCESS':
      return {
        ...state,
        listConsultation: action.payload,
        endReached: true,
        loading: false,
      };

    case 'GET_LIST_LOADING': {
      return {
        ...state,
        listConsultation: ['loading', 'loading', 'loading'],
        loading: true,
      };
    }

    case 'GET_MORE_LIST_LOADING': {
      return {
        ...state,
        listConsultation: [...state.listConsultation, 'loading'],
        loading: true,
      };
    }

    case 'GET_NEWS_LIST_SUCCESS':
      return {
        ...state,
        listConsultation: action.payload.news,
        endReached: action.payload.endReached,
        loading: false
      };

    case 'GET_MORE_NEWS_LIST_SUCESS':
      return {
        ...state,
        listConsultation: [
          ...state.listConsultation.slice(0, state.listConsultation.length - 1),
          ...action.payload.news,
        ],
        endReached: action.payload.endReached,
        loading: false
      };

    case 'GET_EVENT_ALL_SUCCESS':
      return {
        ...state,
        listConsultation: action.payload.events,
        endReached: action.payload.endReached,
        loading: false
      };

    case 'GET_MORE_EVENT_ALL_SUCCESS':
      return {
        ...state,
        listConsultation: [
          ...state.listConsultation.slice(0, state.listConsultation.length - 1),
          ...action.payload.events,
        ],
        endReached: action.payload.endReached,
        loading: false
      };

    case 'GET_SURVEY_LIST_SUCCESS':
      return {
        ...state,
        listConsultation: action.payload.surveys,
        endReached: action.payload.endReached,
        loading: false
      };

    case 'GET_MORE_SURVEY_LIST_SUCCESS':
      return {
        ...state,
        listConsultation: [
          ...state.listConsultation.slice(0, state.listConsultation.length - 1),
          ...action.payload.surveys,
        ],
        endReached: action.payload.endReached,
        loading: false
      };


      
    case 'ADD_COMMENT_ON_CARD_PUBLICATION':
      const indexNewsPostComment = [...state.listConsultation].findIndex(i => {
        return (
          parseInt(i.id_publicpart) === parseInt(action.payload.id_publicpart)
        );
      });
      var newNewsList = [...state.listConsultation];
      if (indexNewsPostComment > -1) {
        newNewsList = produce([...state.listConsultation], draft => {
          draft[indexNewsPostComment].commentsnb =
            parseInt(draft[indexNewsPostComment].commentsnb, 10) + 1;
        });
      }
      return {
        ...state,
        listConsultation: [...newNewsList],
      };

    case 'REMOVE_COMMENT_OF_CARD_PUBLICATION':
      const indexDeleteComment = [...state.listConsultation].findIndex(i => {
        return (
          parseInt(i.id_publicpart) === parseInt(action.payload.id_publicpart)
        );
      });
      var newNewList2 = [...state.listConsultation];
      if (indexDeleteComment > -1) {
        newNewList2 = produce([...state.listConsultation], draft => {
          draft[indexDeleteComment].commentsnb =
            parseInt(draft[indexDeleteComment].commentsnb, 10) - 1;
        });
      }
      return {
        ...state,
        listConsultation: [...newNewList2],
      };

    case 'ADD_LIKE_ON_CARD_PUBLICATION':
      const indexNewsLike = [...state.listConsultation].findIndex(i => {
        return parseInt(i.id_publicpart) === parseInt(action.id_publicpart);
      });
      var newNewList3 = [...state.listConsultation];
      if (indexNewsLike > -1) {
        newNewList3 = produce([...state.listConsultation], draft => {
          draft[indexNewsLike].has_liked = true;
          draft[indexNewsLike].likes =
            parseInt(draft[indexNewsLike].likes, 10) + 1;
        });
      }
      return {
        ...state,
        listConsultation: [...newNewList3],
      };

    case 'REMOVE_LIKE_ON_CARD_PUBLICATION':
      const indexNewsLike2 = [...state.listConsultation].findIndex(i => {
        return parseInt(i.id_publicpart) === parseInt(action.id_publicpart);
      });
      var newNewList4 = [...state.listConsultation];
      if (indexNewsLike2 > -1) {
        newNewList4 = produce([...state.listConsultation], draft => {
          draft[indexNewsLike2].has_liked = false;
          draft[indexNewsLike2].likes =
            parseInt(draft[indexNewsLike2].likes, 10) - 1;
        });
      }
      return {
        ...state,
        listConsultation: [...newNewList4],
      };

    case 'ADD_PEOPLE_ON_CARD_PUBLICATION':
      const indexConsultation5 = [...state.listConsultation].findIndex(i => {
        return (
          parseInt(i.id_publicpart) === parseInt(action.payload.id_publicpart)
        );
      });
      var newConsultation5 = [...state.listConsultation];
      if (indexConsultation5 > -1) {
        newConsultation5 = produce([...state.listConsultation], draft => {
          draft[indexConsultation5].nbparticipate =
            parseInt(draft[indexConsultation5].nbparticipate, 10) + 1;
        });
      }
      return {
        ...state,
        listConsultation: [...newConsultation5],
      };

    case 'REMOVE_PEOPLE_ON_CARD_PUBLICATION':
      const indexConsultation6 = [...state.listConsultation].findIndex(i => {
        return (
          parseInt(i.id_publicpart) === parseInt(action.payload.id_publicpart)
        );
      });
      var newConsultation6 = [...state.listConsultation];
      if (indexConsultation6 > -1) {
        newConsultation6 = produce([...state.listConsultation], draft => {
          draft[indexConsultation6].nbparticipate =
            parseInt(draft[indexConsultation6].nbparticipate, 10) - 1;
        });
      }
      return {
        ...state,
        listConsultation: [...newConsultation6],
      };

    default:
      return state;
  }
};
