import React from 'react';
import {css} from 'emotion';
import {server_img_api} from '../../../../config/constants';

const ContentCard = props => {

  return (
    <div
      className={css`
        width: 100%;
      `}>

      <div
        className={css`
          text-align: left;
          padding-left: 30px;
          color: #29354a;
          margin-top: 5px;
          padding-right: 10px;
          max-height: 4.8em;
          line-height: 1.6em;
          word-wrap: break-word;
          overflow: hidden;
          margin-bottom: 15px;
        `}>
        {props.description}
      </div>


      {props.file_url && (
        <img
          className={css`
            width: 100%;
            max-height: 200px;
            object-fit: cover;
            margin-top: 20px;
          `}
          alt="Participation"
          src={server_img_api + props.file_url}
        />
      )}

    </div>
  );
};

export default ContentCard;
