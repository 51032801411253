import React, {useRef, useState, useEffect, useContext} from 'react';

import {Switch, Route} from 'react-router-dom';
import HomeRightMenu from './discovery/HomeRightMenu';
import GroupRightMenu from './discovery/GroupRightMenu';
import GroupDetailsRightMenu from './discovery/GroupDetailsRightMenu';
import NotificationRightMenu from './discovery/NotificationRightMenu';
import BpRightMenu from './discovery/BpRightMenu';

import MyContext from '../../utils/MyContext';
import InfoPopvox from './components/InfoPopvox';
import {css} from 'emotion';

const RightContent = () => {
  const bodyRefRightContent = useContext(MyContext);
  const childRefRightContent = useRef(null);
  const [cssBlock, setCssBlock] = useState('position:static');

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const scrollRightBlock = () => {
      if (bodyRefRightContent.current.offsetHeight >= childRefRightContent.current.offsetHeight) {
        if (window.scrollY >= (childRefRightContent.current.offsetHeight - windowHeight + 60) ) {
          if (childRefRightContent.current.offsetHeight <= (windowHeight) ) {
            if(cssBlock !== 'position:fixed; top:60px;') {
                setCssBlock('position:fixed; top:60px;');
            }
          } else {
            if(cssBlock !== 'position:fixed; bottom:0px;') {
                setCssBlock('position:fixed; bottom:0px;');
            }
          }
        } else {
          if(cssBlock !== 'position:static;') {
              setCssBlock('position:static;');
          }
        }
      } else {
        if(cssBlock !== 'position:static;') {
            setCssBlock('position:static;');
        }
      }
    };

    scrollRightBlock();
    window.addEventListener('scroll', scrollRightBlock);
    window.addEventListener('resize', scrollRightBlock);

    return () => {
      window.removeEventListener('scroll', scrollRightBlock);
      window.removeEventListener('resize', scrollRightBlock);
    };
  }, []);

  return (
    <div className={css`
      ${cssBlock}
      `}>
    <div
      ref={childRefRightContent}
      className={css`
        height: inherit;
        max-width: 330px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        flex: 1;
      `}>

      <Switch>
        <Route path="/communautes" component={GroupRightMenu} />
        <Route path="/communaute" component={GroupDetailsRightMenu} />
        <Route path="/notification" component={NotificationRightMenu} />
        <Route path="/budget-participatif" component={BpRightMenu} />
        <Route component={HomeRightMenu} />
      </Switch>

      <InfoPopvox />
    </div>

    </div>
  );
};

export default RightContent;
