import React from 'react';
import {css} from 'emotion';
import IconEasyPicture from '../../../common/icons/IconEasyPicture';

const LabelFile = props => {

  if (!props.isDragActive)
    return (
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          border: 3px dashed #b3b3b3;
          width: 100%;
          border-radius: 10px;
          height: 200px;
        `}>
        <label
          htmlFor="upload-photo"
          className={css`
            cursor: pointer;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `}>
          <IconEasyPicture size={50} color={'grey'} />
          <div
            className={css`
              color: #333;
              margin-top: 10px;
            `}>
            Choisir un fichier / photo
          </div>
        </label>

      </div>
    );
  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        border: 3px dashed #ebebeb;
        width: 100%;
        border-radius: 10px;
        height: 300px;
      `}>
      <label
        htmlFor="upload-photo"
        className={css`
          cursor: pointer;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}>
        <IconEasyPicture size={50} color={'#ebebeb'} />
        <div
          className={css`
            color: #ebebeb;
            margin-top: 10px;
          `}>
          Relâchez votre photo ici
        </div>
      </label>
    </div>
  );
};

export default LabelFile;
