import React from 'react';
import {css} from 'emotion';
import {
  server_img_logos,
  server_img_public,
} from '../../../../config/constants';
import reactStringReplace from 'react-string-replace';
import {timeSince} from '../../../../utils/date';

const FullAdminResponse = props => {
  let replacedText = props.text;

  replacedText = reactStringReplace(
    replacedText,
    /(https?:\/\/\S+)/g,
    (match, i) => (
      <a rel="noopener noreferrer" target="_blank" key={match + i} href={match}>
        {match}
      </a>
    ),
  );


  replacedText = reactStringReplace(
    replacedText,
    /(www\S+)/g,
    (match, i) => (
      <a rel="noopener noreferrer" target="_blank" key={match + i} href={'http://'+match}>
        {match}
      </a>
    ),
  );




  return (
    <div
      className={css`
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      `}>
      <div
        className={css`
          background: #f5f8fa;
          border: 1px solid rgba(24, 62, 100, 0.05);
          border-radius: 5px;
          box-sizing: border-box;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
        `}>
        <div
          className={css`
            align-items: center;
            display: flex;

          `}>
          {props.type_user === 9 ? (
            <img
              className={css`
                width: 50px;
              `}
              src={require('../../../../image/logo/popvox.png')}
              alt="popvox"
            />
          ) : (
            <img
              alt="logo"
              className={css`
                height: 30px;
              `}
              src={server_img_logos + props.logo_city}
            />
          )}

          <div style={{flex: 1}}>
            <div
              style={{
                marginLeft: 20,
                fontSize: 13,
                fontWeight: 'bold',

              }}>
              {props.type_user === 9 ? 'Équipe popvox' : props.nom_comm}
            </div>
            <div
              style={{
                marginLeft: 20,
                fontSize: 11,
                color: 'grey',
              }}>
              {'Il y a ' + timeSince(props.date_creation)}
            </div>
          </div>
        </div>

        <div
          className={css`
            text-align: left;
            margin-top: 10px;
            color: #394861;
            line-height: 1.1;
            flex: 1;
            white-space: pre-wrap;
          `}>
          {replacedText}
        </div>

        {props.file_url !== null && (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <img
              style={{
                width: 200,
                marginTop: 20,
                resizeMode: 'contain',
              }}
              src={server_img_public + props.file_url}
              alt=""
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FullAdminResponse;
