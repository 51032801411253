import React from 'react';
import {css} from 'emotion';

import IconEasyLike from '../../../../../common/icons/IconEasyLike';


const LikeCounter = (props) =>{
  return(
    <>
    <div
      className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
      `}>



      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}>
        <div
          className={css`
            cursor: pointer;
          `}
          onClick={props.toggleLike}>
          <IconEasyLike
            color={props.has_liked ? 'rgb(242,101,35)' : '#29354a'}
            size={30}
          />
        </div>
        <div
          className={css`
            color: ${props.has_liked ? 'rgb(242,101,35)' : '#29354a'};
          `}>
          {props.likes}
        </div>
      </div>

      


    </div>
    </>
  )
}

export default LikeCounter;
