import React from "react";
import { css } from "emotion";
import ThematicIconParticipation from "../../../participations/ThematicIconParticipation";
import { participationThemeFr } from "../../../../config/thematics";

const ItemTheme = (props) => {
  return (
    <div
      onClick={() => props.onChooseTheme(props.id)}
      className={css`
        @media (max-width: 500px) {
          width: 50%;
        }
        width: 33.3%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
      `}
      style={props.style}>
      <div
        className={css`
          box-sizing: border-box;
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          border-radius: 50%;
          border: 2px solid
            ${props.theme === props.id ? "rgb(245, 93, 66);" : "#e8e8e8"};
        `}>
        <ThematicIconParticipation id={props.id} />
      </div>

      <div
        className={css`
          font-size: 12px;
          margin-top: 10px;
          text-align: center;
          font-size: bold;
          line-height: 13px;
          width: 120px;
          white-space: initial;
          word-wrap: break-word;
        `}>
        {participationThemeFr[props.id]}
      </div>

    </div>
  );
};

export default ItemTheme;
