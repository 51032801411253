import React from 'react';
import {css} from 'emotion';
import Comment from '../../common/comment/comment/Comment';

const ListCommentParticipation = props => {
  return (
    <div
      className={css`
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        width: 100%;
        margin-top: 0px;
      `}>

        {props.comments.map(item => {

          return (
            <Comment
              onResponse={props.onResponse}
              onReport={props.onReport}
              onDelete={props.onDelete}
              key={item.id_comment}
              item={item}
            />
          );
        })}

    </div>
  );
};

export default ListCommentParticipation;
