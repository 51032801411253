import React, {useEffect, useRef} from 'react';
import {css} from 'emotion';
import Portal from './components/Portal';
import HeaderModal from './components/HeaderModal';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import ButtonEX from '../button/ButtonEX';
const MessageInfo = props => {

  const onChooseFalse = () => {
    props.close();
  };

  const targetRef = useRef(null);

  useEffect(() => {
    targetRef.current && disableBodyScroll(targetRef.current);
    return () => clearAllBodyScrollLocks();
  }, []);

  const clickModal = () => {
    props.close();
  };

  const clickChildren = e => {
    e.stopPropagation();
  };

  return (

    <Portal id='Message'>
      <div
        onClick={clickModal}
        ref={targetRef}
        className={css`
          width: 100%;
          height: 100%;
          position: fixed;
          z-index: 2000;
          top: 0px;
          left: 0px;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          justify-content: center;
          align-items: center;
        `}>
        <div
          onClick={clickChildren}
          className={css`
            background: ${props.color ? props.color : 'white'};
            height: ${props.height};
            box-sizing: border-box;
            border-radius: 5px;
            width: 100%;
            max-width: 850px;
            overflow: hidden;
          `}>
          <div></div>
          {props.headerCustom ? (
            props.headerCustom
          ) : (
            <HeaderModal
              onBack={props.close}
              text={props.textHeader ? props.textHeader : ''}
            />
          )}
          <div
            className={css`
              height: calc(100% - 60px);
              box-sizing: border-box;
              padding-top: 20px;
              padding-bottom: 20px;
              display: flex;
              flex-direction: column;
              align-text: center;
              justify-content: center;
              padding-left: 20px;
              padding-right: 20px;
            `}>
            <div
              className={css`
                text-align: center;
                color: rgb(41, 53, 74);
                font-size: 14px;
              `}>
              {props.text}
            </div>

            <div
              className={css`
                display: flex;
                flex-direction: row;
                margin-top: 30px;
                flex: 1;
              `}>

              <div
                className={css`
                  flex: 1;
                `}></div>


                <ButtonEX onPress={onChooseFalse} width={'75'} text="Fermer" />

            </div>


          </div>
        </div>
      </div>
    </Portal>
  );
};

export default MessageInfo;
