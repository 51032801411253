import React, { useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import {
  getCitiesByName
} from "../../../redux/actions/cityActions";

import { server_img_logos } from "../../../config/constants";
import { isMobile } from "../../../config/constants";
import Media from "react-media";

import history from "../../../utils/history";

import IconEasySearch from "../../common/icons/IconEasySearch";
import IconEasyLocation from "../../common/icons/IconEasyLocation";
import IconEasyLike from "../../common/icons/IconEasyLike";
import IconEasyLocationSuggest from "../../common/icons/IconEasyLocationSuggest";

var timeout = null;
var txttosearch = "";

const SearchBar = (props) => {
  const dispatch = useDispatch();

  const user_coord = useSelector((state) => state.user.coord_user);
  const popkey = useSelector((state) => state.user.popkey);
  const listCity = useSelector((state) => state.city.listCity);
  const listRef = useRef(null);

  const onChoose = (item) => {
    history.push({
      pathname: "/?code_insee=" + item.insee_com
    })
    history.go(0);
  };

  const onChangeTxt = (e) => {
    txttosearch = e.target.value;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      if (typeof txttosearch === "string") {
        dispatch(
          getCitiesByName(
            txttosearch,
            user_coord ? user_coord.longitude : null,
            user_coord ? user_coord.latitude : null,
            popkey
          )
        );
      } else {
        dispatch({ type: "CLEAR_LIST_CITY" });
      }
    }, 500);
  };

  const onFocusSearchBar = (e) => {
    txttosearch = e.target.value;
    if (typeof txttosearch === "string") {
      dispatch(
        getCitiesByName(
          txttosearch,
          user_coord ? user_coord.longitude : null,
          user_coord ? user_coord.latitude : null,
          popkey
        )
      );
    }
  };

  const onBlur = () => {
    dispatch({ type: "CLEAR_LIST_CITY" });
  };

  return (
    <div
      className={css`
        padding-top: 10px;
        padding-bottom: 10px;
        box-sizing: border-box;
        height: 60px;
      `}>
      <div
        className={css`
          background: #f5f9fa;
          border: 1px solid rgba(24, 62, 100, 0.05);
          box-sizing: border-box;
          border-radius: 10px;
          max-width: 400px;
          overflow-x: hidden;
          box-shadow: ${listCity.length > 0
            ? "3px 3px 14px 0px rgba(41, 53, 74,0.3)"
            : "none"};
        `}>
        <div
          className={css`
            display: flex;
            align-items: center;
            margin-left: 10px;
            padding-top: 3px;
          `}>
          <IconEasySearch size={20} color={"#333"} />

          <form
            className={css`
              margin-left: 12px;
              height: 30px;
              width: 100%;
              padding-bottom: 2px;
            `}
            autoComplete="new-city">
            <input
              type="text"
              autoComplete="off"
              className={css`
                height: 30px;
                outline: none;
                width: 100%;
                background: transparent;
                border: none;
                line-height: 30px;
                font-size: 15px;
                &::placeholder {
                  color: rgb(101, 119, 134);
                }
              `}
              placeholder="Changer de lieu"
              onFocus={onFocusSearchBar}
              onChange={onChangeTxt}
              onBlur={onBlur}
            />
          </form>
        </div>

        <div
          className={css`
            width: 100%;
          `}
          ref={listRef}>
          {listCity.map((items) => {
            let title = items.title;

            return items.data.map((item, key) => {
              return (
                <div
                  onMouseDown={() => onChoose(item)}
                  onTouchEnd={() => onChoose(item)}
                  key={key}
                  className={css`
                    &:hover {
                      background: white;
                    }
                  `}
                  style={{ paddingLeft: 20, paddingRight: 20 }}>
                  <div
                    className={css`
                      cursor: pointer;
                      display: flex;
                      flex-direction: row;
                      padding-top: 12px;
                      padding-bottom: 12px;
                      background: #f5f8fa;
                      color: #29354a;
                      align-items: flex-start;
                      &:hover {
                        background-color: white;
                        color: rgb(245, 93, 66);
                      }
                    `}>

                    <Media query={`(min-width: ${isMobile})`}>
                      {(matches) =>
                        matches && (
                          <div style={{ width: 50 }}>
                            <img
                              src={server_img_logos + item.urlimg}
                              style={{ height: 20, maxWidth: "100%" }}
                              alt=""
                            />
                          </div>
                        )
                      }
                    </Media>

                    <div
                      style={{ textAlign: "left", flex: 1, lineHeight: 1.3 }}>
                      {item.nom_comm}{" "}
                      <div style={{ fontSize: 12, color: "#999999" }}>
                        {item.cp}
                      </div>
                    </div>


                    <div
                      style={{
                        fontStyle: "italic",
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}>
                      {title === 3 ? (
                        <IconEasyLocation color={"#999999"} size={16} />
                      ) : title === 2 ? (
                        <IconEasyLike color={"#999999"} size={13} />
                      ) : (
                        title === 1 && (
                          <IconEasyLocationSuggest
                            color={"#999999"}
                            size={16}
                          />
                        )
                      )}
                    </div>


                  </div>
                </div>
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
