import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyCross = (props) =>{
    return(
      <IconPattern size={props.size}>
          <svg
            onClick={props.onPress}
            className={css`
              cursor:${props.pointer?'pointer':'none'};
              display: inline-block;
              position: absolute;
              display:flex;
              top: 0;
              left: 0`}
            version="1.1"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M50.002,24.391c-14.146,0-25.611,11.466-25.611,25.609c0,14.143,11.466,25.609,25.611,25.609   c14.143,0,25.607-11.466,25.607-25.609C75.609,35.857,64.146,24.391,50.002,24.391z M60.928,56.023   c1.363,1.371,1.356,3.588-0.014,4.951c-0.684,0.678-1.576,1.018-2.469,1.018c-0.898,0-1.797-0.344-2.482-1.031L50,54.965   l-5.963,5.996c-0.685,0.687-1.584,1.031-2.482,1.031c-0.893,0-1.785-0.34-2.469-1.018c-1.369-1.363-1.377-3.58-0.014-4.951   L45.062,50l-5.99-6.023c-1.363-1.371-1.355-3.588,0.014-4.951c1.369-1.359,3.584-1.357,4.951,0.014L50,45.035l5.963-5.996   c1.365-1.371,3.578-1.373,4.951-0.014c1.369,1.363,1.377,3.58,0.014,4.951L54.937,50L60.928,56.023z" fill="#656565"/>
              </g>
              </svg>
        </IconPattern>
    )
}

export default IconEasyCross;
