import React, {useState} from 'react';
import {css} from 'emotion';

const InputTextArea = props => {
  const [focus, setFocus] = useState(false);
  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = e => {
    setFocus(false);
    props.onBlur && props.onBlur(e);
  };

  return (
    <>
      <div
        className={css`
          margin-bottom: 10px;
          font-weight: 600;
          ${props.style}
        `}>
        {props.title}{' '}
        {props.facultatif ? (
          <span
            className={css`
              font-size: 12px;
            `}>
            (facultatif)
          </span>
        ) :
        <span
          className={css`
            font-size: 12px;
          `}>
           *
        </span>
      }
      </div>
      <div
        className={css`
          width: 100%;
          background-color: ${focus ? 'white' : '#F5F8FA'};
          box-shadow: ${focus
            ? 'inset 0px 0px 0px 2px rgb(242,101,35)'
            : 'inset 0px 0px 0px 1px #ededed'};
          transition: box-shadow 0.1s;
          border-radius: 10px;
          padding: 20px;
          box-sizing: border-box;
        `}>
        <textarea
          onFocus={onFocus}
          onBlur={onBlur}
          rows={8}
          className={css`
            width: 100%;
            resize: none;
            border: none;
            background: transparent;
            outline: none;
            font-weigth: 400;
            font-size: 14px;
          `}
          onChange={props.onChange}
          value={props.value}
        />
      </div>
    </>
  );
};

export default InputTextArea;
