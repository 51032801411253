import React from 'react';
import {css} from 'emotion';

const CardLoader = () => {
  return (
    <div
      className={css`
        position: relative;
        display: inline-flex;
        flex-direction: column;
        width: 400px;
        border-radius: 10px;
        box-sizing: border-box;
        margin-left: 1em;
        margin-right: 1em;
        background-color: white;
        border: 1px solid rgba(24, 62, 100, 0.1);
      `}>
      <div
        className={css`
          padding-top: 20px;
        `}>
        <div
          className={css`
            height: 50px;
            white-space: normal;
            text-align: left;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
          `}>
          <div
            className={css`
              background-color: #f7f7f7;
              border-radius: 5px;
              height: 20px;
              width: 100%;
            `}></div>
          <div
            className={css`
              background-color: #e8e8e8;
              border-radius: 5px;
              height: 10px;
              margin-top: 10px;
              width: 30%;
            `}></div>
        </div>
        <div
          className={css`
            height: 155px;
            margin-top: 10px;
            width: 100%;
            white-space: normal;
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          <div
            className={css`
              height: 20px;
              background: #f7f7f7;
              width: 100%;
              margin-top: 10px;
              border-radius: 5px;
            `}></div>
          <div
            className={css`
              height: 20px;
              background: #f7f7f7;
              width: 100%;
              margin-top: 10px;
              border-radius: 5px;
            `}></div>
          <div
            className={css`
              height: 20px;
              background: #f7f7f7;
              width: 100%;
              margin-top: 10px;
              border-radius: 5px;
            `}></div>
          <div
            className={css`
              height: 20px;
              background: #f7f7f7;
              width: 100%;
              margin-top: 10px;
              border-radius: 5px;
            `}></div>
        </div>
      </div>
      <div
        className={css`
          height: 50px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding-left: 20px;
          padding-right: 20px;
        `}>
        <div
          className={css`
            height: 20px;
            background: #f7f7f7;
            width: 50px;
            margin-left: 10px;
            border-radius: 5px;
          `}></div>
        <div
          className={css`
            height: 20px;
            background: #f7f7f7;
            width: 50px;
            margin-left: 10px;
            border-radius: 5px;
          `}></div>
        <div
          className={css`
            flex: 1;
            display: flex;
            justify-content: flex-end;
          `}>
          <div
            className={css`
              height: 20px;
              background: #f7f7f7;
              width: 30px;
              margin-left: 10px;
              border-radius: 5px;
            `}></div>
        </div>
      </div>
    </div>
  );
};

export default CardLoader;
