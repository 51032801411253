import UserAPI from "../../api/UserAPI";


export function getUserInfo(popkey) {
  return function (dispatch) {
    return UserAPI.getUserInfo(popkey).then((data) => {
      dispatch({ type: "GET_USER_INFO", payload: data.data });
    });
  };
}
export function setPopkey(popkey) {
  return function (dispatch) {
      dispatch({ type: "SET_POPKEY", payload: popkey });
  };
}

export function goConnect(email, password) {
  return function (dispatch) {
    dispatch({ type: "LOG_IN_LOADING" });
    return UserAPI.logIn(email, password).then((data) => {
      dispatch({ type: "LOG_IN", payload: data.data });
    });
  };
}

export function forgotPwd(email) {
  return function (dispatch) {
    dispatch({ type: "RESET_NEW_PWD_SENT" });
    return UserAPI.forgotPwd(email).then((data) => {
      dispatch({ type: "NEW_PWD_SENT", error: data.data.error });
    });
  };
}

export function deleteUser(popkey) {
  localStorage.setItem("popvox@popkey", "");
  return function (dispatch) {
    return UserAPI.deleteUser(popkey).then(() => {
      dispatch({ type: "DELETE_USER_SUCCESS" });
    });
  };
}

export function createAccount(email, firstName, lastName, pwd, pseudo, avatar) {
  return function (dispatch) {
    return UserAPI.registerUser(email, firstName, lastName, pwd, pseudo, avatar)
      .then((data) => {

        var info = null;
        if (data.data.info) {
          info = data.data.info;
        }

        localStorage.setItem('popvox@popkey', (data.data && data.data.info && data.data.info.auth_token) ? data.data.info.auth_token : null);

        dispatch({
          type: "REGISTER_USER",
          payload: {
            signupError: data.data.error,
            info: info,
          },
        });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateInfo(popkey, firstName, lastName, pseudo, avatar) {
  return function (dispatch) {
    return UserAPI.modifyUserInfos(popkey, firstName, lastName, pseudo, avatar)
      .then((data) => {
        if (data.data.error === 0) {
          dispatch({
            type: "REGISTER_USER",
            payload: {
              signupError: data.data.error,
              info: data.data.info,
            },
          });
        } else {
          dispatch({
            type: "REGISTER_USER_ERROR",
            payload: {
              signupError: data.data.error,
            },
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateAvatar(popkey, avatar) {
  return function (dispatch) {
    return UserAPI.modifyAvatar(popkey, avatar).then((data) => {
      if (data.data.error === 0) {
        dispatch({
          type: "REGISTER_USER",
          payload: {
            signupError: 0,
            info: data.data.info,
          },
        });
      } else {
        dispatch({
          type: "REGISTER_USER_ERROR",
          payload: { signupError: data.data.error },
        });
      }
    });
  };
}

export function cleanPwd() {
  return function (dispatch) {
    dispatch({
      type: "RESET_PASSWORD_SUCCESS",
    });
  };
}

export function updatePassword(popkey, pwd, currentPassword) {
  return function (dispatch) {
    dispatch({
      type: "RESET_PASSWORD_SUCCESS",
    });
    return UserAPI.modifyPwd(popkey, pwd, currentPassword).then((data) => {
      if (data.data.error === 0) {
        dispatch({
          type: "UPDATE_PASSWORD_SUCCESS",
          payload: {
            signupError: 0,
          },
        });
      } else {
        dispatch({
          type: "REGISTER_USER_ERROR",
          payload: {
            signupError: data.data.error,
          },
        });
      }
    });
  };
}

export function add_user_community(community) {
  return function (dispatch) {
    dispatch({
      type: "ADD_USER_COMMUNITY",
      community: community,
    });
  };
}

export function remove_user_community(community) {
  return function (dispatch) {
    dispatch({
      type: "REMOVE_USER_COMMUNITY",
      community: community,
    });
  };
}