import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyPicture = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
          version="1.1" viewBox="0 0 32 32">
      <path d="M3.311,20.728c-0.191,-0.187 -0.298,-0.451 -0.311,-0.723l0,-13.998c0.01,-1.557 1.35,-2.965 2.962,-2.995c6.688,-0.042 13.377,-0.042 20.066,0c1.556,0.029 2.951,1.363 2.961,2.995l0,12.957c-0.004,0.085 -0.002,0.106 -0.018,0.19c-0.152,0.788 -1.445,1.092 -1.87,0.268c-0.072,-0.14 -0.103,-0.297 -0.111,-0.458c0,-4.326 0.08,-8.653 0,-12.978c-0.016,-0.509 -0.46,-0.959 -0.975,-0.975c-6.679,-0.124 -13.362,-0.124 -20.041,0c-0.515,0.016 -0.971,0.465 -0.975,1.003l0,11.577l4.291,-4.291c0.31,-0.287 0.412,-0.292 0.744,-0.292c0.39,0.029 0.469,0.106 0.67,0.292l5.291,5.291l3.291,-3.292c0.311,-0.29 0.449,-0.312 0.809,-0.287c0.354,0.048 0.422,0.117 0.605,0.287l7.996,7.997c0.336,0.359 0.315,0.563 0.293,0.761l0,1.948c-0.009,1.55 -1.326,2.965 -2.961,2.996c-6.678,0.041 -13.355,0 -20.032,0c-1.566,-0.01 -2.986,-1.363 -2.996,-2.996l0,-2.002c0.004,-0.084 0.002,-0.106 0.018,-0.189c0.153,-0.792 1.442,-1.099 1.87,-0.269c0.072,0.14 0.104,0.297 0.111,0.458c0,0.675 -0.012,1.349 0,2.024c0.016,0.508 0.461,0.958 0.975,0.974c6.679,0.125 13.362,0.125 20.041,0c0.516,-0.016 0.972,-0.464 0.975,-1.002l0,-1.582l-6.997,-6.997l-2.585,2.585l1.293,1.292c0.166,0.179 0.191,0.247 0.236,0.377c0.21,0.599 -0.3,1.354 -0.981,1.329c-0.249,-0.01 -0.485,-0.121 -0.669,-0.292l-7.29,-7.29l-5.291,5.29c0,0 -0.047,0.032 -0.126,0.074c-0.333,0.233 -0.803,0.288 -1.16,0.034c-0.004,-0.003 -0.009,-0.006 -0.013,-0.009l-0.001,-0.001c-0.031,-0.023 -0.06,-0.048 -0.088,-0.074l-0.001,-0.001l-0.001,-0.001l-0.003,-0.003l-0.002,-0.002Zm18.72,-13.718c1.688,0.032 3.174,1.681 2.931,3.417c-0.198,1.415 -1.534,2.581 -2.97,2.581c-1.728,0 -3.271,-1.744 -2.952,-3.529c0.242,-1.355 1.503,-2.442 2.914,-2.469c0.038,0 0.038,0 0.077,0Zm-0.052,1.999c-0.765,0.015 -1.311,1.103 -0.685,1.715c0.629,0.614 2.186,-0.103 1.572,-1.201c-0.175,-0.312 -0.523,-0.516 -0.887,-0.514Z" fill={props.color}/>
        </svg>
    </IconPattern>
    )
}

export default IconEasyPicture;
