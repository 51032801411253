import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyProfile = (props) =>{
    return(
      <IconPattern size={props.size}>
            <svg
              className={css`
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                  `}
              data-name="Layer 1"
              id="Layer_1"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg">
              <title/>
              <path d="M20.0511,10.9131A4.9132,4.9132,0,1,1,15.1379,6,4.9233,4.9233,0,0,1,20.0511,10.9131Z" fill={props.color} />
              <path d="M24.2759,25.1693A.8279.8279,0,0,1,23.4451,26H6.8307A.8278.8278,0,0,1,6,25.1693a9.1226,9.1226,0,0,1,5.5991-8.4236.8687.8687,0,0,1,.4319-.0581,5.291,5.291,0,0,0,1.271.6147c.0416.0166.0914.0333.133.05a4.9987,4.9987,0,0,0,3.4059,0c.05-.0166.0914-.0333.1412-.05a5.3862,5.3862,0,0,0,1.2627-.6147.8691.8691,0,0,1,.432.0581A9.1227,9.1227,0,0,1,24.2759,25.1693Z" fill={props.color} />
              </svg>
        </IconPattern>
    )
}

export default IconEasyProfile;
