import React, {useState, useEffect, useRef} from 'react';
import {css} from 'emotion';
import IconEasyChat from '../../icons/IconEasyChat';

const InputActionComment = props => {
  const inputRef = useRef(null);
  const [focus, setFocus] = useState(false);
  const [text, setText] = useState('');

  const onChangeTxt = e => {
    setText(e.target.value);
  };

  // const _handleKeyDown = (e) => {
  //   if (e.key === 'Enter') {
  //     props.onPress(text);
  //     setText('');
  //   }
  // }

  const onSendComment = () => {
    props.onPress(text);
    setText('');
  };

  useEffect(() => {
    props.userResponse && inputRef.current.focus();
  }, [props.userResponse]);

  return (
    <div
      className={css`
        border: ${focus ? '2px solid #f55d42' : '1px solid rgba(24,62,100,0.05)'};
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        border-radius: 10px;
        background-color: ${focus ? 'white' : '#F5F8FA'};
        flex: 1;
        align-items: center;
        box-sizing: border-box;
        height: 75px;
        padding: 5px 20px 10px 10px;
      `}>
      <textarea
        ref={inputRef}
        value={text}
        onChange={onChangeTxt}
        //onKeyDown={_handleKeyDown}
        onBlur={() => {
          setFocus(false);
        }}
        onFocus={() => {
          setFocus(true);
        }}
        placeholder="Votre commentaire..."
        className={css`
          flex: 1;
          border: none;
          outline: none;
          background: transparent;
          color: #29354a;
          resize: none;
          font-size: 15px;
          height: 100%;
          box-sizing: border-box;
          &::placeholder {
            font-family: Poppins, sans-serif;
            color: rgb(101, 119, 134);
          }
        `}
      />
      <div
        className={css`
          cursor: pointer;
        `}
        onClick={onSendComment}>
        <IconEasyChat size={25} color={focus ? '#f55d42' : '#29354a'} />
      </div>
    </div>
  );
};

export default InputActionComment;
