import React, {useState, useEffect} from 'react';

const BlockDiagram = props => {

    const [total, setTotal] = useState(0);
    const audDixiexme = function(nombre) {
      return Math.round(10 * nombre) / 10;
    };

    const getWidth = function(nb, total) {
      if(total !== 0) {
          return audDixiexme((parseInt(nb) / total) * 100);
      }
      return 0;
    }

    useEffect(() => {
      let tmp = 0;
      props.item &&
        props.item.forEach(i => {
          tmp = tmp + parseInt(i.nb, 10);
        });
        setTotal(tmp);
      }, []);

    return (
      props.item &&
      props.item.map((i, nb) => {

        //console.log(i);

        let thewidth = getWidth(parseInt(i.nb, 10), total);



        return (
          <div key={'bd' + nb} style={{marginTop: 5}}>
            <span style={{marginTop: 10}}>{i.entitled}</span>
            <div
              style={{
                flexDirection: 'row',
                flex: 1,
                marginTop: 5,
                display: 'flex',
              }}>
              <div
                style={{
                  height: 25,
                  width: `${thewidth}%`,
                  backgroundColor: '#afd68c',
                  minWidth: 2,
                  borderTopRightRadius: 3,
                  borderBottomRightRadius: 3,
                  display: 'flex',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                }}>
                <span style={{
                  marginLeft: 5,
                  fontSize: 12,
                  color: 'white',
                  fontWeight: 'bold'
                }}>
                  {thewidth >= 40 &&
                    `${thewidth}%`}
                </span>
              </div>

              {thewidth < 40 && (
                <div
                  style={{
                    marginLeft: 10,
                    fontSize: 12,
                    fontWeight: 'bold',
                    paddingTop: 5,
                    color: '#517d29'
                  }}>
                  {`${thewidth}%`}
                </div>
              )}

            </div>
          </div>
        );
      })
    );


};

export default BlockDiagram;
