import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { server_url } from "../../config/constants";

import { getCityInfo } from "../../redux/actions/cityActions";
import {setPopkey} from "../../redux/actions/userActions";

import axios from "axios";
import { server_api_url } from "../../config/constants";

import CityAPI from "../../api/CityAPI";
import QueryString from "query-string";
import AppStart from "./AppStart";
import { css } from "emotion";
import history from "../../utils/history";
import PlainButton from "../common/forms/buttons/PlainButton";

const options = {
  enableHighAccuracy: false,
  maximumAge: 120000,
  timeout: 10000,
};

const AppLoader = (props) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("Chargement en cours...");

  const infoCity = useSelector((state) => state.city.infoCity);

  useEffect(() => {
    document.title = "popvox";

    //RECUPERATION DES VILLES SUGGERRES PAR L'UTILISATEUR
    dispatch({
      type: "SUGGEST_APP_CITY_BY_USER",
      payload: JSON.parse(localStorage.getItem("popvox@inseeSuggest") || "[]"),
    });

    //RECUPERATION POPKEY
    var data_popkey = localStorage.getItem("popvox@popkey");

    var popkey =
      data_popkey !== null && data_popkey !== 'null' && data_popkey.length > 0 ? data_popkey : null;

    if (popkey !== null) {
      dispatch(setPopkey(popkey));
      checkRoute(popkey);
    } else {
      checkRoute(null);
    }
    
  }, []);

  const cancelLocation = (myVar) => {
    if (myVar) {
      clearTimeout(myVar);
    }
    window.location.href = server_url;
  };

  const successGeoloc = (pos) => {
    var crd = pos.coords;
    dispatch({ type: "SET_COORD_USER", payload: crd });

    setMessage("Chargement en cours...");

    axios
      .get(server_api_url + "/city/getinseebycoords", {
        timeout: 1000,
        params: {
          longitude: crd.longitude,
          latitude: crd.latitude,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        if (data && data.insee_com) {
          history.push({
            pathname: "../?code_insee=" + data.insee_com,
            state: { from: "menu" },
          })
          history.go(0);
        } else {
          history.push({
            pathname: "../?code_insee=99999",
            state: { from: "menu" },
          });
          history.go(0);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const error = (err) => {
    console.warn(`ERREUR (${err.code}): ${err.message}`);
    console.log("Geolocation not accepted");

    setMessage(
      "Localisation impossible, veuillez chercher manuellement un nom de commune !"
    );

    let myVar = setTimeout(() => {
      cancelLocation(myVar);
    }, 3000);
  };

  const checkRoute = (popkey) => {

    let locationsearch = QueryString.parse(props.location.search);
    let localstorageinsee = localStorage.getItem("popvox@insee_com");

  

    /* WHAT IS ROUTE */
    if (
      [
        "/",
        "/communautes",
        "/notification",
        "/budget-participatif",
        "/signinstep",
        "/connexion",
        "/participez",
        "/confirm_email",
      ].indexOf(props.location.pathname) >= 0
    ) {

      if (locationsearch && locationsearch.code_insee) {
        localStorage.setItem("popvox@insee_com", locationsearch.code_insee);
        dispatch(getCityInfo(popkey, locationsearch.code_insee));
      } else if (localstorageinsee) {
        /* LOOK LOCAL STORAGE */
        dispatch(getCityInfo(popkey, localstorageinsee));
      } else {
        /* GET DEMOVILLE */
        dispatch(getCityInfo(popkey, "99999"));
      }
    } else if (
      ["/alerte", "/actu", "/sondage", "/evenement"].indexOf(
        props.location.pathname
      ) >= 0
    ) {
      if (locationsearch && locationsearch.id_publicpart) {
        CityAPI.getInseecomByPublicpart(locationsearch.id_publicpart).then(
          (data) => {
            if (
              data &&
              data.data &&
              data.data.insee_com &&
              data.data.insee_com.code_insee
            ) {
              dispatch(getCityInfo(popkey, data.data.insee_com.code_insee));
            } else {
              dispatch(getCityInfo(popkey, "99999"));
            }
          }
        );
      } else {
        dispatch(getCityInfo(popkey, "99999"));
      }
    } else if (["/participation"].indexOf(props.location.pathname) >= 0) {
      if (locationsearch && locationsearch.id_citizenpart) {
        CityAPI.getInseecomByParticipation(locationsearch.id_citizenpart).then(
          (data) => {
            if (
              data &&
              data.data &&
              data.data.insee_com &&
              data.data.insee_com.code_insee
            ) {
              dispatch(getCityInfo(popkey, data.data.insee_com.code_insee));
            } else {
              dispatch(getCityInfo(popkey, "99999"));
            }
          }
        );
      } else {
        dispatch(getCityInfo(popkey, "99999"));
      }
    } else if (
      ["/budget-participatif/details"].indexOf(props.location.pathname) >= 0
    ) {
      if (locationsearch && locationsearch.id_budget_part_project) {
        CityAPI.getInseecomByBpProject(
          locationsearch.id_budget_part_project
        ).then((data) => {
          if (
            data &&
            data.data &&
            data.data.insee_com &&
            data.data.insee_com.code_insee
          ) {
            dispatch(getCityInfo(popkey, data.data.insee_com.code_insee));
          } else {
            dispatch(getCityInfo(popkey, "99999"));
          }
        });
      } else {
        dispatch(getCityInfo(popkey, "99999"));
      }
    } else if (["/geolocation"].indexOf(props.location.pathname) >= 0) {
      navigator.geolocation.getCurrentPosition(successGeoloc, error, options);
      setMessage("Localisation en cours...");
    } else {

      history.push({ pathname: "/", state: { from: "menu" } });

      if (locationsearch && locationsearch.code_insee) {
        localStorage.setItem("popvox@insee_com", locationsearch.code_insee);
        dispatch(getCityInfo(popkey, locationsearch.code_insee));
      } else if (localstorageinsee) {
        /* LOOK LOCAL STORAGE */
        dispatch(getCityInfo(popkey, localstorageinsee));
      } else {
        /* GET DEMOVILLE */
        dispatch(getCityInfo(popkey, "99999"));
      }

    }

    
  };

  if (infoCity == null) {
    return (
      <div
        className={css`
          margin-top: 200px;
          text-align: center;
        `}>
        <img
          className={css`
            width: 200px;
          `}
          src={require("../../image/logo/popvox.png")}
          alt="popvox"
        />
        <br />

        <img
          className={css`
            margin-top: 10px;
            width: 70px;
            height: 70px;
          `}
          src={require("../../image/loading.gif")}
          alt="loading"
        />

        <div
          className={css`
            margin-top: 10px;
            color: grey;
          `}>
          {message}
        </div>

        {props.location.pathname === "/geolocation" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 30,
              marginBottom: 30,
            }}>
            <PlainButton
              disabled={false}
              size={"200px"}
              onClick={cancelLocation}>
              Annuler
            </PlainButton>
          </div>
        )}
        
      </div>
    );
  } else {
    return <AppStart location={props.location} />;
  }
};

export default AppLoader;
