import React from 'react';
import {css} from 'emotion';

const GroupFreeAnswer = props => {

  const onChange = e => {
    let newanswers =[{...props.answers[0]}];
      newanswers[0].comment = e.target.value;
      if (e.target.value.trim() !== '') {
        newanswers[0].result = 1;
      } else {
        newanswers[0].result = 0;
      }
    props.setAnswers(newanswers, props.id_question);
  };

  return (
    <form
      className={css`
        padding: 15px;
        border-top: 1px solid rgb(237, 237, 237);
      `}>
      <div
        className={css`
          margin-top: 10px;
        `}>
        {props.question.charAt(0).toUpperCase() +
          props.question.substring(1).toLowerCase()}
      </div>
      <div
        className={css`
          margin-top: 10px;
        `}>
        <textarea
          onChange={onChange}
          placeholder="Réponse libre..."
          className={css`
            padding-left: 10px;
            padding-right: 10px;
            box-sizing: border-box;
            resize: none;
            width: 95%;
            border-radius: 10px;
            border: 1px solid #cacaca;
            background-color: white;
            height: 200px;
            font-size: 18px;
            padding: 20px;
            box-sizing: border-box;
          `}
        />

      </div>
    </form>
  );
};

export default GroupFreeAnswer;
