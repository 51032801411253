import React,{useState} from 'react';
import {css} from 'emotion';
import IconEasyClose from '../../../icons/IconEasyClose';
import IconEasySearchSimple from '../../../icons/IconEasySearchSimple';

import ListMap from './ListMap';
import {useSelector} from 'react-redux';
import axios from 'axios';


var timeout = null;
var txttosearch = '';


const HeaderModalMap = (props) =>{

  const [textSearchMap, setTextSearchMap] = useState('');
  const [listCity, setListCity] = useState([]);
  const [, setIsFetching] = useState(false);
  const infoCity = useSelector(state => state.city.infoCity);


  const onClearText = () =>{
    setListCity([]);
    txttosearch = '';
    setTextSearchMap('');

  }

  const onChangeTextMap = e => {
    setTextSearchMap(e.target.value);
    txttosearch = e.target.value;

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      if (typeof txttosearch === 'string') {
        if (txttosearch.match(/[A-Za-z0-9]/i)) {
          setIsFetching(true);
          axios
            .get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${txttosearch}.json?proximity=${infoCity.lon},${infoCity.lat}&access_token=pk.eyJ1IjoianVzcHJpZXQiLCJhIjoiY2o5cHRzMjB1MnVxMTJ4czJoaTdhZjVjbCJ9.3LZxp2Lf97TcCYWPAgsyyA&autocomplete=true&limit=5&country=fr`,
            )
            .then(data => {
              setListCity(data.data.features);
              setIsFetching(false);
            });
        }
        if (txttosearch.length === 0) {
          setIsFetching(false);
          setListCity([]);
        }

      }
    }, 500);

  };

  const onSelectCity = (item) =>{
    setListCity([]);
    setTextSearchMap(item.place_name);
    txttosearch = item.place_name;
    props.onSelectCity(item);

  }


  return(
    <>
    <div className={css`display:flex;width:100%;position:absolute;z-index:200;top:20px;box-sizing:border-box;padding:20px;justify-content:center`}>

      <div className={css`background-color:white;width:80%;border-radius:20px;border:1px solid #e3e3e3`}>
      <div className={css`box-sizing:border-box;padding-left:20px;padding-right:20px;background-color:white;border-radius:20px;height:40px;display:flex;align-items:center`}>
        <IconEasySearchSimple color={'#858585'} size={18}/>
        <input value={textSearchMap} onChange={onChangeTextMap} placeholder={'Chercher un lieu'} className={css`margin-left:5px;background:transparent;height:100%;flex:1;border:none;outline:none`}/>
        {textSearchMap.length > 0 && <div onClick={onClearText} className={css`cursor:pointer`}><IconEasyClose color={'#858585'} size={15}/></div>}
        </div>
          {listCity.length > 0 &&  <ListMap onSelectCity={onSelectCity} list={listCity}/> }
          </div>

    </div>


    {
      //Background for list city
      listCity.length > 0 && <div onClick={() => {setListCity([])}} className={css`position:absolute;width:100%;height:100%;background-color:transparent;z-index:199`}></div>
     }
    </>
  )
}


export default HeaderModalMap;
