

// DEV popvox
/*export const isMobile = '678px';
export const server_api_url = 'https://www.setavoo3.xyz/popvox_revolution_api';
export const server_url = 'https://www.setavoo3.xyz/popvox/';
export const share_url = 'https://www.setavoo3.xyz/popvox/share.php';
export const history_url = '/app/';
export const server_img_api = server_url + 'app_images/app/';
export const server_img_photos = server_url + 'app_images/app/photos/';
export const server_img_logos = server_url + 'app_images/app/logos/';
export const server_img_headers = server_url + 'app_images/app/headers/';
export const server_img_icons = server_url + 'app_images/app/icons/';
export const server_img_public = server_url + 'app_images/public/';*/


// INFOPARCS
/*export const isMobile = '678px';
export const share_url = 'https://www.popvox.fr/social.php';
export const server_url =  'https://www.infoparcs.fr/pn/';
export const server_api_url = 'https://www.infoparcs.fr/pnapiv1';
export const history_url = '/app/';
export const server_img_api = server_url + 'app_images/app/';
export const server_img_photos = server_url + 'app_images/app/photos/';
export const server_img_logos = server_url + 'app_images/app/logos/';
export const server_img_headers = server_url + 'app_images/app/headers/';
export const server_img_icons = server_url + 'app_images/app/icons/';
export const server_img_public = server_url + 'app_images/public/';
*/


// SERVEUR MUTUALISE
/*export const isMobile = '678px';
export const server_api_url = 'https://www.setavoo4.xyz/popvox_revolution_api/';
export const server_url = 'https://www.setavoo4.xyz/popvox/';
export const server_img_api = server_url + 'app_images/app/';
export const server_img_photos = server_url + 'app_images/app/photos/';
export const server_img_logos = server_url + 'app_images/app/logos/';
export const server_img_headers = server_url + 'app_images/app/headers/';
export const server_img_icons = server_url + 'app_images/app/icons/';
export const server_img_public = server_url + 'app_images/public/';
export const app_version = 1;
export const history_url = '/popvox/app/';*/


// CONSTANTES POPVOX PROD
export const isMobile = '678px';
export const server_api_url = 'https://www.popvox.fr/popvox_revolution_api/';
export const server_url = 'https://www.popvox.fr/';
export const server_img_api = server_url + 'app_images/app/';
export const server_img_photos = server_url + 'app_images/app/photos/';
export const server_img_logos = server_url + 'app_images/app/logos/';
export const server_img_headers = server_url + 'app_images/app/headers/';
export const server_img_icons = server_url + 'app_images/app/icons/';
export const server_img_public = server_url + 'app_images/public/';
export const app_version = 1;
export const history_url = '/app/';
