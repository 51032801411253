import React, {useState} from 'react';
import {css} from 'emotion';
import FullModal from '../../../common/material-easy-ui/modal/FullModal';

import InputTextArea from '../../../budgetpart/components/post/components/InputTextArea';
import InputFile from '../../../budgetpart/components/post/components/InputFile';
import {server_api_url} from '../../../../config/constants.js';


const GroupQuestion = props => {

  const [uri, setUri] = useState(null);
  const [text, setText] = useState('');

  const onChangeText = e => {
    setText(e.target.value);
  };

  const onSubscribed = () => {
    props.onSubscribed(text, uri, props.question.id);
  };

  return (
    <FullModal
      id={'modal-question'}
      onBack={props.onBackQuestion}
      textHeader="Question groupe">

      <div
        className={css`
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 10px;
          padding-right: 10px;
          padding-left: 20px;
          box-sizing: border-box;
          align-items: center;
          height: 100%;
        `}>
        <div
          className={css`
            text-align: center;
            margin-top: 10px;
          `}>
          {props.question.description}
        </div>


        <InputTextArea
          style={{ marginTop: 30 }}
          value={text}
          onChange={onChangeText}
          title={'Votre réponse'}
        />

        <InputFile
          onClearFileUrl={() => {setUri(null)}}
          url={server_api_url + "/fileandimageuploads/participationimage"}
          onPost={(value) => {setUri(value.pathName)}}
          title={'Modifier l\'image'}
          style={{ marginTop: 30, width: '100%' }}
          fileUrl={uri}
        />


        <div
          onClick={onSubscribed}
          className={css`
            color: white;
            border-radius: 10px;
            width: 100%;
            height: 40px;
            text-align: center;
            background-color: #4842f5;
            line-height: 40px;
          `}>
          S&apos;inscrire
        </div>

      </div>
      
    </FullModal>
  );
};

export default GroupQuestion;
