import React, {useState} from 'react';

import HeaderApp from '../header-app/HeaderApp';
import {Route, Switch} from 'react-router-dom';
import Flux from '../flux/Flux';

import Nav from '../menu/Nav';
import NavBar from '../menu/mobile/NavBar';

import GridResponsive from '../common/grid/grid-responsive';
import RightContent from '../right-content/RightContent';
import EditProfile from '../editProfile/EditProfile';
import EditPassword from '../editPassword/EditPassword';
import MenuAccount from '../menu-account/MenuAccount';

import Connexion from '../connexion/Connexion';

import SignInStep from '../signin-step/SignInStep';
import SurveyDetails from '../publication-details/survey/SurveyDetails';
import AlertDetails from '../publication-details/alert/AlertDetails';
import NewsDetails from '../publication-details/news/NewsDetails';
import EventDetails from '../publication-details/event/EventDetails';
import ParticipationDetails from '../participation-details/ParticipationDetails';
import Participate from '../participate/Participate';
import EditParticipation from '../participations/card/components/EditParticipation';

import Post from '../budgetpart/components/post/Post';
import Details from '../budgetpart/components/details/Details';

const AppStart = props => {
  const [menuAccount, setMenuAccount] = useState(false);  

  const onMenuAccount = () => {
    setMenuAccount(!menuAccount);
  };

  const onCloseMenuAccount = () => {
    setMenuAccount(false);
  };


  return (
    <>
      <GridResponsive
        header={
          <HeaderApp
            location={props.location}
            onMenuAccount={onMenuAccount}
          />
        }
        nav={<Nav location={props.location} />}
        nav_mobile={<NavBar location={props.location} />}
        flux={
          <Flux
            location={props.location}
          />
        }
        right_content={<RightContent />}
      />

      <Switch>
        <Route path="/participation" component={ParticipationDetails} />
        <Route path="/edit-participation" component={EditParticipation} />
        <Route path="/alerte" component={AlertDetails} />
        <Route path="/evenement" component={EventDetails} />
        <Route path="/sondage" component={SurveyDetails} />
        <Route path="/actu" component={NewsDetails} />
        <Route path="/profile/edit" component={EditProfile} />
        <Route path="/profile/new-password" component={EditPassword} />
        <Route path="/connexion" component={Connexion} />
        <Route path="/signinstep" component={SignInStep} />
        <Route path="/participez" component={Participate} />
        <Route path="/budget-participatif/poster" component={Post}/>
        <Route path="/budget-participatif/details" component={Details} />
      </Switch>

      {menuAccount && <MenuAccount onCloseMenuAccount={onCloseMenuAccount} />}
    </>
  );
};

export default AppStart;
