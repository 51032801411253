import React from 'react';
import {css} from 'emotion';
import IconEasyComment from '../../../../common/icons/IconEasyComment';

import LikeCounter from './like/LikeCounter';
import VoteCounter from './vote/VoteCounter';
import LaureatReward from './laureat/LaureatReward';

const Footer = props => {


  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        border-top: 1px solid rgba(232, 232, 232, 0.8);
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 20px;
      `}>

        {
          ((props.current_phase == 1 || props.current_phase == 1.5)) &&
          <LikeCounter toggleLike={props.toggleLike} likes={props.likes} has_liked={props.has_liked} userHasLiked={props.userHasLiked}  />
        }


        {
          ((props.current_phase == 2) || (props.current_phase == 2.5)) &&
          <VoteCounter project_status={props.project_status} current_phase={props.current_phase} toggleVote={props.toggleVote} votes={props.votes} has_voted={props.has_voted} />
        }

        {
          (props.current_phase == 3) &&
          <LaureatReward project_status={props.project_status} />
        }


      <div className={css`flex:1`}></div>

      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}>
        <IconEasyComment size={30} />
        <div>{props.comments}</div>
      </div>

    </div>
  );
};

export default Footer;
