import React, {useState} from 'react';
import {css} from 'emotion';
import Avatar from '../../../common/avatar/Avatar';

import {timeSince} from '../../../../utils/date';
import TypeIconParticipation from "../../TypeIconParticipation";
import TypeShortNameParticipation from "../../TypeShortNameParticipation";

import IconEasyMore from "../../../common/icons/IconEasyMore";

const HeaderCard = props => {
  const [hoverMore, setHovermore] = useState(false);

  const formatText = text => {
    return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        backround-color: red;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
      `}>
      <div
        className={css`
          position: relative;
          width: 50px;
        `}>
        <TypeIconParticipation style={{
          position: "absolute",
          padding: 3,
          right: 0,
          top: 0,
        }} type={props.type} />
        <Avatar
          id_avatar={props.avatar}
          size={40}
        />
      </div>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding-left: 10px;
        `}>
        <div>
          <span
            className={css`
              color: #29354a;
            `}>
            {props.pseudo}
          </span>
          <TypeShortNameParticipation type={props.type} />
        </div>
        <div
          className={css`
            color: #bfbfbf;
            font-size: 13px;
            display: flex;
          `}>
          <div>{formatText(props.nom_comm)},</div>
          <div
            className={css`
              margin-left: 5px;
            `}>
             {timeSince(props.date_creation)}
          </div>
        </div>
      </div>
      <div
        className={css`
          display: flex;
          flex: 1;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
        `}>

        <div
          onMouseLeave={() => {
            setHovermore(false);
          }}
          onMouseEnter={() => {
            setHovermore(true);
          }}
          onClick={(event) => props.onMore(event)}
          className={css`
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            border-radius: 50%;
            justify-content: center;
            &:hover {
              background-color: rgba(245, 93, 66, 0.1);
            }
          `}>
          <IconEasyMore
            color={hoverMore ? 'rgba(245, 93, 66,1)' : '#bfbfbf'}
            size={20}
          />
        </div>
        
      </div>
    </div>
  );
};

export default HeaderCard;
