import React from 'react';
import {css} from 'emotion';
import Avatar from '../../avatar/Avatar';
import InputActionComment from './InputActionComment';
import TokenResponse from './TokenResponse';

const CommentInput = props => {

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #f7f7f7;
        padding-top: 10px;
        padding-bottom: 5px;
      `}>

      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          box-sizing: border-box;
          padding-left: 15px;
          padding-right: 15px;
          box-sizing: border-box;
        `}>
        <Avatar
          size={40}
          id_avatar={props.avatar ? props.avatar : 0}
        />
        <InputActionComment
          userResponse={props.userResponse}
          onPress={props.onPress}
        />
      </div>

      {props.userResponse && (
        <TokenResponse
          removeToken={props.removeToken}
          userResponse={props.userResponse && props.userResponse.pseudo}
        />
      )}

    </div>
  );
};
export default CommentInput;
