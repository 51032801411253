import React from 'react';
import {css} from "emotion";

import ButtonIcon from '../../button/ButtonIcon';
import IconEasyClose from '../../../icons/IconEasyClose';
import IconEasyValid from '../../../icons/IconEasyValid';


const ActionMap = (props) =>{
  return(
    <div className={css`display:flex;flex-direction:row;align-items:center;justify-content:center;position:absolute;bottom:50px;z-index:200;width:100%`}>

     <div className={css`margin-right:40px`}>
     { props.selectCity &&
       <ButtonIcon
       onPress={props.onValid}
       color={'rgba(110, 201, 64,0.5)'}
       hoverColor={'rgba(110, 201, 64,1)'}
       radius={'50%'}
       width={'40px'}
       height={'40px'}
       icon={<IconEasyValid size={20} color={'white'}/>}
       />
     }
       </div>

     <ButtonIcon
      onPress={props.onClose}
       color={'rgba(245, 93, 66,0.5)'}
       hoverColor={'rgba(245, 93, 66,1)'}
       radius={'50%'}
       width={'40px'}
       height={'40px'}
       icon={<IconEasyClose size={20} color={'white'}/>}
       />
       </div>
  )
}

export default ActionMap
