import produce from 'immer';

const initialState = {
  listParticipation: [],
  nb: 0,
  endreachedParticipation: false,
  loadingList: false,
  participation: false,
  loadingComment: false,
  listComment: [],
  commentsnb: 0,
  endReachedComment: false,
  isFollow: false,
  pubanswers: null,
  endOfParticipationsReached: false,
  likeLoading: false,
  page: 0,
};

export function participationReducers(state = initialState, action) {
  switch (action.type) {

    case 'CLEAR_PARTICIPATIONS':
    return {
      ...state,
      listParticipation: [],
      nb: 0,
      endreachedParticipation: false,
      loadingList: false,
    };

    case 'UPDATE_PARTICIPATION':
      let newParticipations = [...state.listParticipation];
      if (newParticipations === null) {
        newParticipations = [];
      }
      const ind = newParticipations.findIndex(i => {
        return i.id_citizenpart === action.payload.participation.id_citizenpart;
      });
      if (ind >= 0) {
        newParticipations[ind] = action.payload.participation;
      }
      return {
        ...state,
        listParticipation: newParticipations,
      };

    case 'GET_FLUX_PARTICIPATION':

    
      return {
        ...state,
        listParticipation: action.payload.listParticipation,
        nb: action.payload.nb,
        endreachedParticipation: action.payload.endReached,
        loadingList: false,
      };

    case 'GET_MORE_FLUX_PARTICIPATION':
      return {
        ...state,
        listParticipation: [
          ...state.listParticipation,
          ...action.payload.listParticipation,
        ],
        nb: action.payload.nb,
        endreachedParticipation: action.payload.endReached,
        loadingList: false,
      };

      

    case 'GET_FLUX_PARTICIPATION_LOADING':
      return {
        ...state,
        loadingList: true,
      };
    case 'INITIALISE_PARTICPATION_DETAILS':
      return {
        ...state,
        participation: action.payload,
        listComment: [],
        endReachedComment: false,
        isFollow: false,
        pubanswers: null,
        commentsnb: action.payload.comments,
      };

    case 'GET_DETAILED_CITIZENPART_SUCCESS':
      return {
        ...state,
        participation: action.payload.participation,
        listComment: action.payload.comments,
        endReachedComment: action.payload.endReached,
        isFollow: action.payload.isFollow,
        pubanswers: action.payload.pubanswers,
        commentsnb: action.payload.commentsnb,
      };

    case 'GET_MORE_COMMENT_PARTICIPATION_LOADING':
      return {
        ...state,
        loadingComment: true,
      };

    case 'GET_MORE_COMMENT_PARTICIPATION_SUCCESS':
      return {
        ...state,
        loadingComment: false,
        endReachedComment: action.payload.endReached,
        listComment: [...action.payload.comments, ...state.listComment],
      };

    case 'POST_PARTICIPATION_COMMENT_SUCCESS':
      const newComment = {...action.payload.comment, newComment: true};
      return {
        ...state,
        listComment: [...state.listComment, newComment],
        participation: {
          ...state.participation,
          comments: state.participation.comments + 1,
        }
      };

      case 'ADD_COMMENT_NB_PARTICIPATION_FLUX':
        const listParticipationPostComment = [...state.listParticipation];
        const indexParticipationPostComment = listParticipationPostComment.findIndex(
          i => {
            return parseInt(i.id_citizenpart, 10) === parseInt(action.payload.id_citizenpart, 10);
          },
        );
        var newParticipationList = [...state.listParticipation];
        if(indexParticipationPostComment > -1) {
          newParticipationList = produce(
            listParticipationPostComment,
            draft => {
              draft[indexParticipationPostComment].comments =
                draft[indexParticipationPostComment].comments + 1;
            },
          );
        }
        return {
          ...state,
          listParticipation: newParticipationList,
        };

    case 'POST_PARTICIPATION_COMMENT_REPLY_SUCCESS':
      var indexComment = state.listComment.findIndex(i => {
        return parseInt(i.id_comment, 10) === parseInt(action.payload.comment.ref_id_comment, 10);
      });
      var count_ref_id = state.listComment.filter(i => {
        return i.ref_id_comment === action.payload.comment.ref_id_comment;
      }).length;
      const newCommentReply = {...action.payload.comment, newComment: true};
      return {
        ...state,
        listComment: [
          ...state.listComment.slice(0, indexComment + count_ref_id + 1),
          newCommentReply,
          ...state.listComment.slice(indexComment + count_ref_id + 1),
        ],
        participation: {
          ...state.participation,
          comments: state.participation.comments + 1,
        },
      };

    case 'DELETE_COMMENT_PARTICIPATION_SUCCES':
      const indexCommentDelete = state.listComment.findIndex(i => {
        return parseInt(i.id_comment, 10) === parseInt(action.payload.id_comment, 10);
      });
      const mess = {
        ...state.listComment[indexCommentDelete],
        avatar: null,
        date_creation: Date.now(),
        description: 'Message supprimé',
        id_user: null,
        mycomment: false,
        pseudo: '',
      };
      return {
        ...state,
        listComment: [
          ...state.listComment.slice(0, indexCommentDelete),
          mess,
          ...state.listComment.slice(indexCommentDelete + 1),
        ],
        participation: {
          ...state.participation,
          comments: state.participation.comments - 1,
        },
      };


    case 'REMOVE_COMMENT_NB_PARTICIPATION_FLUX':
      const listParticipationPostComment3 = [...state.listParticipation];
      const indexParticipationPostComment3 = listParticipationPostComment3.findIndex(
        i => {
          return parseInt(i.id_citizenpart, 10) === parseInt(action.payload.id_citizenpart, 10);
        },
      );

      var newParticipationList3 = [...state.listParticipation];
      if(indexParticipationPostComment3 > -1) {
        newParticipationList3 = produce(
          listParticipationPostComment3,
          draft => {
            draft[indexParticipationPostComment3].comments =
              draft[indexParticipationPostComment3].comments - 1;
          },
        );
      }
      return {
        ...state,
        listParticipation: newParticipationList3,
      };


    case 'ON_FOLLOW_PARTICIPATION':
      return {
        ...state,
        isFollow: action.payload,
      };

    case 'LIKE_CITIZENPART_LOADING':
      return {
        ...state,
        likeLoading: true,
      };

    case 'LIKE_CITIZENPART_WITHOUT_LIST':
      let newsListParticipationState;
      const listParticipationState = [...state.listParticipation];
      const index = listParticipationState.findIndex(i => {
        return parseInt(i.id_citizenpart, 10) === parseInt(action.payload, 10);
      });
      if (index >= 0) {
        newsListParticipationState = produce(listParticipationState, draft => {
          draft[index].has_liked = true;
          draft[index].likes = draft[index].likes + 1;
        });
      } else {
        newsListParticipationState = state.listParticipation;
      }

      return {
        ...state,
        participation: {
          ...state.participation,
          has_liked: true,
          likes: state.participation.likes + 1,
        },
        likeLoading: false,
        listParticipation: newsListParticipationState,
      };

    case 'UNLIKE_CITIZENPART_LOADING':
      return {
        ...state,
        likeLoading: true,
      };


    case 'UNLIKE_CITIZENPART_WITHOUT_LIST':
      let newsListParticipationStateUnlike;
      const listParticipationStateUnlike = [...state.listParticipation];
      const indexUnLike = listParticipationStateUnlike.findIndex(i => {
        return parseInt(i.id_citizenpart, 10) === parseInt(action.payload, 10);
      });
      if (indexUnLike >= 0) {
        newsListParticipationStateUnlike = produce(
          listParticipationStateUnlike,
          draft => {
            draft[indexUnLike].has_liked = false;
            draft[indexUnLike].likes = draft[indexUnLike].likes - 1;
          },
        );
      } else {
        newsListParticipationStateUnlike = state.listParticipation;
      }
      return {
        ...state,
        participation: {
          ...state.participation,
          has_liked: false,
          likes: state.participation.likes - 1,
        },
        likeLoading: false,
        listParticipation: newsListParticipationStateUnlike,
      };

    case 'REMOVE_PARTICIPATION':
      var participations = [...state.listParticipation];
      var indexParticipation = participations.findIndex(i => {
        return parseInt(i.id_citizenpart, 10) === parseInt(action.payload, 10);
      });

      return {
        ...state,
        listParticipation: [
          ...participations.slice(0, indexParticipation),
          ...participations.slice(indexParticipation + 1),
        ],
      };

    case 'SEND_PARTICIPATION':
      return {
        ...state,
        listParticipation: [action.payload, ...state.listParticipation],
      };

    case 'CLEAN_PARTICIPATION':
      return {
        ...state,
        participation: false,
      };
    default:
      return state;
  }
}
