import React from 'react';
import {css} from 'emotion';
import RadioButton from '../../../common/material-easy-ui/input/RadioButton';

const GroupRadioButton = props => {
  const onChange = id => {
    const newStateAnswer = [...props.answers].map(item => {
      return {...item, result: false};
    });

    const index = newStateAnswer.findIndex(item => {
      return parseInt(item.id_survey_answers, 10) === parseInt(id, 10);
    });
    const newAnswer = [
      ...newStateAnswer.slice(0, index),
      {...newStateAnswer[index], result: true},
      ...newStateAnswer.slice(index + 1),
    ];
    props.setAnswers(newAnswer, props.id_question);
  };
  return (
    <form
      className={css`
        padding: 15px;
        border-top: 1px solid rgb(237, 237, 237);
      `}>
      <div
        className={css`
          margin-top: 10px;
        `}>
        {props.question.charAt(0).toUpperCase() +
          props.question.substring(1).toLowerCase()}
      </div>
      <div
        className={css`
          margin-top: 10px;
        `}>
        {props.answers.map((item, i) => {
          return (
            <RadioButton
              id={item.id_survey_answers}
              onChange={onChange}
              checked={props.result}
              key={i}
              label={item.entitled}
            />
          );
        })}
      </div>
    </form>
  );
};

export default GroupRadioButton;
