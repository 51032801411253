import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyWarning = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
          version="1.1" viewBox="0 0 32 32">
          <path
            fill={props.color}
            d="M16.036,4.214c1.103,0.015 2.158,0.668 2.659,1.658l2.214,4.429c0.257,0.579 -0.098,1.317 -0.709,1.432c-0.364,0.069 -0.76,-0.083 -0.984,-0.379c-0.05,-0.066 -0.057,-0.085 -0.097,-0.157c-0.741,-1.481 -1.462,-2.972 -2.222,-4.443c-0.338,-0.641 -1.423,-0.681 -1.779,0.008c-2.956,5.846 -5.978,11.66 -8.789,17.577c-0.277,0.596 0.186,1.413 0.89,1.427c5.863,0.072 11.727,0.118 17.589,0c0.663,-0.019 1.196,-0.793 0.87,-1.45l-4.557,-9.115c-0.321,-0.724 0.358,-1.639 1.168,-1.411c0.208,0.059 0.394,0.186 0.525,0.359c0.05,0.066 0.058,0.085 0.098,0.157c1.523,3.046 3.058,6.086 4.568,9.138c0.871,1.794 -0.457,4.297 -2.672,4.324c-5.866,0.024 -11.731,0.024 -17.597,0c-1.987,-0.024 -3.65,-2.308 -2.673,-4.324c2.909,-5.876 5.839,-11.742 8.797,-17.594c0.501,-0.969 1.545,-1.621 2.648,-1.636c0.026,0 0.026,0 0.053,0Zm-0.027,17.544c0.556,0 1.008,0.45 1.008,1.005c0,0.554 -0.452,1.005 -1.008,1.005c-0.556,0 -1.007,-0.451 -1.007,-1.005c0,-0.555 0.451,-1.005 1.007,-1.005Zm0.057,-12.008c0.182,0.015 0.231,0.035 0.327,0.074c0.327,0.136 0.571,0.457 0.611,0.813c0.006,0.05 0.005,0.062 0.007,0.112l0,8.009c-0.018,0.638 -0.707,1.164 -1.332,0.945c-0.354,-0.123 -0.622,-0.46 -0.664,-0.833c-0.006,-0.049 -0.005,-0.062 -0.007,-0.112l0,-8.009c0.005,-0.183 0.022,-0.233 0.056,-0.33c0.124,-0.353 0.457,-0.622 0.833,-0.665c0.108,-0.012 0.134,-0.005 0.169,-0.004Z"
            />
         </svg>
    </IconPattern>
    )
}

export default IconEasyWarning;
