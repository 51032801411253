import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyMap = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
          version="1.1" viewBox="0 0 32 32">
          <path fill={props.color} d="M28.5259,3.1494a1,1,0,0,0-.9732-.0439L20,6.8818,12.4473,3.1055a1.0008,1.0008,0,0,0-.8946,0l-8,4A1,1,0,0,0,3,8V28a1,1,0,0,0,1.4473.8945L12,25.1182l3.5527,1.7763a1,1,0,0,0,.8946-1.789L13,23.3818V8a1,1,0,0,0-2,0V23.3818l-6,3V8.6182l7-3.5,7,3.5V14a1,1,0,0,0,2,0V8.6182l6-3V18a1,1,0,0,0,2,0V4A1,1,0,0,0,28.5259,3.1494Z"/>
          <path fill={props.color} d="M26.167,24.7529a5.015,5.015,0,1,0-1.4141,1.4141l2.54,2.54a1,1,0,0,0,1.414-1.414ZM19,22a3,3,0,1,1,3,3A3.0033,3.0033,0,0,1,19,22Z"/>
         </svg>
    </IconPattern>
    )
}

export default IconEasyMap;
