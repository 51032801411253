import React from 'react';
import {css} from 'emotion';

const RubricText = (props) =>{
  return(
    <div className={
      css`margin-top:30px;
          padding-left: 30px;
          padding-right: 20px;`
        }
        >
      <div className={css`font-size:16px;color: #29354a;`}>
        {props.title}
      </div>
      <div
        className={css`
          margin-top:5px;
          box-sizing: border-box;
          color: #5c5c5c;
          font-size: 14px;
           white-space: pre-line;
        `}>
          {props.text}
        </div>
      </div>
  )
}

export default RubricText;
