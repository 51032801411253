import React, { useEffect, useState } from "react";
import { css } from "emotion";

import CardProject from "../projects/CardProject";

import Masonry from "react-masonry-component";

const masonryOptions = {
  transitionDuration: 300,
};

const ListProject = (props) => {
  const [firstProjects, setFirstProjects] = useState(null);
  const [secondProjects, setSecondProjects] = useState(null);

  const getRubricName = (idthematic) => {
    var name = "autre";
    if (props.session_rubrics && idthematic) {
      var tmp = props.session_rubrics.find((bp) => {
        return idthematic == bp.id;
      });
      if (tmp) name = tmp.name;
    }
    return name;
  };

  useEffect(() => {
    let first = [];
    let second = [];
    for (let i = 0; i < props.list_project.length; i++) {
      if (
        (props.current_phase == 2 || props.current_phase == 2.5) &&
        (props.list_project[i].project_status == 3 ||
          props.list_project[i].project_status == 4)
      )
        first.push(props.list_project[i]);
      else second.push(props.list_project[i]);
    }
    setFirstProjects(first);
    setSecondProjects(second);
  }, [props.list_project]);

  if (props.list_project && props.list_project.length > 0) {
    return (
      <div>
        {firstProjects && firstProjects.length === 0 ? (
          <div>
            <div
              className={css`
                display: inline-block;
                margin-left: 20px;
                box-sizing: border-box;
                height: 30px;
                background-color: white;
                padding-top: 1px;
                border-radius: 10px;
                margin-right: 20px;
                padding-left: 10px;
                padding-right: 10px;
                align-items: center;
                justify-content: center;
                align-self: flex-start;
                white-space: nowrap;
                font-weight: 700;
                font-size: 15px;
                color: rgb(245, 93, 66);
                border: 2px solid rgb(245, 93, 66);
                text-align: center;
                @media (max-width: 678px) {
                  height: 25px;
                  font-size: 13px;
                  margin-right: 12px;
                  padding-left: 8px;
                  padding-right: 8px;
                }
              `}>
              Vos propositions
            </div>

            <Masonry
              className={css`
                margin: 0px;
                margin-bottom: 80px;
              `}
              options={masonryOptions}>
              {props.list_project.map((item) => {
                return (
                  <CardProject
                    key={item.id_budget_part_project}
                    item={item}
                    current_phase={props.current_phase}
                    thematic={getRubricName(item.thematic)}
                  />
                );
              })}
            </Masonry>
          </div>
        ) : (
          <div>
            <div
              className={css`
                display: inline-block;
                margin-left: 20px;
                box-sizing: border-box;
                height: 30px;
                background-color: white;
                padding-top: 1px;
                border-radius: 10px;
                margin-right: 20px;
                padding-left: 10px;
                padding-right: 10px;
                align-items: center;
                justify-content: center;
                align-self: flex-start;
                white-space: nowrap;
                font-weight: 700;
                font-size: 15px;
                color: rgb(245, 93, 66);
                border: 2px solid rgb(245, 93, 66);
                text-align: center;
                @media (max-width: 678px) {
                  height: 25px;
                  font-size: 13px;
                  margin-right: 12px;
                  padding-left: 8px;
                  padding-right: 8px;
                }
              `}>
              Vos propositions retenues
            </div>

            <Masonry
              className={css`
                margin: 0px;
                margin-bottom: 80px;
              `}
              options={masonryOptions}>
              {firstProjects &&
                firstProjects.map((item) => {
                  return (
                    <CardProject
                      key={item.id_budget_part_project}
                      item={item}
                      current_phase={props.current_phase}
                      thematic={getRubricName(item.thematic)}
                    />
                  );
                })}
            </Masonry>

            <div
              className={css`
                display: inline-block;
                margin-left: 20px;
                box-sizing: border-box;
                height: 30px;
                background-color: white;
                padding-top: 1px;
                border-radius: 10px;
                margin-right: 20px;
                padding-left: 10px;
                padding-right: 10px;
                align-items: center;
                justify-content: center;
                align-self: flex-start;
                white-space: nowrap;
                font-weight: 700;
                font-size: 15px;
                color: rgb(245, 93, 66);
                border: 2px solid rgb(245, 93, 66);
                text-align: center;
                @media (max-width: 678px) {
                  height: 25px;
                  font-size: 13px;
                  margin-right: 12px;
                  padding-left: 8px;
                  padding-right: 8px;
                }
              `}>
              Vos propositions non retenues
            </div>

            <Masonry
              className={css`
                margin: 0px;
                margin-bottom: 80px;
              `}
              options={masonryOptions}>
              {secondProjects &&
                secondProjects.length !== 0 &&
                secondProjects.map((item) => {
                  return (
                    <CardProject
                      key={item.id_budget_part_project}
                      item={item}
                      current_phase={props.current_phase}
                      thematic={getRubricName(item.thematic)}
                    />
                  );
                })}
            </Masonry>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div style={{ textAlign: "center", padding: 40, opacity: 0.35 }}>
        <img
          src={require("../../../../image/empty.png")}
          style={{ width: 70 }}
        />
        <div
          style={{ color: "rgb(245, 93, 66)", fontWeight: 700, fontSize: 13 }}>
          Aucun projet
        </div>
      </div>
    );
  }
};

export default ListProject;
