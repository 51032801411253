import React from 'react';
import {css} from 'emotion';

const LoaderBlock = () => {
  return (
    <div
      className={css`
        width: 100%;
        background: #fff;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
        margin-top: 30px;
        border: 1px solid rgba(24,62,100,0.05);
      `}>
      <div
        className={css`
          display: flex;
          align-items: center;
        `}>
        <div
          className={css`
            height: 20px;
            background-color: #dbdbdb;
            width: 150px;
            border-radius: 5px;
          `}></div>
        <div
          className={css`
            flex: 1;
            display: flex;
            justify-content: flex-end;
          `}>
          <div
            className={css`
              height: 20px;
              background-color: #dbdbdb;
              width: 20px;
              border-radius: 5px;
            `}></div>
        </div>
      </div>
      <div>
        <div
          className={css`
            height: 10px;
            background-color: #ededed;
            width: 150px;
            border-radius: 5px;
            margin-top: 20px;
          `}></div>
        <div
          className={css`
            height: 10px;
            background-color: #ededed;
            width: 150px;
            border-radius: 5px;
            margin-top: 10px;
          `}></div>
        <div
          className={css`
            height: 10px;
            background-color: #ededed;
            width: 150px;
            border-radius: 5px;
            margin-top: 10px;
          `}></div>
        <div
          className={css`
            height: 10px;
            background-color: #ededed;
            width: 150px;
            border-radius: 5px;
            margin-top: 10px;
          `}></div>
        <div
          className={css`
            height: 10px;
            background-color: #ededed;
            width: 150px;
            border-radius: 5px;
            margin-top: 10px;
          `}></div>
        <div
          className={css`
            height: 10px;
            background-color: #eb9d8f;
            width: 75px;
            border-radius: 5px;
            margin-top: 20px;
          `}></div>
      </div>
    </div>
  );
};

export default LoaderBlock;
