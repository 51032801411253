import React from "react";
import { css } from "emotion";

const ItemMenuPublication = (props) => {
  return (
    <div
      onClick={() => {
        props.onChoose(props.selector);
      }}
      className={css`
        box-sizing: border-box;
        cursor: pointer;
        height: 30px;
        background-color: white;
        border-radius: 10px;
        margin-right: 20px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self:flex-start;
        white-space: nowrap;
        font-weight: ${props.selector === props.currentSelect ? 700 : 300};
        font-size: ${props.selector === props.currentSelect ? 15 : 14}px;
        color: ${props.selector === props.currentSelect
          ? "rgb(245, 93, 66)"
          : "rgba(24, 62, 100,0.5)"};

        border: ${props.selector === props.currentSelect
          ? "2px solid rgb(245, 93, 66)"
          : "1px solid rgba(0, 0, 0, 0.15)"};

        text-align: center;

        &:hover {
          opacity: 0.7;
        }


        @media (max-width: 678px) {
          height: 25px;
          font-size: ${props.selector === props.currentSelect ? 13 : 12}px;
          margin-right: 12px;
          padding-left: 8px;
          padding-right: 8px;
        }
        
      `}>
      {props.text}
    </div>
  );
};

export default ItemMenuPublication;
