import React,{useState} from 'react';
import {css} from "emotion";
import IconEasyCross from '../../../../common/icons/IconEasyClose';

const CloseButton = (props) =>{

  const [hover,setHover] = useState(false);

  return(
    <div
        onClick={props.onPress}
        onMouseEnter={()=>setHover(true)}
        onMouseLeave={()=>setHover(false)}
        className={css`
        cursor:pointer;
        border-radius:50%;
        width:30px;
        height:30px;
        background-color:rgba(245, 93, 66,0.1);
        display:flex;
        align-items:center;
        justify-content:center;
        &:hover{
          background-color:rgba(245, 93, 66,1)
        }`}>
      <IconEasyCross size={15} color={hover ? 'white' :'rgba(245, 93, 66,1)'}/>
    </div>)
}

export default CloseButton
