import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyTrash = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
        version="1.1" viewBox="0 0 32 32">
      <path
       fill={props.color}
       d="M24.073,11c0.503,0.059 0.901,0.484 0.921,0.996l0,13.997c-0.01,1.554 -1.344,2.965 -2.96,2.996c-4.012,0.025 -8.025,0 -12.037,0c-1.568,-0.01 -2.985,-1.349 -2.995,-2.996l0,-13.997c0.018,-0.478 0.375,-0.913 0.843,-0.987c0.517,-0.082 1.06,0.306 1.143,0.831c0.011,0.069 0.01,0.087 0.013,0.156c0,4.669 -0.03,9.338 0,14.007c0.01,0.516 0.45,0.976 0.986,0.987c4.007,0.025 8.014,0.025 12.021,0c0.522,-0.01 0.983,-0.464 0.987,-1.003l0,-13.991c0.019,-0.481 0.373,-0.913 0.843,-0.987c0.149,-0.024 0.186,-0.011 0.235,-0.009Zm-6.04,1.997c0.182,0.016 0.231,0.035 0.327,0.075c0.327,0.136 0.57,0.456 0.61,0.811c0.006,0.05 0.005,0.062 0.007,0.112l0,7.997c-0.007,0.229 -0.037,0.304 -0.099,0.434c-0.295,0.611 -1.283,0.754 -1.716,0.144c-0.119,-0.168 -0.179,-0.373 -0.185,-0.578l0,-7.997c0.006,-0.182 0.023,-0.233 0.057,-0.33c0.123,-0.352 0.456,-0.621 0.831,-0.663c0.108,-0.012 0.133,-0.006 0.168,-0.005Zm-3.958,0c0.181,0.016 0.231,0.035 0.326,0.075c0.327,0.135 0.571,0.456 0.611,0.811c0.005,0.05 0.005,0.062 0.006,0.112l0,7.997c-0.006,0.229 -0.037,0.304 -0.099,0.434c-0.295,0.611 -1.283,0.754 -1.716,0.144c-0.119,-0.168 -0.178,-0.373 -0.184,-0.578l0,-7.997c0.005,-0.182 0.022,-0.233 0.056,-0.33c0.123,-0.352 0.456,-0.621 0.832,-0.663c0.108,-0.012 0.133,-0.006 0.168,-0.005Zm-3.075,-5.999l0,-2.998c0.005,-0.183 0.022,-0.233 0.056,-0.331c0.123,-0.352 0.456,-0.62 0.832,-0.663c0.049,-0.005 0.062,-0.005 0.111,-0.006l7.997,0c0.019,0.001 0.037,0.001 0.056,0.002c0.182,0.015 0.232,0.035 0.327,0.074c0.328,0.136 0.57,0.456 0.61,0.812c0.006,0.049 0.005,0.062 0.007,0.112l0,2.998l4.997,0l0.088,0.004c0.076,0.01 0.096,0.01 0.171,0.03c0.743,0.199 0.982,1.404 0.241,1.832c-0.138,0.079 -0.215,0.121 -0.5,0.133l-19.991,0c-0.077,-0.003 -0.097,-0.001 -0.174,-0.015c-0.783,-0.138 -1.101,-1.402 -0.326,-1.85c0.139,-0.08 0.216,-0.121 0.5,-0.134l4.998,0Zm7.996,0l0,-1.999l-5.997,0l0,1.999l5.997,0Z"
      />

        </svg>
    </IconPattern>
    )
}

export default IconEasyTrash;
