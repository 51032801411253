import React, { useState } from 'react';
import {css} from 'emotion';


import IconEasyLike from '../../../../../common/icons/IconEasyLike';

import AccountNotConfirmedAlert from '../../../../../common/alert/AccountNotConfirmedAlert';
import Connexion from '../../../../../connexion/Connexion';
import MessageAlerte from '../../../../../common/material-easy-ui/modal/MessageAlerte';

import {
  likeProject,
  unlikeProject,
} from '../../../../../../redux/actions/budgetPartActions';
import {useSelector, useDispatch} from 'react-redux';

const SimpleLikeCounter = (props) => {
  const dispatch = useDispatch();

  const popkey = useSelector(state => state.user.popkey);
  const info_user = useSelector(state => state.user.info_user);

  const [forbidenUserModal, setForbidenUserModal] = useState(false);
  const [notConfirmedUserModal, setNotConfirmedUserModal] = useState(false);
  const [connect, setConnect] = useState(false);


  const toggleLike = (event) => {
    event.stopPropagation();
    if (popkey) {
      if (info_user.account_blocked === 0) {
        if (!props.has_liked) {
          dispatch(likeProject(popkey, props.details.id_budget_part_project));

        } else {
          dispatch(unlikeProject(popkey, props.details.id_budget_part_project));
        }
      } else if (info_user.account_blocked === 1)
        setForbidenUserModal(true);
      else
        setNotConfirmedUserModal(true)
    } else {
      setConnect(true);
    }
  };

  return (
    <>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
        onClick={(e) => toggleLike(e)}>
        <IconEasyLike
          color={props.has_liked ? 'rgb(242,101,35)' : '#29354a'}
          size={25}
        />
        <div
          className={css`
            color: ${props.has_liked ? 'rgb(242,101,35)' : '#29354a'};
            font-size: 13px;
          `}>
          {props.likes}
        </div>
      </div>


      {connect && <Connexion onBack={() => setConnect(false)} />}

      {forbidenUserModal && (
        <MessageAlerte
          close={() => setForbidenUserModal(false)}
          text={
            "Votre compte a été bloqué par la ville ou l'équipe popvox car vous ne respectiez pas les CGU du service. Pour plus d'informations, vous pouvez contacter les équipes popvox (contact@popvox.fr)."
          }
          textHeader={'Compte bloqué'}
        />
      )}

      {notConfirmedUserModal && (
        <AccountNotConfirmedAlert close={() => setNotConfirmedUserModal(false)} />
      )}
    </>
  )
}

export default SimpleLikeCounter;
