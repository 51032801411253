import React from 'react';
import {css} from 'emotion';
import IconPattern from './IconPattern';

const IconEasySignal = (props) =>{
  return(
    <IconPattern size={props.size}>
      <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
          `}
      version="1.1" viewBox="0 0 32 32">
    <path fill={props.color} d="M8.993,17.012l0,9.009c-0.01,0.256 -0.044,0.326 -0.109,0.454c-0.282,0.553 -1.133,0.72 -1.6,0.254c-0.185,-0.186 -0.283,-0.444 -0.293,-0.708l0,-16.016c0.019,-0.482 0.374,-0.914 0.845,-0.989c0.149,-0.023 0.186,-0.011 0.235,-0.009c0.502,0.06 0.902,0.485 0.922,0.998l0,5.005l7.007,0c0,0 0.072,0.002 0.157,0.012c0.469,0.094 0.825,0.504 0.844,0.989l0,1.001l6.006,0l0,-8.008l-6.006,0l0,3.003c-0.021,0.76 -1.043,1.305 -1.668,0.746c-0.182,-0.162 -0.3,-0.392 -0.328,-0.634c-0.005,-0.05 -0.005,-0.062 -0.006,-0.112l0,-5.005l-7.007,0c0,0 -0.594,-0.065 -0.847,-0.468c-0.354,-0.563 0.028,-1.448 0.735,-1.528c0.05,-0.005 0.062,-0.005 0.112,-0.006l8.008,0c0.019,0.001 0.037,0.001 0.056,0.002c0.182,0.015 0.232,0.035 0.327,0.074c0.328,0.136 0.572,0.457 0.612,0.813c0.005,0.05 0.005,0.062 0.006,0.112l0,1.001l7.007,0c0.241,0.009 0.281,0.034 0.383,0.076c0.363,0.151 0.602,0.528 0.618,0.925l0,10.01c-0.019,0.48 -0.374,0.914 -0.845,0.989c-0.069,0.01 -0.086,0.009 -0.156,0.012l-8.008,0c-0.241,-0.01 -0.281,-0.034 -0.383,-0.076c-0.362,-0.15 -0.602,-0.528 -0.618,-0.925l0,-1.001l-6.006,0Z"/>

      </svg>
  </IconPattern>
  )
}


export default IconEasySignal;
