import React, { useState } from "react";
import { css } from "emotion";
import { server_img_public } from "../../../../config/constants";
import CardInfoGroup from "./components/CardInfoGroup";
import FooterInfoGroup from "./components/FooterInfoGroup";
import IconEasyFullPage from "../../../common/icons/IconEasyFullPage";

const GroupInfo = (props) => {
  const [fullPage, setFullPage] = useState(false);

  return (
    <>
      <div style={{ width: "100%", height: 50 }} />
      <div
        className={css`
          background: #858585;
          width: 100%;
          text-align: center;
          position: relative;
        `}>
        {!fullPage && (
          <div
            onClick={() => {
              if (fullPage) {
                setFullPage(false);
              } else {
                setFullPage(true);
              }
            }}
            style={{ position: "absolute", top: 20, right: 20 }}>
            <IconEasyFullPage color="#cacaca" size={20} />
          </div>
        )}
        <img
          style={{
            width: "100%",
            maxHeight: fullPage ? "none" : "300px",
            objectFit: "cover",
          }}
          alt="communauté"
          src={server_img_public + props.details.file_url}
        />
      </div>

      <CardInfoGroup
        title={props.details.title}
        description={props.details.description}
        footer={
          <FooterInfoGroup
            onSubscribe={props.onSubscribe}
            requested={props.details.requested}
            subscribed={props.details.subscribed}
            members={props.details.members}
          />
        }
      />
    </>
  );
};

export default GroupInfo;
