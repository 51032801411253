import React from 'react';
import {css} from 'emotion';
import IconEasyGroup from '../../../common/icons/IconEasyGroup';

const GroupPresentationBlock = () => {

  return (
    <div
      className={css`
        width: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 15px;
        margin-top: 50px;
        margin-bottom: 20px;
        background: #fff;
        border: 1px solid rgba(24, 62, 100, 0.05);
      `}>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        `}>
        <div
          className={css`
            color: #29354a;
            font-size: 17px;
            text-align: left;
            flex: 1;
            font-weight: 600;
            line-height: 1;
          `}>
          Les communautés
        </div>
        <div>
          <IconEasyGroup color={'#29354a'} size={20} />
        </div>
      </div>
      <div
        className={css`
          border-top: 1px solid #ebebeb;
          margin-top: 10px;
          padding: 10px;
        `}>

        Une communauté est un espace privilégié permettant de discuter d&apos;un sujet particulier.
        Il est nécessaire de rejoindre la communauté pour entamer la discussion.
      </div>


    </div>
  );
};

export default GroupPresentationBlock;
