import React, { useState, useEffect, useRef } from "react";
import Masonry from "react-masonry-component";

import { useSelector, useDispatch } from "react-redux";
import { getFluxParticipation } from "../../redux/actions/participationActions";
import IconEasyPageEmpty from "../common/icons/IconEasyPageEmpty";

import history from "../../utils/history";
import CardParticipation from "../participations/card/CardParticipation";
import "../participations/styles.css";
import { css } from "emotion";

import { participationTypesFr } from "../../config/thematics";
import IconEasyFilter from "../common/icons/IconEasyFilter";

import MenuParticipation from "../participations/components/MenuParticipation";

import ParticipationButton from "../participateButton/ParticipationButton";
import ParticipationFilterModal from "./ParticipationFilterModal";
// eslint-disable-next-line

const ParticipationFlux = (props) => {
  /* STATE REDUX */
  const listParticipation = useSelector(
    (state) => state.participation.listParticipation
  );
  const popkey = useSelector((state) => state.user.popkey);
  const insee_com = useSelector((state) => state.city.infoCity.insee_com);
  const endreachedParticipation = useSelector(
    (state) => state.participation.endreachedParticipation
  );

  const page = useRef(0);
  const isFetching = useRef(false);
  const [displayFilterModal, setDisplayFilterModal] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [displayOrder, setDisplayOrder] = useState(0);
  const [typesToDisplay, setTypesToDisplay] = useState(
    Object.keys(participationTypesFr)
  );
  const [getMyOnly, setGetMyOnly] = useState(false);

  /* DISPATCH */
  const dispatch = useDispatch();

  const scrollEndBottom = () => {
    if (
      window.scrollY >=
      document.body.scrollHeight - window.innerHeight - 200
    ) {
      fetchMoreParticipation();
    }
  };

  const masonryOptions = {
    transitionDuration: 300,
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollEndBottom);

    return () => {
      window.removeEventListener("scroll", scrollEndBottom);
    };
  }, [displayOrder, typesToDisplay, getMyOnly, endreachedParticipation]);

  useEffect(() => {
    isFetching.current = true;
    window.scrollTo(0, 0);

    page.current = 0;
    setTypesToDisplay(Object.keys(participationTypesFr));
    setGetMyOnly(false);
    setDisplayOrder(0);

    dispatch(
      getFluxParticipation(
        0,
        popkey,
        insee_com,
        0,
        Object.keys(participationTypesFr),
        false,
        props.idCommunity
      )
    ).then(() => {
      isFetching.current = false;
    });
  }, [insee_com, popkey]);

  useEffect(() => {

    if (
      !isFetching.current &&
      endreachedParticipation &&
      listParticipation &&
      listParticipation.length === 0
    ) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [listParticipation]);

  const fetchMoreParticipation = () => {
    if (!endreachedParticipation && !isFetching.current) {
      isFetching.current = true;
      page.current++;

      dispatch(
        getFluxParticipation(
          page.current,
          popkey,
          insee_com,
          displayOrder,
          typesToDisplay,
          getMyOnly,
          props.idCommunity
        )
      ).then(() => {
        isFetching.current = false;
      });
    }
  };

  const onParticipate = () => {

    if (props.idCommunity === null) {
      history.push({
        pathname: "/participez",
        state: {
          modal: true,
        },
      });
    } else {
      history.push({
        pathname: `/participez`,
        search: `?id_community=${props.idCommunity}`,
        state: {
          community: {
            id: props.idCommunity,
            code_insee: insee_com,
          },
          modal: true,
        },
      });
    }
  };

  const confirmNewFilter = () => {
    if (typesToDisplay.length === 0) {
      alert("Merci de sélectionner au moins une catégorie à afficher !");
      return;
    }
    setDisplayFilterModal(false);
    isFetching.current = true;
    page.current = 0;

    dispatch(
      getFluxParticipation(
        0,
        popkey,
        insee_com,
        displayOrder,
        typesToDisplay,
        getMyOnly,
        props.idCommunity
      )
    ).then(() => {
      window.scrollTo(0, 0);
      isFetching.current = false;
    });
  };

  return (
    <div style={{ paddingTop: 10 }}>
      <ParticipationButton
        name="Commencez un dialogue"
        onClick={onParticipate}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginRight: 10,
        }}>
        <div style={{ flex: 1 }}>
          {/*<MenuParticipation
            style={{ flex: 1 }}
            label="Vous avez la parole"
            currentSelect={0}
      />*/}

          <div
            className={css`
              display: inline-block;
              margin-left: 20px;
              box-sizing: border-box;
              height: 30px;
              background-color: white;
              padding-top: 1px;
              border-radius: 10px;
              margin-right: 20px;
              padding-left: 10px;
              padding-right: 10px;
              align-items: center;
              justify-content: center;
              align-self: flex-start;
              white-space: nowrap;
              font-weight: 700;
              font-size: 15px;
              color: rgb(245, 93, 66);
              border: 2px solid rgb(245, 93, 66);
              text-align: center;
              @media (max-width: 678px) {
                height: 25px;
                font-size: 13px;
                margin-right: 12px;
                padding-left: 8px;
                padding-right: 8px;
              }
            `}>
            Vous avez la parole
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid rgba(0, 0, 0, 0.15)",
              color: "rgba(0, 0, 0, 0.5)",
              paddingLeft: 5,
              paddingRight: 7,
              paddingTop: 6,
              paddingBottom: 6,
              borderRadius: 7,
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              fontSize: 13,
              lineHeight: 1,
              alignSelf: "flex-end",
              background: "#FFF",
            }}
            onClick={() => setDisplayFilterModal(true)}>
            <div style={{ marginRight: 5 }}>
              <IconEasyFilter color={"rgba(0, 0, 0, 0.4)"} size={16} />
            </div>
            <div
              className={css`
                @media (max-width: 1000px) {
                  display: none;
                }
              `}>
              Filtrer
            </div>
          </div>
        </div>
      </div>

      {displayFilterModal && (
        <ParticipationFilterModal
          setDisplayFilterModal={setDisplayFilterModal}
          displayOrder={displayOrder}
          setDisplayOrder={setDisplayOrder}
          typesToDisplay={typesToDisplay}
          setTypesToDisplay={setTypesToDisplay}
          getMyOnly={getMyOnly}
          setGetMyOnly={setGetMyOnly}
          confirmNewFilter={confirmNewFilter}
        />
      )}

      <div style={{ paddingTop: 30, paddingBottom: 60, margin: "0 auto" }}>
        {isEmpty && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <IconEasyPageEmpty size={75} color="#d1d1d1" />

            <div
              style={{
                textAlign: "center",
                marginTop: 20,
                marginBottom: 20,
                color: "#cacaca",
                maxWidth: 350,
                lineHeight: 1.4,
                fontSize: 16,
                fontWeight: "500",
              }}>
              Retrouvez ici les participations citoyennes de vos communautés
            </div>
          </div>
        )}

        <Masonry options={masonryOptions}>
          {listParticipation.map((item) => {
            return <CardParticipation key={item.id_citizenpart} item={item} />;
          })}
        </Masonry>

        {!endreachedParticipation && (
          <div style={{ textAlign: "center" }}>
            <img
              style={{ width: 70, height: 70, marginTop: 30 }}
              src={require("../../image/loading.gif")}
              alt="loading"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ParticipationFlux;
