import React from "react";
import { css } from "emotion";
import Avatar from "../../common/avatar/Avatar";

import ThematicIconParticipation from "../../participations/ThematicIconParticipation";
import { timeSince } from "../../../utils/date";
import { participationRubricsFR } from "../../../config/thematics";

import TypeIconParticipation from "../../participations/TypeIconParticipation";
import TypeShortNameParticipation from "../../participations/TypeShortNameParticipation";

const formatText = (text) => {
  return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
};

const HeaderParticipationDetails = (props) => {
  if (props.nom_comm === undefined) return <></>;

  return (
    <div
      className={css`
        display: flex;
        margin-top: 20px;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
      `}>
      <div
        className={css`
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        `}>
        <Avatar size={60} id_avatar={props.avatar} />
        <TypeIconParticipation
          style={{
            position: "absolute",
            padding: 3,
            right: 0,
            top: 0,
          }}
          type={props.type}
        />
      </div>

      <div
        className={css`
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          justify-content: center;
          flex: 1;
        `}>
        <div
          className={css`
            padding-left: 10px;
          `}>
          <span
            className={css`
              color: #29354a;
            `}>
            {props.pseudo}
          </span>
          <TypeShortNameParticipation type={props.type} />
        </div>

        <div
          className={css`
            color: #bfbfbf;
            font-size: 13px;
            display: flex;
            padding-left: 10px;
          `}>
          <div>{formatText(props.nom_comm)},</div>
          <div
            className={css`
              margin-left: 5px;
            `}>
            {timeSince(props.date_creation)}
          </div>
        </div>

        <div
          className={css`
            color: #598b99;
            font-size: 13px;
            display: flex;
            padding-left: 10px;
          `}>
          {props.rubric &&
            participationRubricsFR[props.rubric] &&
            participationRubricsFR[props.rubric]}
        </div>
      </div>

      <ThematicIconParticipation id={props.theme ? props.theme : 19} />
    </div>
  );
};

export default HeaderParticipationDetails;
