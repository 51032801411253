import React, {useEffect} from 'react';
import {css} from 'emotion';
import IconEasyGroup from '../../../common/icons/IconEasyGroup';
import {getForUserGroup} from '../../../../redux/actions/groupActions';
import {useSelector, useDispatch} from 'react-redux';
import history from '../../../../utils/history';
import LoaderBlock from './LoaderBlock';

const GroupBlock = () => {
  const dispatch = useDispatch();
  const infoCity = useSelector(state => state.city.infoCity);
  const popkey = useSelector(state => state.user.popkey);
  const listGroupForUser = useSelector(state => state.group.listGroupForUser);
  const loading = useSelector(state => state.group.loading_for_user);


  useEffect(() => {
    infoCity && dispatch(getForUserGroup(popkey, infoCity.insee_com));
  }, [popkey, infoCity.insee_com ]);


  const goToListGroupe = () => {
    history.push({pathname: '/communautes'});
  };

  const goToGroupDetails = item => {
    history.push({
      pathname: '/communaute',
      search: `?id_group=${item.id}`,
      state: {
        from: 'app',
      },
    });
  };

  if (loading) {
    return (
      <div
        className={css`
          margin-top: 20px;
        `}>
        <LoaderBlock />
      </div>
    );
  } else {
    if (listGroupForUser && listGroupForUser.length > 0) {
      return (
        <div
          className={css`
            width: 100%;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 15px;
            margin-top: 30px;
            margin-bottom: 20px;
            background: #fff;
            border: 1px solid rgba(24,62,100,0.05);
          `}>
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            `}>
            <div
              className={css`
                color: #29354a;
                font-size: 17px;
                text-align: left;
                flex: 1;
                font-weight: 600;
                line-height: 1;
              `}>
              Les communautés pour vous
            </div>
            <div>
              <IconEasyGroup color={'#29354a'} size={20} />
            </div>
          </div>
          <div
            className={css`
              border-top: 1px solid #ebebeb;
              margin-top: 10px;
            `}>
            {listGroupForUser.map((item, index) => {
              if(index < 3) {
                return (
                  <div
                    key={'GB' + index}
                    onClick={() => {
                      goToGroupDetails(item);
                    }}
                    className={css`
                      cursor: pointer;
                      color: #29354a;
                      text-align: left;
                      line-height: 1.1;
                      border-top: 1px solid #ebebeb;
                      padding: 12px;
                      &:hover {
                        text-decoration: underline;
                      }
                    `}>
                    <span className={css`
                      `}>
                      {item.title}
                      </span>
                  </div>
                );
              } else {
                return false;
              }

            })}
          </div>
          <div
            onClick={goToListGroupe}
            className={css`
              cursor: pointer;
              color: #f55d42;
              font-size: 14px;
              text-align: left;
              padding-top: 10px;
              padding-bottom: 10px;
              border-top: 1px solid #ebebeb;
              &:hover {
                text-decoration: underline;
              }
            `}>
            En voir plus
          </div>
        </div>
      );
    }
  }

  return false;
};

export default GroupBlock;
