import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyImagine = (props) =>{
    return(
      <IconPattern size={props.size}>
      <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
          `}
       viewBox="0 0 32 32">
      <path
      fill={props.color}
      d="M18.005,29l-4,0c-0.882,-0.018 -1.376,-1.415 -0.46,-1.888c0.141,-0.073 0.298,-0.109 0.46,-0.112c1.346,0 2.693,-0.026 4.039,0.001c0.866,0.051 1.317,1.423 0.42,1.887c-0.141,0.073 -0.298,0.109 -0.459,0.112Zm2,-4l-8,0c-0.866,-0.024 -1.41,-1.431 -0.434,-1.901c0.129,-0.062 0.204,-0.093 0.434,-0.099l8,0c0.018,0 0.037,0.001 0.056,0.002c0.229,0.019 0.302,0.053 0.427,0.123c0.727,0.401 0.555,1.846 -0.483,1.875Zm-3.942,-22c3.293,0.031 6.494,2.008 7.981,4.952c1.236,2.45 1.264,5.477 0.072,7.949c-1.947,4.04 -7.162,6.243 -11.563,4.412c-2.873,-1.195 -5.029,-3.972 -5.461,-7.056c-0.393,-2.804 0.628,-5.767 2.665,-7.733c1.627,-1.57 3.837,-2.487 6.132,-2.523c0.058,-0.001 0.116,-0.001 0.174,-0.001Zm0.942,15.919c0.561,-0.075 1.114,-0.22 1.644,-0.436c2.951,-1.207 4.822,-4.616 4.238,-7.765c-0.579,-3.127 -3.51,-5.645 -6.747,-5.717c-0.06,-0.001 -0.12,-0.001 -0.181,-0.001c-3.193,0.03 -6.171,2.458 -6.812,5.589c-0.469,2.287 0.297,4.786 1.969,6.419c1.05,1.025 2.436,1.678 3.889,1.894l0,-2.073c-1.154,-0.409 -1.992,-1.573 -2,-2.824c0,0 0.215,-0.96 0.94,-1.003c0.526,-0.032 1.026,0.413 1.059,0.958c0,0.02 0,0.023 0.001,0.068c0.023,0.716 0.961,1.25 1.597,0.774c0.25,-0.187 0.4,-0.49 0.403,-0.805c0.001,-0.11 0.203,-0.821 0.744,-0.964c0.653,-0.172 1.306,0.426 1.247,1.197c-0.087,1.14 -0.929,2.129 -1.991,2.546l0,2.143Z"/>
      </svg>
        </IconPattern>
    )
}

export default IconEasyImagine;
