import React, {useEffect, useState} from 'react';
import {css} from 'emotion';
import {
  getNotification,
  readOneNotification,
  deleteNotification
} from '../../redux/actions/notificationActions';
import history from '../../utils/history';
import {useDispatch, useSelector} from 'react-redux';
import CardNotification from './components/CardNotification';
import IconEasyPageEmpty from '../common/icons/IconEasyPageEmpty';
var page = 0;

const Notification = () => {
  const dispatch = useDispatch();
  /* STATE REDUX */
  const popkey = useSelector(state => state.user.popkey);
  const notifications = useSelector(
    state => state.notification.listNotifications,
  );
  const endreached = useSelector(state => state.notification.endReached);

  /* STATE*/
  const [isFetching, setIsFetching] = useState(false);

  const eventScrollBottom = () => {
    if (window.scrollY >= document.body.scrollHeight - window.innerHeight)
      setIsFetching(true);
  };

  useEffect(() => {
    page = 0;
    dispatch(getNotification(popkey, page)).then(() => {
      page = 1;
    });
    window.addEventListener('scroll', eventScrollBottom);

    return () => {
      window.removeEventListener('scroll', eventScrollBottom);
    };
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    !endreached &&
      dispatch(getNotification(popkey, page)).then(() => {
        page = page + 1;
        setIsFetching(false);
      });
  }, [isFetching]);

  useEffect(() => {
    dispatch(getNotification(popkey, page)).then(() => {
      page = 1;
    });
  }, [popkey]);

  const goToPage = item => {
    dispatch(
      readOneNotification(popkey, item.id_users_message, item.click),
    ).then(() => {


    if (item.type_message === 1) {
      history.push({
        pathname: '/participation',
        search: `?id_citizenpart=${item.id_citizenpart}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 2) {
      history.push({
        pathname: '/participation',
        search: `?id_citizenpart=${item.id_citizenpart}`,
        state: {
          modal: true,
        },
      });

    } else if (item.type_message === 3) {

      history.push({
        pathname: '/participation',
        search: `?id_citizenpart=${item.id_citizenpart}`,
        state: {
          modal: true,
        },
      });

    } else if (item.type_message === 4) {
      history.push({
        pathname: '/participation',
        search: `?id_citizenpart=${item.id_citizenpart}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 5) {
      history.push({
        pathname: '/participation',
        search: `?id_citizenpart=${item.id_citizenpart}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 6) {
      history.push({
        pathname: '/participation',
        search: `?id_citizenpart=${item.id_citizenpart}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 7) {
      history.push({
        pathname: '/actu',
        search: `?id_publicpart=${item.id_publicpart}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 8) {
      history.push({
        pathname: '/alerte',
        search: `?id_publicpart=${item.id_publicpart}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 9) {
      history.push({
        pathname: '/evenement',
        search: `?id_publicpart=${item.id_publicpart}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 10) {
      history.push({
        pathname: '/communaute',
        search: `?id_group=${item.id_group}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 11) {
      history.push({
        pathname: '/participation',
        search: `?id_citizenpart=${item.id_citizenpart}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 12) {
      history.push({
        pathname: '/budget-participatif/details',
        search: `?id_budget_part_project=${item.id_budget_part_project}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 13) {
      history.push({
        pathname: '/budget-participatif/details',
        search: `?id_budget_part_project=${item.id_budget_part_project}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 14) {
      history.push({
        pathname: '/budget-participatif/details',
        search: `?id_budget_part_project=${item.id_budget_part_project}`,
        state: {
          modal: true,
        },
      });
    } else if (item.type_message === 15) {
      history.push({
        pathname: '/budget-participatif/details',
        search: `?id_budget_part_project=${item.id_budget_part_project}`,
        state: {
          modal: true,
        },
      });
    }

    });
  };

  const deleteTheNotification = (id_users_message) => {
    dispatch(deleteNotification(popkey, id_users_message))
  }

  return (
    <>
      <div
        className={css`
          flex: 1;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
        `}>
        {notifications.length > 0 && popkey ? (
          notifications.map((item, i) => {
            if (item.date_deleted_citizenpart === null && (item.comment_active === true || item.comment_active === null))
              return <CardNotification deleteNotification={deleteTheNotification} goToPage={goToPage} key={i} item={item} />;
          })
        ) : (
          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 300px;
            `}>
            <IconEasyPageEmpty size={75} color="#d1d1d1" />
            <div
              className={css`
                margin-top: 20px;
                color: #d1d1d1;
              `}>
              Vous n&apos;avez pas de notifications
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Notification;
