import React from 'react';
import {css} from 'emotion';
import IconEasySignal from '../../../common/icons/IconEasySignal';
import IconEasyTrash from '../../../common/icons/IconEasyTrash';
import IconPen from '../../../common/icons/IconPen';
import IconEasyFb from '../../../common/icons/IconEasyFb';
import IconEasyTwitter from '../../../common/icons/IconEasyTwitter';
import {server_api_url} from '../../../../config/constants';
import history from '../../../../utils/history';

const ModalMore = props => {

  const shareOnFb = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var windowReference = window.open();
    let theurl = server_api_url + "/participation/share/" + props.id_citizenpart;
    windowReference.location = "https://www.facebook.com/sharer/sharer.php?u="+theurl;
  }

  const shareOnTwitter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var windowReference = window.open();
    let theurl = server_api_url + "/participation/share/" + props.id_citizenpart;
    windowReference.location = "https://twitter.com/share?url="+ theurl;
  }

  return (
    <>

    <div
      onClick={props.onHideModal}
      className={css`
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 99;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.02);
        border-radius: 10px;
      `}></div>

      <div
        className={css`
          background-color: white;
          border-radius: 10px;
          position absolute;
          right: 15px;
          top: 15px;
          z-index: 100;
          border: 1px solid rgba(24, 62, 100, 0.3);
          box-sizing: border-box;
          overflow: hidden;
          -moz-box-shadow: 3px 3px 14px 0px rgba(24, 62, 100, 0.1);
          -webkit-box-shadow: 3px 3px 14px 0px rgba(24, 62, 100, 0.1);
          -o-box-shadow: 3px 3px 14px 0px rgba(24, 62, 100, 0.1);
          box-shadow: 3px 3px 14px 0px rgba(24, 62, 100, 0.1);
        `}>

        <div
          onClick={event => {
            props.onOption(event, 1);
          }}
          className={css`
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            &:hover {
              background-color: #ffeeed;
            }
          `}>
          <div>
            <IconEasySignal color={'rgba(24,62,100,1)'} size="20" />
          </div>
          <div
            className={css`
              margin-left: 10px;
              font-size: 13px;
              color: rgba(24, 62, 100, 1);
            `}>
            Signaler
          </div>
        </div>

        {!props.private && props.community === null &&
        <>
          <div
            onClick={event => {
              shareOnFb(event);
            }}
            className={css`
              cursor: pointer;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 10px;
              padding-bottom: 10px;
              &:hover {
                background-color: #ffeeed;
              }
            `}>
            <div>
              <IconEasyFb color={'rgba(24,62,100,1)'} size="20" />
            </div>
            <div
              className={css`
                margin-left: 10px;
                font-size: 13px;
                color: rgba(24, 62, 100, 1);
              `}>
              Partager sur Facebook
            </div>
          </div>

          <div
            onClick={event => {
              shareOnTwitter(event);
            }}
            className={css`
              cursor: pointer;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 10px;
              padding-bottom: 10px;
              &:hover {
                background-color: #ffeeed;
              }
            `}>
            <div>
              <IconEasyTwitter color={'rgba(24,62,100,1)'} size="20" />
            </div>
            <div
              className={css`
                margin-left: 10px;
                font-size: 13px;
                color: rgba(24, 62, 100, 1);
              `}>
              Partager sur Twitter
            </div>
          </div>
        </>
        }


        {props.is_my && (
          <>
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                history.push({
                  pathname: '/edit-participation',
                  state: {
                    navigation: true,
                    participation: props.participation
                  },
                })
              }}
              className={css`
                cursor: pointer;
                padding-top: 10px;
                padding-left: 20px;
                padding-bottom: 10px;
                padding-right: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                &:hover {
                  background-color: rgba(24, 62, 100, 0.1);
                }
              `}>
              <div>
                <IconPen color={'rgba(24,62,100,1)'} size="20" />
              </div>
              <div
                className={css`
                  margin-left: 10px;
                  font-size: 13px;
                  color: rgba(24, 62, 100, 1);
                `}>
                Modifier
              </div>
            </div>

            <div
              onClick={event => {
                props.onOption(event, 2);
              }}
              className={css`
                cursor: pointer;
                padding-top: 10px;
                padding-left: 20px;
                padding-bottom: 10px;
                padding-right: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                &:hover {
                  background-color: rgba(24, 62, 100, 0.1);
                }
              `}>
              <div>
                <IconEasyTrash color={'rgba(24,62,100,1)'} size="20" />
              </div>
              <div
                className={css`
                  margin-left: 10px;
                  font-size: 13px;
                  color: rgba(24, 62, 100, 1);
                `}>
                Supprimer
              </div>
            </div>
          </>
        )}
      </div>


    </>
  );
};

export default ModalMore;
