import React from "react";
import { css } from "emotion";
import ItemNavMobile from "./ItemNavMobile";
import IconEasyGroup from "../../common/icons/IconEasyGroup";
import IconEasyHome from "../../common/icons/IconEasyHome";
import IconEasyBudgetPart from "../../common/icons/IconEasyBudgetPart";
import history from "../../../utils/history";
import { useDispatch, useSelector } from "react-redux";

import { clearPublications } from "../../../redux/actions/publicationActions";
import { clearParticipations } from "../../../redux/actions/participationActions";

var mobileSelection = "/";

const NavBar = (props) => {
  const dispatch = useDispatch();
  const infoCity = useSelector((state) => state.city.infoCity);

  const onPress = (pathname) => {
    if (mobileSelection !== "/" && pathname === "/") {
      dispatch(clearPublications());
      dispatch(clearParticipations());
    }
    mobileSelection = pathname;
    history.push({ pathname: pathname, state: { from: "menu" } });
  };

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        height: 60px;
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 200;
        background-color: white;
        border-top: 1px solid rgba(24, 62, 100, 0.1);
      `}>

              
      {infoCity !== null &&
        typeof infoCity.options !== "undefined" &&
        infoCity.options.participations === 1 && (

          <ItemNavMobile
            location={props.location}
            pathname={"/"}
            onPress={onPress}
            icon={
              <IconEasyHome
                color={
                  props.location.pathname === "/"
                    ? "rgb(245,93,66)"
                    : "rgb(24,62,100)"
                }
                size={30}
              />
            }
          />
          )}
          
      {infoCity !== null &&
        typeof infoCity.options !== "undefined" &&
        infoCity.options.groups === 1 && (
          <ItemNavMobile
            location={props.location}
            pathname={"/communautes"}
            onPress={onPress}
            icon={
              <IconEasyGroup
                color={
                  props.location.pathname === "/communautes"
                    ? "rgb(245,93,66)"
                    : "rgb(24,62,100)"
                }
                size={30}
              />
            }
          />
        )}

      {infoCity !== null &&
        typeof infoCity.options !== "undefined" &&
        infoCity.options.budgetpart === 1 && (
          <ItemNavMobile
            location={props.location}
            pathname={"/budget-participatif"}
            onPress={onPress}
            icon={
              <IconEasyBudgetPart
                color={
                  props.location.pathname === "/budget-participatif"
                    ? "rgb(245,93,66)"
                    : "rgb(24,62,100)"
                }
                size={30}
              />
            }
          />
        )}

    </div>
  );
};

export default NavBar;
