import React from 'react';
import {css} from 'emotion';
const TokenMembers = props => {
  return (
    <div
      className={css`
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
      `}>
      <div
        className={css`
          border-radius: 50%;
          height: 10px;
          width: 10px;
          background-color: ${props.subscribed === 1
            ? '#64c416'
            : props.subscribed === 0 && props.requested === 1
            ? 'orange'
            : '#c41616'};
        `}
      />
      <div
        className={css`
          margin-left: 10px;
          color: ${props.subscribed === 1
            ? '#64c416'
            : props.subscribed === 0 && props.requested === 1
            ? 'orange'
            : '#c41616'};
        `}>
        {props.subscribed === 1
          ? 'Inscrit'
          : props.subscribed === 0 && props.requested === 1
          ? 'En attente'
          : 'Non inscrit'}
      </div>
    </div>
  );
};

export default TokenMembers;
