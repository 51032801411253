import React from 'react';
import './styles.css';
import Avatar from '../../avatar/Avatar';
import {css} from 'emotion';
import HeaderComment from './components/HeaderComment';
import FooterComment from './components/FooterComment';
import {server_img_logos} from '../../../../config/constants';
import reactStringReplace from 'react-string-replace';

const Comment = ({item, ...props}) => {

  const onResponse = () => {
    props.onResponse(item.pseudo, item.id_comment);
  };

  const onDelete = () => {
    props.onDelete(item.id_comment, item.ref_id_comment);
  };

  let replacedText = item.description;
  replacedText = reactStringReplace(replacedText, /(https?:\/\/\S+)/g, (match, i) => (
    <a key={match + i} href={match}>{match}</a>
  ));
  replacedText = reactStringReplace(replacedText, /(www.\S+)/g, (match, i) => (
    <a key={match + i} href={'http://' + match}>
      {match}
    </a>
  ));


  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        width: 100%;
      `}>
      <div style={{marginLeft: item.ref_id_comment ? 100 : 0}}>

        { item.type_user === 1 ? (
          <Avatar size={50} id_avatar={item.avatar} />
        ) : item.type_user === 9 ? (
          <div
            className={css`
              box-sizing: border-box;
              padding-right: 5px;
              padding-top: 5px;
            `}>
            <img
              className={css`
                height: 50px;
              `}
              src={require('../../../../image/logo/popvox_round.png')}
              alt=""
            />
          </div>
        ) : (
          <div
            className={css`
              box-sizing: border-box;
              padding-right: 5px;
              padding-top: 5px;
            `}>
            <img
              className={css`
                height: 50px;
              `}
              src={server_img_logos + item.logo_city}
              alt=""
            />
          </div>
        )}
      </div>
      <div style={{width: '100%'}}>
        <div
          className={css`
            min-height: 50px;
            background-color: ${item.type_user === 1
              ? '#F5F8FA'
              : 'rgba(150, 177, 227,0.1)'};
            flex: 1;
            border-radius: 10px;
            padding: 10px;
            border: 1px solid rgba(24,62,100,0.05);
          `}>
          <HeaderComment
            type_user={item.type_user}
            date={item.date_creation}
            pseudo={item.pseudo}
            nom_comm={item.nom_comm}
          />

          <div
            className={css`
              padding-top: 10px;
              color: #545454;
              white-space: pre-wrap;
              word-break: break-word;
              text-overflow: ellipsis;
            `}>
            {replacedText}
          </div>

        </div>

        <FooterComment
          mycomment={item.mycomment}
          onDelete={onDelete}
          onResponse={onResponse}
          ref_id_comment={item.ref_id_comment}
        />

      </div>
    </div>
  );
};

export default Comment;
