import React from 'react';
import {css} from 'emotion';
import IconEasyRespond from '../../../icons/IconEasyRespond';
import IconEasyTrash from '../../../icons/IconEasyTrash';

const FooterComment = ({mycomment, ...props}) => {

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        algin-items: flex-end;
        justify-content: flex-end;
        color: #22b3c4;
        font-size: 15px;
      `}>

{
  !props.ref_id_comment && 
  <div
  onClick={props.onResponse}
  className={css`
    pointer: cursor;
    padding: 5px;
    &:hover {
      opacity: 0.6;
    }
  `}>
  <IconEasyRespond size={20} color={'#f55d42'} />
</div>

}

      {mycomment && (
        <div
          onClick={props.onDelete}
          className={css`
            margin-left: 10px;
            margin-right: 5px;
            &:hover {
              opacity: 0.6;
            }
            padding: 5px;
          `}>
          <IconEasyTrash size={20} color={'#f55d42'} />
        </div>
      )}

    </div>
  );
};

export default FooterComment;
