import React, {useState} from 'react';
import {css} from 'emotion';
import {useSelector, useDispatch} from 'react-redux';
import {removeParticipation} from '../../../redux/actions/participationActions';
import axios from 'axios';
import {server_api_url} from '../../../config/constants';

const CardOptions = props => {
  const dispatch = useDispatch();
  const popkey = useSelector(state => state.user.popkey);

  const [isSend, setIsSend] = useState(false);

  const onSendSignal = () => {
    axios
      .post(server_api_url + '/participation/signalparticipation', {
        id_citizenpart: props.id_citizenpart,
      })
      .then(() => {
        //props.onBack(e);
        setIsSend(true);
      });
  };

  const onRemoveCard = () => {
    dispatch(removeParticipation(popkey, props.id_citizenpart));
  };

  const onSend = e => {
    e.stopPropagation();
    e.preventDefault();

    if (props.option === 1) {
      onSendSignal();
    } else if (props.option === 2) {
      onRemoveCard();
    } else {
      props.onSharing(e);
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: 100,
        background: 'rgba(0,0,0,0.05)',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box'
      }}>
      <div
        className={css`
        background-color: white;
        padding-top: 20px;
        padding-bottom: 20px;
        zIndex: 101,

        margin-right: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        margin-top: 20px;
        border-radius: 10px;
        border: 1px solid rgba(41, 53, 74, 0.1);
        -moz-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74, 0.1);
        -webkit-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74, 0.1);
        -o-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74, 0.1);
        box-shadow: 3px 3px 14px 0px rgba(41, 53, 74, 0.1);
      `}>
        {!isSend ? (
          <>
            <div>
              <div
                className={css`
                  font-size: 14px;
                  color: #29354a;
                  font-weight: bold;
                  margin-bottom: 10px;
                  text-align: left;
                `}>
                {props.option === 1
                  ? 'Signaler cette participation ?'
                  : props.option === 2
                  ? 'Supprimer cette participation?'
                  : 'Voulez vous partagez cette participation ?'}
              </div>

              <div
                className={css`
                  font-size: 14px;
                  color: #29354a;
                  text-align: left;
                `}>
                {props.option === 1
                  ? 'Voulez vous signaler cette participation comme inapproprié ? Elle sera analysée par les équipes popvox...'
                  : props.option === 2
                  ? 'Voulez-vous supprimer définitivement cette participation ?'
                  : 'Voulez vous partagez cette participation ?'}
              </div>
            </div>

            <div
              className={css`
                margin-left: 20px;
                text-align: center;
              `}>
              <div
                onClick={event => onSend(event)}
                className={css`
                  color: #29354a;
                  margin-top: 10px;
                  font-size: 13px;
                  background-color: #f5f8fa;
                  padding: 5px 10px 5px 10px;
                  width: 80px;
                  border-radius: 10px;
                  border: 1px solid rgba(41, 53, 74, 0.1);
                  &:hover {
                    background-color: #f55d42;
                    color: white;
                    cursor: pointer;
                  }
                `}>
                Oui
              </div>
              <div
                onClick={event => props.onBack(event)}
                className={css`
                  color: #29354a;
                  margin-top: 10px;
                  font-size: 13px;
                  background-color: #f5f8fa;
                  padding: 5px 10px 5px 10px;
                  width: 80px;
                  border-radius: 10px;
                  border: 1px solid rgba(41, 53, 74, 0.1);
                  margin-top: 10px;
                  &:hover {
                    background-color: #f55d42;
                    color: white;
                    cursor: pointer;
                  }
                `}>
                Annuler
              </div>
            </div>
          </>
        ) : (
          <div>
            <div
              className={css`
                font-size: 14px;
                color: #29354a;
                font-weight: bold;
                margin-bottom: 10px;
                text-align: left;
              `}>
              Merci !!
            </div>
            <div
              className={css`
                font-size: 14px;
                color: #29354a;
                text-align: left;
              `}>
              Cette participation a été signalée comme inapproprié et sera
              analysée...
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CardOptions;
