import React, { useState, useEffect } from "react";
import { css } from "emotion";
import FullModal from "../../common/material-easy-ui/modal/FullModal";

import history from "../../../utils/history";
import HeaderPublication from "../common/HeaderPublication";
import QueryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import {
  getNewsDetails,
  getNewsComments,
  postComment,
  removeComment,
  likeNews,
  disLikeNews,
} from "../../../redux/actions/newsActions";
import { server_img_public } from "../../../config/constants";
//import ScrollView from '../../common/scrollView/ScrollView';
import MessageAlerte from "../../common/material-easy-ui/modal/MessageAlerte";
import Connexion from "../../connexion/Connexion";
import AccountNotConfirmedAlert from "../../common/alert/AccountNotConfirmedAlert";

import IconEasyComment from "../../common/icons/IconEasyComment";
import IconEasyLike from "../../common/icons/IconEasyLike";
import CommentInput from "../../common/comment/comment-input/CommentInput";
import ListCommentPublication from "../common/ListCommentPublication";
import PlaceAndDate from "../common/PlaceAndDate";
import IconEasyAlert from "../../common/icons/IconEasyAlert";
import IconEasyNews from "../../common/icons/IconEasyNews";
import IconEasySurvey from "../../common/icons/IconEasySurvey";
import IconEasyEvent from "../../common/icons/IconEasyEvent";

const typeTo = {
  1: {
    color: "#eb5d37",
    icon: <IconEasyAlert size={20} color="white" />,
  },
  2: {
    color: "#3cc8db",
    icon: <IconEasyNews size={20} color="white" />,
  },
  3: {
    color: "#f7AA20",
    icon: <IconEasySurvey size={20} color="white" />,
  },
  6: {
    color: "#de5b84",
    icon: <IconEasyEvent size={20} color="white" />,
  },
};

const get = function (obj, key) {
  return key.split(".").reduce(function (o, x) {
    return typeof o === "undefined" || o === null ? o : o[x];
  }, obj);
};

var page = 0;

// eslint-disable-next-line
const NewsDetails = ({ location, ...props }) => {
  const dispatch = useDispatch();
  const popkey = useSelector((state) => state.user.popkey);
  const info_user = useSelector((state) => state.user.info_user);
  const details = useSelector((state) => state.news.newsDetails);
  const comments = useSelector((state) => state.news.newsComments);
  const endReachedComment = useSelector(
    (state) => state.news.endReachedComment
  );
  const [userResponse, setUserResponse] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [connect, setConnect] = useState(false);
  const [forbidenUserModal, setForbidenUserModal] = useState(false);
  const [notConfirmedUserModal, setNotConfirmedUserModal] = useState(false);

  /* FUNCTION BACK */
  const onBack = () => {
    dispatch({ type: "CLEAN_PUBLICATION" });
    if (get(location, "state.modal")) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    page = 0;
    setIsFetching(true);
    dispatch(
      getNewsDetails(
        popkey,
        QueryString.parse(location.search).id_publicpart,
        location.state
      )
    );
    dispatch(
      getNewsComments(
        popkey,
        QueryString.parse(location.search).id_publicpart,
        0
      )
    ).then(() => {
      page = page + 1;
      setIsFetching(false);
    });
  }, [location.search, location.state, popkey, dispatch]);

  const onResponse = (pseudo, id_comment) => {
    setUserResponse({ pseudo: pseudo, id_comment: id_comment });
  };
  const onReport = () => {};

  const getMoreComment = () => {
    if (!isFetching) {
      setIsFetching(true);
      return dispatch(
        getNewsComments(
          popkey,
          QueryString.parse(location.search).id_publicpart,
          page
        )
      ).then(() => {
        page = page + 1;
        setIsFetching(false);
      });
    }
  };

  const removeToken = () => {
    setUserResponse(null);
  };

  const onSendComment = (text) => {
    if (popkey) {
      if (info_user.account_blocked === 0) {
        const id_ref = userResponse ? userResponse.id_comment : null;
        dispatch(postComment(popkey, details.id_publicpart, id_ref, text)).then(
          () => {
            if (id_ref === null) {
              setTimeout(() => {
                var element = document.getElementById("modalScroller");
                element.scrollTop = element.scrollHeight - element.clientHeight;
              }, 500);
            }
          }
        );

        setUserResponse(null);
      } else if (info_user.account_blocked === 1) setForbidenUserModal(true);
      else setNotConfirmedUserModal(true);
    } else {
      setConnect(true);
    }
  };

  const onDeleteComment = (id_comment, id_ref) => {
    dispatch(removeComment(id_comment, id_ref, details.id_publicpart));
  };

  const onLike = () => {
    if (popkey) {
      if (info_user.account_blocked === 0) {
        if (!details.has_liked)
          dispatch(likeNews(popkey, details.id_publicpart));
        else dispatch(disLikeNews(popkey, details.id_publicpart));
      } else if (info_user.account_blocked === 1) {
        setForbidenUserModal(true);
      } else setNotConfirmedUserModal(true);
    } else {
      setConnect(true);
    }
  };

  if (details && details.type !== undefined) {
    return (
      <>
        <FullModal
          onBack={onBack}
          textHeader="Actualité"
          height={"97%"}
          footer={
            <>
              <CommentInput
                onPress={onSendComment}
                removeToken={removeToken}
                userResponse={userResponse}
                avatar={info_user && info_user.avatar}
              />

              {connect && <Connexion onBack={() => setConnect(false)} />}
              {forbidenUserModal && (
                <MessageAlerte
                  close={() => setForbidenUserModal(false)}
                  text={
                    "Votre compte a été bloqué par la ville ou l'équipe popvox car vous ne respectiez pas les CGU du service. Pour plus d'informations, vous pouvez contacter les équipes popvox (contact@popvox.fr)."
                  }
                  textHeader={"Compte bloqué"}
                />
              )}

              {notConfirmedUserModal && (
                <AccountNotConfirmedAlert
                  close={() => setNotConfirmedUserModal(false)}
                />
              )}
            </>
          }>
            
          <>
            {details.file_url ? (
              <>
                <img
                  alt="illustration de l'Actualité"
                  className={css`
                    width: 100%;
                  `}
                  src={server_img_public + details.file_url}
                />
              </>
            ) : (
              details.youtube_code &&
              details.youtube_code !== "" && (
                <div
                  className={css`
                    position: relative;
                    padding-bottom: 56.25%;
                    padding-top: 25px;
                    height: 0;
                  `}>
                  <iframe
                    className={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                    `}
                    id="ytplayer"
                    type="text/html"
                    width="400"
                    height="260"
                    title={details.youtube_code}
                    src={
                      "https://www.youtube.com/embed/" +
                      details.youtube_code +
                      "?autoplay=0&origin=https://www.popvox.fr"
                    }
                    // eslint-disable-next-line
                    allowfullscreen
                    frameBorder="0"
                  />
                </div>
              )
            )}

            <div
              className={css`
                margin-top: 20px;
                display: flex;
                align-items: center;
                padding-left: 20px;
                padding-right: 20px;
              `}>
              <div
                className={css`
                  height: 40px;
                  width: 40px;
                  background-color: ${typeTo[details.type].color};
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}>
                {typeTo[details.type].icon}
              </div>
              <div
                className={css`
                  flex: 1;
                  text-align: left;
                  padding-left: 10px;
                `}>
                <div
                  className={css`
                    font-weight: 600;
                    color: #29354a;
                  `}>
                  {details.title}
                </div>
                <PlaceAndDate
                  date_begin={details.date_begin}
                  date_end={details.date_end}
                  nom_comm={details.nom_comm}
                />
              </div>
            </div>
            <HeaderPublication
              date_begin={details.date_begin}
              type={details.type}
              community={details.community}
            />

            <div
              className={css`
                box-sizing: border-box;
                margin-top: 20px;
                padding-left: 20px;
                padding-right: 20px;
                color: #29354a;
                font-size: 17px;
              `}>
              <style scoped>
                {`
                  img {
                    max-width: 100%;
                  }
                `}
              </style>

              <div
                className={css`
                  box-sizing: border-box;
                  margin-top: 20px;
                  padding-left: 20px;
                  padding-right: 20px;
                  color: #29354a;
                  font-size: 17px;
                `}
                dangerouslySetInnerHTML={{
                  __html: details.description
                    ? details.description
                        .replace(new RegExp(/href="www/g), 'href="http://www')
                        .replace(new RegExp(/href='www/g), "href='http://www")
                    : "",
                }}
              />
            </div>

            {details.youtube_code &&
              details.youtube_code !== "" &&
              details.file_url && (
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  <iframe
                    id="ytplayer"
                    type="text/html"
                    width="400"
                    height="260"
                    title={details.youtube_code}
                    src={
                      "https://www.youtube.com/embed/" +
                      details.youtube_code +
                      "?autoplay=0&origin=https://www.popvox.fr"
                    }
                    frameBorder="0"
                  />
                </div>
              )}

            <div
              className={css`
                margin-top: 20px;
                padding-left: 20px;
                padding-right: 20px;
                box-sizing: border-box;
              `}>
              <div
                className={css`
                  padding-top: 20px;
                  padding-bottom: 20px;
                  border-bottom: 1px solid rgba(232, 232, 232, 0.8);
                  border-top: 1px solid rgba(232, 232, 232, 0.8);
                  flex-direction: row;
                  display: flex;
                  width: 100%;
                  box-sizing: border-box;
                  justify-content: flex-end;
                  align-items: center;
                `}>
                <div
                  className={css`
                    flex: 1;
                    display: flex;
                    align-items: center;
                  `}>
                  <div
                    onClick={onLike}
                    className={css`
                      cursor: pointer;
                    `}>
                    <IconEasyLike
                      size={30}
                      onPress={onLike}
                      color={details.has_liked ? "#ff006a" : "grey"}
                    />
                  </div>
                  <div
                    className={css`
                      font-size: 13px;
                      color: ${details.has_liked ? "#ff006a" : "grey"};
                    `}>
                    {details.likes}
                  </div>
                </div>
                <div
                  className={css`
                    flex: 1;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                  `}>
                  <IconEasyComment size={30} color={"grey"} />
                  <div
                    className={css`
                      font-size: 13px;
                      color: grey;
                    `}>
                    {details.commentsnb}
                  </div>
                </div>
              </div>
            </div>

            {!endReachedComment && !isFetching ? (
              <div
                className={css`
                  cursor: pointer;
                  color: #f55d42;
                  font-size: 14px;
                  margin-right: 20px;
                  text-align: right;
                  margin-top: 20px;
                  &:hover {
                    text-decoration: underline;
                  }
                `}
                onClick={() => getMoreComment()}>
                Commentaires plus anciens
              </div>
            ) : (
              isFetching && (
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ width: 70, height: 70, marginTop: 30 }}
                    src={require("../../../image/loading.gif")}
                    alt="loading"
                  />
                </div>
              )
            )}

            <ListCommentPublication
              onResponse={onResponse}
              onDelete={onDeleteComment}
              onReport={onReport}
              comments={comments}
            />
          </>
        </FullModal>
      </>
    );
  } else {
    return false;
  }
};

export default NewsDetails;
