import NewsAPI from '../../api/NewsAPI';

export function initialiseNewsDetails(item) {
  return function(dispatch) {
    dispatch({type: 'INITIALISE_NEWS_DETAILS', payload: item});
  };
}

export function getNewsDetails(popkey, id_news) {
  return function(dispatch) {
    dispatch({type: 'GET_NEWS_DETAILS_LOADING'});
    return NewsAPI.getNewsDetails(popkey, id_news).then(data => {
      if (data.error) dispatch({type: 'GET_NEWS_DETAILS_ERROR'});
      else {
        dispatch({
          type: 'GET_NEWS_DETAILS_SUCCCES',
          payload: {
            newsDetails: data.data.newsDetails,
          },
        });
      }
    });
  };
}

export function getNewsComments(popkey, id_news, page) {
  return function(dispatch) {
    dispatch({type: 'GET_NEWS_COMMENT_LOADING'});
    return NewsAPI.getNewsComments(id_news, page, popkey).then(data => {
      if (data.error) {
        dispatch({type: 'GET_NEWS_COMMENT_ERROR'});
      } else {
        if (page === 0) {
          dispatch({
            type: 'GET_NEWS_COMMENT_SUCCES',
            payload: {
              newsComment: data.data.newsComments,
              endReached: data.data.endReached,
            },
          });
        } else {
          dispatch({
            type: 'GET_NEWS_MORE_COMMENT_SUCCES',
            payload: {
              newsComment: data.data.newsComments,
              endReached: data.data.endReached,
            },
          });
        }
      }
    });
  };
}

export function postComment(
  popkey,
  id_news,
  ref_id_comment,
  description
) {
  return function(dispatch) {
    return NewsAPI.postComment(popkey, id_news, ref_id_comment, description)
      .then(data => {
        if (data.error) {
          dispatch({type: 'POST_NEW_COMMENT_ERROR'});
        } else {
          if (data.data.postComment.ref_id_comment === null) {
            dispatch({
              type: 'POST_NEW_COMMENT_SUCCESS',
              id_publicpart: id_news,
              payload: data.data.postComment,
            });
          } else {
            dispatch({
              type: 'POST_NEW_COMMENT_REPLY_SUCCESS',
              id_publicpart: id_news,
              payload: data.data.postComment,
            });
          }
          dispatch({
            type: 'ADD_COMMENT_ON_CARD_PUBLICATION',
            payload: {
              id_publicpart: id_news,
            },
          });
          dispatch({
            type: 'ADD_GROUP_COMMENT_ON_CARD_PUBLICATION',
            payload: {
              id_publicpart: id_news,
            },
          });

        }
      })
      .catch(() => {
        dispatch({type: 'POST_NEW_COMMENT_ERROR'});
      });
  };
}

export function likeNews(popkey, id_news) {
  return function(dispatch) {
    dispatch({type: 'POST_LIKE_NEWS_LOADING'});
    return NewsAPI.postLike(popkey, id_news).then(data => {
      if (data.error) {
        dispatch({type: 'POST_LIKE_NEWS_ERROR'});
      } else {
        dispatch({
          type: 'POST_LIKE_NEWS_SUCCES',
          id_publicpart: id_news,
        });

        dispatch({
          type: 'ADD_LIKE_ON_CARD_PUBLICATION',
          id_publicpart: id_news,
        });
        dispatch({
          type: 'ADD_GROUP_LIKE_ON_CARD_PUBLICATION',
          id_publicpart: id_news,
        });

      }
    });
  };
}

export function disLikeNews(popkey, id_news) {
  return function(dispatch) {
    dispatch({type: 'DIS_LIKE_NEWS_LOADING'});
    return NewsAPI.disLike(popkey, id_news).then(data => {
      if (data.error) {
        dispatch({type: 'DIS_LIKE_NEWS_ERROR'});
      } else {
        dispatch({
          type: 'DIS_LIKE_NEWS_SUCCES',
          id_publicpart: id_news,
        });
        dispatch({
          type: 'REMOVE_LIKE_ON_CARD_PUBLICATION',
          id_publicpart: id_news,
        });
        dispatch({
          type: 'REMOVE_GROUP_LIKE_ON_CARD_PUBLICATION',
          id_publicpart: id_news,
        });
      }
    });
  };
}

export function removeComment(
  id_comment,
  ref_id_comment,
  id_news
) {
  return function(dispatch) {
    dispatch({type: 'DELETE_COMMENT_NEWS_LOADING'});
    return NewsAPI.deleteComment(id_comment, ref_id_comment).then(data => {
      if (data.error) {
        dispatch({type: 'DELETE_COMMENT_NEWS_ERROR'});
      } else {
        dispatch({
          type: 'DELETE_COMMENT_NEWS_SUCCES',
          payload: data.data.id_comment,
          id_publicpart: id_news,
        });

          dispatch({
            type: 'REMOVE_COMMENT_OF_CARD_PUBLICATION',
            payload: {
              id_publicpart: id_news,
            },
          });
          dispatch({
            type: 'REMOVE_GROUP_COMMENT_OF_CARD_PUBLICATION',
            payload: {
              id_publicpart: id_news,
            },
          });
      }
    });
  };
}
