import React, { useEffect, useRef } from "react";
import { css } from "emotion";
import Portal from "./components/Portal";
import HeaderModal from "./components/HeaderModal";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

const FullModal = (props) => {
  const targetRef = useRef(null);

  useEffect(() => {
    targetRef.current !== null && disableBodyScroll(targetRef.current);

    return () => clearAllBodyScrollLocks();
  }, []);

  return (
    <Portal id={props.id}>
      <div
        //onClick={clickModal}

        className={css`
          width: 100%;
          height: 100%;
          position: fixed;
          z-index: 2000;
          top: 0px;
          left: 0px;
          background-color: rgba(0, 0, 0, 0.6);
          justify-content: center;
          align-items: center;
          display: flex;
          max-height: 100vh;
          overflow: hidden;
          touch-action: none;
        `}>

        <div
          className={css`
            animation-duration: 0.2s;
            animation-fill-mode: both;
            animation-name: fadeInUp;
            background: ${props.color ? props.color : "white"};
            height: ${props.height};
            width: 778px;
            border-radius: 5px;
            max-height: 100%;
            display: flex;
            flex-direction: column;
            touch-action: none;
            overflow: hidden;

            @media screen and (max-width: 678px) {
              width: 100%;
              height: 100%;
              border-radius: 0px;
              touch-action: none;
              overflow: hidden;
            }
          `}>

          <HeaderModal
            onBack={() => {
              props.onBack();
            }}
            text={props.textHeader ? props.textHeader : "title"}
          />

          <div
            id="modalScroller"
            className={css`
              overflow-x: hidden;
              overflow-y: scroll;
              -webkit-overflow-scrolling: touch;
              flex: 1;
            `}
            ref={targetRef}>
            {props.children}
          </div>

            {props.footer && props.footer }


        </div>
      </div>
    </Portal>
  );
};

export default FullModal;
