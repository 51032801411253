import React, { useEffect, useState, useRef } from "react";
import { css } from "emotion";
import FullModal from "../common/material-easy-ui/modal/FullModal";
import MessageAlerte from "../common/material-easy-ui/modal/MessageAlerte";
import history from "../../utils/history";
import QueryString from "query-string";

import ParticipationMap from "./components/ParticipationMap";
import AccountNotConfirmedAlert from "../common/alert/AccountNotConfirmedAlert";
import { participationStatusFr } from "../../config/thematics";

import { useSelector, useDispatch } from "react-redux";

import {
  getDetailedCitizenpart,
  getMoreComment,
  postComment,
  removeComment,
  likeCitizenpart,
  unlikeCitizenpart,
} from "../../redux/actions/participationActions";
import { server_img_api } from "../../config/constants";
//import ScrollView from '../common/scrollView/ScrollView';
import CommentInput from "../common/comment/comment-input/CommentInput";

import "moment/locale/fr";
import Connexion from "../connexion/Connexion";
import HeaderParticipationDetails from "./components/HeaderParticipationDetails";
import FullAdminResponse from "../participations/card/components/FullAdminResponse";
import FooterParticipationDetails from "./components/FooterParticipationDetails";
import ListCommentParticipation from "./components/ListCommentParticipation";
import TokenTitle from "../common/material-easy-ui/material/TokenTitle";

// eslint-disable-next-line
const ParticipationDetails = ({ location, ...props }) => {
  const dispatch = useDispatch();

  const popkey = useSelector((state) => state.user.popkey);
  const info_user = useSelector((state) => state.user.info_user);
  const details = useSelector((state) => state.participation.participation);
  const pubanswers = useSelector((state) => state.participation.pubanswers);
  const comments = useSelector((state) => state.participation.listComment);
  const endReachedComment = useSelector(
    (state) => state.participation.endReachedComment
  );
  const loadingComment = useSelector(
    (state) => state.participation.loadingComment
  );
  const [forbidenUserModal, setForbidenUserModal] = useState(false);
  const [deletedModal, setDeletedModal] = useState(false);
  const [userResponse, setUserResponse] = useState(null);
  const [connect, setConnect] = useState(false);
  const [notConfirmedUserModal, setNotConfirmedUserModal] = useState(false);
  const page = useRef(0);
  const [isLoading, setIsLoading] = useState(false);

  const get = function (obj, key) {
    return key.split(".").reduce(function (o, x) {
      return typeof o === "undefined" || o === null ? o : o[x];
    }, obj);
  };

  /* FUNCTION BACK */
  const onBack = () => {
    //dispatch({type:'CLEAN_PARTICIPATION'})
    if (get(location, "state.modal")) {
      history.goBack();
      setTimeout(() => {
        dispatch({ type: "CLEAN_PARTICIPATION" });
      }, 300);
    } else {
      history.push("/");
      setTimeout(() => {
        dispatch({ type: "CLEAN_PARTICIPATION" });
      }, 300);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      page.current = 0;
      dispatch(
        getDetailedCitizenpart(
          popkey,
          QueryString.parse(location.search).id_citizenpart,
          location.state
        )
      ).then(() => {
        setDeletedModal(true);
        setIsLoading(false);
      });
    }
  }, [location.search, location.state, popkey]);

  const getMoreComments = () => {
    if (!loadingComment) {
      page.current++;
      return dispatch(
        getMoreComment(popkey, details.id_citizenpart, page.current)
      );
    }
  };

  const onResponse = (pseudo, id_comment) =>
    setUserResponse({ pseudo: pseudo, id_comment: id_comment });

  const onReport = () => console.log("report");

  const removeToken = () => {
    setUserResponse(null);
  };

  const onSendComment = (text) => {
    if (popkey) {
      if (info_user.account_blocked === 0) {
        if (text.trim().length > 1) {
          const id_ref = userResponse ? userResponse.id_comment : null;
          dispatch(postComment(popkey, details.id_citizenpart, id_ref, text));
          if (id_ref === null) {
            setTimeout(() => {
              var element = document.getElementById("modalScroller");
              element.scrollTop = element.scrollHeight - element.clientHeight;
            }, 300);
          }

          setUserResponse(null);
        }
      } else if (info_user.account_blocked === 1) {
        setForbidenUserModal(true);
      } else setNotConfirmedUserModal(true);
    } else {
      setConnect(true);
    }
  };

  const onDeleteComment = (id_comment, id_ref) => {
    dispatch(removeComment(id_comment, id_ref, details.id_citizenpart));
  };

  const onLike = () => {
    if (popkey) {
      if (info_user.account_blocked === 0) {
        if (!details.has_liked) {
          dispatch(likeCitizenpart(popkey, details.id_citizenpart));
        } else {
          dispatch(unlikeCitizenpart(popkey, details.id_citizenpart));
        }
      } else if (info_user.account_blocked === 1) {
        setForbidenUserModal(true);
      } else {
        setNotConfirmedUserModal(true);
      }
    } else {
      setConnect(true);
    }
  };

  if (details) {
    return (
      <FullModal
        id="fullmodal"
        onBack={onBack}
        textHeader="Participation"
        height={"97%"}
        footer={
          <>
            <CommentInput
              onPress={onSendComment}
              removeToken={removeToken}
              userResponse={userResponse}
              avatar={info_user && info_user.avatar}
            />

            {connect && (
              <Connexion
                onBack={() => {
                  setConnect(false);
                }}
              />
            )}

            {forbidenUserModal && (
              <MessageAlerte
                close={() => setForbidenUserModal(false)}
                text={
                  "Votre compte a été bloqué par la ville ou l'équipe popvox car vous ne respectiez pas les CGU du service. Pour plus d'informations, vous pouvez contacter les équipes popvox (contact@popvox.fr)."
                }
                textHeader={"Compte bloqué"}
              />
            )}

            {notConfirmedUserModal && (
              <AccountNotConfirmedAlert
                close={() => setNotConfirmedUserModal(false)}
              />
            )}
          </>
        }>
        <div>
          {details.file_url ? (
            <img
              alt="illustration de la participation"
              className={css`
                width: 100%;
              `}
              src={server_img_api + details.file_url}
            />
          ) : (
            false
          )}

          <HeaderParticipationDetails
            rubric={details.rubric}
            theme={details.theme}
            type={details.type}
            date_creation={details.date_creation}
            nom_comm={details.nom_comm}
            pseudo={details.pseudo}
            avatar={details.avatar}
          />

          <div
            className={css`
              box-sizing: border-box;
              padding-left: 20px;
              padding-right: 20px;
              color: #29354a;
              font-size: 18px;
              margin-top: 20px;
              margin-bottom: 20px;
            `}>
            {details.description}
          </div>

          {details && details.lon && details.lat && (
            <ParticipationMap
              lon={details.lon}
              lat={details.lat}
              type={details.type}
            />
          )}

          <div
            className={css`
              display: flex;
              flex: 1;
              width: 100%;
              align-items: flex-start;
              box-sizing: border-box;
              padding-left: 20px;
              flex-direction: row;
              padding-right: 20px;
              margin-top: 20px;
              display: wrap;
            `}>
            {details.status !== undefined && (
              <TokenTitle
                style={{ marginLeft: 10, whiteSpace: "nowrap" }}
                background={"#fff"}
                colorText={"rgba(41, 53, 74,1)"}
                text={
                  participationStatusFr[details.status]
                    ? participationStatusFr[details.status]
                    : "Reçu"
                }
              />
            )}

            {details.private && (
              <TokenTitle
                style={{ marginLeft: 10, whiteSpace: "nowrap" }}
                text={"Mode privé"}
                background="#fff"
                colorText="#454545"
              />
            )}

            {!details.community && !details.private && (
              <TokenTitle
                style={{
                  marginLeft: 10,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "block",
                }}
                text="Discussion publique"
                background="#fff"
                colorText="#454545"
              />
            )}

            {details.community && (
              <TokenTitle
                style={{
                  marginLeft: 10,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "block",
                }}
                text={details.community}
                background="#fff"
                colorText="#454545"
              />
            )}
          </div>

          <FooterParticipationDetails
            has_liked={details.has_liked}
            onLike={onLike}
            comments={details.comments}
            likes={details.likes}
          />

          {pubanswers &&
            pubanswers.map((item, index) => {
              return (
                <FullAdminResponse
                  key={"ADMINRESP" + index}
                  logo_city={item.urlimg}
                  text={item.description}
                  file_url={item.file_url}
                  type_user={item.type_user}
                  nom_comm={item.nom_comm}
                  date_creation={item.date_creation}
                />
              );
            })}

          {!endReachedComment && !loadingComment ? (
            <div
              className={css`
                cursor: pointer;
                color: #f55d42;
                font-size: 14px;
                margin-right: 20px;
                text-align: right;
                margin-top: 20px;
                &:hover {
                  text-decoration: underline;
                }
              `}
              onClick={() => getMoreComments()}>
              Commentaires plus anciens
            </div>
          ) : (
            loadingComment && (
              <div style={{ textAlign: "center" }}>
                <img
                  style={{ width: 70, height: 70, marginTop: 30 }}
                  src={require("../../image/loading.gif")}
                  alt="loading"
                />
              </div>
            )
          )}

          <ListCommentParticipation
            onResponse={onResponse}
            onDelete={onDeleteComment}
            onReport={onReport}
            comments={comments}
          />
        </div>
      </FullModal>
    );
  } else if (!isLoading && deletedModal) {
    return (
      <MessageAlerte
        close={() => setDeletedModal(false)}
        text={"Cette participation n'existe plus..."}
        textHeader={"Participation supprimée"}
      />
    );
  }

  return false;
};

export default ParticipationDetails;
