import CityAPI from '../../api/CityAPI';

export function getFavoriteCities(popkey) {
  return function(dispatch) {
    CityAPI.getCitiesByName('', null, null, popkey).then(data => {
      dispatch({
        type: 'FAVORITE_CITIES_SUCCESS',
        payload: data.data,
      });
    });
  };
}

export function getCitiesByName(text, long, lat, popkey) {
  return function(dispatch) {
    if (text === '' || text === null || typeof text === 'undefined') {
      dispatch({type: 'FETCH_CITY_CHOICE_LIST_LOAD'});
      CityAPI.getCitiesByName('', long, lat, popkey).then(data => {
        dispatch({
          type: 'FETCH_CITY_CHOICE_LIST',
          payload: data.data,
        });

        dispatch({
          type: 'FAVORITE_CITIES_SUCCESS',
          payload: data.data,
        });
      });
    } else {
      dispatch({type: 'FETCH_CITY_CHOICE_LIST_LOAD'});
      CityAPI.getCitiesByName(text, long, lat, popkey).then(data => {
        dispatch({
          type: 'FETCH_CITY_CHOICE_LIST',
          payload: data.data,
        });
      });
    }
  };
}

export function getCityInfo(popkey, code_insee) {
  return function(dispatch) {
    CityAPI.getCityInformation(popkey, code_insee).then(data => {
      dispatch({type: 'SET_CITY_INFOS', payload: data.data});
    });
  };
}

export function setCityInfo(item) {
  return function(dispatch) {
    dispatch({type: 'SET_CITY_INFOS', payload: item});
  };
}

export function addfavoritecity(popkey, insee_com) {
  return function(dispatch) {
    CityAPI.addfavoritecity(popkey, insee_com).then(() => {
      dispatch({type: 'ADD_FAVORITE_CITY'});
    });
  };
}


export function removefavoritecity(popkey, insee_com) {
  return function(dispatch) {
    CityAPI.removefavoritecity(popkey, insee_com).then(() => {
      dispatch({type: 'REMOVE_FAVORITE_CITY'});
    });
  };
}
