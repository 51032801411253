import React from "react";
import { css } from "emotion";
import { participationTypesShort } from "../../config/thematics";

const TypeShortNameParticipation = (props) => {


  return (
    <span
      className={css`
        color: ${participationTypesShort[props.type].color};
      `}>
      {" "+participationTypesShort[props.type].text}
    </span>
  );
};

export default TypeShortNameParticipation;
