import React, {useState, useEffect} from 'react';
import FullModal from '../common/material-easy-ui/modal/FullModal';

import './styles.css';

import UserAPI from '../../api/UserAPI';
import SigninStepPassword from './components/SigninStepPassword';
import SigninStepPersonnalData from './components/SigninStepPersonnalData';
import SigninStepAvatar from './components/SigninStepAvatar';
import {createAccount, updateAvatar} from '../../redux/actions/userActions';
import {useDispatch, useSelector} from 'react-redux';
import history from '../../utils/history';
import Button from '../common/forms/buttons/Button';

const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const SignInStep = () => {
  const dispatch = useDispatch();

  // const [code, setCode] = useState(null);
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState(1);
  const [pseudo, setPseudo] = useState('');
  const [step, setStep] = useState(1);
  // const [error_code, setError_code] = useState(null);
  const [error_password, setError_password] = useState(null);
  const [error_pseudo, setError_pseudo] = useState(null);
  const [error_firstname, setError_firstname] = useState(null);
  const [error_lastname, setError_lastname] = useState(null);
  const [error_email, setError_email] = useState(null);
  const [secondPassword, setSecondPassword] = useState('');

  /*REDUX*/
  const popkey = useSelector(state => state.user.popkey);

  useEffect(() => {
    if (!pseudo.match(/^([a-zA-Z0-9-_]{1,36})$/) && !pseudo === '')
      setError_pseudo({
        error: true,
        message: "Votre pseudo n'est pas au bon format",
      });
    else {
      setError_pseudo(null);
    }
  }, [pseudo]);

  // const sendCode = () => {
  //   setError_code(false);
  //   UserAPI.whatisguys(parseInt(code)).then(data => {
  //     if (data && data.data.email) {
  //       setEmail(data.data.email);
  //       setStep(1);
  //     } else if (data && data.data.error) {
  //       console.log('error');
  //       setError_code(true);
  //     }
  //   });
  // };

  // const onChangeCode = e => {
  //   setCode(e.target.value);
  // };

  const onChangePassword = e => {
    setPassword(e.target.value);
    if (secondPassword !== e.target.value) {
      setError_password({
        error: true,
        message: 'Les deux champs de mot de passe ne sont pas les mêmes'
      })
    }
    setError_password(null);
  };

  const onChangeSecondpasswordPassword = e => {
    setSecondPassword(e.target.value);
    if (password !== e.target.value) {
      setError_password({
        error: true,
        message: 'Les deux champs de mot de passe ne sont pas les mêmes'
      })
    } else  {
      setError_password(null)
    }
    setError_password(null);
  };

  const onChangePseudo = e => {
    setPseudo(e.target.value);
  };

  const onChangeFirstname = e => {
    setFirstname(e.target.value);
  };

  const onChangeLastname = e => {
    setLastname(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1).toLowerCase());
  };

  const sendPassword = () => {
    onSend();
    if (password !== secondPassword) {
      setError_password({
        error: true,
        message:
          'Les deux champs de mot de passe ne sont pas les mêmes',
      });
    } else if ((password.length >= 4 && !/\s/.test(password))) {
      setStep(2);
    } else {
      setError_password({
        error: true,
        message:
          'Votre mot de passe doit contenir au moins 4 caractères et aucun espace',
      });
    }
  };

  const onBlurPassword = e => {
    if (
      (e.target.value.length >= 4 && !/\s/.test(e.target.value)) ||
      e.target.value === ''
    ) {
      setError_password(null);
    } else {
      setError_password({
        error: true,
        message:
          'Votre mot de passe doit contenir au moins 4 caractères et aucun espace',
      });
    }
  };

  const goCreateAccount = () => {
    if (firstname.trim() == '') {
      setError_firstname({error: true, message: 'Votre prénom est vide'});
    } else if (lastname.trim() === '') {
      setError_lastname({error: true, message: 'Votre nom est vide'});
    } else if (pseudo.trim() === ''){
      setError_pseudo({error: true, message: 'Votre pseudo est vide'});
    } else if (pseudo.includes(' ')) {
      setError_pseudo({error: true, message: 'Votre pseudo ne doit pas contenir d\'espace !'});
    } else if (error_pseudo && error_pseudo.error && error_pseudo.error === true) {
      setError_pseudo({error: true, message: error_pseudo.message});
    } else {
      UserAPI.testPseudo(pseudo).then(data => {
        if (data.data.pseudoExist) {
          setError_pseudo({error: true, message: 'Ce pseudo est déjà pris'});
        } else {
          dispatch(
            createAccount(email, firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase(), lastname.charAt(0).toUpperCase() + lastname.slice(1).toLowerCase(), password, pseudo, avatar),
          )
            .then(() => {
              setStep(3);
            })
            .catch((err) => console.log(err));
        }
      });
    }

  };

  const pseudoTesting = e => {
    if (!error_pseudo) {
      UserAPI.testPseudo(e.target.value).then(data => {
        if (data.data.pseudoExist) {
          setError_pseudo({error: true, message: 'Ce pseudo est déjà pris'});
        } else {
          setError_pseudo({error: false, message: 'Le pseudo est valide'});
        }
      });
    }
  };

  const onBlurFirstname = () => {
    if (firstname.trim() === '')
      setError_firstname({error: true, message: 'Votre prénom est vide'});
    else setError_firstname(null);
  };

  const onBlurLastname = () => {
    if (lastname.trim() === '')
      setError_lastname({error: true, message: 'Votre nom est vide'});
    else setError_lastname(null);
  };

  const chooseAvatar = item => {
    setAvatar(item);
  };

  const onUpdateAvatar = () => {
    dispatch(updateAvatar(popkey, avatar)).then(() => {
      setStep(4);
      UserAPI.sendMail(email);
    });
  };

  const onBack = () => {
    if (document.referrer === "") {
      history.replace('/');
    } else {
      if(history.length > 0) {
        history.go(-1);
      } else {
        history.replace('/');
      }
    }
  };

  const emailExist = email => {
    return UserAPI.existTestEmail(email).then(data => {
      return data.data.emailExist;
    });
  };

  const _handleKeyDown = e => {
    if (e.key === 'Enter') {
      onSend();
    }
  };

  const onChangeEmail = e => {
    setEmail(e.target.value.trim().toLowerCase());
    if(error_email !== null) {
      setError_email(null);
    }
  };

  const clearInput = () => {
    setEmail('');
    setError_email(null);
  }

  async function onBlurEmail(e) {
    if (e.target.value !== '') {
      if (validateEmail(e.target.value)) {
        if (await emailExist(e.target.value)) {
          setError_email({
            error: true,
            message: "L'adresse email fournie existe déjà.",
          });
        } else {
          setError_email(null);
        }
      } else {
        setError_email({
          error: true,
          message: "L'adresse email fournie est incorrecte.",
        });
      }
    } else {
      setError_email(null);
    }
  }

  async function onSend() {
    if (email !== '') {
      if (validateEmail(email)) {
        if (await emailExist(email)) {
          setError_email({
            error: true,
            message: "L'adresse email fournie existe déjà.",
          });
        } else
          setError_email(null);
      } else {
        setError_email({
          error: true,
          message: "L'adresse email fournie est incorrecte.",
        });
      }
    } else {
      setError_email(null);
    }
  }

  return (
    <FullModal 
      onBack={onBack} 
      textHeader="Inscription">

      <div style={{padding: 10}}>
        {step === 1 ? (
          <SigninStepPassword
            onChangePassword={onChangePassword}
            password={password}
            sendPassword={sendPassword}
            error_password={error_password}
            onBlurPassword={onBlurPassword}
            onBlurEmail={onBlurEmail}
            clearInput={clearInput}
            onChangeEmail={onChangeEmail}
            email={email}
            error_email={error_email}
            _handleKeyDown={_handleKeyDown}
            secondPassword={secondPassword}
            setSecondPassword={onChangeSecondpasswordPassword}
          />
        ) : step === 2 ? (
          <SigninStepPersonnalData
            goCreateAccount={goCreateAccount}
            pseudo={pseudo}
            firstname={firstname}
            lastName={lastname}
            onChangePseudo={onChangePseudo}
            onChangeFirstname={onChangeFirstname}
            onChangeLastname={onChangeLastname}
            error_pseudo={error_pseudo}
            error_firstname={error_firstname}
            error_lastname={error_lastname}
            onBlurPseudo={pseudoTesting}
            onBlurLastname={onBlurLastname}
            onBlurFirstname={onBlurFirstname}
          />
        ) : step === 3 ? (
          <SigninStepAvatar
            onUpdateAvatar={onUpdateAvatar}
            chooseAvatar={chooseAvatar}
            avatar={avatar}
          />
        ) : step === 4 && (
          <div style={{margin: 20}}>
            <div style={{marginTop: 20}}>
              Félicitations ! Votre compte PopVox a été créé.
            </div>
            <div style={{marginTop: 20}}>
              Afin d&apos;accéder à toutes les fonctionnalités PopVox, veuillez confirmer votre compte en utilisant le lien que vous avez reçu par mail.
            </div>

            <div
              style={{
                marginBottom: 10,
                marginTop: 10,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                float: 'right'
              }}>
              <Button onClick={onBack}>OK</Button>
            </div>
          </div>
        )}
      </div>
    </FullModal>
  );
};

export default SignInStep;
