"use strict";

const initialState = {
  infoCity: null,
  city_communities: [],
  alertNb: 0,
  listCity: [],
  load_list_city: false,
  citySuggestAppByUser: [],
  favoriteCities: [],
};

export function cityReducers(state = initialState, action) {
  switch (action.type) {
    case "SUGGEST_APP_CITY_BY_USER":
      return {
        ...state,
        citySuggestAppByUser: action.payload,
      };
    case "ADD_CITY_TO_APP_SUGGEST":
      return {
        ...state,
        citySuggestAppByUser: [...state.citySuggestAppByUser, action.payload],
      };

    case "FAVORITE_CITIES_SUCCESS":
      return {
        ...state,
        favoriteCities: action.payload,
      };

    case "FETCH_CITY_CHOICE_LIST_LOAD":
      return {
        ...state,
        load_list_city: true,
      };

    case "FETCH_CITY_CHOICE_LIST":
      return {
        ...state,
        listCity: action.payload,
        load_list_city: false,
      };

    case "CLEAR_LIST_CITY":
      return {
        ...state,
        listCity: [],
      };

    //NEW !!
    case "SET_CITY_INFOS":
      return {
        ...state,
        infoCity: action.payload.city,
        city_communities: action.payload.communities,
        alertNb: action.payload.alertNb,
      };

    case "ADD_FAVORITE_CITY":
      return {
        ...state,
        infoCity: { ...state.infoCity, favorite: true },
      };

    case "REMOVE_FAVORITE_CITY":
      return {
        ...state,
        infoCity: { ...state.infoCity, favorite: false },
      };

    default:
      return state;
  }
}
