import React, { useEffect, useState } from "react";

import FullModal from "../common/material-easy-ui/modal/FullModal";

import ParticipateMenu from "./participate-menu/ParticipateMenu";
import SelectCommunity from "./participate-step/SelectCommunity";
import ParticipateDescription from "./participate-step/ParticipateDescription";

import Connexion from "../connexion/Connexion";
import MessageAlerte from "../common/material-easy-ui/modal/MessageAlerte";
import PointsNotification from "../common/material-easy-ui/modal/PointsNotification";
import AccountNotConfirmedAlert from "../common/alert/AccountNotConfirmedAlert";

import { participationTypesFr } from "../../config/thematics";

import { useSelector, useDispatch } from "react-redux";
import { sendParticipation } from "../../redux/actions/participationActions";
import history from "../../utils/history";
import GroupAPI from "../../api/GroupAPI";
import QueryString from "query-string";

const Participate = (props) => {
  const dispatch = useDispatch();

  const infoCity = useSelector((state) => state.city.infoCity);
  const popkey = useSelector((state) => state.user.popkey);
  const info_user = useSelector((state) => state.user.info_user);
  const user_communities = useSelector((state) => state.user.communities);
  const city_communities = useSelector((state) => state.city.city_communities);

  //VALEURS CHOISIES PAR L'UTILISATEUR
  const [type, setType] = useState(null);
  const [theme, setTheme] = useState(null);
  const [rubric, setRubric] = useState(null);
  const [description, setDescription] = useState("");
  const [uriPicture, setUriPicture] = useState(null);
  const [adressPart, setAdressPart] = useState("");
  const [coordPart, setCoordPart] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [idCommunity, setIdCommunity] = useState(
    QueryString.parse(props.location.search).id_community
      ? QueryString.parse(props.location.search).id_community
      : null
  );

  /*const [communityChoose, setCommunityChoose] = useState({
    id: null,
    title: infoCity.nom_comm + " (PUBLIC)",
    code_insee: infoCity.insee_com,
    private: false,
  });*/

  //VALEURS GLOBALES A CHOISIR :
  const [allTheThematics, setAllTheThematics] = useState([]);


  const [allTheThemes, setAllTheThemes] = useState(null);
  const [allTheRubrics, setAllTheRubrics] = useState(null);


  //CAMERA MAPBOX
  const [centerCoordPart, setCenterCoordPart] = useState([
    infoCity.lon,
    infoCity.lat,
  ]);

  //POPUP CONNECT & FORMULAIRE STEP
  const [step, setStep] = useState(QueryString.parse(props.location.search).id_community ? 0 : -1);
  const [connect, setConnect] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [forbidenUserModal, setForbidenUserModal] = useState(false);
  const [notConfirmedUserModal, setNotConfirmedUserModal] = useState(false);
  const [pointNotification, setPointNotification] = useState(false);

  const onSend = () => {
    if (
      description && description.trim() !== "" &&
      (theme !== null || allTheThemes == null || allTheThemes.length == 0) &&
      (rubric || allTheRubrics == null || allTheRubrics.length === 0)
    ) {
      if (!isSending) {
        setIsSending(true);
        if (popkey) {
          if (info_user.account_blocked === 0) {
            let participation = {};
            participation.type = type;
            participation.theme = theme;
            participation.rubric = rubric;
            participation.description = description;
            participation.private = isPrivate;
            participation.community = idCommunity;

            if (coordPart !== null) {
              participation.lat = coordPart[1];
              participation.lng = coordPart[0];
            } else {
              participation.lat = null;
              participation.lng = null;
            }
            participation.file_url = uriPicture ? uriPicture.pathName : null;
            participation.fulladdress =
              adressPart.trim() === "" ? null : adressPart;

            if (participation.file_url || participation.description !== "") {
              dispatch(
                sendParticipation(popkey, participation, infoCity.insee_com)
              ).then(() => {
                setIsSending(false);
                setPointNotification(true);
              });
            }
          } else if (info_user.account_blocked === 1) {
            setForbidenUserModal(true);
            setIsSending(false);
          } else {
            setNotConfirmedUserModal(true);
            setIsSending(false);
          }
        } else {
          setConnect(true);
          setIsSending(false);
        }
      }
    } else {
      alert("Merci de remplir tous les champs obligatoires...");
    }

    /*if (
      (props.uriPicture || props.description !== "") &&
      props.theme !== null
    ) {
      
      if (!isSending) {
        setIsSending(true);
        if (popkey) {
          if (info_user.account_blocked === 0) {
            let participation = {};
            participation.type = type;
            participation.theme = theme;
            participation.rubric = rubric;
            participation.description = description;
            participation.private = isPrivate;
            participation.community = idCommunity;

            if (coordPart !== null) {
              participation.lat = coordPart[1];
              participation.lng = coordPart[0];
            } else {
              participation.lat = null;
              participation.lng = null;
            }
            participation.file_url = uriPicture ? uriPicture.pathName : null;
            participation.fulladdress =
              adressPart.trim() === "" ? null : adressPart;

            if (participation.file_url || participation.description !== "") {
              dispatch(
                sendParticipation(popkey, participation, infoCity.insee_com)
              ).then(() => {
                setIsSending(false);
                setPointNotification(true);
              });
            }
          } else if (info_user.account_blocked === 1) {
            setForbidenUserModal(true);
            setIsSending(false);
          } else {
            setNotConfirmedUserModal(true);
            setIsSending(false);
          }
        } else {
          setConnect(true);
          setIsSending(false);
        }
      }
    } else {
      alert("Merci de remplir à minima une description ou une photo");
    }*/
  };

  const get = function (obj, key) {
    return key.split(".").reduce(function (o, x) {
      return typeof o === "undefined" || o === null ? o : o[x];
    }, obj);
  };

  /* FUNCTION BACK */
  const onBack = () => {
    //dispatch({type:'CLEAN_PARTICIPATION'})
    if (get(props.location, "state.modal")) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  /* ACTION BUTTON HEADER */
  const onBackDetails = () => {
    setConnect(false);
  };

  const onSelectType = (id) => {
    const thethemesselected = allTheThematics.types
    .find((i) => i.id === id);
    setAllTheThemes(thethemesselected.themes);
    setType(id);
    setTheme(null);
    setAllTheRubrics(null);
    setStep(1);
  };

  const onSelectCommunity = (id_community, private_status) => {
    setIdCommunity(id_community);
    setIsPrivate(private_status);

    if (id_community === null) {
      setAllTheThematics(infoCity.thematics);
      setStep(0);
    } else {
      GroupAPI.getCommunityThematics(popkey, id_community).then((data) => {
        setAllTheThematics(data.data.thematics);
        if (data.data.thematics && data.data.thematics.types.length === 1) {
          onSelectType(data.data.thematics.types[0].id);
        } else {
          setStep(0);
        }
      });
    }
  };

  const textHeaderParticipate = () => {
    if (step === -1) return "Choisir une communauté";
    else if (step > 0) return `${participationTypesFr[type]}`;
    else return "Participez";
  };

  const onNextStep = () => {
    setStep(step + 1);
  };

  const onBackStep = () => {
    let stepTmp = step - 1;
    setStep(stepTmp);
  };

  /* ADRESS */
  const setAdress = (text) => setAdressPart(text);

  const setCoord = (lng, lat) => {
    if (lat !== null && lng !== null) {
      setCoordPart([lng, lat]);
    } else {
      setCoordPart(null);
    }
  };

  const setCenterCoord = (lng, lat) => setCenterCoordPart([lng, lat]);

  const onChooseTheme = (id) => {
    if (theme != id) {
      setTheme(id);

      const rubrics = allTheThematics.types
        .find((i) => i.id === type)
        .themes.find((i) => i.id === id);

      if (rubrics) {
        if (rubrics.rubrics.length === 1) {
          setRubric(rubrics.rubrics[0]);
        } else {
          setAllTheRubrics(rubrics.rubrics);
        }

        if (rubrics.rubrics.length === 0) {
          setRubric(null);
        }
      }

    } else {
      setTheme(null);
      setAllTheRubrics(null);
    }
  };

  useEffect(() => {
    if (QueryString.parse(props.location.search).id_community) {
      let this_default_private_status = false;
      for (let i = 0; i < city_communities.length; i++) {
        if (city_communities[i].id == QueryString.parse(props.location.search).id_community) {
          this_default_private_status = city_communities[i].default_private_status;
        }
      }
      onSelectCommunity(QueryString.parse(props.location.search).id_community, this_default_private_status );
    }
  }, []);


  useEffect(() => {
    setRubric(null);
  }, [theme]);

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  function onUploadPicture(file) {
    setUriPicture(file);
  }

  function onRemovePicture() {
    setUriPicture(null);
  }

  return (
    <FullModal
      id="modal-participate-menu"
      onBack={onBack}
      textHeader={`${textHeaderParticipate()}`}
      height={"95%"}
      color={"#F5F8FA"}
      footer={
        <>
          {connect && <Connexion onBack={onBackDetails} />}

          {notConfirmedUserModal && (
            <AccountNotConfirmedAlert
              close={() => setNotConfirmedUserModal(false)}
            />
          )}

          {forbidenUserModal && (
            <MessageAlerte
              close={() => setForbidenUserModal(false)}
              text={
                "Votre compte a été bloqué par la ville ou l'équipe popvox car vous ne respectiez pas les CGU du service. Pour plus d'informations, vous pouvez contacter les équipes popvox (contact@popvox.fr)."
              }
              textHeader={"Compte bloqué"}
            />
          )}

          {pointNotification && (
            <PointsNotification type={type} close={() => history.goBack()} />
          )}
        </>
      }>

      {step === -1 ? (
        <SelectCommunity
          onSelectCommunity={onSelectCommunity}
          user_communities={user_communities}
          city_communities={city_communities}
          infoCity={infoCity}
          setAllTheThematics={setAllTheThematics}
          setStep={setStep}
        />
      ) : step === 0 ? (
        <ParticipateMenu
          onSelect={onSelectType}
          onBackStep={onBackStep}
          onNextStep={onNextStep}
          thematics={allTheThematics}
        />
      ) : (
        step === 1 && (
          <ParticipateDescription
            isFull={
              description && description.trim() !== "" &&
              (theme !== null || allTheThemes == null || allTheThemes.length == 0) &&
              (rubric || allTheRubrics == null || allTheRubrics.length === 0)
            }
            setAdress={setAdress}
            setCoord={setCoord}
            setCenterCoord={setCenterCoord}
            coord={coordPart}
            centerCoord={centerCoordPart}
            adress={adressPart}
            onBackStep={onBackStep}
            onNextStep={onNextStep}
            type={type}
            onChooseTheme={onChooseTheme}
            setRubric={setRubric}
            theme={theme}
            allTheRubrics={allTheRubrics}
            rubric={rubric}
            onUploadPicture={onUploadPicture}
            onRemovePicture={onRemovePicture}
            uriPicture={uriPicture}
            description={description}
            onSend={onSend}
            onChangeDescription={onChangeDescription}
            allTheThematics={allTheThematics}
          />
        )
      )}

    </FullModal>
  );
};

export default Participate;
