import React, {useState} from 'react';
import {css} from 'emotion';
import IconEasyClose from '../../icons/IconEasyClose';
import IconEasyLoad from '../../icons/IconEasyLoad';


const CloseSimpleButton = props => {

  const [, setHover] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={props.onPress}
      className={css`
            border-radius:50%;
            padding:2px;
            box-sizing:border-box;
            cursor:pointer;
            display:flex;
            justify-content:center;
              border:1px solid transparent;
            align-items:center;
             transition: 0.1s;
            &:hover {
              border:1px solid white;
              padding:6px;
            }
            `}
      style={{...props.style}}>
      {props.load ?
        <div className={css`animation:spin 1s linear infinite; `}>
          <IconEasyLoad
          size={20}
          color={'white'}
        />
      </div>
        : <IconEasyClose
        size={20}
        color={'white'}
      />
    }
    </div>
  );
};

export default CloseSimpleButton;
