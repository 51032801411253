import axios from "axios";
import { server_api_url } from "../config/constants";

class UserAPI {

  static getUserInfo(popkey) {
    return axios.get(server_api_url + "/users/getuserinfo", {
      params: {
        popkey: popkey
      }
    });
  }
  
  static forgotPwd(email) {
    return axios.get(server_api_url + "/users/forgotpwd", {
      params: {
        email: email
      }
    });
  }

  static deleteUser(popkey) {
    return axios.post(server_api_url + "/users/deleteuser", {
      popkey: popkey
    });
  }


  static logIn(email, pwd) {
    return axios.get(server_api_url + "/users/login", {
      params: {
        email: email,
        pwd: pwd
      }
    });
  }

  static testPseudo(pseudo) {
    return axios.get(server_api_url + "/users/testpseudo", {
      params: {
        pseudo: pseudo
      }
    });
  }

  static registerUser(email, firstName, lastName, pwd, pseudo, avatar) {
    return axios.post(server_api_url + "/users/register", {
      email: email,
      firstName: firstName,
      lastName: lastName,
      pwd: pwd,
      pseudo: pseudo,
      avatar: avatar
    });
  }

  static modifyUserInfos(popkey, firstName, lastName, pseudo, avatar) {
    return axios.post(server_api_url + "/users/modify", {
      firstName: firstName,
      lastName: lastName,
      pseudo: pseudo,
      popkey: popkey,
      avatar: avatar
    });
  }

  static modifyAvatar(popkey, avatar) {
    return axios.post(server_api_url + "/users/modifyavatar", {
      avatar: avatar,
      popkey: popkey
    });
  }

  static modifyPwd(popkey, pwd, currentPassword) {
    return axios.post(server_api_url + "/users/modifypwd", {
      pwd: pwd,
      popkey: popkey,
      oldpwd: currentPassword
    });
  }

  static existTestEmail(email) {
    return axios.get(server_api_url + "/users/emailtest", {
      params: {
        email: email
      }
    });
  }

  static sendMail(email) {
    return axios.post(server_api_url + "/users/sendmail", {
      email: email
    });
  }

  static whatisguys(code) {
    return axios.get(server_api_url + "/users/whatisguys", {
      params: {
        code: code
      }
    });
  }


  static addPoints(popkey, type) {
    return axios.post(server_api_url + "/points/addpoints", {
      popkey: popkey,
      type: type
    });
  }

  static confirmAccount(email) {
    return axios.post(server_api_url + "/users/confirm_account", {
      email: email
    });
  }


}

export default UserAPI;
