import React, {useRef} from 'react';

import Media from 'react-media';
import {isMobile} from '../../../config/constants';
import MyContext from '../../../utils/MyContext';
import {css} from 'emotion';

const GridResponsive = props => {
  const refGrid = useRef(null);

  return (
    <>

      {props.header}

      <div
        className={css`
          display: flex;
          flex-direction: row;
          flex: 1;
        `}>
        <Media query={`(max-width: ${isMobile})`}>

          {matches =>
            !matches ? (
              <div
                className={css`
                  width: calc(50% - 550px);
                  min-width: 230px;
                  @media (max-width: 1510px) {
                    width: 230px;
                    @media (max-width: 1200px) {
                      width: 90px;
                      min-width: 90px;
                    }
                  }
                `}>
                  
                <div
                  className={css`
                    z-index: 20;
                    position: fixed;
                    width: inherit;
                  `}>
                  {props.nav}
                </div>

              </div>
            ) : (
              props.nav_mobile
            )
          }

        </Media>

        <div
          ref={refGrid}
          className={css`
            width: 1020px;
            min-height: calc(100vh - 60px);
            border-left: 1px solid #ebebeb;
            border-right: 1px solid #ebebeb;
            padding-top: 60px;
            @media (max-width: 1510px) {
              width: calc(100% - 230px - 300px);
              @media (max-width: 1200px) {
                width: calc(100% - 90px - 300px);
                @media (max-width: 1100px) {
                  width: calc(100% - 90px);
                  @media (max-width: ${isMobile}) {
                    width: calc(100%);
                  }
                }
              }
            }
          `}>
          {props.flux}
        </div>

        <MyContext.Provider value={refGrid}>
          <Media query={`(max-width: 1100px)`}>
            {matches =>
              !matches ? (
                <div
                  className={css`
                    width: calc(50% - 470px);
                    max-width: 100%;
                    padding-top: 60px;
                    position: relative;
                    @media (max-width: 1510px) {
                      width: 300px;
                    }
                  `}>
                  {props.right_content}
                </div>
              ) : (
                false
              )
            }
          </Media>
        </MyContext.Provider>

      </div>

      <Media query={`(max-width: ${isMobile})`}>
        {matches => (!matches ? false : props.nav_mobile)}
      </Media>

    </>
  );
};

export default GridResponsive;
