import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyClose = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
        viewBox="0 0 24 24">
        <rect fill={props.color} height="2" transform="matrix(0.7071068 -0.7071068 0.7071068 0.7071068 -4.9705629 12)" width="24.6274166" x="-0.3137085" y="11"/>
        <rect fill={props.color} height="24.6274166" transform="matrix(0.7071068 -0.7071068 0.7071068 0.7071068 -4.9705629 12)" width="2" x="11" y="-0.3137085"/>
        </svg>
        </IconPattern>
    )
}

export default IconEasyClose;
