import React from "react";
import "./styles.css";
import IconEasyGroup from "../../common/icons/IconEasyGroup";
import { useSelector } from "react-redux";

import IconEasyHome from "../../common/icons/IconEasyHome";
import IconEasyBudgetPart from "../../common/icons/IconEasyBudgetPart";

import history from "../../../utils/history";

import ItemNav from "./ItemNav";

var selection = "/";

const SideNav = (props) => {
  const infoCity = useSelector((state) => state.city.infoCity);

  const onPress = (pathname) => {
    selection = pathname;
    history.push({ pathname: pathname, state: { from: "menu" } });
  };

  return (
    <div className="container-sidenav">
      
      {infoCity !== null &&
        typeof infoCity.options !== "undefined" &&
        infoCity.options.participations === 1 && (
          <ItemNav
            /*'/alerte', '/participation', '/actu', '/sondage', '/evenement' */
            onPress={onPress}
            location={props.location}
            pathname={"/"}
            colorNames={["/"]}
            icon={
              <IconEasyHome
                size={24}
                color={
                  ["/"].includes(props.location.pathname)
                    ? "#f55d42"
                    : "#29354a"
                }
              />
            }
            text="En direct"
          />
        )}

      {infoCity !== null &&
        typeof infoCity.options !== "undefined" &&
        infoCity.options.groups === 1 && (
          <ItemNav
            onPress={onPress}
            location={props.location}
            pathname={"/communautes"}
            colorNames={["/communautes", "/communaute"]}
            icon={
              <IconEasyGroup
                size={30}
                color={
                  ["/communautes", "/communaute"].includes(
                    props.location.pathname
                  )
                    ? "#f55d42"
                    : "#29354a"
                }
              />
            }
            text="Communautés"
          />
        )}

      {infoCity !== null &&
        typeof infoCity.options !== "undefined" &&
        infoCity.options.budgetpart === 1 && (
          <ItemNav
            onPress={onPress}
            location={props.location}
            pathname={"/budget-participatif"}
            colorNames={["/budget-participatif"]}
            selection={selection}
            icon={
              <IconEasyBudgetPart
                size={26}
                color={
                  props.location.pathname === "/budget-participatif"
                    ? "#f55d42"
                    : "#29354a"
                }
              />
            }
            text="Budget Participatif"
          />
        )}
    </div>
  );
};

export default SideNav;
