import React from 'react';
import {css} from 'emotion';
import Avatar from '../../common/avatar/Avatar';
import {server_img_logos} from '../../../config/constants';
import IconEasyTrash from '../../common/icons/IconEasyTrash';

const typeToText = (type) => {
  switch (type) {
    case 1:
      //CITOYEN - PARTICIPATION
      return 'a commenté votre participation';
    case 2:
      //CITOYEN - PARTICIPATION
      return 'a liké votre participation';
    case 3:
      //CITOYEN - PARTICIPATION
      return 'a répondu à votre commentaire (participation)';
    case 4:
      //MAIRIE - PARTICIPATION
      return 'a répondu à votre participation';
    case 5:
      //MAIRIE - PARTICIPATION
      return 'a modifié le statut de votre participation en public';
    case 6:
      //MAIRIE - PARTICIPATION
      return 'a modifié le statut de votre participation en privé';
    case 7:
      //CITOYEN - ACTUALITE
      return 'a commenté votre commentaire (publication)';
    case 8:
      //CITOYEN - ALERTE
      return 'a commenté votre commentaire (publication)';
    case 9:
      //CITOYEN - ÉVÈNEMENT
      return 'a commenté votre commentaire (publication)';
    case 10:
      //MAIRIE - GROUPE
      return 'a accepté votre demande de rejoindre une communauté';
    case 11:
      //MAIRIE - PARTICIPATION
      return 'a répondu à une participation que vous suivez';

    case 12:
      //MAIRIE - ANSWER BP
      return 'a répondu à votre projet (budget participatif)';
    case 13:
      //CITOYEN - COMMENT BP
      return 'a commenté votre projet (budget participatif)';
    case 14:
      //MAIRIE - BP
      return 'a liké votre projet (budget participatif)';
    case 15:
      //MAIRIE - BP
      return 'a commenté votre commentaire (budget participatif)';

      case 16:
        //MAIRIE - GROUPE
        return 'vous a inscrit(e) dans une communauté';

    default:
      return 'vous a envoyé un message';
  }
};

const ContentCardNotification = (props) => {

  return (
    <div
      className={css`
        display: flex;
        margin-left: 20px;
        margin-right: 20px;
        align-items: center;
      `}>
      {props.type_user === 1 ? (
        <Avatar size={50} id_avatar={props.avatar} />
      ) : props.type_user === 9 ? (
        <div
          className={css`
            box-sizing: border-box;
            padding-right: 5px;
            padding-top: 5px;
          `}>
          <img
            className={css`
              height: 50px;
            `}
            src={require('../../../image/logo/popvox_round.png')}
            alt=""
          />
        </div>
      ) : (
        <div
          className={css`
            box-sizing: border-box;
            padding-right: 5px;
            padding-top: 5px;
          `}>
          <img
            className={css`
              height: 50px;
            `}
            src={server_img_logos + props.urlimg}
            alt=""
          />
        </div>
      )}

      <div
        className={css`
          margin-left: 10px;
          font-size: 15px;
          flex: 1;
        `}>
        <b>
          {props.type_user === 9
            ? 'Équipe popvox'
            : props.type_user === 1
            ? props.pseudo
              ? props.pseudo
              : 'Anonyme'
            : props.nom_comm}
        </b>
        {' ' + typeToText(props.type)}
      </div>

      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.deleteNotification();
        }}
        className={css`
          margin-left: 10px;
          margin-right: 5px;
          &:hover {
            opacity: 0.6;
          }
          padding: 5px;
        `}>
        <IconEasyTrash size={20} color={'#f55d42'} />
      </div>
    </div>
  );
};

export default ContentCardNotification;
