import React, { useState } from 'react';
import {css} from 'emotion';

import MessageInfo from '../../../common/material-easy-ui/modal/MessageInfo';


const BpPresentationBlock = props => {

const [message1, setMessage1Visibility] = useState(false);
const [message2, setMessage2Visibility] = useState(false);
const [message3, setMessage3Visibility] = useState(false);
const [message4, setMessage4Visibility] = useState(false);
const [message5, setMessage5Visibility] = useState(false);

    return (
      <>
      <div
        className={css`
          width: 100%;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 15px;
          margin-top: 30px;
          margin-bottom: 20px;
          background: #fff;
          border: 1px solid rgba(24, 62, 100, 0.05);
        `}>

        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          `}>

          <div
            className={css`
              color: #29354a;
              font-size: 17px;
              text-align: left;
              flex: 1;
              font-weight: 600;
              line-height: 1;
              padding-bottom: 20px;

            `}>
            Comment ça marche ?
          </div>

          </div>


          <div
            onClick={() => {
              setMessage1Visibility(true)
            }}

            className={css`
              cursor: pointer;
              color: #29354a;
              text-align: left;
              padding: 12px;
              border-top: 1px solid #ebebeb;
              line-height: 1;
              &:hover {
                text-decoration: underline;
              }
            `}>
            <span
              className={css`
                overflow-wrap: break-word;
              `}>
              Dépot des projets
            </span>
          </div>


          <div
            onClick={() => {
              setMessage2Visibility(true);
            }}
            className={css`
              cursor: pointer;
              color: #29354a;
              text-align: left;
              padding: 12px;
              border-top: 1px solid #ebebeb;
              line-height: 1;
              &:hover {
                text-decoration: underline;
              }
            `}>
            <span
              className={css`
                overflow-wrap: break-word;
              `}>
              Analyse des projets
            </span>
          </div>


          <div
          onClick={() => {
            setMessage3Visibility(true);
          }}
            className={css`
              cursor: pointer;
              color: #29354a;
              text-align: left;
              padding: 12px;
              border-top: 1px solid #ebebeb;
              line-height: 1;
              &:hover {
                text-decoration: underline;
              }
            `}>
            <span
              className={css`
                overflow-wrap: break-word;
              `}>
              Voter pour des projets
            </span>
          </div>


          <div
            onClick={() => {
              setMessage4Visibility(true);
            }}
            className={css`
              cursor: pointer;
              color: #29354a;
              text-align: left;
              padding: 12px;
              border-top: 1px solid #ebebeb;
              line-height: 1;
              &:hover {
                text-decoration: underline;
              }
            `}>
            <span
              className={css`
                overflow-wrap: break-word;
              `}>
              Présentation des lauréats
            </span>
          </div>


          <div
            onClick={() => {
              setMessage5Visibility(true);
            }}
            className={css`
              cursor: pointer;
              color: #29354a;
              text-align: left;
              padding: 12px;
              border-top: 1px solid #ebebeb;
              line-height: 1;
              &:hover {
                text-decoration: underline;
              }
            `}>
            <span
              className={css`
                overflow-wrap: break-word;
              `}>
              Mise en oeuvre
            </span>
          </div>

          <div
            onClick={() => window.open('https://www.popvox.fr/bp/' + props.infoCity.insee_com + '.html', '_blank')}
            className={css`
              cursor: pointer;
              color: #f55d42;
              font-size: 14px;
              text-align: left;
              padding-top: 10px;
              padding-bottom: 10px;
              border-top: 1px solid #ebebeb;
              &:hover {
                text-decoration: underline;
              }
            `}>
            Voir le règlement
          </div>


        </div>


        {
          message1 && (
          <MessageInfo
            close={() => setMessage1Visibility(false)}
            text={
              "Toute personne habitant la ville peut déposer un projet en cliquant sur le bouton + en bas à droite de l’écran. Chacun peut ainsi proposer facilement des projets qui répondent à ses besoins. Toutes les propositions de projet des citoyens sont visibles dans PopVox."
            }
            textHeader={'Dépot des projets'}
          />)
        }

        {
          message2 && (
          <MessageInfo
            close={() => setMessage2Visibility(false)}
            text={
              "Les projets proposés font l’objet d’une analyse de recevabilité selon des critères et des modalités qui sont précisées dans le règlement du budget participatif. Les projets jugés recevables sont soumis ensuite au vote citoyen."
            }
            textHeader={'Analyse des projets'}
          />)
        }

        {
          message3 && (
          <MessageInfo
            close={() => setMessage3Visibility(false)}
            text={
              "Tous les citoyens sont invités à voter pour le projet de leur choix. Le vote se fait directement et uniquement sur PopVox. Vous avez changé d’avis sur un projet ? Pas de panique, votre vote est modifiable jusqu’au jour de clôture des votes."
            }
            textHeader={'Voter pour des projets'}
          />)
        }

        {
          message4 && (
          <MessageInfo
            close={() => setMessage4Visibility(false)}
            text={
              "Les projets ayant reçus le plus de votes favorables et dont le coût additionné au coût des autres projets ayant recueillis un nombre de vote au moins équivalent, permet de respecter l’enveloppe affectée par la Ville au budget participatif en cours, seront désignés comme lauréats. La mise en œuvre des projets peut commencer !"
            }
            textHeader={'Présentation des lauréats'}
          />)
        }

        {
          message5 && (
          <MessageInfo
            close={() => setMessage5Visibility(false)}
            text={
              "Les projets lauréats seront mis en œuvre dans des conditions fixées par la Ville et les habitants. Restez informés sur PopVox des actualités des projets lauréats."
            }
            textHeader={'Mise en oeuvre'}
          />)
        }

      </>



    );

};

export default BpPresentationBlock;
