import React from 'react';
import {css} from 'emotion';


const ButtonVote = (props) =>{

  if(!props.has_voted) {
    return(
      <div onClick={props.toggleVote}
      className={css`
        cursor:pointer;
        color:rgb(242, 101, 35);
        background-color:white;
        border: 1px solid rgb(242, 101, 35);
        padding-left:10px;
        padding-right:10px;
        padding-top:5px;
        padding-bottom:5px;
        border-radius:5px;
        margin-left: 20px;
        font-size: 16px;
        &:hover{
          background-color:rgb(242, 101, 35);
          border:1px solid rgb(242, 101, 35);
          color:white;
        }
        `
      }>
        Voter pour ce projet
      </div>
    )
  }


  return (
    <div onClick={props.toggleVote}
      className={css`
        cursor:pointer;
        color:rgb(242, 101, 35);
        background-color:white;

        padding-left:10px;
        padding-right:10px;
        padding-top:5px;
        padding-bottom:5px;
        border-radius:5px;
        margin-left: 10px;
        font-size: 14px;
        &:hover{
          text-decoration: underline;
        }
        `
    }>
      Annuler mon vote
    </div>


  )

}

export default ButtonVote;
