import React from 'react';

import IconEasyProfile from '../../common/icons/IconEasyProfile';
import {css} from 'emotion';

// eslint-disable-next-line
const CreateAccountMenu = React.forwardRef((props, ref) => {

  return (
    <div
      ref={ref}
      className={css`
        flex-direction: row;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        padding-left: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        overflow: hidden;
        cursor: pointer;
        &:hover{
            background-color: rgba(245, 93, 66,0.1);
        }
      `}>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <IconEasyProfile
          size={16}
          color={'rgba(245, 93, 66,1)'}
        />
      </div>
      <div
        className={css`
          margin-left: 10px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover{
              color: rrgba(245, 93, 66,1);
          }
        `}>
        Créer un compte
      </div>

    </div>
  );
});

export default CreateAccountMenu;
