import React, {useState} from 'react';
import {css} from 'emotion';
import Avatar from '../../../../common/avatar/Avatar';
import moment from 'moment';
import 'moment/locale/fr';
import IconEasyMore from '../../../../common/icons/IconEasyMore';

moment.locale('fr');

const CapitalizeFirst = text => {
  return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
};

const HeaderCard = props => {
  const [hoverMore, setHovermore] = useState(false);

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        padding: 20px;
      `}>
      <div>
        <Avatar
          id_avatar={props.item.avatar}
          size={50}
        />
      </div>
      <div
        className={css`
          padding-left: 10px;
        `}>
        <div>
          {props.item.pseudo}
          <span
            className={css`
              color: orange;
            `}>
            {' '}
            a déposé
          </span>
        </div>

        <div
          className={css`
            color: grey;
            font-size: 15px;
            display: flex;
          `}>
          <div>{CapitalizeFirst(props.item.nom_comm)}</div>
          <div>, {moment(props.item.date_creation).fromNow()}</div>
        </div>
      </div>
      <div
        className={css`
          display: flex;
          flex: 1;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
        `}>
        <div
          onMouseLeave={() => {
            setHovermore(false);
          }}
          onMouseEnter={() => {
            setHovermore(true);
          }}
          onClick={props.onMore}
          className={css`
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            border-radius: 50%;
            justify-content: center;
            &:hover {
              background-color: rgba(245, 93, 66, 0.1);
            }
          `}>
          <IconEasyMore
            color={hoverMore ? 'rgba(245, 93, 66,1)' : '#bfbfbf'}
            size={20}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderCard;
