import axios from "axios";
import { server_api_url } from "../config/constants";

class GroupAPI {

  static getCommunities(userKey, codeinsee) {
    return axios.get(server_api_url + '/communities/all', {
      params: {
        popkey: userKey,
        codeinsee: codeinsee
      }
    });
  }

  static getCommunityThematics(popkey, id_community) {
    return axios.get(server_api_url + '/communities/communitythematics', {
      params: {
        popkey: popkey,
        community_id: id_community
      }
    });
  }

  static getCommunity(popkey, id_community) {
    return axios.get(server_api_url + '/communities/details', {
      params: {
        popkey: popkey,
        id_community: id_community
      }
    });
  }

  static subscribeUser(userKey, commID, questionID, answer, fileurl) {
    return axios.post(server_api_url + '/communities/subscribe',{
      popkey:userKey,
      id_community: commID,
      id_question: questionID,
      answer: answer,
      fileurl: fileurl
    })
  }

  static unsubscribeUser(popkey, id_community) {
    return axios.post(server_api_url + '/communities/unsubscribe',{
      popkey:popkey,
      id_community: id_community,
    })
  }

  static sendRqst(userKey, commID, questionID, answer, fileurl) {
    return axios.post(server_api_url + '/communities/sendrqst',{
      popkey:userKey,
      id_community: commID,
      id_question: questionID,
      answer: answer,
      fileurl: fileurl
    })
  }


  static getUserHasSubscribeGroupe(popkey,id_groupe){

      return axios.get(server_api_url + '/communities/getuserhassubscribegroupe',{
        params:{
          popkey:popkey,
          id_groupe:id_groupe
        }
      })
  }

  static getForUserGroup(popkey,code_insee){
    return axios.get(server_api_url + '/communities/groupforuser',{
      params:{
        popkey:popkey,
        code_insee:code_insee
      }
    })
  }

}

export default GroupAPI;
