import produce from 'immer';

const initialState = {
  budget_part_session: null,
  isLoading: true,
  list_project: [],
  list_map: [],
  project: false,
  likeLoading: false,
  comments: [],
  loadingComment: false,
  endReachedComment: false,
  commentsnb: false,
  pubanswers: false,
  endReachedProject: false,
  listAllBudgetPart: [],
  nb_votes_user: 0,
  statusProject: false,
};

export function budgetpartReducers(state = initialState, action) {
  switch (action.type) {

    //NEW !! INITIAL BUDGET PART
    case 'GET_BUDGET_PART':
      return {
        ...state,
        budget_part_session: action.payload.budget_part_session,
        isLoading: false,
        list_project: action.payload.bp_projects_list,
        list_map: action.payload.bp_projects_for_map_list,
        endReachedProject: action.payload.end_reached,
        nb_votes_user: parseInt(action.payload.nb_votes_user, 10),
      };

    //NEW !! GET MORE PROJECTS
    case 'GET_MORE_PROJECTS':
      return {
        ...state,
        list_project: [
          ...state.list_project,
          ...action.payload.bp_projects_list,
        ],
        endReachedProject: action.payload.end_reached,
      };

    //NEW !! GET DETAILS PROJECTS
    case 'GET_DETAILS_BUDGET_PART':
      return {
        ...state,
        project: action.payload.details,
        comments: action.payload.comments,
        endReachedComment: action.payload.endreached,
        commentsnb: action.payload.commentsnb,
        pubanswers: action.payload.pubanswers,
        statusProject: action.payload.statusProject,
      };

    //NEW !! Like  and unlike project loading
    case 'LIKE_PROJECT_LOADING':
      return {
        ...state,
        likeLoading: true,
      };

    //NEW !! Like project
    case 'LIKE_PROJECT':
      let newsListParticipationState;
      const listParticipationState = [...state.list_project];
      const index = listParticipationState.findIndex(i => {
        return (
          parseInt(i.id_budget_part_project) === parseInt(action.payload, 10)
        );
      });
      if (index >= 0) {
        newsListParticipationState = produce(listParticipationState, draft => {
          draft[index].has_liked = true;
          draft[index].likes = draft[index].likes + 1;
          draft[index].userHasLiked = [];
        });
      } else {
        newsListParticipationState = state.list_project;
      }
      return {
        ...state,
        project: {
          ...state.project,
          has_liked: true,
          likes: state.project.likes + 1,
        },
        likeLoading: false,
        list_project: newsListParticipationState,
      };

    //NEW !! unLike project
    case 'UNLIKE_PROJECT':
      let newsListParticipationStateUnlike;
      const listParticipationStateUnlike = [...state.list_project];
      const indexUnLike = listParticipationStateUnlike.findIndex(i => {
        return (
          parseInt(i.id_budget_part_project, 10) ===
          parseInt(action.payload, 10)
        );
      });
      if (indexUnLike >= 0) {
        newsListParticipationStateUnlike = produce(
          listParticipationStateUnlike,
          draft => {
            draft[indexUnLike].has_liked = false;
            draft[indexUnLike].likes = draft[indexUnLike].likes - 1;
            draft[indexUnLike].userHasLiked = [];
          },
        );
      } else {
        newsListParticipationStateUnlike = state.list_project;
      }
      return {
        ...state,
        project: {
          ...state.project,
          has_liked: false,
          likes: state.project.likes - 1,
        },
        likeLoading: false,
        list_project: newsListParticipationStateUnlike,
      };

    // new !! function vote
    case 'VOTE_PROJECT':

      let list_project_vote_state;
      var list_projectVote = [...state.list_project];
      var indexProjectVote = list_projectVote.findIndex(i => {
        return (
          parseInt(i.id_budget_part_project, 10) ===
          parseInt(action.payload, 10)
        );
      });
      if (indexProjectVote >= 0) {
        list_project_vote_state = produce(list_projectVote, draft => {
          draft[indexProjectVote].has_voted = true;
          draft[indexProjectVote].votes = draft[indexProjectVote].votes + 1;
        });
      } else {
        list_project_vote_state = [...state.list_project];
      }
      return {
        ...state,
        nb_votes_user: state.nb_votes_user + 1,
        project: {
          ...state.project,
          has_voted: true,
          votes: state.project.votes + 1,
        },
        list_project: list_project_vote_state,
      };

      // new !! function vote
      case 'UNVOTE_PROJECT':
        let list_project_vote_state2;
        var list_projectVote2 = [...state.list_project];
        var indexProjectVote2 = list_projectVote2.findIndex(i => {
          return (
            parseInt(i.id_budget_part_project, 10) ===
            parseInt(action.payload, 10)
          );
        });
        if (indexProjectVote2 >= 0) {
          list_project_vote_state2 = produce(list_projectVote2, draft => {
            draft[indexProjectVote2].has_voted = false;
            draft[indexProjectVote2].votes = draft[indexProjectVote2].votes - 1;
          });
        } else {
          list_project_vote_state2 = [...state.list_project];
        }
        return {
          ...state,
          nb_votes_user: state.nb_votes_user - 1,
          project: {
            ...state.project,
            has_voted: false,
            votes: state.project.votes - 1,
          },
          list_project: list_project_vote_state2,
        };

        //New !! Get more
        case 'GET_MORE_PROJECT_PART':
          return {
            ...state,
            list_project: [...state.list_project, ...action.payload.list_project],
            endReachedProject: action.payload.endReachedProject,
          };

          case 'POST_PROJECT_SUCCESS':
            return {
              ...state,
              list_project: [action.payload.project, ...state.list_project],
              list_map:
                action.payload.marker !== null
                  ? [...state.list_map, action.payload.marker]
                  : [...state.list_map],
            };

          case 'CLEAN_PROJECT':
            return {
              ...state,
              project: false,
              comment: [],
              endReachedComment: false,
              commentsnb: 0,
              pubanswers: false,
            };

          case 'POST_PROJECT_COMMENT_SUCCESS':
            const newComment = {...action.payload.comment, newComment: true};
            return {
              ...state,
              comments: [...state.comments, newComment],
            };



                case 'ADD_COMMENT_NB_PROJECT_FLUX':
                  const listParticipationPostComment = [...state.list_project];
                  const indexParticipationPostComment = listParticipationPostComment.findIndex(
                    i => {
                      return (
                        parseInt(i.id_budget_part_project, 10) ===
                        parseInt(action.payload.id_budget_part_project, 10)
                      );
                    },
                  );
                  var newParticipationList = [...state.list_project];
                  if (indexParticipationPostComment > -1) {
                    newParticipationList = produce(listParticipationPostComment, draft => {
                      draft[indexParticipationPostComment].comments =
                        draft[indexParticipationPostComment].comments + 1;
                    });
                  }
                  return {
                    ...state,
                    list_project: newParticipationList,
                    project: {
                      ...state.project,
                      comments: state.project ? state.project.comments + 1 : 0,
                    },
                  };

                case 'POST_PROJECT_COMMENT_REPLY_SUCCESS':
                  var indexComment = state.comments.findIndex(i => {
                    return (
                      parseInt(i.id_comment, 10) ===
                      parseInt(action.payload.comment.ref_id_comment, 10)
                    );
                  });
                  var count_ref_id = state.comments.filter(i => {
                    return i.ref_id_comment === action.payload.comment.ref_id_comment;
                  }).length;
                  const newCommentReply = {...action.payload.comment, newComment: true};
                  return {
                    ...state,
                    comments: [
                      ...state.comments.slice(0, indexComment + count_ref_id + 1),
                      newCommentReply,
                      ...state.comments.slice(indexComment + count_ref_id + 1),
                    ],
                  };

                case 'GET_MORE_COMMENT_PROJECT_LOADING':
                  return {
                    ...state,
                    loadingComment: true,
                  };

                case 'GET_MORE_COMMENT_PROJECT_SUCCESS':
                  return {
                    ...state,
                    loadingComment: false,
                    endReachedComment: action.payload.endreached,
                    comments: [...action.payload.comments, ...state.comments],
                  };

                case 'DELETE_COMMENT_PROJECT_SUCCES':
                  const indexCommentDelete = state.comments.findIndex(i => {
                    return (
                      parseInt(i.id_comment, 10) === parseInt(action.payload.id_comment, 10)
                    );
                  });
                  return {
                    ...state,
                    comments: [
                      ...state.comments.slice(0, indexCommentDelete),
                      ...state.comments.slice(indexCommentDelete + 1),
                    ],
                    project: {
                      ...state.project,
                    },
                  };

                case 'REMOVE_COMMENT_NB_PROJECT_FLUX':
                  const listParticipationPostComment3 = [...state.list_project];
                  const indexParticipationPostComment3 = listParticipationPostComment3.findIndex(
                    i => {
                      return (
                        parseInt(i.id_budget_part_project, 10) ===
                        parseInt(action.payload.id_budget_part_project, 10)
                      );
                    },
                  );

                  var newParticipationList3 = [...state.list_project];
                  if (indexParticipationPostComment3 > -1) {
                    newParticipationList3 = produce(
                      listParticipationPostComment3,
                      draft => {
                        draft[indexParticipationPostComment3].comments =
                          draft[indexParticipationPostComment3].comments - 1;
                      },
                    );
                  }
                  return {
                    ...state,
                    list_project: newParticipationList3,
                    project: {
                      ...state.project,
                      comments: state.project ? state.project.comments - 1 : 0,
                    },
                  };

                case 'REMOVE_PROJECT_BUDGET_PART_SUCCESS':
                  var list_project = [...state.list_project];
                  var list_map = [...state.list_map];
                  var indexMap = list_map.findIndex(i => {
                    return (
                      parseInt(i.id_budget_part_project, 10) ===
                      parseInt(action.payload, 10)
                    );
                  });

                  var indexProject = list_project.findIndex(i => {
                    return (
                      parseInt(i.id_budget_part_project, 10) ===
                      parseInt(action.payload, 10)
                    );
                  });

                  return {
                    ...state,
                    list_project: [
                      ...list_project.slice(0, indexProject),
                      ...list_project.slice(indexProject + 1),
                    ],
                    list_map: [
                      ...list_map.slice(0, indexMap),
                      ...list_map.slice(indexMap + 1),
                    ],
                  };



/*
    case 'GET_BUDGET_PART_LIST_HISTORY':
      return {
        ...state,
        listAllBudgetPart: action.payload.data,
      };

    case 'GET_PROJECT_LIST':
      return {
        ...state,
        list_project: action.payload.list_project,
      };

    case 'GET_PROJECT_LIST_MAP':
      return {
        ...state,
        list_map: action.payload.list_project,
      };

    case 'FILTRE_PROJECT':
      return {
        ...state,
        list_project: action.payload.list_project,
        list_map: action.payload.list_map,
        endReachedProject: action.payload.endReachedProject,
      };


      case 'GET_DETAILS_ONE':
        return {
          ...state,
          project: action.payload.details,
          comments: action.payload.comments,
          endReachedComment: action.payload.endreached,
          commentsnb: action.payload.commentsnb,
          pubanswers: action.payload.pubanswers,
        };

      case 'GET_DETAILS_BUDGET_PART_VOTE':
        return {
          ...state,
          project: action.payload.details,
        };
*/





    default:
      return state;
  }
}
