import React, {useState, useEffect} from 'react';
import check from '../../../../image/icon/icon-simple/check.svg';
import cross_round from '../../../../image/icon/icon-simple/cross-round.svg';
import {css} from 'emotion';

const InputEasy = props => {
  const [error, setError] = useState(props.error);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if(props.error && props.error != null) {
      setError(props.error);
    }
  }, [props.error]);

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = e => {
    setFocus(false);
    props.onBlur && props.onBlur(e);
  };

  return (

    <div
      className={css`
        background-color: ${focus ? 'white' : '#F5F8FA'};
        box-shadow: ${focus
          ? 'inset 0px 0px 0px 2px #f55d42'
          : 'inset 0px 0px 0px 1px #ededed'};
        transition: box-shadow 0.1s;
      `}
      style={{
        ...props.style,
        width: props.width,
        borderRadius: '10px',
        position: 'relative',
      }}>

      <div style={{flexDirection: 'column', display: 'flex', flex: 1}}>
        <div
          style={{
            textAlign: 'left',
            fontSize: '10px',
            width: '100%',
            ...props.labelStyle,
          }}>
          {props.label}
        </div>
        <input
          className={css`
            &&::placeholder {
              color: rgb(101, 119, 134);
            }
          `}
          onFocus={onFocus}
          disabled={props.disabled}
          onBlur={onBlur}
          onChange={props.onChangeText}
          style={{
            backgroundColor: 'transparent',
            width: '100%',
            outline: 'none',
            border: 'none',
            ...props.inputStyle,
          }}
          placeholder={props.placeholder}
          onKeyDown={
            typeof props.onKeyDown === 'function' ? props.onKeyDown : undefined
          }
          value={props.text}
        />
      </div>

      { (error && (error != null)) && (
        <img
          src={error.error ? cross_round : check}
          alt=""
          style={{width: 30, height: 30}}
        />
      )}

    {(error && (error != null) && error.message) && (
        <div
          style={{
            width: props.width,
            color: error.error ? 'red' : '#0DB3C5',
            height: 10,
            position: 'absolute',
            bottom: '-10px',
          }}
          className="error_input">
          {error.message}
        </div>
      )}


    </div>
  );
};

export default InputEasy;
