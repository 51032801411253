import React from "react";
import { css } from "emotion";
import CloseSimpleButton from "../../../../common/material-easy-ui/button/CloseSimpleButton";

const SupportPicture = (props) => {
  return (
    <div
      className={css`
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 300px;
      `}>
      <div
        className={css`
          align-items: center;
          padding-right: 20px;
          height: 50px;
          display: flex;

          flex-direction: row;
        `}>
        <div
          className={css`
            flex: 1;
            color: white;
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
          `}>
          <span className={css``}>
            {props.load ? "Chargement de l'image ..." : props.nameFile}
          </span>
        </div>
        <div
          className={css`
            display: flex;
            justify-content: center;
            min-width: 40px;
          `}>
          <CloseSimpleButton
            onPress={props.onClose}
            load={props.load}
            color={"white"}
            size={20}
          />
        </div>
      </div>
    </div>
  );
};

export default SupportPicture;
