import React, {useState} from 'react';
import {css} from 'emotion';
import ReactMapboxGl, {ZoomControl } from 'react-mapbox-gl';

import Button from '../../common/forms/buttons/Button';

import MarkerMap from './MarkerMap';
//import IconEasyGeoloc from '../../common/icons/IconEasyGeoloc';

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoianVzcHJpZXQiLCJhIjoiY2o5cHRzMjB1MnVxMTJ4czJoaTdhZjVjbCJ9.3LZxp2Lf97TcCYWPAgsyyA',
});

const AlertMap = props => {
  const [mapVisible, setMapVisible] = useState(false);

  const _toggleVisibility = () => {
    setMapVisible(!mapVisible);
  };

  return (
    <>
      {mapVisible ? (
        <div
          className={css`
            height: 300px;
            flex: 1;
            padding: 20px;
          `}>
          <Map
            center={[props.markers[0].lng, props.markers[0].lat]}
            zoom={[10]}
            // eslint-disable-next-line
            style={"mapbox://styles/mapbox/streets-v9"}
            containerStyle={{
              height: '100%',
              width: '100%',
            }}>
            <ZoomControl />
              {
                props.markers.map((point, key) =>
                  <MarkerMap key={key} point={point} />
                )
              }

          </Map>
        </div>
      ) : (
        <div
          style={{
            paddingTop: 20,
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          <Button style={{marginRight: 20}} onClick={_toggleVisibility}>
            {/*<IconEasyGeoloc color={'#f29483'} size={20} />*/}
            <div >Carte</div>
          </Button>
        </div>
      )}
    </>
  );
};

export default AlertMap;
