import React, { useState, useEffect } from "react";

import Community from "./components/Community";

import { server_img_public, server_img_logos } from "../../../config/constants";
import history from "../../../utils/history";
import "./components/Community.css";

export default function SelectCommunity(props) {
  const [otherGroups, setOtherGroups] = useState([]);
  const [localUserCommunities, setLocalUserCommunities] = useState([]);

  useEffect(() => {
    setOtherGroups([]);
    setLocalUserCommunities([]);

    if (props.city_communities !== null) {

      if (props.city_communities.length === 0) {

        props.setAllTheThematics(props.infoCity.thematics);

      } else {

        if (
          props.user_communities !== null &&
          props.user_communities.length > 0
        ) {

          let tmp = [];
          let localUserCommunitiesTmp = [];
          for (
            let i = 0;
            props.city_communities && i < props.city_communities.length;
            i++
          ) {
            if (
              !props.user_communities.find(
                (x) => x.id === props.city_communities[i].id
              )
            ) {
              tmp.push(props.city_communities[i]);
            } else {
              localUserCommunitiesTmp.push(props.city_communities[i]);
            }
          }
          setOtherGroups(tmp);
          setLocalUserCommunities(localUserCommunitiesTmp);

        } else {
          setOtherGroups(props.city_communities);
        }

      }
    }

  }, [props.user_communities, props.city_communities]);

  const goToCommunity = (id) => {
    history.push({
      pathname: "/communaute",
      search: `?id_group=${id}`,
    });
  };

  return (
    <div className="communitiesContainer">
      
      {
        /* SI PAS DE PUBLIC FLOW, pas de private flow, ET PAS DE USER COMMUNITIES*/
        props.infoCity &&
        props.infoCity.options &&
        props.infoCity.options.publicflow === 0 &&
        props.infoCity.options.privateflow === 0 &&
        localUserCommunities.length === 0 ? (
          <></>
        ) : (
          <div
            style={{
              fontWeight: "700",
              fontSize: 20,
            }}>
            Avec qui souhaitez vous dialoguer ?
          </div>
        )
      }

      {localUserCommunities.map((item) => {
        const data = props.city_communities
          ? props.city_communities.find((x) => x.id === item.id)
          : null;

        if (data) {
          return (
            <Community
              title={data.title}
              key={"user_communities" + data.id}
              type={3}
              isPrivate={data.default_private_status}
              image={data ? server_img_public + data.file_url : null}
              onClick={() =>
                props.onSelectCommunity(data.id, data.default_private_status)
              }
              activated
            />
          );
        }

        return false;
      })}

      {props.infoCity !== null &&
        props.infoCity.nom_comm &&
        props.infoCity.options.publicflow === 1 && (
          <Community
            title={props.infoCity.nom_comm + " & tout le monde"}
            type={1}
            image={server_img_logos + props.infoCity.urlimg}
            onClick={() => props.onSelectCommunity(null, false)}
            activated
          />
        )}

      {props.infoCity !== null &&
        props.infoCity.nom_comm &&
        props.infoCity.options.privateflow === 1 && (
          <Community
            title={props.infoCity.nom_comm}
            type={2}
            image={server_img_logos + props.infoCity.urlimg}
            onClick={() => props.onSelectCommunity(null, true)}
            activated
          />
        )}

      {otherGroups.length > 0 && (
        <>
          {
            /* SI PAS DE PUBLIC FLOW, PAS DE PRIVATE FLOW ET PAS DE USER COMMUNITIES*/
            props.infoCity &&
              props.infoCity.options &&
              props.infoCity.options.privateflow === 0 &&
              props.infoCity.options.publicflow === 0 &&
              (props.user_communities == null ||
                props.user_communities.length === 0) && (
                <div
                  style={{
                    fontWeight: "700",
                    fontSize: 20,
                    marginTop: 50,
                  }}>
                  Rejoignez une communauté pour commencer le dialogue
                </div>
              )
          }

          {
            /* SI PUBLIC FLOW OU PRIVATE FLOW ET PAS DE USER COMMUNITIES*/
            props.infoCity &&
              props.infoCity.options &&
              (props.infoCity.options.publicflow === 1 ||
                props.infoCity.options.privateflow === 1) &&
              localUserCommunities.length === 0 && (
                <div
                  style={{
                    fontWeight: "700",
                    fontSize: 20,
                    marginTop: 50,
                  }}>
                  Communautés disponibles pour le dialogue
                </div>
              )
          }

          {
            /* SI USER COMMUNITIES*/
            localUserCommunities.length != 0 && (
              <div
                style={{
                  fontWeight: "700",
                  fontSize: 20,
                  marginTop: 50,
                }}>
                Autres communautés disponibles pour le dialogue
              </div>
            )
          }

          {otherGroups.map((item) => {
            return (
              <Community
                type={4}
                key={item.id}
                title={item.title}
                image={server_img_public + item.file_url}
                onClick={() => goToCommunity(item.id)}
              />
            );
          })}
        </>
      )}

      <br />

      <div style={{ width: "100%", height: 100 }} />

    </div>
  );
}
