import React from 'react';
import {css} from 'emotion';

const shadow =
  '-webkit-box-shadow: -1px 10px 6px -6px rgba(245,93,66,0.5);-moz-box-shadow: -1px 10px 6px -6px rgba(245,93,66,0.5);box-shadow: -1px 10px 6px -6px rgba(245,93,66,0.5);';

const Item1 = props => {
  return (
    <div
      className={css`
        flex: 1;
      `}>
      <div
        className={css`
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        `}>
        {/*LITTLE CIRCLE*/}
        <div
          className={css`
            width: 10px;
            height: 10px;
            background-color: ${props.current_phase <= props.items.num
              ? 'white'
              : 'rgba(245,93,66,1)'};
            border: 2px solid
              ${props.current_phase >= props.items.num
                ? 'rgba(245,93,66,1)'
                : 'rgba(171, 171, 171,0.2)'};
            border-radius: 50%;
            position: relative;
            display: flex;
            justify-content: center;
          `}>

          {/* BIG CIRCLE*/}


          {props.current_phase == props.items.num && (
            <div
              className={css`
                ${shadow}color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background-color: rgba(245, 93, 66, 1);
              `}>
              <div
                className={css`
                  font-size: 19px;
                `}>
                {props.items.num}
              </div>
            </div>
          )}
        </div>

        {/*BAR*/}
        <div
          className={css`
            background: ${props.current_phase > props.items.num
              ? 'rgba(245,93,66,1)'
              : 'rgba(171, 171, 171,0.2)'};
            height: 5px;
            flex: 0.5;
            margin-left: -2px;
          `}></div>
      </div>
      <div
        className={css`
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}>
        <div
          className={css`
            color: ${props.current_phase == props.items.num
              ? 'black'
              : 'rgba(171, 171, 171,0.5)'};
            font-size: 13px;
            text-align: center;
          `}>
          <div>{props.items.text}</div>

        </div>
      </div>
    </div>
  );
};

const Item2 = props => {
  return (
    <div
      className={css`
        flex: 1;
      `}>
      <div
        className={css`
          height: 40px;
          display: flex;
          align-items: center;
        `}>
        {/*BAR*/}
        <div
          className={css`
            background: ${props.current_phase >= props.items.num
              ? 'rgba(245,93,66,1)'
              : 'rgba(171, 171, 171,0.2)'};
            height: 5px;
            flex: 1;
            margin-left: -2px;
          `}></div>

        <div
          className={css`
            margin-left: -2px;
            position: relative;
            width: 10px;
            height: 10px;
            border: 2px solid
              ${props.current_phase >= props.items.num
                ? 'rgba(245,93,66,1)'
                : 'rgba(171, 171, 171,0.2)'};
            border-radius: 50%;
            display: flex;
            justify-content: center;
            background: ${props.current_phase >= props.items.num
              ? 'rgba(245,93,66,1)'
              : 'white'};
          `}>
          {/* BIG CIRCLE*/}
          {props.current_phase == props.items.num && (
            <div
              className={css`
                ${shadow}color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background-color: rgba(245, 93, 66, 1);
              `}>
              <div
                className={css`
                  font-size: 19px;
                `}>
                {props.items.num}
              </div>
            </div>
          )}
        </div>
        <div
          className={css`
            height: 5px;
            flex: 1;
            background: ${props.current_phase > props.items.num
              ? 'rgba(245,93,66,1)'
              : 'rgba(171, 171, 171,0.2)'};
            margin-left: -2px;
          `}></div>
      </div>
      <div
        className={css`
          height: 80px;
          align-items: center;
          display: flex;
          justify-content: center;
        `}>
        <div
          className={css`
            color: ${props.current_phase == props.items.num
              ? 'black'
              : 'rgba(171, 171, 171,0.5)'};
            font-size: 13px;
            text-align: center;
          `}>
          <div>{props.items.text}</div>
          {/* (parseFloat(props.current_phase) < parseFloat(props.items.num)) && (
            <div>{moment(props.date).format('L')}</div>
          )*/}
        </div>
      </div>
    </div>
  );
};

const Item3 = props => {
  return (
    <div
      className={css`
        flex: 1;
      `}>
      <div
        className={css`
          height: 40px;
          display: flex;
          align-items: center;
        `}>
        {/*BAR*/}
        <div
          className={css`
            background: ${props.current_phase >= props.items.num
              ? 'rgba(245,93,66,1)'
              : 'rgba(171, 171, 171,0.2)'};
            height: 5px;
            flex: 0.5;
            margin-left: -2px;
          `}></div>
        <div
          className={css`
            margin-left: -2px;
            position: relative;
            display: flex;
            justify-content: center;
            width: 10px;
            height: 10px;
            border: 2px solid rgba(171, 171, 171, 0.2);
            border-radius: 50%;
          `}>
          {/* BIG CIRCLE*/}
          {props.current_phase == props.items.num && (
            <div
              className={css`
                ${shadow}color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background-color: rgba(245, 93, 66, 1);
              `}>
              <div
                className={css`
                  font-size: 19px;
                `}>
                {props.items.num}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={css`
          height: 80px;
          align-items: center;
          justify-content: center;
          display: flex;
        `}>
        <div
          className={css`
            color: ${props.current_phase == props.items.num
              ? 'black'
              : 'rgba(171, 171, 171,0.5)'};
            font-size: 13px;
            text-align: center;
          `}>
          <div>{props.items.text}</div>

        </div>
      </div>
    </div>
  );
};

const Item = props => {
  if (props.type === 1) return <Item1 date={props.date} current_phase={props.current_phase} items={props.items} />;
  else if (props.type === 2)
    return <Item2 date={props.date} current_phase={props.current_phase} items={props.items} />;
  else return <Item3 date={props.date}  current_phase={props.current_phase} items={props.items} />;
};

export default Item;
