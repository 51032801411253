import React from 'react';
import {css} from 'emotion';

const checkmark = css`
  position: absolute;
  top: 0.5px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #eee;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const inpout = css`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked ~ .${checkmark} {
    background-color: #29354a;
  }
`;

const container = css`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover .${checkmark} {
    background: #ccc;
  }
  & .${checkmark}:after {
    border-radius: 5px;
    top: 7px;
    left: 7px;
    width: 6px;
    height: 6px;
    background: white;
  }
  & .${inpout}:checked ~ .${checkmark} {
    background-color: #29354a;
  }
  & .${inpout}:checked ~ .${checkmark}:after {
    display: block;
  }
`;

const CheckBox = props => {
  const onChange = e => {
    props.onChange(props.id, e.target.value);
  };

  return (
    <label className={container}>
      {props.label}
      <input
        onChange={onChange}
        className={inpout}
        type="checkbox"
        checked={props.checked}
        id={props.id}
        name="checkbox"
      />
      <div className={checkmark}></div>
    </label>
  );
};

export default CheckBox;
