import React from 'react';
import {css} from 'emotion';
import ButtonEasy from '../../common/material-easy-ui/button/ButtonEasy';
import Avatar from '../../common/avatar/Avatar';

const SigninStepAvatar = props => {

  const tabsAvatar = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39];
  const chooseAvatar = item => {
    props.chooseAvatar(item);
  };

  return (
    <div
      className={css`
        flex-direction: column;
        display: flex;
        align-items: center;
        height: 500px;
        max-height: 100%;
      `}>

      <div className="avatar-picker-contains">
        {tabsAvatar.map((item, index) => {
          return (
            <div
              key={"AVATAR" + index}
              className={'avatar-picker-item'}
              style={item === props.avatar ? {border: "3px solid rgb(13, 179, 197)", borderRadius: '100%'} : {border: "3px solid transparent", borderRadius: '100%'}}
              onClick={() => {
                chooseAvatar(item);
              }}>
              <Avatar size={80} id_avatar={item} />
            </div>
          );
        })}
      </div>


      <div
        className={css`
          font-weight: 600;
          text-align: center;
          marginTop: 20px;
        `}>
        Choisissez votre avatar
      </div>

      <div
        className={css`
          height: 100px;
          width: 100%;
          text-align: center;
        `}>
        <ButtonEasy
          onPress={props.onUpdateAvatar}
          width={'200px'}
          text={'Valider'}
        />
      </div>
    </div>
  );
};

export default SigninStepAvatar;
