const initialState = {
  city_communities: [],
  community: false,
  question: false,
  isLoadingCommunities: false,
  isLoadingCommunity: false,
  isLoadingUserCommunities: false,
  isSubscribing: false,
  isGettingQuestions: false,
  isSendingRequest: false,
  error: null,
  userHasSubscribeGroupe: false,
  listGroupForUser: [],
  loading_for_user: false,
};

export function groupReducers(state = initialState, action) {
  switch (action.type) {
    case "GET_COMMUNITIES_LOADING":
      return {
        ...state,
        isLoadingCommunities: true,
        error: null,
      };

    case "GET_COMMUNITIES_SUCCESS":
      return {
        ...state,
        isLoadingCommunities: false,
        city_communities: [...action.city_communities],
        error: null,
      };
    case "GET_MORE_COMMUNITIES_SUCCESS":
      let city_communities = state.city_communities;
      if (action.city_communities !== null) {
        city_communities = state.city_communities.concat(
          action.city_communities
        );
      }
      return {
        ...state,
        isLoadingCommunities: false,
        city_communities: city_communities,
        error: null,
      };
    case "GET_COMMUNITIES_ERROR":
      return {
        ...state,
        isLoadingCommunities: false,
        error: action.error,
      };
    case "GET_COMMUNITY_LOADING":
      return {
        ...state,
        isLoadingCommunity: true,
      };
    case "GET_COMMUNITY_SUCCESS":
      return {
        ...state,
        isLoadingCommunity: false,
        community: action.community,
        question: action.question,
        error: null,
      };

    case "CLEAN_GROUP_DETAILS":
      return {
        ...state,
        isLoadingCommunity: false,
        community: false,
        question: false,
        error: null,
      };

    case "GET_COMMUNITY_ERROR":
      return {
        ...state,
        isLoadingCommunity: false,
        error: action.error,
      };
    case "SUBSCRIBE_COMMUNITY_LOADING":
      return {
        ...state,
        isSubscribing: true,
        error: null,
      };
    case "SUBSCRIBE_COMMUNITY_SUCCESS":
      return {
        ...state,
        isSubscribing: false,
        community: { ...state.community, subscribed: 1 },
        userHasSubscribeGroupe: true,
        error: null,
        isLoadingCommunities: false,
      };

    case "UNSUBSCRIBE_COMMUNITY_SUCCESS":
      return {
        ...state,
        isSubscribing: false,
        userHasSubscribeGroupe: false,
        community: { ...state.community, subscribed: 0, requested: 0 },
        error: null,
        isLoadingCommunities: false,
      };
    case "SUBSCRIBE_COMMUNITY_ERROR":
      return {
        ...state,
        isSubscribing: false,
        userHasSubscribeGroupe: false,
        error: action.error,
      };
    case "GET_RQST_QUESTIONS_LOADING":
      return {
        ...state,
        isGettingQuestions: true,
        error: null,
      };
    case "GET_RQST_QUESTIONS_SUCCESS":
      return {
        ...state,
        isGettingQuestions: false,
        questions: action.questions,
        error: null,
      };
    case "GET_RQST_QUESTIONS_ERROR":
      return {
        ...state,
        isGettingQuestions: false,
        error: action.error,
      };
    case "SEND_RQST_LOADING":
      return {
        ...state,
        isSendingRequest: true,
        error: null,
      };
    case "SEND_RQST_SUCCESS":
      return {
        ...state,
        isSendingRequest: false,
        community: { ...state.community, requested: 1 },
        error: null,
      };
    case "SEND_RQST_ERROR":
      return {
        ...state,
        isSendingRequest: false,
        error: action.error,
      };

    case "USER_HAS_SUBSCRIBE_GROUPE":
      return {
        ...state,
        userHasSubscribeGroupe: action.payload,
      };
    case "CLEAN_COMMUNITY_PAGE":
      return {
        ...state,
        userHasSubscribeGroupe: false,
      };

    case "GET_FOR_USER_GROUP_LOADING":
      return {
        ...state,
        loading_for_user: true,
      };

    case "GET_FOR_USER_GROUP_SUCCESS":
      return {
        ...state,
        loading_for_user: false,
        listGroupForUser: action.payload,
      };

    default:
      return state;
  }
}
