import React from 'react';
import IconEasyPageEmpty from '../../../common/icons/IconEasyPageEmpty';

const GroupNotSubscribe = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 100,
        width: '100%',
        height: 400,
        textAlign: 'center',
      }}>
      <IconEasyPageEmpty size={75} color="#d1d1d1" />


      <div style={{
        color: '#d1d1d1',
        textAlign: 'center',
        marginTop: 30
      }}>
        Inscrivez vous pour voir le contenu !
      </div>

    </div>
  );
};

export default GroupNotSubscribe;
