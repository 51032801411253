import React from 'react';
import {css} from 'emotion';


const ListMap = (props) =>{

  return(

      <div className={css`
          box-sizing:border-box;

          border-bottom-left-radius:20px;
          border-bottom-right-radius:20px;
          box-sizing:border-box;

          background-color:white;
          width:100%;
          margin-top:0px;
          padding-bottom:3px;`

        }>
        {props.list.length > 0 &&

          <div className={css`  box-sizing:border-box; padding-left:20px;
                padding-right:20px;`}>
                <div className={css`border-top:1px solid #e3e3e3;padding-top:3px`}></div>
          </div>
      }
        {props.list.map((item, i)=>{
          return (
          <div key={i} className={css`cursor:pointer;display:flex;align-items:center;padding-left:20px;padding-right:20px;box-sizing:border-box;font-size:14px;height:30px;&:hover{background-color:#ebebeb}`}>
            <div onClick={()=>props.onSelectCity(item)} className={css`white-space: nowrap;overflow:hidden`}>{item.place_name}</div>
            </div>)
          }) }
        </div>



  )
}


export default ListMap
