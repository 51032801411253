import BudgetPartAPI from '../../api/BudgetPartAPI';

// NEW !! FONCTION APPELEE ON START
export function getBudgetPart(insee_com, popkey, rubric, sort) {

  return function(dispatch) {
    BudgetPartAPI.getfullbp(insee_com, popkey, rubric, sort).then(data => {
      dispatch({
        type: 'GET_BUDGET_PART',
        payload: data.data,
      });
    });
  };

}

// NEW !! FONCTION GET MORE PROJECTS
export function getMoreProjects(id_budget_part_session, popkey, rubric, sort, phase, all_ids_budget_part_project) {
  return function(dispatch) {
    BudgetPartAPI.getMoreProjects(id_budget_part_session, popkey, rubric, sort, phase, all_ids_budget_part_project).then(data => {
      dispatch({
        type: 'GET_MORE_PROJECTS',
        payload: data.data,
      });
    });
  };
}


// NEW !! FUNCTION PROJET DETAILLÉ
export function getProjectDetails(popkey, id_budget_project) {
  return function(dispatch) {
    return BudgetPartAPI.GetProjectDetails(popkey, id_budget_project)
      .then(data => {
        if (data.hasOwnProperty('data')) {
          dispatch({
            type: 'GET_DETAILS_BUDGET_PART',
            payload: {
              details: data.data.detailed_project,
              comments: data.data.project_comments,
              commentsnb: data.data.project_comments_nb,
              endreached: data.data.project_comments_end_reached,
              statusProject: data.data.detailed_project_phase,
              pubanswers: data.data.project_pubanswers,
            },
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
}

// NEW !! FUNCTION like
export function likeProject(popkey, id_budget_part_project) {
  return function(dispatch) {
    dispatch({type: 'LIKE_PROJECT_LOADING'});
    BudgetPartAPI.likeProject(popkey, id_budget_part_project).then(() => {
      dispatch({
        type: 'LIKE_PROJECT',
        payload: id_budget_part_project,
      });
    });
  };
}

// NEW !! FUNCTION unlike
export function unlikeProject(popkey, id_budget_part_project) {
  return function(dispatch) {
    dispatch({type: 'LIKE_PROJECT_LOADING'});
    BudgetPartAPI.unlikeProject(popkey, id_budget_part_project).then(() => {
      dispatch({
        type: 'UNLIKE_PROJECT',
        payload: id_budget_part_project,
      });
    });
  };
}

// NEW !! FUNCTION Vote for a project
export function voteProject(popkey, id_budget_project) {
  return function(dispatch) {
    BudgetPartAPI.voteProject(
      popkey,
      id_budget_project,
    ).then(() => {
      dispatch({
        type: 'VOTE_PROJECT',
        payload: id_budget_project,
      });
    });
  };
}

// NEW !! FUNCTION Vote for a project
export function unvoteProject(popkey, id_budget_project) {
  return function(dispatch) {
    BudgetPartAPI.unvoteProject(
      popkey,
      id_budget_project,
    ).then(() => {
      dispatch({
        type: 'UNVOTE_PROJECT',
        payload: id_budget_project,
      });
    });
  };
}

// NEW !! FUNCTION details
export function getDetailsBudgetPart(popkey, id_budget_project) {
  return function(dispatch) {
    return BudgetPartAPI.getDetailsBudgetPart(popkey, id_budget_project)
      .then(data => {
        if (data.hasOwnProperty('data')) {
          dispatch({
            type: 'GET_DETAILS_BUDGET_PART',
            payload: {
              details: data.data.project,
              comments: data.data.comments,
              commentsnb: data.data.commentsnb,
              endreached: data.data.endReached,
              extension: data.data.extension,
              statusProject: data.data.statusProject,
              pubanswers: data.data.pubanswers,
            },
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
}




export function getMoreProject(
  codeinsee,
  popkey,
  id_budget_part_session,
  rubric,
  sort,
  phase,
  page,
  list_id,
) {
  return function(dispatch) {
    return BudgetPartAPI.filtreProject(
      codeinsee,
      popkey,
      id_budget_part_session,
      rubric,
      sort,
      phase,
      page,
      list_id,
    ).then(data => {
      dispatch({
        type: 'GET_MORE_PROJECT_PART',
        payload: data.data,
      });
    });
  };
}


export function postProject(
  id_budget_part_session,
  popkey,
  project,
  annex,
  code_insee,
) {
  return function(dispatch) {
    return BudgetPartAPI.postProject(
      id_budget_part_session,
      popkey,
      project,
      annex,
      code_insee,
    ).then(() => {
      dispatch({
        type: 'POST_PROJECT',
      });
    });
  };
}


export function goVote(popkey, id_budget_project, id_budget_part_session) {
  return function(dispatch) {
    BudgetPartAPI.goVote(
      popkey,
      id_budget_project,
      id_budget_part_session,
    ).then(() => {
      dispatch({
        type: 'HAS_VOTE_DETAILS',
      });

      dispatch({
        type: 'HAS_VOTE_BY_LIST',
        payload: id_budget_project,
      });
    });
  };
}

export function postComment(
  popkey,
  id_budget_part_project,
  ref_id_comment,
  description,
) {
  return function(dispatch) {
    return BudgetPartAPI.postComment(
      popkey,
      id_budget_part_project,
      ref_id_comment,
      description,
    )
      .then(data => {
        if (data.error) {
          dispatch({type: 'POST_PROJECT_COMMENT_ERROR'});
        } else {
          if (data.data.comment.ref_id_comment === null) {
            dispatch({
              type: 'POST_PROJECT_COMMENT_SUCCESS',
              payload: {
                comment: data.data.comment,
              },
            });
          } else {
            dispatch({
              type: 'POST_PROJECT_COMMENT_REPLY_SUCCESS',
              payload: {
                comment: data.data.comment,
              },
            });
          }

          dispatch({
            type: 'ADD_COMMENT_NB_PROJECT_FLUX',
            payload: {
              id_budget_part_project: data.data.comment.id_budget_part_project,
            },
          });

        }
      })
      .catch(() => {
        dispatch({type: 'POST_PROJECT_COMMENT_ERROR'});
      });
  };
}

export function getMoreComment(popkey, id_budget_part_project, page) {
  return function(dispatch) {
    dispatch({type: 'GET_MORE_COMMENT_PROJECT_LOADING'});
    return BudgetPartAPI.getMoreComment(
      page,
      popkey,
      id_budget_part_project,
    ).then(data => {
      dispatch({
        type: 'GET_MORE_COMMENT_PROJECT_SUCCESS',
        payload: {
          comments: data.data.comments,
          endreached: data.data.endReached,
        },
      });
    });
  };
}

export function removeComment(
  id_comment,
  ref_id_comment,
  id_budget_part_project,
) {
  return function(dispatch) {
    dispatch({type: 'DELETE_COMMENT_PARTICIPATION_LOADING'});
    return BudgetPartAPI.deleteComment(id_comment).then(
      data => {
        if (data.error) {
          dispatch({type: 'DELETE_COMMENT_PARTICIPATION_ERROR'});
        } else {
          dispatch({
            type: 'DELETE_COMMENT_PROJECT_SUCCES',
            payload: {
              id_comment: id_comment,
            },
          });

          dispatch({
            type: 'REMOVE_COMMENT_NB_PROJECT_FLUX',
            payload: {
              id_budget_part_project: id_budget_part_project,
            },
          });

        }
      },
    );
  };
}

export function removeProject(popkey, id_budget_part_project) {
  return function(dispatch) {
    return BudgetPartAPI.removeProject(popkey, id_budget_part_project).then(
      data => {
        return dispatch({
          type: 'REMOVE_PROJECT_BUDGET_PART_SUCCESS',
          payload: data.data,
        });
      },
    );
  };
}



/*

export function getAllBudgetPart(insee_com) {
  return function(dispatch) {
    BudgetPartAPI.getAllBudgetPart(insee_com).then(data => {
      dispatch({
        type: 'GET_BUDGET_PART_LIST_HISTORY',
        payload: data.data,
      });
    });
  };
}

export function getBudgetPartArchive(popkey, id_budget_part_session) {
  return function(dispatch) {
    BudgetPartAPI.getBudgetPartArchive(popkey, id_budget_part_session).then(
      data => {
        dispatch({
          type: 'GET_BUDGET_PART_ARCHIVE',
          payload: data.data,
        });
      },
    );
  };
}


export function filtreProject(
  codeinsee,
  popkey,
  id_budget_part_session,
  rubric,
  sort,
  phase,
  page,
) {
  return function(dispatch) {
    return BudgetPartAPI.filtreProject(
      codeinsee,
      popkey,
      id_budget_part_session,
      rubric,
      sort,
      phase,
      page,
    ).then(data => {
      dispatch({
        type: 'FILTRE_PROJECT',
        payload: data.data,
      });
    });
  };
}


export function getBudgetPartListProject(insee_com, popkey, phase) {
  return function(dispatch) {
    BudgetPartAPI.getBudgetPartListProject(insee_com, popkey, phase).then(
      data => {
        dispatch({
          type: 'GET_PROJECT_LIST',
          payload: data.data,
        });
      },
    );
  };
}



export function getProjectMap(id_budget_part_session, phase) {
  return function(dispatch) {
    BudgetPartAPI.getProjectMap(id_budget_part_session, phase).then(data => {
      dispatch({
        type: 'GET_PROJECT_LIST_MAP',
        payload: data.data,
      });
    });
  };
}


export function getDetailsLaureatBudgetPart(popkey, id_budget_project, page) {
  return function(dispatch) {
    return BudgetPartAPI.getDetailsLaureatBudgetPart(
      popkey,
      id_budget_project,
      page,
    ).then(data => {
      dispatch({
        type: 'GET_DETAILS_ONE',
        payload: {
          details: data.data.details,
          comments: data.data.comments,
          commentsnb: data.data.commentsnb,
          endreached: data.data.endReached,
          pubanswers: data.data.pubanswers,
        },
      });
    });
  };
}

export function getDetailedOneBudgetPart(popkey, id_budget_project, page) {
  return function(dispatch) {
    return BudgetPartAPI.getDetailedOneBudgetPart(
      popkey,
      id_budget_project,
      page,
    ).then(data => {
      dispatch({
        type: 'GET_DETAILS_ONE',
        payload: {
          details: data.data.details,
          comments: data.data.comments,
          commentsnb: data.data.commentsnb,
          endreached: data.data.endReached,
          pubanswers: data.data.pubanswers,
        },
      });
    });
  };
}

export function getDetailedOneBudgetPartVote(popkey, id_budget_project) {
  return function(dispatch) {
    return BudgetPartAPI.getDetailedOneBudgetPartVote(
      popkey,
      id_budget_project,
    ).then(data => {
      dispatch({
        type: 'GET_DETAILS_BUDGET_PART_VOTE',
        payload: {
          details: data.data.details,
        },
      });
    });
  };
}


export function goVoteByList(
  popkey,
  id_budget_project,
  id_budget_part_session,
) {
  return function(dispatch) {
    return BudgetPartAPI.goVote(
      popkey,
      id_budget_project,
      id_budget_part_session,
    ).then(data => {
      dispatch({
        type: 'HAS_VOTE_BY_LIST',
        payload: id_budget_project,
      });
    });
  };
}

*/
