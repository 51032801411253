import React from 'react';
import {css} from 'emotion';
import Items from './components/Items';
import InfoComplementaire from './components/InfoComplementaire';

const TimeLine = props => {

  const exemple = [
    {num: 1, text: 'Proposez un projet'},
    {num: 2, text: 'Votez'},
    {num: 3, text: 'Découvrez les lauréats'},
  ];

  if(props.budget_part_session) {
    return (
      <>
      <div
        className={css`
          display: flex;
        `}>
        <Items
          items={exemple[0]}
          date={props.budget_part_session.date_phase_1}
          current_phase={props.budget_part_session.current_phase}
          num={exemple[0].num}
          type={1}
        />
        <Items
          items={exemple[1]}
          date={props.budget_part_session.date_phase_2}
          current_phase={props.budget_part_session.current_phase}
          num={exemple[1].num}
          type={2}
        />
        <Items
          items={exemple[2]}
          date={props.budget_part_session.date_phase_3}
          current_phase={props.budget_part_session.current_phase}
          num={exemple[2].num}
          type={3}
        />
      </div>

      <InfoComplementaire
        current_phase={props.budget_part_session.current_phase}
        date_phase_1_bis={props.budget_part_session.date_phase_1_bis}
        date_phase_2={props.budget_part_session.date_phase_2}
        date_phase_2_bis={props.budget_part_session.date_phase_2_bis}
        date_phase_3={props.budget_part_session.date_phase_3}
      />
      
      </>
    );
  } else {
    return false;
  }

};

export default TimeLine;
