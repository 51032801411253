import React from 'react';
import {css} from 'emotion';

const Button = props => {
  return (
    <div
      style={props.style}
      onClick={props.onClick}
      className={css`
        background-color: #fff;
        display: inline-block;
        padding: 8px 15px 8px 15px;
        color: #f55d42;
        border-radius: 15px;
        text-align: center;
        cursor: pointer;
        border: 2px solid #f55d42;
        display: flex;
        width: ${props.size ? props.size : null};
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #f55d42;
          color: #fff;
        }
      `}>
      {props.children}
    </div>
  );
};

export default Button;
