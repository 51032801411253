import React,{useState} from 'react';
import {css} from 'emotion';
import IconEasyClose from '../../../../common/icons/IconEasyClose';

const TokenFile = ({index,destroyFiles,text}) =>{
  const [hover,setHover] = useState(false);

  return(
    <div
      onMouseEnter={()=>{setHover(true)}}
      onMouseLeave={()=>{setHover(false)}}
     className={css`
        margin-right:10px;
        font-size:10px;
        padding-left:10px;
        padding-right:10px;
        padding-top:5px;
        padding-bottom:5px;
        background-color:${!hover?'#F5F8FA':'rgb(242,101,35)'};
        color:${hover?'white':'grey'};
        box-shadow: {!hover ?'inset 0px 0px 0px 1px #ededed':'inset 0px 0px 0px 1px rgb(242,101,35)'};
        border-radius:25px;
        display:inline-flex;
        flex-direction:row;
        align-items:center;
        margin-top:15px;
        user-select: none;


        `}>
        <div className={css`flex:1;`}>{text}</div>
        <div onClick={()=>{destroyFiles(index)}} className={css`margin-left:10px;cursor:pointer`}><IconEasyClose color={hover?'white':'grey'} size={8}/></div>

        </div>
  )
}

export default TokenFile;
