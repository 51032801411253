import React from 'react';
import GroupPresentationBlock from './components/GroupPresentationBlock';
import WelcomeBlock from './components/WelcomeBlock';
import FreemiumBlock from './components/FreemiumBlock';
import {useSelector} from 'react-redux';

const GroupRightMenu = () => {
  const infoCity = useSelector(state => state.city.infoCity);

  return (

    <div className="rightMenuContainer">
      {infoCity && infoCity.premium !== 1 ? <FreemiumBlock /> : <WelcomeBlock />}
      <GroupPresentationBlock />

    </div>
  );
};

export default GroupRightMenu;
