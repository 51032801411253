import React, {useEffect, useState} from 'react';
import Avatar from '../../common/avatar/Avatar';
import iconPicker from '../../../image/icon/icon-simple/photo-picker.svg';
import AvatarPicker from './AvatarPicker';

const StartAvatarPicker = props => {
  //const [avatar,setAvatar] = useState(0);

  const [avatarPicker, setAvatarPicker] = useState(false);

  const openAvatarPicker = () => {
    setAvatarPicker(true);
  };

  const closeAvatarPicker = () => {
    setAvatarPicker(false);
  };


  const chooseAvatar = item => {
    props.chooseAvatar(item);
  };

  useEffect(() => {
    avatarPicker && setAvatarPicker(false);
  }, [props.avatar]);

  return (
    <div className="start-picker-avatar">
      <div
        onClick={openAvatarPicker}
        style={{
          width: '100px',
          height: '100px',
          cursor:'pointer',
          borderRadius: '50%',
          overflow: 'hidden',
          position: 'relative',
        }}>
        <Avatar
          size={100}
          id_avatar={props.avatar ? props.avatar : 0}
        />
        <div
          style={{
            background: 'rgba(0,0,0,0.5)',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <img
            alt='Choisir un Avatar'
            src={iconPicker}
            style={{width: '20px', height: '20px', position: 'relative'}}
          />
        </div>
      </div>
      
      { avatarPicker &&
        <AvatarPicker closeAvatarPicker={closeAvatarPicker} chooseAvatar={chooseAvatar} />
      }

    </div>
  );
};

export default StartAvatarPicker;
