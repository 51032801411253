import React from 'react';
import {css} from 'emotion';


const WarningMessage = (props) =>{
  return(
    <div style={{padding: 10, marginTop: 20, marginBottom: 20}}>
      <div
        className={css`
          border-radius: 4px;
          padding: 20px;
          background: rgba(245, 93, 66, 1);
          box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.04);
        `}>
        <div
          style={{
            textAlign: 'center',
            color: '#FFF',
            fontSize: 16,
            fontWeight: 'bold',
          }}>
          {props.title}
        </div>
        <div
          style={{
            textAlign: 'center',
            color: '#FFF',
            fontSize: 16,
            marginTop: 10,
            lineHeight: 1.2,
          }}>
          {props.message}
        </div>
      </div>
    </div>
  )
}

export default WarningMessage;
