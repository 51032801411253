import React from 'react';
import {css} from 'emotion';
const TokenMembers = props => {
  return (

      <div
        onClick={props.onSubscribe}
        className={css`
          margin-left: 10px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 10px;
          padding-top: 5px;
          padding-bottom: 5px;
          
          margin-left: 20px;
          cursor: pointer;
          text-align: center;
          background-color: ${props.subscribed === 1
            ? '#c41616'
            : props.subscribed === 0 && props.requested === 1
            ? 'orange'
            : '#64c416'};
          color: white;
          cursor: pointer;
          &:hover {
            background-color: ${props.subscribed === 1
              ? 'rgba(196, 54, 22,0.5)'
              : props.subscribed === 0 && props.requested === 1
              ? 'rgba(255, 164, 54,0.5)'
              : 'rgba(100, 196, 22,0.5)'};
          }
        `}>
        {props.subscribed === 1
          ? 'se désinscrire'
          : props.subscribed === 0 && props.requested === 1
          ? 'En attente'
          : "s'inscrire"}
      </div>

  );
};

export default TokenMembers;
