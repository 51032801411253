import React from "react";
import { css } from "emotion";

import IconEasyParticipate from "../common/icons/IconEasyParticipate";
import IconEasyWarning from "../common/icons/IconEasyWarning";
import IconEasyAppreciate from "../common/icons/IconEasyAppreciate";
import IconEasyImagine from "../common/icons/IconEasyImagine";
import IconEasyQuestion from "../common/icons/IconEasyQuestion";
import IconEasyCar from "../common/icons/IconEasyCar";
import IconEasyEye from "../common/icons/IconEasyEye";
import IconEasyMore from "../common/icons/IconEasyMore";
import IconEasyFeel from "../common/icons/IconEasyFeel";

const TypeIconParticipation = (props) => {

  const typeToIcon = (type, size) => {
    
    switch (type) {
      case 1:
        return <IconEasyWarning size={size} color="white" />;
      case 2:
        return <IconEasyAppreciate size={size} color="white" />;
      case 3:
        return <IconEasyImagine size={size} color="white" />;
      case 4:
        return <IconEasyQuestion size={size} color="white" />;
      case 6:
        return <IconEasyParticipate size={size} color="white" />;
      case 7:
        return <IconEasyCar size={size} color="#FFF" />;
      case 8:
        return <IconEasyEye size={size} color="#FFF" />;
      case 9:
        return <IconEasyFeel size={size} color="#FFF" />;
      default:
        return <IconEasyMore size={size} color="white" />;
    }
  };

  const typeToColor = (type) => {
    switch (type) {
      case 1:
        return "rgba(204, 49, 67, 1)";
      case 2:
        return "rgba(209, 107, 160, 1)";
      case 3:
        return "rgba(52, 207, 106, 1)";
      case 4:
        return "rgba(237, 205, 0, 1)";
      case 6:
        return "rgba(252, 186, 3, 1)";
      case 7:
        return "rgba(149, 129, 125, 1)";
      case 8:
        return "rgba(41, 188, 198, 1)";
        case 9:
          return "rgba(209, 107, 160, 1)";
      default:
        return "#999999";
    }
  };

  return (
    <div
      className={css`
        background-color: ${typeToColor(props.type)};
        border-radius: 50%;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        
        height: ${props.size ? props.size : 20}px;
        width: ${props.size ? props.size : 20}px;
      `}
      style={props.style}>
      {typeToIcon(props.type, props.size ? props.size - 5 : 18 )}
    </div>
  );
};

export default TypeIconParticipation;
