import React, {useState} from 'react';
import {css} from 'emotion';
import axios from 'axios';
import IconEasySearch from '../../../common/icons/IconEasySearch';
import IconEasyGeoloc from '../../../common/icons/IconEasyGeoloc';
import {useSelector} from 'react-redux';


var timeout = null;
var txttosearch = '';

const SearchBarMap = props => {
  
  const [listCity, setListCity] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const infoCity = useSelector(state => state.city.infoCity);

  const onChangeTextMap = e => {
    txttosearch = e.target.value;

    if (timeout) {
      clearTimeout(timeout);
    }


    timeout = setTimeout(() => {
      if (typeof txttosearch === 'string') {
        if (txttosearch.match(/[A-Za-z0-9]/i)) {
          setIsFetching(true);
          axios
            .get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${txttosearch}.json?proximity=${infoCity.lon},${infoCity.lat}&access_token=pk.eyJ1IjoianVzcHJpZXQiLCJhIjoiY2o5cHRzMjB1MnVxMTJ4czJoaTdhZjVjbCJ9.3LZxp2Lf97TcCYWPAgsyyA&autocomplete=true&limit=5&country=fr`,
            )
            .then(data => {
              setListCity(data.data.features);
              setIsFetching(false);
            });
        }
        if (txttosearch.length === 0) {
          setIsFetching(false);
          setListCity([]);
        }

      }
    }, 500);
  };

  const onChooseCity = item => {
    props.onChooseCity(item.center, item.place_name);
    setListCity([]);
  };

  const maPosition = position => {
    props.onGeoloc(position.coords.latitude, position.coords.longitude);
    setIsFetching(false);
  };

  const onGeoloc = () => {
    if (navigator.geolocation) {
      setIsFetching(true);
      navigator.geolocation.getCurrentPosition(maPosition);
    }
  };

  return (
    <div
      className={css`
        position: absolute;
        top: 20px;
        left: 10%;
        width: 80%;
        z-index: 1000;
        -moz-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74, 0.1);
        -webkit-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74, 0.1);
        -o-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74, 0.1);
        box-shadow: 3px 3px 14px 0px rgba(41, 53, 74, 0.1);
        background-color: white;
        padding: 5px 10px 5px 10px;
        border-radius: 10px;
      `}>
      <div
        className={css`
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        `}>

          <IconEasySearch color={'#c7c7c7'} size={20} />


        <input
          onChange={onChangeTextMap}
          className={css`
            flex: 1;
            outline: none;
            border: none;
            font-size: 15px;
            margin-left: 10px;
            &::placeholder {
              color: #c7c7c7;
            }
          `}
          placeholder="Chercher un Lieu"
        />


          {
            isFetching ?
            <img
              className={css`
                height: 25px;
              `}
              src={require('../../../../image/loading.gif')}
              alt="loading"
            />
            :
            <div
              onClick={onGeoloc}
              className={css`
                margin-left: 10px;
                cursor: pointer;
                width: 30px;
              `}>
              <IconEasyGeoloc color={'#5574a6'} size={20} />
            </div>
          }
        

      </div>

      <div
        className={css`
          margin-top: 5px;
        `}>
        {listCity.map((item, index) => {
          return (
            <div
              onClick={() => onChooseCity(item)}
              key={"ADD" + index}
              className={css`
                padding: 10px;
                box-sizing: border-box;
                background: white;
                width: 100%;
                border-top: 1px solid #e8e8e8;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              `}>
              {item.place_name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchBarMap;
