import React from 'react';
import {css} from 'emotion';

import IconEasyVote from '../../../../../common/icons/IconEasyVote';
import ButtonVote from './ButtonVote';

const VoteCounter = (props) =>{



  if ((props.project_status == 3) || (props.project_status == 4)) {
    return(
      <>
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}>

          <IconEasyVote
            color={'#29354a'}
            size={25}
          />

          <div
            className={css`
              color: '#29354a';
              font-size: 13px;
              margin-left: 5px;
            `}>
            {props.votes}
          </div>

        </div>

        {
          props.has_voted &&
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              background: green;
              padding: 6px 12px 6px 12px;
              border-radius: 25px;
              color: #fff;
              margin-left: 20px;
              font-weight: bold;
            `}>
            Vote attribué
        </div>
        }

        {(props.current_phase == 2 && (props.project_status == 3 || props.project_status == 4)) ?
            <ButtonVote has_voted={props.has_voted} toggleVote={props.toggleVote}/>
          :
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 2px solid green;
                padding: 6px 11px 6px 12px;
                border-radius: 25px;
                color: green;
                margin-left: 20px;
                font-weight: bold;
              `}>
              Retenu pour le vote
            </div>
        }
      </>
    )
  }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
      `}>
      <div
        style={{
          color: 'grey',
          fontSize: 16,
          padding: '3px 10px 3px 10px',
          borderRadius: 25,
          border: '1px solid grey',
        }}>
        Non retenu
      </div>
    </div>
  );
}

export default VoteCounter;
