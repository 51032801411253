import React from 'react';
import {css} from 'emotion';

const ItemNav = props => {
  return (
    <div
      onClick={() => {
        props.onPress(props.pathname);
      }}
      className={css`
        margin-top: 20px;
        padding-right: 10px;
        padding-left: 7px;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        flex-direction: row;
        background-color: ${props.colorNames.includes(props.location.pathname)
          ? 'white'
          : 'transparent'};
        border-radius: 15px;
        cursor: pointer;
        border: ${props.colorNames.includes(props.location.pathname)
          ? '2px solid #f55d42'
          : '2px solid transparent'};
        &:hover {
          background-color: #ffeeed;
        }
        @media (max-width: 1200px) {
          padding: 0px;
        }
      `}>
      <div
        className={css`
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 1200px) {
            background-color: ${props.colorNames.includes(
              props.location.pathname,
            )
              ? '#fff'
              : 'none'};
            :hover {
              background-color: ${props.colorNames.includes(
                props.location.pathname,
              )
                ? 'none'
                : '#FFEEED'};
            }
          }
        `}>
        {props.icon}
      </div>
      <div
        className="item-menu-text"
        style={{
          fontSize: 18,
          marginLeft: 5,
          fontWeight: 600,
          lineHeight: 1.2,
          paddingTop: 5,
          paddingBottom: 5,
          color: props.colorNames.includes(props.location.pathname)
            ? '#f55d42'
            : '#29354a',
        }}>
        {props.text}
      </div>
    </div>
  );
};

export default ItemNav;
