import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyInfo = (props) =>{
    return(
      <IconPattern size={props.size}>
    <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        `}
    version="1.1" viewBox="0 0 32 32">
    <path
    fill={props.color}
    d="M16.277,3.046c0.227,0.104 0.306,0.11 0.475,0.304c0.421,0.485 0.218,1.412 -0.472,1.616c-0.164,0.048 -0.337,0.04 -0.497,0.043c-4.599,0.095 -8.982,3.312 -10.335,7.858c-0.812,2.73 -0.516,5.775 0.826,8.298c1.859,3.494 5.674,5.849 9.723,5.849c3.962,0 7.838,-2.306 9.722,-5.849c1.868,-3.513 1.636,-8.047 -0.646,-11.376c-1.286,-1.876 -3.165,-3.331 -5.303,-4.115l-0.251,-0.135c-0.407,-0.403 -0.541,-1.078 -0.116,-1.508c0.203,-0.205 0.495,-0.313 0.777,-0.294c0.167,0.011 0.323,0.074 0.486,0.137c4.639,1.784 8.058,6.392 8.318,11.468c0.204,3.984 -1.542,8.018 -4.597,10.601c-3.36,2.842 -8.206,3.803 -12.408,2.438c-4.479,-1.456 -8.009,-5.489 -8.804,-10.191c-0.693,-4.1 0.702,-8.503 3.709,-11.457c2.402,-2.359 5.736,-3.721 9.111,-3.727c0.094,0.014 0.188,0.027 0.282,0.04Zm-0.224,9.965c0.182,0.015 0.231,0.035 0.327,0.075c0.328,0.136 0.571,0.456 0.611,0.812c0.005,0.049 0.005,0.062 0.006,0.112l0,8.002c-0.006,0.23 -0.037,0.305 -0.099,0.434c-0.295,0.613 -1.284,0.756 -1.717,0.145c-0.119,-0.168 -0.179,-0.373 -0.185,-0.579l0,-8.002c0.006,-0.183 0.023,-0.233 0.057,-0.331c0.123,-0.352 0.456,-0.621 0.832,-0.663c0.108,-0.012 0.133,-0.006 0.168,-0.005Zm-0.056,-1.999c0.555,0 1.006,-0.449 1.006,-1.003c0,-0.555 -0.451,-1.004 -1.006,-1.004c-0.556,0 -1.007,0.449 -1.007,1.004c0,0.554 0.451,1.003 1.007,1.003Z"/>
    </svg>
    </IconPattern>
    )
}

export default IconEasyInfo;
