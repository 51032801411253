import React, {useState, useCallback} from 'react';
import {css} from 'emotion';
import axios from 'axios';
import LabelFile from './LabelFile';
import PicturePreview from './PicturePreview';

import Compress from 'compress.js';
import { useDropzone } from "react-dropzone";
import { server_img_api } from '../../../../../config/constants.js';

const compress = new Compress();

function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

const InputFile = props => {
  const [uri, setUri] = useState(props.uri);
  const [load, setLoad] = useState(false);
  const [nameFile, setNameFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(props.fileUrl);

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    dragDropFileHandler (acceptedFiles)
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const onClose = () => {
    setUri(null);
    setNameFile(null);
    setLoad(false);
    setFileUrl(null);
    props.onClearFileUrl();
  };

  //function resize Image
  async function resizeImageFn(file) {
    const resizedImage = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 0.6, // the quality of the image, max is 1,
      maxWidth: 600, // the max width of the output image, defaults to 1920px
      maxHeight: 2000, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const alt = img.alt;
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
    return {
      uploadfile: resizedFiile,
      base64: base64str,
      alt: alt,
    };
  }

  //function Send BackEnd
  const sendImage = data => {
    var file = blobToFile(data);
    var formData = new FormData();
    formData.append('image', file);

    const configs = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    setLoad(true);
    axios
      .post(props.url, formData, configs)
      .then(res => {
        props.onPost(res.data);
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
      });
  };

  // const fileChangedHandler = event => {
  //   var fileInput = false;
  //   if (event.target.files[0]) {
  //     fileInput = true;
  //   }
  //   if (fileInput) {
  //     resizeImageFn(event.target.files[0]).then(data => {
  //       //Affichage de l'image
  //       setUri('data:image/jpeg;base64,' + data.base64);
  //       setNameFile(data.alt);

  //       if (props.url)
  //         sendImage(data.uploadfile);
  //     });
  //   }
  // };

  const dragDropFileHandler = (file) => {
    if (file) {
      resizeImageFn(file[0]).then(data => {
        //Affichage de l'image
        setUri('data:image/jpeg;base64,' + data.base64);
        setNameFile(data.alt);
        if (props.url)
          sendImage(data.uploadfile);
      });
    }
  }

  return (
    <div
      className={css`
        ${props.style}
      `}>

      <div
        className={css`
          padding-left: 5px;
          padding-right: 5px;
          paddinng-top: 10px;
          padding-bottom: 20px;
          margin-top: 20px;
        `}>
        {uri && fileUrl === null ? (
          <PicturePreview
            onClose={onClose}
            nameFile={nameFile}
            load={load}
            uri={uri}
          />
        ) : fileUrl ? (

          <PicturePreview
            onClose={onClose}
            nameFile={nameFile}
            load={load}
            uri={server_img_api + fileUrl}
          />

        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <LabelFile isDragActive={isDragActive} />
          </div>
        )}
      </div>
    </div>
  );
};

export default InputFile;
