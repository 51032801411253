import React, {useState} from 'react';
import {css} from 'emotion';
import IconEasyFiles from '../../../../common/icons/IconEasyFiles';
import axios from 'axios';
import TokenFile from './TokenFile';

const InputFileBasic = props => {
  const [fileList, setFileList] = useState([]);

  const onChangeFile = e => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append('file', file);

    const configs = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    axios.post(props.url, formData, configs).then(data => {
      props.onChangeFiles(data.data.pathName);
      setFileList(prevState => [...prevState, data.data.prevPath]);
    });
  };

  const destroyFiles = index => {
    setFileList(prevState => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ]);
    props.removeFiles(index);
  };

  return (
    <>
      {props.title && (
        <div
          className={css`
            font-weight: 600;
            margin-bottom: 10px;
            ${props.style}
          `}>
          {props.title}{' '}
          {props.facultatif ? (
            <span
              className={css`
                font-size: 12px;
              `}>
              (facultatif)
            </span>
          ) :
          <span
            className={css`
              font-size: 12px;
            `}>
             *
          </span>
        }
        </div>
      )}
      <label htmlFor="extends-files">
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}>
          <div
            className={css`
              width: 60px;
              height: 40px;
              background: rgb(242,101,35);
              border-radius: 5px;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            `}>
            <IconEasyFiles color={'white'} size={30} />
          </div>
          <div
            className={css`
              margin-left: 10px;
            `}>
            Ajouter des fichiers annexes
            <span
              className={css`
                font-size: 12px;
              `}>
              (.PDF,JPG,.PNG,.DOC,...)
            </span>
          </div>
        </div>

        <input
          onChange={onChangeFile}
          className={css`
            display: none;
          `}
          type="file"
          name="files"
          id="extends-files"
        />
      </label>
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 5px;
          flex-wrap: wrap;
        `}>
        {fileList &&
          fileList.map((item, i) => {
            return (
              <TokenFile key={i} index={i} destroyFiles={destroyFiles} text={item} />
            );
          })}
      </div>
    </>
  );
};

export default InputFileBasic;
