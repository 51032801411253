import React from 'react';

import {css} from 'emotion';
import {server_img_api} from '../../../../../config/constants';
import HeaderDetails from './HeaderDetails';

const BaseDetails = props => {
  return (
    <div>
      {props.item.img_project && (
        <img
          alt="illustration du projet"
          className={css`
            width: 100%;
          `}
          src={server_img_api + props.item.img_project}
        />
      )}
      <HeaderDetails
        date_creation={props.item.date_creation}
        nom_comm={props.item.nom_comm}
        pseudo={props.item.pseudo}
        avatar={props.item.avatar}
      />
    </div>
  );
};

export default BaseDetails;
