import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyArrowDown = (props) =>{
  return(
    <IconPattern size={props.size}>
      <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;`}
        version="1.1" viewBox="0 0 32 32">
        <path d="M16.196,20.984c0.062,-0.019 0.127,-0.032 0.187,-0.057c0.163,-0.068 0.191,-0.107 0.325,-0.217l8.004,-8.005c0.083,-0.101 0.167,-0.204 0.217,-0.324c0.176,-0.424 0.015,-0.959 -0.368,-1.215c-0.328,-0.219 -0.784,-0.219 -1.112,0c-0.055,0.036 -0.101,0.083 -0.152,0.124l-7.297,7.297l-7.296,-7.297l-0.152,-0.124c-0.058,-0.031 -0.113,-0.067 -0.173,-0.092c-0.423,-0.176 -0.955,-0.02 -1.215,0.368c-0.219,0.328 -0.219,0.784 0,1.112c0.037,0.054 0.083,0.101 0.125,0.151l8.004,8.005c0.051,0.041 0.097,0.088 0.152,0.124c0.109,0.073 0.231,0.124 0.36,0.15c0.128,0.025 0.26,0.012 0.391,0Z"
        fill={props.color}
        />
      </svg>
    </IconPattern>
  )
}

export default IconEasyArrowDown;
