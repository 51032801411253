import React, {useState} from 'react';
import {css} from 'emotion';
import IconEasyClose from '../../icons/IconEasyClose';

const CloseButtonEasy = props => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={props.onPress}
      className={css`
            border-radius:50%;
            padding:2px;
            box-sizing:border-box;
            cursor:pointer;
            display:flex;
            justify-content:center;
            align-items:center;
             transition: 0.1s;
            &:hover {
              background-color:rgba(1245, 93, 66,0.2);
              padding:8px;
            }
            `}
      style={{...props.style}}>
      <IconEasyClose
        size={20}
        color={hover ? 'rgba(1245, 93, 66,1)' : 'rgb(41, 53, 74)'}
      />
    </div>
  );
};

export default CloseButtonEasy;
