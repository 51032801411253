import axios from 'axios';
import {server_api_url} from '../config/constants';

class SurveyAPI {
  static getSurvey(popkey, id_publicpart) {
    return axios.get(server_api_url + '/surveys/details', {
      params: {
        popkey: popkey,
        id_publicpart: id_publicpart,
      },
    });
  }

  static postSurvey(popkey, answers, comment, id_publicpart) {
    return axios.post(server_api_url + '/surveys/postSurvey', {
      popkey: popkey,
      answers: answers,
      comment: comment,
      id_publicpart: id_publicpart,
    });
  }
}

export default SurveyAPI;
