import React from 'react';
import {css} from 'emotion';

const FooterParticipateStep = props => {
  return (
    <div
      className={css`
        height: 50px;
        display: flex;
        background-color: #f5f8fa;
        flex-direction: row;
        line-height: 50px;
        border-top: 1px solid rgba(41, 53, 74, 0.1);
        position : fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background: #fff;
        z-index: 9990;
      `}>
      {props.children}
    </div>
  );
};

export default FooterParticipateStep;
