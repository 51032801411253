// POPVOX  
export const participationTypesFr = {
  1: "J'alerte",
  2: "J'apprécie",
  3: 'Je propose',
  4: 'Je questionne',
  6: 'Je critique',
  7: 'Covoiturage',
  8: "J'observe",
  9: "Je ressens"
};

export const participationSlogansFr = {
  1: "Je suis témoin d'un risque ou d'un dysfonctionnement",
  2: 'Je partage un avis, une critique, un sentiment...',
  3: "J'ai une idée, une proposition d'amélioration, un conseil",
  4: 'Je pose une question, une demande de précisions...',
  6: "Je n'apprécie pas une décision, un service, un lieu...",
  7: 'Je recherche ou propose un covoiturage',
  8: "Je partage une photo d'un lieu, d'une espèce...",
  9: 'Je partage un avis, une critique, un sentiment...',
};

export const badgesTitles = {
  1 : "Récompense : Éclat",
  2 : "Récompense : Dynamisme",
  3 : "Récompense : Utilité",
  4 : "Récompense : Volontariat",
  5 : "Récompense : Implication",
  6 : "Récompense : Engagement",
  7 : "Récompense : Mobilisation",
  8 : "Récompense : Mérite",
  9 : "Récompense : Honneur"
};

export const participationTypesShort = {
  1: {
    text: 'prévient',
    color: 'rgba(204, 49, 67, 1)'
  },
  2: {
    text: 'apprécie',
    color: 'rgba(209, 107, 160, 1)'
  },
  3: {
    text: 'propose',
    color: 'rgba(52, 207, 106, 1)'
  },
  4: {
    text: 'questionne',
    color: 'rgba(237, 205, 0, 1)'
  },
  6: {
    text: 'critique',
    color: 'rgba(252, 186, 3, 1)'
  },
  7: {
    text: 'covoiturage',
    color: 'rgba(149, 129, 125, 1)'
  },
  8: {
    text: 'observe',
    color: 'rgba(41, 188, 198, 1)'
  },
  9: {
    text: 'ressent',
    color: 'rgba(209, 107, 160, 1)'
  },
};



//ATTENTION ! A REMETTRE POUR INFOPARCS
/*export const participationTypesFr = {
    1: 'Je préviens',
    //2: "J'apprécie",
    3: 'Je propose',
    4: 'Je questionne',
    //6: 'Je critique',
    //7: 'Covoiturage',
    8: "J'observe",
    9: "Je ressens"
  };

  export const participationSlogansFr = {
    1: "Je suis témoin d'un risque ou d'un dysfonctionnement",
    2: 'Je partage un avis, une critique, un sentiment...',
    3: "J'ai une idée, une proposition d'amélioration, un conseil",
    4: 'Je pose une question, une demande de précisions...',
    6: "Je n'apprécie pas une décision, un service, un lieu...",
    7: 'Je recherche ou propose un covoiturage',
    8: "Je partage une photo d'un lieu, d'une espèce...",
    9: 'Je partage un avis, une critique, un sentiment...',
  };
  
  export const badgesTitles = {
    1: 'Visiteur',
    2: 'Randonneur',
    3: 'Baroudeur',
    4: 'Voyageur',
    5: 'Aventurier',
    6: 'Explorateur',
    7: 'Pionnier',
    8: 'Eclaireur',
    9: 'Parc Addict',
  };
  
  export const participationTypesShort = {
    1: {
      text: 'prévient',
      color: 'rgba(204, 49, 67, 1)'
    },
    2: {
      text: 'apprécie',
      color: 'rgba(209, 107, 160, 1)'
    },
    3: {
      text: 'propose',
      color: 'rgba(52, 207, 106, 1)'
    },
    4: {
      text: 'questionne',
      color: 'rgba(237, 205, 0, 1)'
    },
    6: {
      text: 'critique',
      color: 'rgba(252, 186, 3, 1)'
    },
    7: {
      text: 'covoiturage',
      color: 'rgba(149, 129, 125, 1)'
    },
    8: {
      text: 'observe',
      color: 'rgba(41, 188, 198, 1)'
    },
    9: {
      text: 'ressent',
      color: 'rgba(209, 107, 160, 1)'
    },
  };
  */


  
  export const participationThemeFr = {
    //NOUVEAUX TYPE 2 DYSFONCTIONNEMENTS
    1: 'Voirie ',
    2: 'Eclairage & signalisation',
    3: 'Réseau électrique',
    4: 'Propreté & déchets',
    5: 'Eau & assainissement',
    6: 'Nuisance',
    7: 'Espace vert & parc',
    8: 'Équipement & mobilier urbain',
    9: 'Transport & mobilité',
    10: 'Accessibilité',
    11: 'Télécomm...',
    12: 'Intempéries',
    13: 'Objets et animaux perdus',
    //NEW INFOPARCS
    14: 'Risque incendie',
    15: 'Pollution',
    //4: "Propreté & déchets",
    16: 'Pratique inadaptée',
    17: 'Dégradation ou usure',
    18: 'Espèce invasive',
    //EO NEW INFOPARCS
    19: 'Autre',
  
    //NOUVEAUX TYPE 2 APPRECIATION / JE RESSENS
    22: 'Urbanisme',
    21: 'Cadre de vie',
    29: 'Mobilité',
    26: 'Vie citoyenne',
    25: 'Culture & éducation',
    32: 'Social',
    33: 'Sport & santé',
    28: 'Finances & commerces',
    30: 'Tranquilité publique',
  
    //ANCIENS TYPES APPRECIATION
    23: 'Equipement & Mobilier urbain',
    24: 'Services publics de la Ville',
    27: 'Projets de la mairie',
    31: 'Jeunesse & éducation',
    34: 'Environnement',
    //NEW INFOPARCS : AUCUN
    39: 'Autre',
  
    // NOUVEAUX TYPE 3 IDEES / JE PROPOSE
    42: 'Paysage, Urbanisme et Voirie',
    41: 'Cadre de vie',
    49: 'Mobilité',
    46: 'Vie citoyenne',
    45: 'Culture & éducation',
    52: 'Social',
    53: 'Sports & loisirs',
    48: 'Finances & commerces',
    50: 'Tranquilité publique',
    //Ancients types 2 IDEES
    43: 'Equipement & Mobilier urbain',
    44: 'Services publics de la Ville',
    47: 'Projets de la mairie',
    51: 'Jeunesse & éducation',
    54: "Protection de l'environnement",
    //NEW INFOPARCS
    40: "Conservation des espèces",
    //54: "Protection de l'environnement",
    //42: 'Paysage, Urbanisme et Voirie',
    55: "Vie associative",
    //53: 'Sports & loisirs',
    56: "Tourisme",
    57: "Culture et patrimoine",
    58: "Évènement",
    //EO NEW INFOPARCS
    59: 'Autre',
  
    //NOUVEAUX TYPE 4 QUESTIONS
    62: 'Urbanisme',
    61: 'Cadre de vie',
    69: 'Mobilité',
    66: 'Vie citoyenne',
    65: 'Culture & éducation',
    72: 'Social',
    73: 'Sport & santé',
    68: 'Finances & commerces',
    70: 'Tranquilité publique',
    //Ancients types 2 QUESTIONS
    63: 'Equipement & Mobilier urbain',
    64: 'Services publics de la Ville',
    67: 'Projets de la mairie',
    71: 'Jeunesse & éducation',
    74: 'Environnement',
    //NEW INFOPARCS
    75: 'Accessibilité',
    76: 'Activités',
    77: 'Règlementation',
    78: 'Espèce animale',
    78001: 'Espèce végétale',
    78002: 'Évènement',
    78003: 'Milieu naturel',
    //EO NEW INFOPARCS
    79: 'Autre',
  
    //NOUVEAUX TYPE 6 Critiques
    82: 'Urbanisme',
    81: 'Cadre de vie',
    89: 'Mobilité',
    86: 'Vie citoyenne',
    85: 'Culture & éducation',
    92: 'Social',
    93: 'Sport & santé',
    88: 'Finances & commerces',
    90: 'Tranquilité publique',
    99: 'Autre',
    //Ancients types 6 Critiques
    83: 'Equipement & Mobilier urbain',
    84: 'Services publics de la Ville',
    87: 'Projets de la mairie',
    91: 'Jeunesse & éducation',
    94: 'Environnement',
  
    //NOUVEAUX TYPE 8 Covoit
    101: 'Proposition covoiturage',
    102: 'Recherche covoiturage',
    119: 'Autre',
  
    //NOUVEAUX TYPE 8 Observation
    125: 'Paysage',
    126: 'Coucher de soleil',
    127: 'Espèce animale',
    128: 'Espèce végétale',
    //NEW INFOPARCS
    //127: 'Espèce animale',
    //128: 'Espèce végétale',
    //125: 'Paysage',
    129: 'Culture et patrimoine',
    130: 'Phénomène remarquable',
    //EO NEW INFOPARCS
    139: 'Autre',
  };
  
  export const participationRubricsFR = {
    //DYSFONCTIONNEMENTS !!!!
    // Voirie 1
    1: 'Chaussée',
    2: 'Trottoir',
    3: 'Marquage au sol',
    4: 'Piste cyclable',
    5: 'Carrefour',
    6: 'Giratoire',
    19: 'Autre',
    //Eclairage & Signalisation	2
    21: 'Lampadaire abimé',
    22: 'Lampadaire éteint',
    23: 'Lampadaire clignotant',
    24: 'Eclairage allumé en journée',
    25: 'Eclairage insuffisant',
    26: 'Feu tricolore',
    27: 'Panneau de signalisation',
    28: 'Passage piéton',
    39: 'Autre',
    //Réseau électrique	3
    41: 'Coffret électrique endommagé',
    42: 'Cables ou poteau détériorés',
    43: "Coupure d'électricité",
    44: 'Réseau électrique instable',
    59: 'Autre',
    //Propreté & Gestion des déchets	4
    61: 'Poubelles pleines',
    62: 'Poubelles renversées',
    63: 'Containers inadaptés',
    64: 'Containers manquants',
    65: 'Containers dégradés',
    66: 'Propreté & Dépôts sauvages',
    67: 'Déjections animales',
    68: 'Pollution',
    69: 'Tag & Grafitti',
    70: 'Affichage sauvage',
    71: 'Avaloir ou caniveau obstrué',
    79: 'Autre',
    //Eau & assainissement	5
    81: "Coupure d'eau",
    82: 'Manque de pression',
    83: 'Gout de l’eau du robinet',
    84: "Couleur de l'eau du robinet",
    85: "Fuite d'eau",
    86: 'Arrosage inutile / Gachis',
    87: 'Borne incendie',
    88: 'Canalisation endommagée',
    89: 'Avaloir ou caniveau obstrué',
    90: 'Pollution',
    91: "Odeur d'égouts",
    92: "Bruits d'eau anormaux",
    93: "Débordements d'eau",
    99: 'Autre',
    //C nuisances	6
    101: 'Bruit',
    102: 'Mauvaises Odeurs',
    103: 'Animaux nuisibles',
    104: 'Elément inesthétique',
    105: 'Tapage nocturne',
    119: 'Autre',
    //Espaces verts	7
    121: 'Parc mal entretenus',
    122: 'Arbres ou plantes dégradés',
    123: 'Plantes Invasives',
    124: 'Arrosage inutile / Gachis',
    139: 'Autre',
    //Equipements vétuste ou dégradé	8
    141: 'Batiment',
    142: 'Banc',
    143: 'Aire de Jeu',
    144: 'Plot & barrière',
    145: 'Fontaines',
    146: 'Escalators',
    147: 'Toilettes publiques',
    148: 'Abribus',
    149: 'Agrès sportif',
    159: 'Autre',
    //Transport	9
    161: 'Stationnement',
    162: 'Circulation',
    163: 'Sens de circulation inadapté',
    164: 'voiture ventouse',
    165: 'Bus',
    166: 'Véhicules Electriques',
    167: 'Metro / Tramway',
    168: 'vélo',
    179: 'Autre',
    //Accessibilité	10
    181: 'Accés handicapé manquant',
    182: 'Accès complexe',
    183: 'Horaires inadaptés',
    184: 'Signalétique manquante',
    199: 'Autre',
    //Télécommunication	11
    201: 'Problème réseau mobile',
    202: 'wifi public',
    203: 'Mauvaise réception TV',
    219: 'Autre',
    //Intempéries	12
    221: 'Inondation',
    222: 'Verglas',
    223: 'NeigeAbondante',
    224: 'Branche ou Arbre coupé',
    225: 'Eboulement',
    226: 'Lignes électriques endommagées',
    239: 'Autre',
    //Animaux et objets perdUs 13
    240: 'Animal trouvé',
    241: 'Animal perdu',
    242: 'Objet trouvé',
    243: 'Objet perdu',
    259: 'Autre',
    //POLLUTION 15
    260 : "Pollution de l’air",
    261 : "Pollution du sol",
    262 : "Pollution de l’eau",
    263 : "Pollution sonore",
    264 : "Pollution lumineuse",
    279 : "Autre",
    //PRATIQUE INADAPTEE 16
    280 : "Camping/ Bivouac / Caravaning",
    281 : "Aménagement illégal",
    282 : "Braconnage",
    283 : "Travaux non autorisées",
    299 : "Autre",
  
    //APPRECIATION !!!!
    //Urbanisme
    1000: 'Habitat',
    1001: 'Voirie',
    1002: 'Réseaux',
    1003: 'Mobilier urbain',
    1004: 'Equipements de la Ville',
    1005: 'Parcs et Jardins',
    1006: 'Zone piétonne',
    1007: 'Accessibilité',
    1019: 'Autre',
    //Environnement - cadre de vie
    1021: 'Cadre de vie',
    1022: 'Littoral',
    1023: 'Espace naturel',
    1024: 'Espace boisé',
    1025: 'Jardin public',
    1026: 'Energie',
    1027: 'Propreté',
    1028: 'Environnement',
    1029: 'Condition animale',
    1030: 'Transition écologique',
    1039: 'Autre',
    //Mobilité
    1040: 'Vélo',
    1041: 'Zone piétonne',
    1042: 'Bus',
    1043: 'Métro',
    1044: 'Tram',
    1045: 'Rues',
    1046: 'Trottoirs',
    1047: 'Handicap',
    1048: 'Stationnement',
    1059: 'Autre',
    //Vie citoyenne
    1060: 'Vie associative',
    1061: 'Bénévolat',
    1062: 'Animations',
    1063: 'Démocratie participative',
    1064: 'Vie des quartiers',
    1065: 'Référents de quartiers',
    1066: 'Budget participatif',
    1067: 'Infoparcs',
    1068: 'Politique de la ville',
    1079: 'Autre',
    //Culture & Education
    1080: 'Culture',
    1081: 'Patrimoine',
    1082: 'Animations',
    1083: 'Vie scolaire',
    1084: 'Cantines',
    1085: 'Espaces culturels',
    1086: 'Jeunesse',
    1087: 'Petite enfance',
    1088: 'Ecoles',
    1089: 'Bénévolat',
    1099: 'Autre',
    //Social
    1100: 'Actions sociales',
    1101: 'Solidarités',
    1102: 'Jeunesse',
    1103: 'Handicaps',
    1119: 'Autre',
    //Sport & santé
    1120: 'Sport',
    1121: 'Santé',
    1122: 'Bien être',
    1139: 'Autre',
    //Finances & commerces
    1140: 'Commerces',
    1141: 'Finances publiques',
    1142: 'Tourisme',
    1143: 'Economie',
    1144: 'Finances',
    1145: 'Administration générale',
    1159: 'Autre',
    //Tranquilité publique
    1160: 'Sécurité',
    1161: 'Tranquilité publique',
    1179: 'Autre',
    //Autre
    1999: 'Autre',
  
    //IDEES !!!!
    //Urbanisme
    2000: 'Habitat',
    2001: 'Voirie',
    2002: 'Réseaux',
    2003: 'Mobilier urbain',
    2004: 'Equipements de la Ville',
    2005: 'Parcs et Jardins',
    2006: 'Zone piétonne',
    2007: 'Accessibilité',
    2019: 'Autre',
    //Environnement
    2021: 'Cadre de vie',
    2022: 'Littoral',
    2023: 'Espace naturel',
    2024: 'Espace boisé',
    2025: 'Jardin public',
    2026: 'Energie',
    2027: 'Propreté',
    2028: 'Environnement',
    2029: 'Condition animale',
    2030: 'Transition écologique',
    2039: 'Autre',
    //Mobilité
    2040: 'Vélo',
    2041: 'Zone piétonne',
    2042: 'Bus',
    2043: 'Métro',
    2044: 'Tram',
    2045: 'Rues',
    2046: 'Trottoirs',
    2047: 'Handicap',
    2048: 'Stationnement',
    2059: 'Autre',
    //Vie citoyenne
    2060: 'Vie associative',
    2061: 'Bénévolat',
    2062: 'Animations',
    2063: 'Démocratie participative',
    2064: 'Vie des quartiers',
    2065: 'Référents de quartiers',
    2066: 'Budget participatif',
    2067: 'Infoparcs',
    2068: 'Politique de la ville',
    2079: 'Autre',
    //Culture & Education
    2080: 'Culture',
    2081: 'Patrimoine',
    2082: 'Animations',
    2083: 'Vie scolaire',
    2084: 'Cantines',
    2085: 'Espaces culturels',
    2086: 'Jeunesse',
    2087: 'Petite enfance',
    2088: 'Ecoles',
    2089: 'Bénévolat',
    2099: 'Autre',
    //Social
    2100: 'Actions sociales',
    2101: 'Solidarités',
    2102: 'Jeunesse',
    2103: 'Handicaps',
    2119: 'Autre',
    //Sport & santé
    2120: 'Sport',
    2121: 'Santé',
    2122: 'Bien être',
    2139: 'Autre',
    //Finances & commerces
    2140: 'Commerces',
    2141: 'Finances publiques',
    2142: 'Tourisme',
    2143: 'Economie',
    2144: 'Finances',
    2145: 'Administration générale',
    2159: 'Autre',
    //Tranquilité publique
    2160: 'Sécurité',
    2161: 'Tranquilité publique',
    2179: 'Autre',
    //Autre
    2999: 'Autre',
  
    //QUESTIONS !!!!
    //Urbanisme
    3000: 'Habitat',
    3001: 'Voirie',
    3002: 'Réseaux',
    3003: 'Mobilier urbain',
    3004: 'Equipements de la Ville',
    3005: 'Parcs et Jardins',
    3006: 'Zone piétonne',
    3007: 'Accessibilité',
    3019: 'Autre',
    //Environnement
    3021: 'Cadre de vie',
    3022: 'Littoral',
    3023: 'Espace naturel',
    3024: 'Espace boisé',
    3025: 'Jardin public',
    3026: 'Energie',
    3027: 'Propreté',
    3028: 'Environnement',
    3029: 'Condition animale',
    3030: 'Transition écologique',
    3039: 'Autre',
    //Mobilité
    3040: 'Vélo',
    3041: 'Zone piétonne',
    3042: 'Bus',
    3043: 'Métro',
    3044: 'Tram',
    3045: 'Rues',
    3046: 'Trottoirs',
    3047: 'Handicap',
    3048: 'Stationnement',
    3059: 'Autre',
    //Vie citoyenne
    3060: 'Vie associative',
    3061: 'Bénévolat',
    3062: 'Animations',
    3063: 'Démocratie participative',
    3064: 'Vie des quartiers',
    3065: 'Référents de quartiers',
    3066: 'Budget participatif',
    3067: 'Infoparcs',
    3068: 'Politique de la ville',
    3079: 'Autre',
    //Culture & Education
    3080: 'Culture',
    3081: 'Patrimoine',
    3082: 'Animations',
    3083: 'Vie scolaire',
    3084: 'Cantines',
    3085: 'Espaces culturels',
    3086: 'Jeunesse',
    3087: 'Petite enfance',
    3088: 'Ecoles',
    3089: 'Bénévolat',
    3099: 'Autre',
    //Social
    3100: 'Actions sociales',
    3101: 'Solidarités',
    3102: 'Jeunesse',
    3103: 'Handicaps',
    3119: 'Autre',
    //Sport & santé
    3120: 'Sport',
    3121: 'Santé',
    3122: 'Bien être',
    3139: 'Autre',
    //Finances & commerces
    3140: 'Commerces',
    3141: 'Finances publiques',
    3142: 'Tourisme',
    3143: 'Economie',
    3144: 'Finances',
    3145: 'Administration générale',
    3159: 'Autre',
    //Tranquilité publique
    3160: 'Sécurité',
    3161: 'Tranquilité publique',
    3179: 'Autre',
    //Autre
    3999: 'Autre',
  
    //CRITIQUES !!!!
    //Urbanisme
    4000: 'Habitat',
    4001: 'Voirie',
    4002: 'Réseaux',
    4003: 'Mobilier urbain',
    4004: 'Equipements de la Ville',
    4005: 'Parcs et Jardins',
    4006: 'Zone piétonne',
    4007: 'Accessibilité',
    4019: 'Autre',
    //Environnement
    4021: 'Cadre de vie',
    4022: 'Littoral',
    4023: 'Espace naturel',
    4024: 'Espace boisé',
    4025: 'Jardin public',
    4026: 'Energie',
    4027: 'Propreté',
    4028: 'Environnement',
    4029: 'Condition animale',
    4030: 'Transition écologique',
    4039: 'Autre',
    //Mobilité
    4040: 'Vélo',
    4041: 'Zone piétonne',
    4042: 'Bus',
    4043: 'Métro',
    4044: 'Tram',
    4045: 'Rues',
    4046: 'Trottoirs',
    4047: 'Handicap',
    4048: 'Stationnement',
    4059: 'Autre',
    //Vie citoyenne
    4060: 'Vie associative',
    4061: 'Bénévolat',
    4062: 'Animations',
    4063: 'Démocratie participative',
    4064: 'Vie des quartiers',
    4065: 'Référents de quartiers',
    4066: 'Budget participatif',
    4067: 'Infoparcs',
    4068: 'Politique de la ville',
    4079: 'Autre',
    //Culture & Education
    4080: 'Culture',
    4081: 'Patrimoine',
    4082: 'Animations',
    4083: 'Vie scolaire',
    4084: 'Cantines',
    4085: 'Espaces culturels',
    4086: 'Jeunesse',
    4087: 'Petite enfance',
    4088: 'Ecoles',
    4089: 'Bénévolat',
    4099: 'Autre',
    //Social
    4100: 'Actions sociales',
    4101: 'Solidarités',
    4102: 'Jeunesse',
    4103: 'Handicaps',
    4119: 'Autre',
    //Sport & santé
    4120: 'Sport',
    4121: 'Santé',
    4122: 'Bien être',
    4139: 'Autre',
    //Finances & commerces
    4140: 'Commerces',
    4141: 'Finances publiques',
    4142: 'Tourisme',
    4143: 'Economie',
    4144: 'Finances',
    4145: 'Administration générale',
    4159: 'Autre',
    //Tranquilité publique
    4160: 'Sécurité',
    4161: 'Tranquilité publique',
    4179: 'Autre',
    //Autre
    4999: 'Autre',
  
    //autre     19
    9999: 'Autre',
    999: 'Autre',
  };
  
  export const participationStatusFr = {
    0: 'reçu',
    1: 'reçu',
    2: 'pris en compte',
    3: 'traité',
    4: 'traité',
  };
  
  export const publicationTypesFr = {
    1: 'alerte',
    2: 'actualité',
    3: 'sondage',
    4: 'publication',
    5: 'publication',
    6: 'évènement',
  };
  
  export const publicationTypesFrShortForUrl = {
    1: 'actu',
    2: 'actu',
    3: 'sondage',
    4: 'actu',
    5: 'actu',
    6: 'evenement',
  };
  