import React from 'react';
import {css} from 'emotion';

const PlainButton = props => {

  if (props.disabled) {
    return (
      <div
        style={props.style}
        className={css`
          background-color: #e8e8e8;
          display: inline-block;
          padding: 8px 15px 8px 15px;
          color: #757575;
          border-radius: 15px;
          text-align: center;
          cursor: pointer;
          border: 2px solid #9c9c9c;
          display: flex;
          width: ${props.size ? props.size : null};
          flex-direction: row;
          align-items: center;
          justify-content: center;

        `}>
        {props.children}
      </div>
    )
  }
  return (
    <div
      style={props.style}
      onClick={props.onClick}
      className={css`
        background-color: #f55d42;
        display: inline-block;
        padding: 8px 15px 8px 15px;
        color: #fff;
        border-radius: 15px;
        text-align: center;
        cursor: pointer;
        border: 2px solid #f55d42;
        display: flex;
        width: ${props.size ? props.size : null};
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #fcdfd9;
          color: #f55d42;
        }
      `}>
      {props.children}
    </div>
  );
};

export default PlainButton;
