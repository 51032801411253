import axios from 'axios';
import {server_api_url} from '../config/constants';

class BudgetPartAPI {

  // NEW !! INITIAL GET BP AND PROJECTS
  static getfullbp(insee_com, popkey, rubric, sort) {
    return axios.get(server_api_url + '/budgetpart/getfullbp', {
      params: {
        popkey: popkey,
        codeinsee: insee_com,
        rubric: rubric,
        sort: sort,
      },
    });
  }


  // NEW !! GET MORE PROJECTS
  static getMoreProjects(id_budget_part_session, popkey, rubric, sort, phase, all_ids_budget_part_project) {
    return axios.get(server_api_url + '/budgetpart/getmoreprojects', {
      params: {
        popkey: popkey,
        id_budget_part_session: id_budget_part_session,
        rubric: rubric,
        sort: sort,
        phase: phase,
        all_ids_budget_part_project: all_ids_budget_part_project
      },
    });
  }


  // NEW !! GET PROJECT DETAILS
  static GetProjectDetails(popkey, id_budget_part_project) {
    return axios.get(server_api_url + '/budgetpart/getprojectdetails', {
      params: {
        popkey: popkey,
        id_budget_part_project: id_budget_part_project,
      },
    });
  }

 // New !! vote for project
 static voteProject(popkey, id_budget_part_project) {
   return axios.post(server_api_url + '/budgetpart/vote', {
     popkey: popkey,
     id_budget_part_project: id_budget_part_project
   });
 }

 // New !! vote for project
 static unvoteProject(popkey, id_budget_part_project) {
   return axios.post(server_api_url + '/budgetpart/unvote', {
     popkey: popkey,
     id_budget_part_project: id_budget_part_project
   });
 }

 static getPopupMap(id_budget_part_project) {
   return axios.get(server_api_url + '/budgetpart/project/popup', {
     params: {
       id_budget_part_project: id_budget_part_project,
     },
   });
 }

 static postProject(
   id_budget_part_session,
   popkey,
   project,
   annex,
   code_insee,
 ) {
   return axios.post(server_api_url + '/budgetpart/bdgpost', {
     popkey: popkey,
     project: project,
     annex: annex,
     id_budget_part_session: id_budget_part_session,
     code_insee: code_insee,
   });
 }

 static likeProject(popkey, id_budget_part_project) {
   return axios.post(server_api_url + '/budgetpart/likeproject', {
     popkey: popkey,
     id_budget_part_project: id_budget_part_project,
   });
 }

 static unlikeProject(popkey, id_budget_part_project) {
   return axios.post(server_api_url + '/budgetpart/unlikeproject', {
     popkey: popkey,
     id_budget_part_project: id_budget_part_project,
   });
 }

 static postComment(
   popkey,
   id_budget_part_project,
   ref_id_comment,
   description,
 ) {
   return axios.post(server_api_url + '/budgetpart/postcomment', {
     popkey: popkey,
     id_budget_part_project: id_budget_part_project,
     ref_id_comment: ref_id_comment,
     description: description,
   });
 }

 static getMoreComment(page, popkey, id_budget_part_project) {
   return axios.get(server_api_url + '/budgetpart/comments', {
     params: {
       id_budget_part_project: id_budget_part_project,
       page: page,
       popkey: popkey,
     },
   });
 }

 static deleteComment(id_comment) {
   return axios.post(server_api_url + '/budgetpart/deletecomment', {
     id_comment: id_comment
   });
 }

 static removeProject(popkey, id_budget_part_project) {
   return axios.post(server_api_url + '/budgetpart/removeproject', {
     popkey: popkey,
     id_budget_part_project: id_budget_part_project,
   });
 }

 static subscribeBdg(
   popkey,
   id_budget_part_session,
 ) {
   return axios.post(server_api_url + '/budgetpart/subscribe', {
      popkey: popkey,
      id_budget_part_session: id_budget_part_session,
    });
 }

}
export default BudgetPartAPI;
