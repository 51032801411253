import React from 'react';
import {css} from 'emotion';

const ActionButton = props => {
  return (
    <div
      onClick={props.onPress}
      style={props.styles}
      className={css`
        cursor: pointer;
        background: ${!props.select ? 'white' : 'rgba(245, 93, 66,1)'};
        border: 1px solid rgba(245, 93, 66, 1);
        padding-left: 10px;
        padding-right: 10px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
      `}>
      {props.icon}
    </div>
  );
};

export default ActionButton;
