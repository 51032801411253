import React, {useState} from 'react';
import ControlMap from '../../../common/material-easy-ui/modal/components/ControlMap';
import BudgetPartAPI from '../../../../api/BudgetPartAPI';
import {css} from 'emotion';
import ReactMapboxGl from 'react-mapbox-gl';
import MarkerMap from './components/MarkerMap';
import PopupMap from './components/PopupMap';
const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoianVzcHJpZXQiLCJhIjoiY2o5cHRzMjB1MnVxMTJ4czJoaTdhZjVjbCJ9.3LZxp2Lf97TcCYWPAgsyyA',
});

const MapProject = props => {

  const [center, setCenter] = useState([props.lon, props.lat]);
  const [zoom, setZoom] = useState([props.zoomLevel]);

  const [popup, setPopup] = useState(false);

  const zoomIn = () => {
    setZoom([zoom[0] + 1]);
  };

  const zoomOut = () => {
    setZoom([zoom[0] - 1]);
  };

  const getPosition = () => {
    // Simple wrapper
    return new Promise((res, rej) => {
      navigator.geolocation.getCurrentPosition(res, rej);
    });
  };

  const onGeoLocUser = () => {
    getPosition()
      .then(data => {
        setCenter([data.coords.longitude, data.coords.latitude]);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const showPopup = id_budget_part_project => {
    BudgetPartAPI.getPopupMap(id_budget_part_project).then(data => {
      setPopup(data.data.project);
    });
  };

  return (
    <div
      className={css`
        width: 100%;
        height: 600px;
        padding-left: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        box-sizing: border-box;
      `}>
      <div
        className={css`
          position: relative;
          width: 100%;
          height: 100%;
          margin-top: 40px;
        `}>
        <ControlMap
          zoomOut={zoomOut}
          zoomIn={zoomIn}
          onGeoLocUser={onGeoLocUser}
        />
        <Map
          onClick={() => {
            setPopup(false);
          }}
          center={center}
          zoom={zoom}
          // eslint-disable-next-line
          style="mapbox://styles/mapbox/streets-v9"
          containerStyle={{
            height: '100%',
            width: '100%',
          }}>
          {props.list_map.length > 0 &&
            props.list_map.map(item => {
              return (
                <MarkerMap
                  key={"MK" + item.id_budget_part_project}
                  id={item.id_budget_part_project}
                  showPopup={showPopup}
                  coord={JSON.parse(item.marker).coordinates}
                />
              );
            })}

          {popup && (
            <PopupMap goToProjectPage={props.goToProjectPage} item={popup} />
          )}
        </Map>
      </div>
    </div>
  );
};

export default MapProject;
