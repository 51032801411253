import {combineReducers} from 'redux';
import {userReducers} from './reducers/userReducer';
import {cityReducers} from './reducers/cityReducer';
import {newsReducers} from './reducers/newsReducers';
import {publicationReducers} from './reducers/publicationReducers';
import {participationReducers} from './reducers/participationReducers';
import {surveyReducers} from './reducers/surveyReducers';
import {eventReducers} from './reducers/eventReducers';
import {notificationReducers} from './reducers/notificationReducers';
import {groupReducers} from './reducers/groupReducers';
import {budgetpartReducers} from './reducers/budgetpartReducers';

export default combineReducers({
  user: userReducers,
  city: cityReducers,
  news: newsReducers,
  publication: publicationReducers,
  participation: participationReducers,
  survey: surveyReducers,
  event: eventReducers,
  notification: notificationReducers,
  group: groupReducers,
  budgetpart:budgetpartReducers
});
