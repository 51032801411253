import React, {useState, useEffect} from 'react';
import FullModal from '../common/material-easy-ui/modal/FullModal';

import './styles.css';
import InputPasswordEasy from '../common/material-easy-ui/input/InputPassword';
import {updatePassword, cleanPwd} from '../../redux/actions/userActions';
import {useSelector, useDispatch} from 'react-redux';
import history from '../../utils/history';
import Button from '../common/forms/buttons/Button';

const EditPassword = () => {
  const dispatch = useDispatch();

  const popkey = useSelector(state => state.user.popkey);
  const signupError = useSelector(state => state.user.signupError);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [error_oldPassword, setError_oldPassword] = useState(null);
  const [error_newPassword, setError_NewPassword] = useState(null);
  const [error_newPassword2, setError_NewPassword2] = useState(null);

  useEffect(() => {
    if(signupError && signupError === 1) {
      setError_oldPassword({
        error: true,
        message: "Mauvais mot de passe !",
      });
    } else  if(signupError === 0){
      history.goBack();
    }

    return dispatch(cleanPwd());

  }, [signupError]);

  const onChangeCurrentPassword = e => {
    setCurrentPassword(e.target.value);
    setError_oldPassword(null)
  };

  const onChangeNewPassword = e => {
    setNewPassword(e.target.value);
    setError_NewPassword(null);
  };

  const onChangeNewPassword2 = e => {
    setNewPassword2(e.target.value);
    setError_NewPassword2(null);
  };

  const onBlurNewPassword = () => {
    if (
      (newPassword.length >= 4 && !/\s/.test(newPassword)) ||
      newPassword === ''
    ) {
      setError_NewPassword(null);
    } else {
      setError_NewPassword({
        error: true,
        message: "Votre nouveau mot-de-passe n'est pas dans le bon format",
      });
    }
  };

  const onBlurNewPassword2 = () => {
    if (
      (newPassword2.length >= 4 && !/\s/.test(newPassword2)) ||
      newPassword2 === ''
    ) {
      if (newPassword2 !== newPassword) {
        setError_NewPassword2({
          error: true,
          message: "Votre confirmation de mot-de-passe n'est pas valide",
        });
      } else {
        setError_NewPassword2(null);
      }
    } else {
      setError_NewPassword2({
        error: true,
        message: "Votre nouveau mot-de-passe n'est pas dans le bon format",
      });
    }
  };

  const onUpdatePassword = () => {
    if (currentPassword !== '' && newPassword !== '' && newPassword2 !== '') {
      if (
        error_newPassword === null && error_newPassword2 === null && error_oldPassword === null
      ) {
        dispatch(updatePassword(popkey, newPassword, currentPassword));
      }
    }
  };

  const onBack = () => {
    history.goBack();
  };

  return (
    <FullModal
      id="modal-edit-password"
      textHeader="Modifier votre mot-de-passe"
      onBack={onBack}>
      <div className="modal-edit-password-container">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <InputPasswordEasy
            width={'80%'}
            style={{
              marginTop: 40,
              height: 40,
              paddingLeft: 20,
              paddingRight: 20,
              display: 'flex',
              alignItems: 'center',
              paddingTop: 5,
              paddingBottom: 5,
            }}
            inputStyle={{fontSize: 15}}
            labelStyle={{color: '#cccccc'}}
            label={'Mot-de-passe actuel'}
            placeholder={'Mot-de-passe actuel'}
            value={currentPassword}
            onChange={onChangeCurrentPassword}
            onBlur={() => console.log()}
            error={error_oldPassword}
          />
          <InputPasswordEasy
            width={'80%'}
            style={{
              marginTop: 20,
              height: 40,
              paddingLeft: 20,
              paddingRight: 20,
              display: 'flex',
              alignItems: 'center',
              paddingTop: 5,
              paddingBottom: 5,
            }}
            inputStyle={{fontSize: 15}}
            labelStyle={{color: '#cccccc'}}
            label={'Nouveau mot-de-passe'}
            placeholder={'Nouveau mot-de-passe'}
            onChange={onChangeNewPassword}
            onBlur={onBlurNewPassword}
            value={newPassword}
            error={error_newPassword}
          />
          <InputPasswordEasy
            width={'80%'}
            style={{
              marginBottom: 20,
              marginTop: 20,
              height: 40,
              paddingLeft: 20,
              paddingRight: 20,
              display: 'flex',
              alignItems: 'center',
              paddingTop: 5,
              paddingBottom: 5,
            }}
            inputStyle={{fontSize: 15}}
            labelStyle={{color: '#cccccc'}}
            label={'Confirmer nouveau mot-de-passe'}
            placeholder={'Confirmer nouveau mot-de-passe'}
            onChange={onChangeNewPassword2}
            value={newPassword2}
            onBlur={onBlurNewPassword2}
            error={error_newPassword2}
          />


          <Button
            style={{marginTop: 20, marginBottom: 20}}
            onClick={onUpdatePassword}>
            Modifier
          </Button>

        </div>
      </div>
    </FullModal>
  );
};

export default EditPassword;
