import React, { useEffect, useState } from "react";

const avatarSrc = id => {
  switch (id) {
    case 0:
      return require('../../../image/avatars/avatar0.png');
    case 1:
      return require('../../../image/avatars/avatar1.png');
    case 2:
      return require('../../../image/avatars/avatar2.png');
    case 3:
      return require('../../../image/avatars/avatar3.png');
    case 4:
      return require('../../../image/avatars/avatar4.png');
    case 5:
      return require('../../../image/avatars/avatar5.png');
    case 6:
      return require('../../../image/avatars/avatar6.png');
    case 7:
      return require('../../../image/avatars/avatar7.png');
    case 8:
      return require('../../../image/avatars/avatar8.png');
    case 9:
      return require('../../../image/avatars/avatar9.png');
    case 10:
      return require('../../../image/avatars/avatar10.png');
    case 11:
      return require('../../../image/avatars/avatar11.png');
    case 12:
      return require('../../../image/avatars/avatar12.png');
    case 13:
      return require('../../../image/avatars/avatar13.png');
    case 14:
      return require('../../../image/avatars/avatar14.png');
    case 15:
      return require('../../../image/avatars/avatar15.png');
    case 16:
      return require('../../../image/avatars/avatar16.png');
      case 17:
            return require('../../../image/avatars/avatar17.png');
          case 18:
            return require('../../../image/avatars/avatar18.png');
          case 19:
            return require('../../../image/avatars/avatar19.png');

          case 20:
            return require('../../../image/avatars/avatar20.png');
          case 21:
            return require('../../../image/avatars/avatar21.png');
          case 22:
            return require('../../../image/avatars/avatar22.png');
          case 23:
            return require('../../../image/avatars/avatar23.png');
          case 24:
            return require('../../../image/avatars/avatar24.png');
          case 25:
            return require('../../../image/avatars/avatar25.png');
          case 26:
            return require('../../../image/avatars/avatar26.png');
          case 27:
            return require('../../../image/avatars/avatar27.png');
          case 28:
            return require('../../../image/avatars/avatar28.png');
          case 29:
            return require('../../../image/avatars/avatar29.png');

          case 30:
            return require('../../../image/avatars/avatar30.png');
          case 31:
            return require('../../../image/avatars/avatar31.png');
          case 32:
            return require('../../../image/avatars/avatar32.png');
          case 33:
            return require('../../../image/avatars/avatar33.png');
          case 34:
            return require('../../../image/avatars/avatar34.png');
          case 35:
            return require('../../../image/avatars/avatar35.png');
          case 36:
            return require('../../../image/avatars/avatar36.png');
          case 37:
            return require('../../../image/avatars/avatar37.png');
          case 38:
            return require('../../../image/avatars/avatar38.png');
          case 39:
            return require('../../../image/avatars/avatar39.png');
          case 40:
            return require('../../../image/avatars/avatar40.png');



    default:
      return require('../../../image/avatars/avatar0.png');
  }
};

const Avatar = props => {
  const [source, setSource] = useState(
    require("../../../image/avatars/avatar0.png")
  );
  useEffect(() => {
    setSource(avatarSrc(props.id_avatar));
  }, [props.id_avatar]);
  return (
    <div style={{width: props.size, height: props.size, backgroundColor: '#FFF', borderRadius: (props.size/2), textAlign: 'center'}}>
      <img
        className={props.className? props.className : null}
        style={{ height: props.size, borderRadius: (props.size/2)}}
        src={source}
        alt='Avatar'
      />
    </div>
  );
};

export default Avatar;
