import React, { useEffect} from 'react';
import {
  getListCommunities,
} from '../../redux/actions/groupActions';

import {useSelector, useDispatch} from 'react-redux';
import CardGroup from './components/cardGroup/CardGroup';
//import CardLoadGroup from './components/cardGroup/CardLoadGroup';
import IconEasyPageEmpty from '../common/icons/IconEasyPageEmpty';
import {css} from 'emotion';

const GroupList = () => {
  const dispatch = useDispatch();

  /* STATE REDUX */
  const infoCity = useSelector(state => state.city.infoCity);
  const popkey = useSelector(state => state.user.popkey);
  const communities_city = useSelector(state => state.group.city_communities);
  const loading = useSelector(state => state.group.isLoadingCommunities);

  useEffect(() => {
    dispatch(getListCommunities(popkey, infoCity.insee_com));
  }, []);

  useEffect(() => {
    dispatch(getListCommunities(popkey, infoCity.insee_com));
  }, [infoCity, popkey]);

  return (
    <>
      <div
        className={css`
          width: 100%;
          max-width: 700px;
          margin: 0 auto;
          box-sizing: border-box;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 50px;
        `}>
        {loading ? (
          <div
            className={css`
              margin-top: 50px;
              text-align: center;
            `}>

            <img
              className={css`
                margin-top: 10px;
                width: 70px;
                width: 70px;
              `}
              src={require('../../image/loading.gif')}
              alt="loading"
            />

            <div
              className={css`
                margin-top: 10px;
                color: #d1d1d1;
                font-family: 'Poppins', sans-serif !important;
              `}>
              Chargement des communautées
            </div>
          </div>
        ) : communities_city.length > 0 ? (
          communities_city.map((item, key) => {
            return <CardGroup key={"GROUP"+key} item={item} />;
          })
        ) : (
          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 300px;
            `}>
            <IconEasyPageEmpty size={75} color="#d1d1d1" />
            <div
              className={css`
                margin-top: 20px;
                color: #d1d1d1;
              `}>
              Votre ville n&apos;a pas encore crée de groupe...
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GroupList;
