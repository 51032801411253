import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyAppreciate = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display:inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
        viewBox="0 0 32 32">
      <path
      fill={props.color}
      d="M12.228,24l0,1c-0.007,0.21 -0.025,0.244 -0.057,0.335c-0.122,0.343 -0.443,0.606 -0.806,0.656c-0.061,0.008 -0.076,0.007 -0.137,0.009l-6.237,0c-0.21,-0.007 -0.245,-0.026 -0.335,-0.058c-0.343,-0.121 -0.606,-0.442 -0.656,-0.806c-0.008,-0.06 -0.007,-0.075 -0.009,-0.136l0,-12c0.007,-0.209 0.025,-0.244 0.057,-0.334c0.122,-0.343 0.443,-0.606 0.806,-0.656c0.061,-0.008 0.076,-0.007 0.137,-0.01l6.237,0c0.023,0.001 0.046,0.002 0.069,0.003c0.208,0.021 0.242,0.042 0.33,0.08c0.354,0.154 0.588,0.525 0.601,0.917l0,1l0.514,0l3.724,-7.447c0,0 0.383,-0.537 0.894,-0.552c0.435,0 0.87,-0.002 1.305,0c1.829,0.02 3.418,1.968 2.785,3.891l-1.141,3.425c-0.086,0.232 -0.145,0.297 -0.256,0.404c-0.481,0.462 -1.447,0.269 -1.653,-0.442c-0.04,-0.135 -0.05,-0.279 -0.03,-0.419c0.008,-0.059 0.024,-0.118 0.041,-0.176c0.383,-1.147 0.787,-2.288 1.148,-3.443c0.172,-0.566 -0.292,-1.233 -0.909,-1.24l-0.672,0l-3.723,7.447c-0.085,0.157 -0.11,0.178 -0.167,0.238c-0.186,0.198 -0.454,0.306 -0.728,0.314l-1.132,0l0,6l3.132,0c0,0 0.072,0.002 0.157,0.012c0.197,0.04 0.314,0.063 0.55,0.281l1.707,1.707c1.977,0 3.954,0.033 5.93,0c0.448,-0.013 0.855,-0.358 0.941,-0.798c0.494,-2.681 0.915,-5.375 1.345,-8.068c0.082,-0.559 -0.377,-1.129 -0.977,-1.134l-1.653,0c-0.256,-0.01 -0.326,-0.043 -0.454,-0.109c-0.552,-0.281 -0.719,-1.132 -0.253,-1.598c0.185,-0.185 0.443,-0.282 0.707,-0.293l1.66,0c1.661,0.012 3.155,1.582 2.963,3.314c-0.004,0.041 -0.01,0.081 -0.016,0.122c-0.43,2.695 -0.897,5.384 -1.345,8.076c-0.238,1.375 -1.504,2.48 -2.938,2.488l-6.324,0c-0.41,-0.016 -0.496,-0.098 -0.707,-0.293l-1.707,-1.707l-2.718,0Zm-6.237,-10l0,10l4.237,0l0,-10c-1.333,0 -2.904,0 -4.237,0Z"/>
        </svg>
        </IconPattern>
    )
}

export default IconEasyAppreciate;
