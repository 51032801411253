import React, { useEffect, useState } from "react";
import { css } from "emotion";
import FullModal from "../../common/material-easy-ui/modal/FullModal";

import MessageAlerte from "../../common/material-easy-ui/modal/MessageAlerte";
import history from "../../../utils/history";
import HeaderPublication from "../common/HeaderPublication";
import QueryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { getSurvey, postSurvey } from "../../../redux/actions/surveyActions";
import { server_img_public } from "../../../config/constants";

import Connexion from "../../connexion/Connexion";
import PointsNotification from "../../common/material-easy-ui/modal/PointsNotification";
import AccountNotConfirmedAlert from "../../common/alert/AccountNotConfirmedAlert";

import GroupCheckBox from "./components/GroupCheckBox";
import GroupRadioButton from "./components/GroupRadioButton";
import GroupEvaluation from "./components/GroupEvaluation";
import GroupFreeAnswer from "./components/GroupFreeAnswer";

import ButtonEasy from "../../common/material-easy-ui/button/ButtonEasy";
import ResultSurvey from "./components/resultSurvey/ResultSurvey";
import PlaceAndDate from "../common/PlaceAndDate";
import IconEasyAlert from "../../common/icons/IconEasyAlert";
import IconEasyNews from "../../common/icons/IconEasyNews";
import IconEasySurvey from "../../common/icons/IconEasySurvey";
import IconEasyEvent from "../../common/icons/IconEasyEvent";

const get = function (obj, key) {
  return key.split(".").reduce(function (o, x) {
    return typeof o === "undefined" || o === null ? o : o[x];
  }, obj);
};

const typeTo = {
  1: {
    color: "#eb5d37",
    icon: <IconEasyAlert size={20} color="white" />,
  },
  2: {
    color: "#3cc8db",
    icon: <IconEasyNews size={20} color="white" />,
  },
  3: {
    color: "#f7AA20",
    icon: <IconEasySurvey size={20} color="white" />,
  },
  6: {
    color: "#de5b84",
    icon: <IconEasyEvent size={20} color="white" />,
  },
};

// eslint-disable-next-line
const SurveyDetails = ({ location, ...props }) => {
  const dispatch = useDispatch();

  /* SELECTOR */
  const popkey = useSelector((state) => state.user.popkey);
  const info_user = useSelector((state) => state.user.info_user);
  const details = useSelector((state) => state.survey.surveyDetails);
  const survey = useSelector((state) => state.survey.surveyForm);
  const resultSurveys = useSelector((state) => state.survey.resultSurvey);
  const [pointNotification, setPointNotification] = useState(false);

  const [surveyState, setSurveyState] = useState(null);
  const [comment] = useState("");
  const [connect, setConnect] = useState(false);
  const [forbidenUserModal, setForbidenUserModal] = useState(false);
  const [hasAnswered, setHasAnswered] = useState(false);
  const [notConfirmedUserModal, setNotConfirmedUserModal] = useState(false);

  const onBack = () => {
    dispatch({ type: "CLEAN_SURVEY" });
    if (get(location, "state.modal")) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  const onBackDetails = () => {
    setConnect(false);
  };

  useEffect(() => {
    dispatch(
      getSurvey(popkey, QueryString.parse(location.search).id_publicpart)
    );
  }, [location.search, popkey, dispatch]);

  useEffect(() => {
    setSurveyState(survey);
  }, [survey]);

  const setAnswers = (answers, id_question) => {
    const index = [...surveyState].findIndex((i) => {
      return i.id_survey_question === id_question;
    });
    setSurveyState([
      ...surveyState.slice(0, index),
      { ...surveyState[index], answers: answers },
      ...surveyState.slice(index + 1),
    ]);
  };

  // const onChangeComment = e => {
  //   setComment(e.target.value);
  // };

  const sendSurvey = () => {
    if (popkey) {
      if (info_user.account_blocked === 0) {
        var allAnswers = [];
        if (surveyState.length > 0) {
          allAnswers = surveyState
            .map((item) => {
              return item.answers;
            })
            .reduce((a, b) => a.concat(b));

          dispatch(
            postSurvey(
              popkey,
              allAnswers,
              comment,
              QueryString.parse(location.search).id_publicpart
            )
          ).then(() => {
            setHasAnswered(true);
            setPointNotification(true);
          });
        }
      } else if (info_user.account_blocked === 1) setForbidenUserModal(true);
      else setNotConfirmedUserModal(true);
    } else {
      setConnect(true);
    }
  };

  return (
    <FullModal
      onBack={onBack}
      textHeader="Sondage"
      height={"97%"}
      footer={
        <>
          {connect && <Connexion onBack={onBackDetails} />}
          {forbidenUserModal && (
            <MessageAlerte
              close={() => setForbidenUserModal(false)}
              text={
                "Votre compte a été bloqué par la ville ou l'équipe popvox car vous ne respectiez pas les CGU du service. Pour plus d'informations, vous pouvez contacter les équipes popvox (contact@popvox.fr)."
              }
              textHeader={"Compte bloqué"}
            />
          )}
          {notConfirmedUserModal && (
            <AccountNotConfirmedAlert
              close={() => setNotConfirmedUserModal(false)}
            />
          )}
          {pointNotification && (
            <PointsNotification
              type={101}
              close={() => {
                dispatch(
                  getSurvey(
                    popkey,
                    QueryString.parse(location.search).id_publicpart
                  )
                );

                setPointNotification(false);
              }}
            />
          )}
        </>
      }>
      <>
        {details.file_url ? (
          <img
            alt="illustration de l'Actualité"
            className={css`
              width: 100%;
            `}
            src={server_img_public + details.file_url}
          />
        ) : (
          details.youtube_code &&
          details.youtube_code !== "" && (
            <div
              className={css`
                position: relative;
                padding-bottom: 56.25%;
                padding-top: 25px;
                height: 0;
              `}>
              <iframe
                className={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                `}
                id="ytplayer"
                type="text/html"
                width="400"
                height="260"
                title={details.youtube_code}
                src={
                  "https://www.youtube.com/embed/" +
                  details.youtube_code +
                  "?autoplay=0&origin=https://www.popvox.fr"
                }
                // eslint-disable-next-line
                allowfullscreen
                frameBorder="0"
              />
            </div>
          )
        )}
        <div
          className={css`
            margin-top: 20px;
            display: flex;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
          `}>
          <div
            className={css`
              height: 40px;
              width: 40px;
              background-color: ${typeTo[3].color};
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            `}>
            {typeTo[3].icon}
          </div>
          <div
            className={css`
              flex: 1;
              text-align: left;
              padding-left: 10px;
            `}>
            <div
              className={css`
                font-weight: 600;
                color: #29354a;
              `}>
              {details.title}
            </div>
            <PlaceAndDate
              date_begin={details.date_begin}
              date_end={details.date_end}
              nom_comm={details.nom_comm}
            />
          </div>
        </div>
        <HeaderPublication
          community={details.community ? details.community : "Public"}
          date_begin={details.date_begin}
          type={details.type}
        />
        <div
          className={css`
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
            margin-top: 20px;
          `}>
          <div
            className={css`
              margin-bottom: 20px;
            `}>
            <div
              className={css`
                box-sizing: border-box;
                margin-top: 40px;
                color: #29354a;
                font-size: 17px;
              `}
              dangerouslySetInnerHTML={{
                __html: details.description
                  ? details.description
                      .replace(new RegExp(/href="www/g), 'href="http://www')
                      .replace(new RegExp(/href='www/g), "href='http://www")
                  : "",
              }}
            />
          </div>

          {details.youtube_code &&
            details.youtube_code !== "" &&
            details.file_url && (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <iframe
                  id="ytplayer"
                  type="text/html"
                  width="400"
                  height="260"
                  title={details.youtube_code}
                  src={
                    "https://www.youtube.com/embed/" +
                    details.youtube_code +
                    "?autoplay=0&origin=https://www.popvox.fr"
                  }
                  frameBorder="0"
                />
              </div>
            )}

          {surveyState &&
            surveyState.map((item, i) => {
              if (item.type === 1) {
                return (
                  <GroupRadioButton
                    id_question={item.id_survey_question}
                    setAnswers={setAnswers}
                    key={i}
                    answers={item.answers}
                    question={item.question}
                  />
                );
              } else if (item.type === 2) {
                return (
                  <GroupCheckBox
                    id_question={item.id_survey_question}
                    setAnswers={setAnswers}
                    key={i}
                    answers={item.answers}
                    question={item.question}
                  />
                );
              } else if (item.type === 3) {
                return (
                  <GroupEvaluation
                    id_question={item.id_survey_question}
                    setAnswers={setAnswers}
                    key={i}
                    question={item.question}
                    answers={item.answers}
                  />
                );
              } else if (item.type === 4) {
                return (
                  <GroupFreeAnswer
                    id_question={item.id_survey_question}
                    setAnswers={setAnswers}
                    key={i}
                    question={item.question}
                    answers={item.answers}
                  />
                );
              }

              return false;
            })}
        </div>

        {surveyState && !hasAnswered && (
          <>
            <div
              className={css`
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                box-sizing: border-box;
                padding-top: 20px;
                padding-bottom: 20px;
              `}>
              <div style={{ padding: 30, textAlign: "right" }}>
                <ButtonEasy
                  height={30}
                  onPress={sendSurvey}
                  text={" Envoyer mes réponses "}
                />
              </div>
            </div>
          </>
        )}

        {resultSurveys && <ResultSurvey resultSurvey={resultSurveys} />}

        <div style={{ height: 80, width: "100%" }} />
      </>
    </FullModal>
  );
};

export default SurveyDetails;
