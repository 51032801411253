import ParticipationAPI from "../../api/ParticipationAPI";

export function getFluxParticipation(
  page,
  popkey,
  code_insee,
  order,
  types,
  getMyOnly,
  idCommunity
) {
  return function (dispatch) {
    dispatch({ type: "GET_FLUX_PARTICIPATION_LOADING" });
    return ParticipationAPI.getListParticipation(
      page,
      popkey,
      code_insee,
      order,
      types,
      getMyOnly,
      idCommunity
    ).then((data) => {
      if (page === 0) {
        dispatch({
          type: "GET_FLUX_PARTICIPATION",
          payload: {
            listParticipation: data.data.participations,
            nb: data.data.nb,
            endReached: data.data.endReached,
          },
        });
      } else {
        dispatch({
          type: "GET_MORE_FLUX_PARTICIPATION",
          payload: {
            listParticipation: data.data.participations,
            nb: data.data.nb,
            endReached: data.data.endReached,
          },
        });
      }
    });
  };
}

export function clearParticipations() {
  return function (dispatch) {
    dispatch({ type: "CLEAR_PARTICIPATIONS" });
  };
}

export function updateParticipation(participation) {
  return function (dispatch) {
    dispatch({
      type: "UPDATE_PARTICIPATION",
      payload: {
        participation: participation,
      },
    });
  };
}

export function getMyParticipations(page, popkey) {
  return function (dispatch) {
    dispatch({ type: "GET_FLUX_PARTICIPATION_LOADING" });
    return ParticipationAPI.getMyParticipations(page, popkey).then((data) => {
      if (page === 0) {
        dispatch({
          type: "GET_FLUX_PARTICIPATION",
          payload: {
            listParticipation: data.data.participations,
            nb: data.data.nb,
            endReached: data.data.endReached,
          },
        });
      } else {
        dispatch({
          type: "GET_MORE_FLUX_PARTICIPATION",
          payload: {
            listParticipation: data.data.participations,
            nb: data.data.nb,
            endReached: data.data.endReached,
          },
        });
      }
    });
  };
}

export function initaliseParticipAtionDetails(item) {
  return function (dispatch) {
    dispatch({ type: "INITIALISE_PARTICPATION_DETAILS", payload: item });
  };
}

export function getDetailedCitizenpart(popkey, id_citizenpart) {
  return function (dispatch) {
    return ParticipationAPI.getDetailedCitizenpart(popkey, id_citizenpart).then(
      (data) => {
        dispatch({
          type: "GET_DETAILED_CITIZENPART_SUCCESS",
          payload: {
            participation: data.data.participation,
            comments: data.data.comments,
            pubanswers: data.data.pubanswers,
            commentsnb: data.data.commentsnb,
            endReached: data.data.endReached,
            isFollow: data.data.isFollow,
          },
        });
      }
    );
  };
}

export function getMoreComment(popkey, id_citizenpart, page) {
  return function (dispatch) {
    dispatch({ type: "GET_MORE_COMMENT_PARTICIPATION_LOADING" });
    return ParticipationAPI.getMoreComment(page, popkey, id_citizenpart).then(
      (data) => {
        dispatch({
          type: "GET_MORE_COMMENT_PARTICIPATION_SUCCESS",
          payload: {
            comments: data.data.comments,
            endReached: data.data.endReached,
          },
        });
      }
    );
  };
}

export function postComment(
  popkey,
  id_citizenpart,
  ref_id_comment,
  description
) {
  return function (dispatch) {
    return ParticipationAPI.postComment(
      popkey,
      id_citizenpart,
      ref_id_comment,
      description
    )
      .then((data) => {
        if (data.error) {
          dispatch({ type: "POST_PARTICIPATION_COMMENT_ERROR" });
        } else {
          if (data.data.comment.ref_id_comment === null) {
            dispatch({
              type: "POST_PARTICIPATION_COMMENT_SUCCESS",
              payload: {
                comment: data.data.comment,
              },
            });
          } else {
            dispatch({
              type: "POST_PARTICIPATION_COMMENT_REPLY_SUCCESS",
              payload: {
                comment: data.data.comment,
              },
            });
          }
          dispatch({
            type: "ADD_COMMENT_NB_PARTICIPATION_FLUX",
            payload: {
              id_citizenpart: data.data.comment.id_citizenpart,
            },
          });

          dispatch({
            type: "ADD_COMMENT_NB_PARTICIPATION_GROUP_FLUX",
            payload: {
              id_citizenpart: data.data.comment.id_citizenpart,
            },
          });
        }
      })
      .catch(() => {
        dispatch({ type: "POST_PARTICIPATION_COMMENT_ERROR" });
      });
  };
}

export function removeComment(id_comment, ref_id_comment, id_citizenpart) {
  return function (dispatch) {
    dispatch({ type: "DELETE_COMMENT_PARTICIPATION_LOADING" });
    return ParticipationAPI.deleteComment(id_comment, ref_id_comment).then(
      (data) => {
        if (data.error) {
          dispatch({ type: "DELETE_COMMENT_PARTICIPATION_ERROR" });
        } else {
          dispatch({
            type: "DELETE_COMMENT_PARTICIPATION_SUCCES",
            payload: {
              id_comment: id_comment,
            },
          });

          dispatch({
            type: "REMOVE_COMMENT_NB_PARTICIPATION_FLUX",
            payload: {
              id_citizenpart: id_citizenpart,
            },
          });

          dispatch({
            type: "REMOVE_COMMENT_NB_PARTICIPATION_GROUP_FLUX",
            payload: {
              id_citizenpart: id_citizenpart,
            },
          });
        }
      }
    );
  };
}

export function onFollow(popkey, id_citizenpart) {
  return function (dispatch) {
    return ParticipationAPI.postSubscription(popkey, id_citizenpart).then(
      (data) => {
        dispatch({
          type: "ON_FOLLOW_PARTICIPATION",
          payload: data.data.isFollow,
        });
      }
    );
  };
}

export function likeCitizenpart(popkey, id_citizenpart) {
  return function (dispatch) {
    dispatch({ type: "LIKE_CITIZENPART_LOADING" });
    return ParticipationAPI.likeCitizenpart(popkey, id_citizenpart).then(() => {
      dispatch({
        type: "LIKE_CITIZENPART_WITHOUT_LIST",
        payload: id_citizenpart,
      });

      dispatch({
        type: "LIKE_CITIZENPART_IN_LIST_GROUP",
        payload: id_citizenpart,
      });
    });
  };
}

export function unlikeCitizenpart(popkey, id_citizenpart) {
  return function (dispatch) {
    dispatch({ type: "UNLIKE_CITIZENPART_LOADING" });
    return ParticipationAPI.unlikeCitizenpart(popkey, id_citizenpart).then(
      () => {
        dispatch({
          type: "UNLIKE_CITIZENPART_WITHOUT_LIST",
          payload: id_citizenpart,
        });

        dispatch({
          type: "UNLIKE_CITIZENPART_IN_LIST_GROUP",
          payload: id_citizenpart,
        });
      }
    );
  };
}

export function removeParticipation(popkey, id_citizenpart) {
  return function (dispatch) {
    return ParticipationAPI.removeParticipation(popkey, id_citizenpart).then(
      (data) => {
        dispatch({ type: "REMOVE_PARTICIPATION", payload: data.data });

        dispatch({
          type: "REMOVE_PARTICIPATION_GROUPE",
          payload: data.data,
        });
      }
    );
  };
}

export function sendParticipation(popkey, participation, insee_com) {
  return function (dispatch) {
    return ParticipationAPI.sendParticipation(
      popkey,
      participation,
      insee_com
    ).then((data) => {
      if (data && data.data && data.data.participation) {
        dispatch({
          type: "SEND_PARTICIPATION",
          payload: { ...data.data.participation },
        });

        dispatch({
          type: "SEND_GROUP_PARTICIPATION",
          payload: { ...data.data.participation },
        });
      }
    });
  };
}
