import React from "react";
import FullModal from "../common/material-easy-ui/modal/FullModal";

import FilterElement from "./components/FilterElement";


import {participationTypesFr} from '../../config/thematics';


const ParticipationFilterModal = props => {

  const allTypes = Object.keys(participationTypesFr);

  const selectElement = id => {
    let typesToDisplaytmp = props.typesToDisplay;
    let temp = [...typesToDisplaytmp];

    if (temp.includes(id)) {
      const index = temp.indexOf(id);
      temp.splice(index, 1);
    } else temp.push(id);

    props.setTypesToDisplay(temp);
  };


  return (
    <FullModal
          onBack={() => {
            props.setDisplayFilterModal(false);
            return false;
          }}
          textHeader={"Paramètres d'affichage"}>


          <div style={{ margin: 10 }}>
            <div style={{ fontSize: 18, fontWeight: 500,  }}>Ordre d&apos;affichage</div>

            <div style={{ display: "flex" }}>
              <FilterElement
                title="Pertinence"
                selected={props.displayOrder === 0}
                onClick={() => props.setDisplayOrder(0)}
              />
              <FilterElement
                title="Chronologique"
                selected={props.displayOrder === 1}
                onClick={() => props.setDisplayOrder(1)}
              />
            </div>

            <div style={{ fontSize: 18, fontWeight: 500, marginTop: 20 }}>Types</div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {allTypes.map((item) => {
                return (
                  <FilterElement
                    key={item}
                    title={participationTypesFr[item]}
                    selected={props.typesToDisplay.includes(item)}
                    onClick={() => selectElement(item)}
                  />
                );
              })}
            </div>

            <div style={{ fontSize: 18, fontWeight: 500,  marginTop: 20 }}>Auteur</div>

            <div style={{ display: "flex" }}>
              <FilterElement
                title="Toutes"
                selected={!props.getMyOnly}
                onClick={() => props.setGetMyOnly(false)}
              />
              <FilterElement
                title="Mes participations"
                selected={props.getMyOnly}
                onClick={() => props.setGetMyOnly(true)}
              />
            </div>

            <div style={{ alignItems: "right", width: "100%", marginTop: 30 }}>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid rgb(245, 93, 66)",
                  color: "white",
                  padding: 5,
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                  margin: 10,
                  width: 150,
                  float: "right",
                  backgroundColor: "rgb(245, 93, 66)",
                }}
                onClick={() => props.confirmNewFilter()}>
                Confirmer
              </div>

            </div>
          </div>
        </FullModal>
  );
};

export default ParticipationFilterModal;
