import React from 'react';
import moment from 'moment';

// const shadow =
//   '-webkit-box-shadow: -1px 10px 6px -6px rgba(245,93,66,0.5);-moz-box-shadow: -1px 10px 6px -6px rgba(245,93,66,0.5);box-shadow: -1px 10px 6px -6px rgba(245,93,66,0.5);';

const InfoComplementaire = props => {
  if (props.current_phase == 1) {
    return (
      <div style={{textAlign: 'center', marginTop: 10}}>
        <div
          style={{
            padding: '5px 10px 4px 10px',
            border: '2px solid rgb(245,93,66)',
            borderRadius: 20,
            display: 'inline-block',
            fontSize: 13,
            fontWeight: 'bold',
            color: 'rgb(245,93,66)',
          }}>
          Fin des dépôts : {moment(props.date_phase_1_bis).format('L')}
        </div>
      </div>
    );
  } else if (props.current_phase == 1.5) {
    return (
      <div style={{textAlign: 'center', marginTop: 10}}>
        <div
          style={{
            padding: '5px 10px 4px 10px',
            border: '2px solid rgb(245,93,66)',
            borderRadius: 20,
            display: 'inline-block',
            fontSize: 13,
            fontWeight: 'bold',
            color: 'rgb(245,93,66)',
          }}>
          Début des votes : {moment(props.date_phase_2).format('L')}
        </div>
      </div>
    );
  } else if (props.current_phase == 2) {
    return (
      <div style={{textAlign: 'center', marginTop: 10}}>
        <div
          style={{
            padding: '5px 10px 4px 10px',
            border: '2px solid rgb(245,93,66)',
            borderRadius: 20,
            display: 'inline-block',
            fontSize: 13,
            fontWeight: 'bold',
            color: 'rgb(245,93,66)',
          }}>
          Fin des votes : {moment(props.date_phase_2_bis).format('L')}
        </div>
      </div>
    );
  } else if (props.current_phase == 2.5) {
    return (
      <div style={{textAlign: 'center', marginTop: 10}}>
        <div
          style={{
            padding: '5px 10px 4px 10px',
            border: '2px solid rgb(245,93,66)',
            borderRadius: 20,
            display: 'inline-block',
            fontSize: 13,
            fontWeight: 'bold',
            color: 'rgb(245,93,66)',
          }}>
          Présentation lauréats : {moment(props.date_phase_3).format('L')}
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export default InfoComplementaire;
