import SurveyAPI from '../../api/SurveyAPI';


export function getSurvey(popkey, id_publicpart) {
  return function(dispatch) {
    dispatch({type: 'GET_SURVEY_LOADING'});
    return SurveyAPI.getSurvey(popkey, id_publicpart).then(data => {      
      if (data.data.result === true) {

        dispatch({
          type: 'GET_SURVEY_SUCCESS',
          payload: {
            data: data.data.survey,
            isForm: true,
            detailed_survey: data.data.detailed_survey,
          },
        });
      } else {
        dispatch({
          type: 'GET_SURVEY_RESPONSE_SUCCESS',
          payload: {
            isForm: false,
            resultSurvey: data.data.resultSurvey,
            detailed_survey: data.data.detailed_survey,
          },
        });
      }
    });
  };
}

export function postSurvey(popkey, answers, comment, id_publicpart) {
  return function(dispatch) {
    dispatch({type: 'POST_SURVEY_LOADING'});
    return SurveyAPI.postSurvey(popkey, answers, comment, id_publicpart)
      .then(() => {
        dispatch({type: 'POST_SURVEY_SUCCESS'});

        dispatch({
          type: 'ADD_PEOPLE_ON_CARD_PUBLICATION',
          payload: {
            id_publicpart: id_publicpart
          }
        });

        dispatch({
          type: 'ADD_GROUP_PEOPLE_ON_CARD_PUBLICATION',
          payload: {
            id_publicpart: id_publicpart
          }
        });


      })
      .catch(() => {
        dispatch({type: 'POST_SURVEY_ERROR'});
      });
  };
}
