import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyFilter = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
          version="1.1" viewBox="0 0 32 32">
        <path d="M20.211,19.035c0.435,0.127 0.743,0.512 0.765,0.972l0,6.002c-0.022,0.45 -0.32,0.839 -0.758,0.971l-8.003,2c-0.607,0.122 -1.213,-0.334 -1.243,-0.97l0,-11.702l-7.835,-11.753c0,0 -0.362,-1.071 0.38,-1.447c0.139,-0.07 0.293,-0.101 0.452,-0.108l24.01,0c0.744,0.035 1.225,0.901 0.832,1.555l-8.003,12.005c-0.463,0.628 -1.581,0.496 -1.797,-0.289c-0.059,-0.215 -0.044,-0.448 0.042,-0.653c0.033,-0.078 0.047,-0.095 0.09,-0.168l6.966,-10.449l-20.271,0l6.967,10.449c0.196,0.323 0.158,0.319 0.168,0.555l0,10.724l6.002,-1.501l0,-5.221c0.029,-0.616 0.6,-1.095 1.236,-0.972Z" fill={props.color}/>
        </svg>
    </IconPattern>
    )
}

export default IconEasyFilter;
