import React, {useState} from 'react';
import {css} from 'emotion';
import HeaderCard from './components/HeaderCard';
import ModalMore from './components/ModalMore';
import Footer from './components/Footer';
import CardOptions from './components/CardOptions';

import TokenTitle from '../../../common/material-easy-ui/material/TokenTitle';
import {server_img_api} from '../../../../config/constants';
import history from '../../../../utils/history';

const CardProject = props => {

  const [more, setMore] = useState(false);
  const [option, setOption] = useState(0);

  const goToPageDetails = () => {
    history.push({
      pathname: '/budget-participatif/details',
      search: `?id_budget_part_project=${props.item.id_budget_part_project}`,
      state: {
        modal: true,
      },
    });
  };

  const onMore = e => {
    e.stopPropagation();
    setMore(true);
  };

  const onHideModal = e => {
    e.stopPropagation();
    setMore(false);
  };

  const onOption = (e, option) => {
    e.stopPropagation();
    setMore(false);
    setOption(option);
  };

  const onBackOption = e => {
    e.stopPropagation();
    setOption(0);
  };

  const onSharing = e => {
    e.stopPropagation();
    setOption(4);
  };

    return (
      <div
        onClick={goToPageDetails}
        className={css`
          margin-top: 20px;
          float: left;
          width: calc(50% - 20px);
          @media (max-width: 678px) {
            width: calc(100% - 20px);
          }
          @media (max-width: 1510px) {
            width: calc(100% - 20px);
          }
          margin-left: 10px;
          margin-right: 10px;
          position: relative;
          background: white;
          margin-top: 20px;
          border-radius: 4px;
          box-sizing: border-box;
          box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.04);
          cursor: pointer;
          border-top: ${ (props.item.project_status == 4 && props.current_phase == 3) ? '4px solid rgba(130, 201, 119, 0.5)' : '1px solid rgba(24, 62, 100, 0.1)'} ;
        `}>
        {more && (
          <ModalMore
            id_budget_part_project={props.item.id_budget_part_project}
            is_my={props.item.is_my}
            onOption={onOption}
            onHideModal={onHideModal}
          />
        )}

        {
          option !== 0 &&
          <CardOptions
            onSharing={onSharing}
            option={option}
            id_budget_part_project={props.item.id_budget_part_project}
            onBack={onBackOption}
          />
        }

        <HeaderCard onMore={onMore} item={props.item} />

        <div
          className={css`
            padding-left: 20px;
            padding-right: 20px;
            font-weight: bold;
            line-height: 1.1em;
          `}>
          {props.item.title}
        </div>


        <div
          className={css`
            padding-left: 20px;
            padding-right: 20px;
            margin-top: 10px;
            max-height: 3.6em;
            line-height: 1.2em;
            word-wrap: break-word;
            overflow: hidden;
          `}>
          {props.item.synthese}
        </div>

        <div
          className={css`
            color: #bfbfbf;
            font-size: 13px;
            display: flex;
            padding-left: 10px;
            margin-top: 20px;
          `}>


          <TokenTitle
            colorText={'rgba(41, 53, 74,1)'}
            background={'#F5F8FA'}
            text={props.thematic}
            style={{marginLeft: '10px'}}
          />

        </div>

        {props.item.img_project && (
          <img
            className={css`
              width: 100%;
              max-height: 200px;
              object-fit: cover;
              margin-top: 20px;
            `}
            alt="Budget participation"
            src={server_img_api + props.item.img_project}
          />
        )}

        <Footer
          current_phase={props.current_phase}
          likes={props.item.likes}
          has_liked={props.item.has_liked}
          comments={props.item.comments}
          votes={props.item.votes}
          has_voted={props.item.has_voted}
          project_status={props.item.project_status}
          details={props.item}
        />

      </div>
    );

};

export default CardProject;
