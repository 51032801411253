import React from 'react';
import GroupPresentationBlock from './components/GroupPresentationBlock';

const GroupDetailsRightMenu = () => {

  return (

    <div className="rightMenuContainer">

      <GroupPresentationBlock />

    </div>
  );
};

export default GroupDetailsRightMenu;
