import React from 'react';
import NotificationPresentationBlock from './components/NotificationPresentationBlock';

const NotificationRightMenu = () => {

  return (
    <div className="rightMenuContainer">

      <NotificationPresentationBlock />

    </div>
  );
};

export default NotificationRightMenu;
