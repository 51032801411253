import React from 'react';
import IconEasyProfile from '../../common/icons/IconEasyProfile';
import {css} from 'emotion';

// eslint-disable-next-line
const ProfileMenuAccount = React.forwardRef((props, ref) => {

  return (
    <div
      id={'profile-menu-account'}
      ref={ref}
      className={css`
        flex-direction: row;
        width: 100%;
        display: flex;
        padding-left: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        &:hover{
            background-color: rgba(245, 93, 66,0.1);
        }
        overflow: hidden;
        cursor: pointer;
        border-bottom: 1px solid #e8e8e8;
      `}>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <IconEasyProfile size={18} color={'#f55d42'} />
      </div>
      <div
        className={css`
          margin-left: 10px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover{
              color: rrgba(245, 93, 66,1);
          }
        `}>
        Mon compte
      </div>
    </div>
  );
});

export default ProfileMenuAccount;
