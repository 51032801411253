import React from 'react';
import { css } from 'emotion';
import { timeSince } from "../../../../../utils/date";


const HeaderComment = ({...props}) =>{
  return(
    <div className={css`display:flex;flex-direction:row`}>
        <div className={css`color:#29354a;font-weight:600;font-size:15px;display:flex;align-items:center`}>
          {props.type_user === 9 ? 'Équipe popvox' : (props.type_user === 1 ? ( props.pseudo  ? props.pseudo : 'Anonyme') : props.nom_comm) }
        </div>
        <div className={css`color:${props.type_user === 1 ? '#c9c9c9' : '#919191'};margin-left:10px;font-size:15px;display:flex;align-items:center`}>
          {`Il y a ${timeSince(props.date)}`}
        </div>
    </div>
  )
}

export default HeaderComment;
