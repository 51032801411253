import React, {useState, useEffect} from 'react';
import show_password from '../../../../image/icon/icon-simple/show_password.svg';
import hide_password from '../../../../image/icon/icon-simple/hide_password.svg';
import { css } from 'emotion';

const InputPassword = props => {
  const [type, setType] = useState('password');
  const [focus, setFocus] = useState(false);

  const [error, setError] = useState(null);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const changeType = () => {
    if (type === 'password') setType('text');
    else {
      setType('password');
    }
  };

  const onFocus = () => {
    setFocus(true);
  };

  const _onBlur = (e) => {
    setFocus(false);
    props.onBlur && props.onBlur(e);
  };

  return (
    <div
      className={css`
        background-color: ${focus ? 'white' : '#F4F8F9'};
        box-shadow: ${focus
          ? 'inset 0px 0px 0px 2px #f55d42'
          : 'inset 0px 0px 0px 1px #ededed'};
        transition: box-shadow 0.1s;
      `}
      style={{
        ...props.style,
        width: props.width,
        borderRadius: '10px',
        flexDirection: 'row',
        position: 'relative',
      }}>
      <div style={{flexDirection: 'column', display: 'flex', flex: 1}}>
        <div
          style={{
            textAlign: 'left',
            fontSize: '10px',
            width: '100%',
            ...props.labelStyle,
          }}>
          {props.label}
        </div>
        <input
          disabled={props.disabled}
          onBlur={(e) => _onBlur(e)}
          onFocus={onFocus}
          onChange={props.onChange}
          onKeyDown={typeof(props.onKeyDown) === 'function'  ? props.onKeyDown : undefined}
          type={type}
          className={css`
            &::placeholder {
              color: rgb(101, 119, 134);
            }
          `}
          style={{
            backgroundColor: 'transparent',
            width: '100%',
            outline: 'none',
            border: 'none',
            ...props.inputStyle,
          }}
          placeholder={props.placeholder}
          value={props.text}
        />
      </div>
      <div onClick={changeType}>
        <img
          src={type === 'password' ? show_password : hide_password}
          alt=""
          style={{width: 20, height: 20}}
        />
      </div>
      {error && (
        <div
          style={{
            width: props.width,
            color: error.error ? 'red' : '#0DB3C5',
            height: 10,
            position: 'absolute',
            bottom: '-10px',
          }}
          className="error_input">
          {error.message}
        </div>
      )}
    </div>
  );
};

export default InputPassword;
