import NotificationAPI from '../../api/NotificationAPI';

export function getNotification(popkey, page) {
  return function(dispatch) {
    return NotificationAPI.getNotification(popkey, page).then(data => {
      if (page === 0) {
        dispatch({
          type: 'GET_NOTIFICATION_SUCCESS',
          payload: {
            notifications: data.data.notifications,
            nb: data.data.nbMessage,
            endReached: data.data.endReached,
          },
        });
      } else {
        dispatch({
          type: 'GET_MORE_NOTIFICATION_SUCCESS',
          payload: {
            notifications: data.data.notifications,
            nb: data.data.nbMessage,
            endReached: data.data.endReached,
          },
        });
      }
    });
  };
}

export function readOneNotification(popkey, id_users_message, click) {
  return function(dispatch) {
    return NotificationAPI.readOneNotification(popkey, id_users_message).then(
      () => {
        dispatch({
          type: 'READ_ONE_MESSAGE',
          payload: {
            id_users_message: id_users_message,
          },
        });
        if (!click) {
          dispatch({type: 'MESSAGE_READ'});
        }
      },
    );
  };
}


export function deleteNotification(popkey, id_users_message) {
  return function(dispatch) {
    return NotificationAPI.deleteNotification(popkey, id_users_message).then(
      () => {
        dispatch({
          type: 'DELETE_NOTIFICATION',
          payload: {
            id_users_message: id_users_message,
          },
        });
      },
    );
  };
}
