import React from 'react';
import {css} from 'emotion';

import Dotdotdot from 'react-dotdotdot';

import moment from 'moment';
import 'moment/locale/fr';
import IconEasyAlert from '../../../common/icons/IconEasyAlert';
import IconEasySurvey from '../../../common/icons/IconEasySurvey';
import IconEasyNews from '../../../common/icons/IconEasyNews';
import IconEasyEvent from '../../../common/icons/IconEasyEvent';
import IconEasyMore from '../../../common/icons/IconEasyMore';


import {server_img_public} from '../../../../config/constants';


const titleString = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const typeTo = {
  1: {
    color: '#eb5d37',
    icon: <IconEasyAlert size={20} color="white" />,
  },
  2: {
    color: '#3cc8db',
    icon: <IconEasyNews size={20} color="white" />,
  },
  3: {
    color: '#f7AA20',
    icon: <IconEasySurvey size={20} color="white" />,
  },
  6: {
    color: '#de5b84',
    icon: <IconEasyEvent size={20} color="white" />,
  },
};

const CardContentPublication = props => {
  return (
    <div
      className={css`
        padding-top: 15px;
      `}>

      <div
        className={css`
          height: 50px;
          padding-left: 15px;
          padding-right: 15px;
          display: flex;
          flex-direction: row;
          white-space: normal;
          box-sizing: border-box;
        `}>

        <div
          className={css`
            height: 30px;
            width: 30px;
            background: ${typeTo[props.type].color};
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          {typeTo[props.type].icon}
        </div>

        <div
          className={css`
            flex: 1;
          `}>
          <Dotdotdot
            clamp={2}
            className={css`
              color: rgb(41, 53, 74);
              font-weight: 500;
              line-height: 0.9;
              text-align: left;
              margin-left: 15px;
            `}>
            {titleString(props.title)}
          </Dotdotdot>

          <div
            className={css`
              color: #bfbfbf;
              font-size: 13px;
              margin-top: 0px;
              text-align: left;
              margin-left: 15px;
              line-height: 0.9;
              margin-top:5px;
            `}>
            {props.nom_comm ? titleString(props.nom_comm) : 'France'},{' '}
            {!props.date_end
              ? moment(props.date_begin)
                  .locale('fr')
                  .format('ll')
              : `${moment(props.date_begin)
                  .locale('fr')
                  .format('ll')
                  .replace(/[0-9]{4}/g, '')} - ${moment(props.date_end)
                  .locale('fr')
                  .format('ll')} `}
          </div>
        </div>


          <div
            onClick={(event) => props.onMore(event)}
            className={css`
              height: 100%;
              width: 40px;
              display: flex;
              align-items: center;
              border-radius: 50%;
              justify-content: center;
              &:hover {
                background-color: rgba(245, 93, 66, 0.1);
              }
            `}>
            <IconEasyMore
              color={'#bfbfbf'}
              size={20}
            />
          </div>

      </div>

      {(props.image !== '' && props.image !== null) ? (
        <div
          className={css`
            width: 100%;
            height: 173px;
            margin-top: 2px;
            background-image: url(${server_img_public +
              props.image});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          `}
        />
      ) : props.youtube_code && props.youtube_code !== "" ? (

        <div
          className={css`
            width: 100%;
            height: 173px;
            margin-top: 2px;
            background-image: url(${"https://img.youtube.com/vi/" + props.youtube_code +"/mqdefault.jpg"});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          `}
        />


      ) : (

        <div
          className={css`
            height: 175px;
            width: 100%;
            white-space: normal;
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            align-items: center;
            overflow: hidden;
            background-color : #b0b0b0;
            
          `}>
          <Dotdotdot
            className={css`
              text-align: left;
              font-size: 17px;
              color: #fff;
            `}
            clamp={6}>
            {props.description &&
              props.description.replace(/<[^>]*>?/gm, '').replace(/(\r\n|\n|\r)/gm, "").replace(/&nbsp;/gm, " ")
            }
          </Dotdotdot>
        </div>
      )}

    </div>
  );
};

export default CardContentPublication;
