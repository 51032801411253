import React from "react";
import { css } from "emotion";
import ItemMenuPublication from "./ItemMenuPublication";

const MenuPublication = (props) => {
  return (

    <div style={{
      overflowY: 'scroll',
      overflowX: 'scroll',
      width : '100%',
      marginTop: 30,
      marginBottom: 5,
      boxSizing: 'border-box',
    }}>

    <div
      className={css`
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        margin-right: 20px;
        box-sizing: border-box;
      `}>

      <ItemMenuPublication
        text={"À la Une"}
        onChoose={props.onChoose}
        selector={0}
        currentSelect={props.currentSelect}
      />

      <ItemMenuPublication
        text={"Actualités"}
        onChoose={props.onChoose}
        selector={1}
        currentSelect={props.currentSelect}
      />
      <ItemMenuPublication
        text={"Sondages"}
        onChoose={props.onChoose}
        selector={2}
        currentSelect={props.currentSelect}
      />
      <ItemMenuPublication
        text={"Agenda"}
        onChoose={props.onChoose}
        selector={3}
        currentSelect={props.currentSelect}
      />
    </div>
    </div>
  );
};

export default MenuPublication;
