import React from 'react';
import {css} from 'emotion';

import IconEasyComment from '../../../../common/icons/IconEasyComment';
import SimpleLikeCounter from './like/SimpleLikeCounter';
import VoteCounter from './vote/VoteCounter';
import LaureatReward from './laureat/LaureatReward';

const Footer = props => {



  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        margin-left: 30px;
        border-top: ${props.file_url
          ? 'none'
          : '1px solid rgba(232, 232, 232,0.8)'};
        margin-top: ${props.file_url ? '0px' : '20px'};
        margin-right: 30px;
        padding-top: 20px;
        padding-bottom: 20px;
      `}>

      {
        (props.current_phase == 1 || props.current_phase == 1.5) &&
        <SimpleLikeCounter likes={props.likes} has_liked={props.has_liked} details={props.details} />
      }


      {
        ((props.current_phase == 2) || (props.current_phase == 2.5)) &&
        <VoteCounter project_status={props.project_status} votes={props.votes} has_voted={props.has_voted}  />
      }

      {
        (props.current_phase == 3) &&
        <LaureatReward project_status={props.project_status} />
      }

      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          flex: 1;
        `}>
        <div>
          {' '}
          <IconEasyComment color={'#29354a'} size={25} />
        </div>
        <div
          className={css`
            color: #29354a;
            font-size: 13px;
          `}>
          {props.comments}
        </div>
      </div>
    </div>
  );
};

export default Footer
