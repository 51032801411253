import React from 'react';
import {css} from 'emotion';

import ActionButton from './components/ActionButton';
import LeftActionButton from './components/LeftActionButton';
import RightActionButton from './components/RightActionButton';
import IconEasyFilter from '../../../common/icons/IconEasyFilter';
import IconEasyCard from '../../../common/icons/IconEasyCard';
import IconEasyMap from '../../../common/icons/IconEasyMap';
import TimeLine from '../time-line/TimeLine';
import Content from './components/Content';

const SubHeader = (props) => {
  return (
    <>
      <div
        className={css`
        box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.04);
        border-radius: 3px;
          padding: 20px;
          margin-left: 10px;
          margin-right: 10px;
          position: relative;
          z-index: 1;
          background-color: white;
          margin-top: -20px;
          box-sizing: border-box;
        `}>
        <div
          className={css`
            margin-top: 20px;
          `}>
          {' '}
          {props.budget_part_session && (
            <TimeLine budget_part_session={props.budget_part_session} />
          )}{' '}
        </div>
        <Content budget_part_session={props.budget_part_session} />
        <div
          className={css`
            margin-top: 20px;
            border-top: 1px solid rgba(24, 62, 100, 0.1);
            padding-top: 20px;
          `}>
          <div
            className={css`
              display: flex;
              flex-diretion: row;
              align-items: flex-end;
            `}>
            <LeftActionButton
              select={!props.map}
              onPress={props.onShowList}
              icon={
                <>
                  <div
                    style={{
                      color: !props.map ? 'white' : 'rgba(245, 93, 66,1)',
                      marginRight: 6,
                    }}>
                    Liste{' '}
                  </div>{' '}
                  <IconEasyCard
                    color={!props.map ? 'white' : 'rgba(245, 93, 66,1)'}
                    size={20}
                  />{' '}
                </>
              }
            />

            <RightActionButton
              select={props.map}
              onPress={props.onShowMap}
              styles={{
                marginRight: 20,
              }}
              icon={
                <>
                  <IconEasyMap
                    color={props.map ? 'white' : 'rgba(245, 93, 66,1)'}
                    size={20}
                  />{' '}
                  <div
                    style={{
                      color: props.map ? 'white' : 'rgba(245, 93, 66,1)',
                      marginLeft: 6,
                    }}>
                    Carte{' '}
                  </div>{' '}
                </>
              }
            />
            {props.map ?
              <div style={{textAlign: 'center', width: '100%', color: 'grey'}}>
                Seuls les projets géolocalisés sont visibles sur la carte.
              </div>
            : <></>}

            <div
              className={css`
                display: flex;
                justify-content: flex-end;
                flex: 1;
              `}>
              <div
                className={css`
                  display: flex;
                  margin-right: 20px;
                `}>
                {' '}
              </div>

              <ActionButton
                onPress={props.showFilter}
                icon={
                  <>
                    <div
                      style={{
                        color: 'rgba(245, 93, 66,1)',
                        marginRight: 10,
                      }}>
                      Filtres{' '}
                    </div>{' '}
                    <IconEasyFilter color={'rgba(245, 93, 66,1)'} size={20} />{' '}
                  </>
                }
              />
            </div>
          </div>
        </div>{' '}
      </div>
    </>
  );
};

export default SubHeader;
