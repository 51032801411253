import React from 'react';
import {css} from 'emotion';
import {useSelector, useDispatch} from 'react-redux';
import {removeProject} from '../../../../../redux/actions/budgetPartActions';
import axios from 'axios';
import {server_api_url} from '../../../../../config/constants';

const CardOptions = props => {
  const dispatch = useDispatch();
  const popkey = useSelector(state => state.user.popkey);

  const onSendSignal = e => {
    e.stopPropagation();
    axios
      .post(server_api_url + '/budgetpart/onsignalbudgetpart', {
        id_budget_part_project: props.id_budget_part_project,
      })
      .then(() => {
        props.onBack(e);
      });
  };

  const onRemoveCard = e => {
    e.stopPropagation();
    dispatch(
      removeProject(popkey, props.id_budget_part_project, props.location),
    ).then(() => {
      props.onBack(e);
    });
  };

  const onSend = e => {
    if (props.option === 1) {
      onSendSignal(e);
    } else if (props.option === 2) {
      onRemoveCard(e);
    } else {
      props.onSharing(e);
    }
  };

  return (

    <div
    className={css`
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.1);
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      border-radius: 10px;
    `}>
    <div
      className={css`
        margin-top: 20px;
        background-color: white;
        position: absolute;
        z-index: 11;
        right: 20px;
        top: 20px;
        width: 90%;
        background: white;
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        border-radius: 10px;
        box-sizing: border-box;
        border: 1px solid rgba(24, 62, 100, 0.1);
        -moz-box-shadow: 3px 3px 3px 0px rgba(41, 53, 74, 0.1);
        -webkit-box-shadow: 3px 3px 3px 0px rgba(41, 53, 74, 0.1);
        -o-box-shadow: 3px 3px 3px 0px rgba(41, 53, 74, 0.1);
        box-shadow: 3px 3px 3px 0px rgba(41, 53, 74, 0.1);
      `}>
      <div>
        <div
          className={css`
            font-size: 14px;
            color: #29354a;
            font-weight: bold;
            margin-bottom: 10px;
            text-align: left;
          `}>
          {props.option === 1
            ? 'Signaler ce projet ?'
            : props.option === 2
            ? 'Supprimer ce projet?'
            : 'Voulez vous partagez ce projet ?'}
        </div>

        <div
          className={css`
            font-size: 14px;
            color: #29354a;
            text-align: left;
          `}>
          {props.option === 1
            ? 'Voulez vous signaler ce projet comme inapproprié ? Elle sera analysée par les équipes popvox...'
            : props.option === 2
            ? 'Voulez-vous supprimer définitivement ce projet ?'
            : 'Voulez vous partagez ce projet ?'}
        </div>
      </div>
      <div
        className={css`
          margin-left: 20px;
        `}>
        <div
          onClick={onSend}
          className={css`
            color: #29354a;
            margin-top: 10px;
            font-size: 13px;
            background-color: #f5f8fa;
            padding: 5px 10px 5px 10px;
            width: 80px;
            border-radius: 10px;
            border: 1px solid rgba(41, 53, 74, 0.1);
            &:hover {
              background-color: rgb(242,101,35);
              color: white;
              cursor: pointer;
            }
          `}>
          Oui
        </div>
        <div
          onClick={props.onBack}
          className={css`
            color: #29354a;
            margin-top: 10px;
            font-size: 13px;
            background-color: #f5f8fa;
            padding: 5px 10px 5px 10px;
            width: 80px;
            border-radius: 10px;
            border: 1px solid rgba(41, 53, 74, 0.1);
            margin-top: 10px;
            &:hover {
              background-color: rgb(242,101,35);
              color: white;
              cursor: pointer;
            }
          `}>
          Annuler
        </div>
      </div>
    </div>
    </div>
  );
};
export default CardOptions;
