import React from 'react';
import {css} from 'emotion';

const TokenTitle = (props) =>{
  return(
    <div
      style={{...props.style}}
      className={css`
      background-color:${props.background};
      color:${props.colorText};
      padding-left:15px;
      padding-right:15px;
      padding-top:2px;
      padding-bottom:2px;
      border-radius:10px;
      box-sizing:border-box;
      font-weight:600;
      font-size:12px;
      border: 1px solid rgba(24,62,100,0.2);
      `}>
      {props.text}
    </div>
  )
}

export default TokenTitle;
