import React from 'react';
import {css} from 'emotion';
import moment from 'moment';
import 'moment/locale/fr';

const PlaceAndDate = props => {
  return (
    <div
      className={css`
        box-sizing: border-box;
        color: grey;
        font-size: 13px;
      `}>
      {props.nom_comm ? props.nom_comm : 'France'},{' '}
      {!props.date_end
        ? moment(props.date_begin)
            .locale('fr')
            .format('ll')
        : `${moment(props.date_begin)
            .locale('fr')
            .format('ll')
            .replace(/[0-9]{4}/g, '')} - ${moment(props.date_end)
            .locale('fr')
            .format('ll')} `}
    </div>
  );
};

export default PlaceAndDate;
