import React, { useEffect, useState } from "react";
import { css } from "emotion";
import IconEasyArrowDown from "../../../../common/icons/IconEasyArrowDown";

const Content = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");

  const onShow = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (props.budget_part_session) {
      if (props.budget_part_session.current_phase == 0) {
        setText(`Retrouvez dans peu de temps le budget participatif de votre ville !

                  Cela vous permettra d’imaginer avec vos élus la ville de demain, en proposant des projets, en commentant, ou en votant pour les projets des autres citoyens.
                  Les meilleurs idées seront sélectionnés et réalisés par la collectivité, dans la limite du budget défini.
                  
                  A très bientôt !`);
      } else if (
        props.budget_part_session.current_phase == 1 ||
        props.budget_part_session.current_phase == 1.5
      ) {
        setText(props.budget_part_session.text_phase_1);
      } else if (
        props.budget_part_session.current_phase == 2 ||
        props.budget_part_session.current_phase == 2.5
      ) {
        setText(props.budget_part_session.text_phase_2);
      } else {
        setText(props.budget_part_session.text_phase_3);
      }
    }
  }, [props.budget_part_session]);

  return (
    <div
      className={css`
        margin-top: 20px;
      `}>
      <div
        className={css`
          line-height: ${isOpen ? "none" : "1.5em"};
          font-size: 15px;
          height: ${isOpen ? "none" : "3em"};
          overflow: ${isOpen ? "none" : "hidden"};
          white-space: pre-line;
        `}>
        {text}
      </div>

      <div
        className={css`
          display: flex;
          justify-content: center;
          margin-top: 20px;
        `}>
        <div
          onClick={onShow}
          className={css`
            transform: rotate(${isOpen ? "180deg" : "0deg"});
            transform-origin: center center;
            margin: 0 auto;
            display: inline;
            border-radius: 50%;
            cursor: pointer;
          `}>
          <IconEasyArrowDown color={"rgba(245,93,66,1"} size={30} />
        </div>
      </div>
    </div>
  );
};

export default Content;
