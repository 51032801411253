import React, { useRef } from "react";
import { css } from "emotion";
import ReactMapboxGl, { ZoomControl } from "react-mapbox-gl";
import { server_api_url } from "../../../config/constants";

import FooterParticipateStep from "./components/FooterParticipateStep";
import SearchBarMap from "./components/SearchBarMap";
import MarkerMap from "./components/MarkerMap";
import { useSelector } from "react-redux";
import {
  participationThemeFr,
  participationRubricsFR,
} from "../../../config/thematics";
import ItemTheme from "./components/ItemTheme";
import ItemRubrics from "./components/ItemRubrics";
import InputFile from "../../common/forms/inputs/InputFile";
import InputTextArea from "../../common/forms/inputs/InputTextArea";

import HorizontalScroll from "../../common/horizontalScroll/HorizontalScroll";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoianVzcHJpZXQiLCJhIjoiY2o5cHRzMjB1MnVxMTJ4czJoaTdhZjVjbCJ9.3LZxp2Lf97TcCYWPAgsyyA",
});

const ParticipateDescription = (props) => {
  const timer = useRef(null);
  const clicks = useRef(0);
  const scrollRef = useRef(null);
  const infoCity = useSelector((state) => state.city.infoCity);

  const zoomLevel = infoCity.zoom_level ? infoCity.zoom_level : 8;

  const onChooseCity = (coord) => {
    props.setCoord(coord[0], coord[1]);
    props.setCenterCoord(coord[0], coord[1]);
  };

  const placeMarker = (map, evt) => {
    clicks.current = clicks.current + 1;
    if (clicks.current === 1) {
      props.setCoord(evt.lngLat.lng, evt.lngLat.lat);
      timer.current = setTimeout(() => {
        clicks.current = 0;
      }, 600);
    }
  };

  const onGeoloc = (lat, long) => {
    props.setCoord(long, lat);
    props.setCenterCoord(long, lat);
  };

  return (
    <>
      <div
        className={css`
          background: white;
        `}>

        {props.allTheThematics &&
          props.allTheThematics.types.find((i) => i.id === props.type).themes
            .length > 0 && (
            <>
              <div
                className={css`
                  padding-top: 10px;
                  font-weight: 600;
                  margin-left: 20px;
                `}>
                Choisir un thème *
              </div>

              <HorizontalScroll
                onScrollEndLeft={() => {
                  return false;
                }}
                ref={scrollRef}>

                <div
                  className={css`
                    display: flex;
                    flex-direction: row;
                    margin-left: 20px;
                    margin-right: 20px;
                    box-sizing: border-box;
                    align-items: flex-start;
                    justify-content: flex-start;
                  `}>
                  {props.allTheThematics.types
                    .find((i) => i.id === props.type)
                    .themes.map((item, key) => {
                      if (participationThemeFr[item.id] !== undefined) {
                        return (
                          <ItemTheme
                            key={key}
                            style={{ marginRight: 20 }}
                            theme={props.theme}
                            onChooseTheme={props.onChooseTheme}
                            id={item.id}
                          />
                        );
                      }
                      return false;
                    })}
                </div>
              </HorizontalScroll>
            </>
          )}

        {props.allTheRubrics !== null && props.allTheRubrics.length > 1 && (
          <>
            <div
              className={css`
                padding-top: 10px;
                font-weight: 600;
                margin-left: 20px;
              `}>
              Choisir une rubrique *
            </div>

            <div
              style={{
                flexdirection: "row",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {props.allTheRubrics.map((item, key) => {
                if (participationRubricsFR[item]) {
                  return (
                    <ItemRubrics
                      key={key}
                      rubric={props.rubric}
                      onChooseRubric={(therubric) => {
                        props.setRubric(therubric);
                      }}
                      id={item}
                      text={participationRubricsFR[item]}
                    />
                  );
                }
                return false;
              })}
            </div>
          </>
        )}

        <div style={{ padding: 20 }}>
          <InputTextArea
            value={props.description}
            onChange={props.onChangeDescription}
            title={"Description libre"}
          />

          <div
            className={css`
              margin-top: 30px;
              font-weight: 600;
            `}>
            Illustration (Photo)
          </div>

          <InputFile
            onClearFileUrl={props.onRemovePicture}
            url={server_api_url + "/fileandimageuploads/participationimage"}
            onPost={props.onUploadPicture}
            title={"Ajouter une image"}
            style={{ marginTop: 0, width: "100%" }}
            fileUrl={props.uriPicture}
          />
        </div>

        <div
          className={css`
                padding-top: 10px;
                font-weight: 600;
                margin-bottom : 20px
                margin-top: 10px;
                margin-left: 20px;
              `}>
          Localisation
        </div>

        <div
          className={css`
            margin-top: 20px;
            height: 250px;
            position: relative;
            padding-left: 20px;
            padding-right: 20px;
          `}>
          <Map
            onClick={placeMarker}
            center={props.centerCoord}
            zoom={[zoomLevel]}
            // eslint-disable-next-line
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
              height: "100%",
              width: "100%",
            }}>
            <ZoomControl position="bottom-right" />

            {props.coord !== null && (
              <MarkerMap type={props.type} coord={props.coord} />
            )}
          </Map>
          <SearchBarMap onGeoloc={onGeoloc} onChooseCity={onChooseCity} />
        </div>

        <div style={{ height: 100, width: "100%" }} />
        
      </div>

      


      <FooterParticipateStep>
        <div
          onClick={props.onBackStep}
          className={css`
            flex: 1;
            text-align: center;
            cursor: pointer;
            &:hover {
              background-color: #f55d42;
              color: white;
            }
            transition: 0.3s;
          `}>
          Précédent
        </div>

        <div
          onClick={props.onSend}
          className={css`
            flex: 1;
            font-weight: bold;
            text-align: center;
            cursor: pointer;
            background-color: ${props.isFull
              ? "#f55d42"
              : "rgba(41, 53, 74, 0.1)"};
            color: white;

            border-left: 1px solid rgba(41, 53, 74, 0.1);
            &:hover {
              opacity: 0.8;
            }
            transition: 0.3s;
          `}>
          Envoyer
        </div>
      </FooterParticipateStep>

    </>
  );
};

export default ParticipateDescription;
