import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyStars = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
          version="1.1" viewBox="0 0 32 32">
      <path
      fill={props.color}
      d="M16.148,3.006c0.085,0.016 0.107,0.017 0.189,0.044c0.308,0.104 0.544,0.359 0.661,0.669l2.587,7.962l8.372,0c0,0 0.693,0.079 0.942,0.589c0.185,0.38 0.111,0.869 -0.18,1.178c-0.06,0.063 -0.079,0.074 -0.146,0.128l-7.389,5.368c-0.754,0.496 -1.965,-0.269 -1.592,-1.227c0.067,-0.17 0.107,-0.264 0.361,-0.468l4.78,-3.473l-5.909,0c0,0 -0.205,-0.005 -0.368,-0.067c-0.293,-0.11 -0.516,-0.357 -0.628,-0.657l-1.826,-5.619l-1.826,5.619c0,0 -0.069,0.194 -0.177,0.33c-0.195,0.245 -0.5,0.38 -0.819,0.394l-5.909,0l4.78,3.473c0,0 0.163,0.126 0.259,0.271c0.172,0.261 0.207,0.592 0.122,0.901l-1.826,5.619l4.78,-3.473c0,0 0.17,-0.116 0.337,-0.162c0.302,-0.083 0.628,-0.014 0.895,0.162l4.78,3.473l-0.661,-2.036l-0.027,-0.096c-0.015,-0.087 -0.022,-0.109 -0.024,-0.197c-0.02,-0.69 0.774,-1.283 1.455,-0.995c0.275,0.116 0.481,0.359 0.588,0.641l1.658,5.102c0.22,0.802 -0.65,1.647 -1.445,1.271c-0.078,-0.038 -0.095,-0.052 -0.167,-0.1l-6.773,-4.921l-6.773,4.921l-0.081,0.054c-0.281,0.149 -0.38,0.15 -0.559,0.146c-0.64,-0.015 -1.155,-0.704 -0.972,-1.371l2.587,-7.962l-6.773,-4.921c0,0 -0.38,-0.301 -0.426,-0.738c-0.054,-0.517 0.335,-1.042 0.848,-1.139c0.085,-0.016 0.107,-0.014 0.194,-0.018l8.372,0l2.587,-7.962c0,0 0.072,-0.201 0.186,-0.34c0.225,-0.275 0.58,-0.409 0.956,-0.373Z"/>
        </svg>
    </IconPattern>
    )
}

export default IconEasyStars;
