import React from "react";
import TokenTitle from "../../../common/material-easy-ui/material/TokenTitle";

import "./Community.css";

export default function Community(props) {
  return (
    <div
      className="communityContainer shadow clickable"
      onClick={props.onClick}>
      {props.type === 1 || props.type === 2 ? (
        <div className="communityImageContainerFull">
          <img
            src={props.image}
            alt="Communauté"
            className="communityImageFull"
          />
        </div>
      ) : (
        <div className="communityImageContainer">
          <img src={props.image} alt="Communauté" className="communityImage" />
        </div>
      )}

      <div style={{ flex: 1, paddingLeft: 20 }}>
        {props.type === 1 && (
          <TokenTitle
            style={{
              alignSelf: "center",
              display: "inline-block",
              marginBottom: 3,
            }}
            background={"#F5F8FA"}
            colorText={"rgba(41, 53, 74,1)"}
            text="Discussion publique"
          />
        )}

        {props.type === 2 && (
          <TokenTitle
            style={{
              alignSelf: "center",
              display: "inline-block",
              marginBottom: 3,
            }}
            background={"#F5F8FA"}
            colorText={"rgba(41, 53, 74,1)"}
            text="Discussion privée"
          />
        )}

        {props.type === 3 && (
          <TokenTitle
            style={{
              alignSelf: "center",
              display: "inline-block",
              marginBottom: 3,
            }}
            background={"#F5F8FA"}
            colorText={"rgba(41, 53, 74,1)"}
            text="Communauté"
          />
        )}

        {props.type === 3 &&
          typeof props.isPrivate !== "undefined" &&
          props.isPrivate === true && (
            <TokenTitle
              style={{
                alignSelf: "center",
                display: "inline-block",
                marginBottom: 3,
                marginLeft: 10,
              }}
              background={"#F5F8FA"}
              colorText={"rgba(41, 53, 74,1)"}
              text="Discussion privée"
            />
          )}

        <div style={{ fontSize: 18 }}>{props.title}</div>

        {props.type === 4 && (
                   <div
                   style={{
                     fontWeight: 'bold',
                     marginLeft: 5,
                     fontSize: 13,
                     color: 'grey'
                   }}>
                   INSCRIPTION NECESSAIRE
                 </div>
        )}
      </div>
    </div>
  );
}
