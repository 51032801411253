import React from 'react';
import SideNav from './desktop/SideNav';

import './styles.css';

const Nav =(props) =>{
  return(
    <div className="side-nav">
      <SideNav location={props.location}/>
    </div>
  )
}

export default Nav
