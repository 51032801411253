import React, {useState} from 'react';
import {css} from 'emotion';
import IconEasyNews from '../../../common/icons/IconEasyNews';
import IconEasyAlert from '../../../common/icons/IconEasyAlert';
import IconEasyEvent from '../../../common/icons/IconEasyEvent';
import IconEasySurvey from '../../../common/icons/IconEasySurvey';

import 'moment/locale/fr';
import IconEasyLike from '../../../common/icons/IconEasyLike';
import IconEasyComment from '../../../common/icons/IconEasyComment';
import TokenTitle from '../../../common/material-easy-ui/material/TokenTitle';
import IconEasyGroup from '../../../common/icons/IconEasyGroup';

import MessageAlerte from '../../../common/material-easy-ui/modal/MessageAlerte';
import Connexion from '../../../connexion/Connexion';
import AccountNotConfirmedAlert from '../../../common/alert/AccountNotConfirmedAlert'

import {useSelector, useDispatch} from 'react-redux';
import {
  likeNews,
  disLikeNews,
} from '../../../../redux/actions/newsActions';

const typeToData = {
  1: {
    entitled: 'Alerte',
    colorText: 'rgba(41, 53, 74,1)',
    color: '#F5F8FA',
    icon: <IconEasyAlert color={'#183E64'} size={25} />,
  },
  2: {
    entitled: 'Actualité',
    colorText: 'rgba(41, 53, 74,1)',
    color: '#F5F8FA',
    icon: <IconEasyNews color={'#183E64'} size={25} />,
  },
  3: {
    entitled: 'Sondage',
    colorText: 'rgba(41, 53, 74,1)',
    color: '#F5F8FA',
    icon: <IconEasySurvey color={'#183E64'} size={25} />,
  },
  6: {
    entitled: 'Agenda',
    colorText: 'rgba(41, 53, 74,1)',
    color: '#F5F8FA',
    icon: <IconEasyEvent color={'#183E64'} size={25} />,
  },
};

const FooterNews = props => {
  const dispatch = useDispatch();

  const popkey = useSelector(state => state.user.popkey);
  const info_user = useSelector(state => state.user.info_user);

  const toggleLike = (event) => {
    event.stopPropagation();
    if (popkey) {
      if (info_user.account_blocked === 0) {
        if (!props.has_liked)
          dispatch(likeNews(popkey, props.details.id_publicpart));
        else
          dispatch(disLikeNews(popkey, props.details.id_publicpart));
      } else if (info_user.account_blocked === 1) {
        props.setForbidenUserModal(true);
      } else
        props.setNotConfirmedUserModal(true)
    } else {
      props.setConnect(true);
    }
  }

  return (
    <div
      className={css`
        flex: 1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
      `}>
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
        onClick={(e) => toggleLike(e)}>
        <IconEasyLike
          color={props.has_liked ? '#ff006a' : '#95a1ad'}
          size={20}
        />
        <div
          className={css`
            font-size: 10px;
            margin-right: 10px;
            color: ${props.has_liked ? '#ff006a' : '#95a1ad'};
          `}>
          {props.likes}
        </div>
      </div>
      <div
        className={css`
          display: flex;
          align-items: center;
        `}>
        <IconEasyComment color={'#95a1ad'} size={20} />
        <div
          className={css`
            font-size: 10px;
            color: #95a1ad;
          `}>
          {props.comments}
        </div>
      </div>
    </div>
  );
};

const FooterAlert = props => {
  return (
    <div
      className={css`
        flex: 1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
      `}>
      <div
        className={css`
          display: flex;
          align-items: center;
        `}>
        <IconEasyComment color={'#95a1ad'} size={20} />
        <div
          className={css`
            font-size: 10px;
            color: #95a1ad;
          `}>
          {props.comments}
        </div>
      </div>
    </div>
  );
};

const FooterSurvey = props => {
  return (
    <div
      className={css`
        flex: 1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
      `}>
      <div
        className={css`
          display: flex;
          align-items: center;
        `}>
        <IconEasyGroup color={'#95a1ad'} size={20} />
        <div
          className={css`
            font-size: 10px;
            color: #95a1ad;
          `}>
          {props.nbparticipate}
        </div>
      </div>
    </div>
  );
};


const HeaderCardPublication = props => {
  const [connect, setConnect] = useState(false);
  const [forbidenUserModal, setForbidenUserModal] = useState(false);
  const [notConfirmedUserModal, setNotConfirmedUserModal] = useState(false);

  const [details, setDetails] = useState(props.details);

  return (
    <div
      className={css`
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;
        align-items: flex-start;
        margin-top:10px;
      `}>

      <TokenTitle
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
        text={typeToData[props.type].entitled}
        background='#fff'
        colorText='#454545'
      />

      <TokenTitle
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          marginLeft: 10,
          overflow: 'hidden'
        }}
        text={props.community ? props.community : 'Public'}
        background='#fff'
        colorText='#454545'
      />

      {props.type === 1 ? (
        <FooterAlert comments={props.comments} />
      ) : props.type === 2 ? (
        <FooterNews
          has_liked={props.has_liked}
          comments={props.comments}
          likes={props.likes}
          setNotConfirmedUserModal={setNotConfirmedUserModal}
          setForbidenUserModal={setForbidenUserModal}
          setConnect={setConnect}
          details={details}
          setDetails={setDetails}
        />
      ) : (
        <FooterSurvey nbparticipate={props.nbparticipate} />
      )}

      {connect && <Connexion onBack={() => setConnect(false)} />}
      {forbidenUserModal && (
        <MessageAlerte
          close={() => setForbidenUserModal(false)}
          text={
            "Votre compte a été bloqué par la ville ou l'équipe popvox car vous ne respectiez pas les CGU du service. Pour plus d'informations, vous pouvez contacter les équipes popvox (contact@popvox.fr)."
          }
          textHeader={'Compte bloqué'}
        />
      )}
      {notConfirmedUserModal && (
        <AccountNotConfirmedAlert close={() => setNotConfirmedUserModal(false)} />
      )}

    </div>
  );
};

export default HeaderCardPublication;
