import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyLocationSuggest = (props) =>{
    return(
      <IconPattern size={props.size}>
    <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        `}
    version="1.1" viewBox="0 0 91 91">
<g><path d="M68.592,39.138c0-4.322-1.258-8.45-3.623-12.021l3.598-3.68l-2.432-2.377L44.574,43.114L38.715,35.1   l-2.744,2.006l8.229,11.257l18.334-18.755c1.74,2.868,2.658,6.129,2.658,9.53c0,8.641-6.121,16.225-14.555,18.033   c-0.572,0.122-1.039,0.529-1.24,1.078l-2.303,6.292c-0.143-0.001-0.266-0.002-0.348-0.002c-0.098,0-0.244,0.001-0.422,0.003   l-2.416-6.353c-0.203-0.533-0.66-0.93-1.217-1.055c-8.338-1.869-14.389-9.438-14.389-17.997c0-10.17,8.273-18.444,18.445-18.444   c3.404,0,6.73,0.936,9.619,2.706l1.777-2.898c-3.424-2.099-7.365-3.208-11.396-3.208c-12.047,0-21.846,9.8-21.846,21.845   c0,9.825,6.736,18.55,16.145,21.093l1.699,4.464c-5.104,0.369-13.082,1.607-13.082,5.74c0,5.731,15.334,5.897,17.082,5.897   s17.084-0.166,17.084-5.897c0-4.157-8.07-5.385-13.17-5.746l1.615-4.413C61.785,57.792,68.592,49.052,68.592,39.138z    M60.309,70.435c-1.055,0.985-5.979,2.497-13.563,2.497S34.24,71.42,33.186,70.435c0.922-0.859,4.799-2.122,10.82-2.427   l1.152,3.031c0.252,0.66,0.885,1.096,1.59,1.096c0.004,0,0.006,0,0.01,0c0.711-0.005,1.342-0.449,1.586-1.116l1.104-3.013   C55.492,68.307,59.385,69.572,60.309,70.435z" fill={props.color}/></g>
  </svg>
    </IconPattern>
    )
}

export default IconEasyLocationSuggest;
