
export function timeSince(date) {
  var datedate = new Date(date);
  var seconds = Math.floor(new Date(  Date.now() - datedate ) / 1000);

  var interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return  interval + " ans";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return  interval + " mois";
  }
  interval = Math.floor(seconds / 86400);
  if (interval === 1) {
    return  interval + " jour";
  } else if (interval > 1) {
    return  interval + " jours";
  }
  interval = Math.floor(seconds / 3600);
  if (interval === 1) {
    return  interval + " heure";
  } else if (interval > 1) {
    return  interval + " heures";
  }
  interval = Math.floor(seconds / 60);
  if (interval === 1) {
    return  interval + " minute";
  } else if (interval > 1){
    return  interval + " minutes";
  }

  return "< 1 mn";
}



export function formatFullDateFr(dateStr) {
    var date = new Date(dateStr);
    const monthFr = {
      0: "janv.",
      1: "fév.",
      2: "mars",
      3: "avr.",
      4: "mai",
      5: "juin",
      6: "juil.",
      7: "aout",
      8: "setp.",
      9: "oct.",
      10: "nov.",
      11: "déc."
    };

    var dateFrStr = "";
    dateFrStr += date.getDate();
    dateFrStr += " " + monthFr[date.getMonth()];
    dateFrStr += " " + date.getFullYear();

    return dateFrStr;
  }



  export function timeSpentFromSeconds(minutes) {
  let time = minutes * 60 * 1000;
  let result = 0;

  //Differentes Unités de mesure de temps
  const minute = 60000;
  const hour = 3600000;
  const day = 86400000;
  const month = 2592000000;
  const year = 31536000000;

  time < minute?
  result = "1 min"
    : time >= minute && time < hour
      ? (result = ` ${Math.floor(time / minute)}min`)
      : time >= hour && time < day
        ? result = ` ${Math.floor(time / hour)}h`
        : time >= day && time < month
          ? result = ` ${Math.floor(time / day)}J`
          : time >= month && time < year
            ? result = ` ${Math.floor(time / month)} Mois`
            : time >= year
              ? result = ` ${Math.floor(time / year)} Ans`
              : result =  " ";

  return result;
}
