import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyWorld = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
          version="1.1" viewBox="0 0 32 32">
        <path
         fill={props.color}
         d="M22.589,11.999l-3.294,-3.294c-0.121,-0.127 -0.146,-0.176 -0.192,-0.27c-0.166,-0.342 -0.116,-0.778 0.128,-1.074c0.027,-0.032 0.035,-0.04 0.064,-0.07l2.001,-2.001c0.207,-0.197 0.485,-0.304 0.778,-0.29c0.238,0.023 0.462,0.125 0.636,0.29l4.001,4.001c0.121,0.127 0.146,0.176 0.192,0.27c0.166,0.342 0.116,0.779 -0.128,1.074c-0.027,0.033 -0.035,0.04 -0.064,0.071l-2.001,2l0,0l-14.002,14.002c-0.155,0.144 -0.345,0.248 -0.551,0.281c-0.069,0.011 -0.086,0.009 -0.156,0.012l-4.001,0c-0.533,-0.022 -0.978,-0.46 -1,-1l0,-4.001c0.008,-0.211 0.068,-0.419 0.191,-0.588c0.041,-0.056 0.055,-0.068 0.102,-0.119l10.998,-10.998c0.3,-0.277 0.304,-0.292 0.749,-0.292c0.269,0.022 0.34,0.062 0.47,0.14c0.474,0.282 0.629,0.982 0.302,1.44c-0.042,0.06 -0.057,0.072 -0.106,0.126l-10.706,10.706l0,2.586l2.587,0l13.002,-13.002Zm-0.586,15.002l-8.002,0c-0.229,-0.006 -0.304,-0.037 -0.433,-0.099c-0.608,-0.292 -0.756,-1.284 -0.145,-1.717c0.168,-0.119 0.373,-0.178 0.578,-0.184l8.002,0c0.018,0 0.037,0.001 0.056,0.001c0.229,0.02 0.302,0.054 0.428,0.124c0.576,0.318 0.681,1.274 0.095,1.691c-0.168,0.119 -0.374,0.178 -0.579,0.184Zm-0.586,-19.003l2.586,2.586l0.586,-0.586l-2.586,-2.586c-0.196,0.195 -0.391,0.391 -0.586,0.586Z"/>
         </svg>
    </IconPattern>
    )
}

export default IconEasyWorld;
