const intitalState = {
  surveyLoading: false,
  surveyForm: false,
  isForm: false,
  resultSurvey: false,
  showAlert: false,
  surveyDetails: false,
};

export const surveyReducers = (state = intitalState, action) => {
  switch (action.type) {


    case "CLEAN_SURVEY":
      return {
        ...state,
        surveyLoading: false,
        surveyForm: false,
        isForm: false,
        resultSurvey: false,
        showAlert: false,
        surveyDetails: false,
      };

    case "GET_SURVEY_LOADING":
      return {
        ...state,
        surveyLoading: true,
        surveyForm: false,
        isForm: false,
        resultSurvey: false,
        showAlert: false,
        surveyDetails: false,
      };

    case "GET_SURVEY_SUCCESS":
      return {
        ...state,
        surveyLoading: true,
        surveyForm: action.payload.data,
        isForm: action.payload.isForm,
        surveyDetails: action.payload.detailed_survey,
        resultSurvey: false,
      };

    case "GET_SURVEY_RESPONSE_SUCCESS":
      return {
        ...state,
        isForm: action.payload.isfForm,
        surveyForm: false,
        resultSurvey: action.payload.resultSurvey,
        surveyDetails: action.payload.detailed_survey,
      };

    case "POST_SURVEY_LOADING":
      return {
        ...state,
      };

    case "POST_SURVEY_SUCCESS":
      return {
        ...state,
      };
      
    case "POST_SURVEY_ERROR":
      return {
        ...state,
      };

    default:
      return state;
  }
};
