import React from 'react';
import {css} from 'emotion';
import FullModal from '../../../../../common/material-easy-ui/modal/FullModal';
import Button from '../../../../../common/material-easy-ui/button/ButtonEX';

const SubscribeModal = props => {

  return (
    <FullModal
      onBack={props.onBack}
      id="modal-subribe-bdg"
      textHeader="Budget Participatif">
      <div
        className={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 50px;
        `}>
        {`J'atteste sur l'honneur résider à ${props.nom_comm} et avoir plus de ${props.age_limit} ans`}
      </div>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          padding-bottom: 50px;
        `}>
        <Button width={'100'} onPress={props.onValid} text="OUI" />
        <Button width={'100'} onPress={props.onBack} text="NON" />
      </div>
    </FullModal>
  );
};

export default SubscribeModal;
