import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyQuestion = (props) =>{
    return(
      <IconPattern size={props.size}>
      <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
          `}
       viewBox="0 0 32 32">
       <path
        fill={props.color}
        d="M16.089,3.009c0.307,0.044 0.386,0.101 0.523,0.209c0.631,0.494 0.384,1.784 -0.652,1.788c-0.036,0 -0.072,0 -0.107,0c-4.126,0.059 -8.101,2.579 -9.863,6.415c-1.415,3.08 -1.312,6.813 0.329,9.832c1.6,2.945 4.594,5.093 7.932,5.625c4.438,0.707 9.219,-1.574 11.421,-5.625c1.908,-3.511 1.709,-8.064 -0.571,-11.422c-1.285,-1.894 -3.158,-3.359 -5.333,-4.159l-0.088,-0.037c-0.257,-0.139 -0.284,-0.192 -0.365,-0.299c-0.503,-0.665 0.181,-1.899 1.201,-1.52c4.778,1.771 8.166,6.359 8.463,11.431c0.236,4.029 -1.521,8.116 -4.622,10.723c-3.378,2.839 -8.242,3.786 -12.456,2.388c-4.428,-1.47 -7.934,-5.465 -8.729,-10.168c-0.824,-4.868 1.361,-10.133 5.551,-12.964c2.125,-1.436 4.674,-2.217 7.271,-2.221c0.031,0.001 0.063,0.002 0.095,0.004Zm-0.094,20.001c0.556,0 1.007,0.45 1.007,1.004c0,0.554 -0.451,1.004 -1.007,1.004c-0.555,0 -1.007,-0.45 -1.007,-1.004c0,-0.554 0.452,-1.004 1.007,-1.004Zm0.032,-16.004c2.578,0.025 4.952,2.242 4.971,4.988c0.015,2.187 -1.729,4.133 -4.002,4.804l0,3.214c-0.009,0.253 -0.043,0.328 -0.112,0.461c-0.309,0.594 -1.279,0.721 -1.706,0.116c-0.092,-0.13 -0.152,-0.282 -0.174,-0.44c-0.008,-0.061 -0.007,-0.076 -0.009,-0.137l0,-3.663c0.007,-0.661 0.508,-1.24 1.179,-1.402c1.375,-0.342 2.771,-1.306 2.822,-2.87c0.053,-1.582 -1.351,-3.049 -2.962,-3.069c-1.581,-0.02 -3.035,1.369 -3.04,3c0,0 -0.108,0.745 -0.666,0.943c-0.61,0.217 -1.344,-0.273 -1.335,-1.009c0.001,-0.064 0.003,-0.128 0.006,-0.192c0.126,-2.481 2.277,-4.701 4.93,-4.743c0.033,-0.001 0.066,-0.001 0.098,-0.001Z"
       />
      </svg>
        </IconPattern>
    )
}

export default IconEasyQuestion;
