import React, {useState} from 'react';

import {css} from 'emotion';
import HeaderCardPublication from './components/HeaderCardPublication';
import CardContentPublication from './components/CardContentPublication';
import ModalMore from './components/ModalMore';

import history from '../../../utils/history';

const CardPublication = props => {
  const [more, setMore] = useState(false);


  const onPress = () => {
    if (props.has_moove === "true") {
      history.push({
        pathname:
          props.item.type === 1
            ? '/alerte'
            : props.item.type === 2
            ? '/actu'
            : props.item.type === 3
            ? '/sondage'
            : '/evenement',
        search: `?id_publicpart=${props.item.id_publicpart}`,
        state: {
          modal: true,
        },
      });
    } else {
      return false;
    }
  };

  const onHideModal = e => {
    e.stopPropagation();
    setMore(false);
  };

  const onMore = e => {
    e.stopPropagation();
    e.preventDefault();
    setMore(true);
  };

  return (
    <div onClick={onPress} className={css`
    position: relative;
    display: inline-block;
    vertical-align: top;
    flex-direction: column;
    width: 420px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-left: 1em;
    margin-right: 1em;
    max-width: calc(100% - 70px);
    background-color: white;
    box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.04);
    overflow: hidden;
  `}>

      {more && (
        <ModalMore
          id_publicpart={props.item.id_publicpart}
          type={props.item.type}
          onHideModal={onHideModal}
          private={props.item.community !== null}
        />
      )}

      <CardContentPublication
        type={props.item.type}
        id_publicpart={props.item.id_publicpart}
        date_begin={props.item.date_begin}
        date_end={props.item.date_end}
        nom_comm={props.item.nom_comm}
        image={props.item.file_url}
        title={props.item.title}
        description={props.item.description}
        youtube_code={props.item.youtube_code}
        onMore={onMore}
      />

      <HeaderCardPublication
        nbparticipate={props.item.nbparticipate}
        community={props.item.community}
        comments={props.item.commentsnb}
        likes={props.item.likes}
        has_liked={props.item.has_liked}
        date_end={props.item.date_end}
        date_begin={props.item.date_begin}
        type={props.item.type}
        nom_comm={props.item.nom_comm}
        details={props.item}
      />

    </div>
  );
};

export default CardPublication;
