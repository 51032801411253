import React from 'react';
import {css} from 'emotion';
import {server_img_public} from '../../../../config/constants';
import ContentCardGroup from './components/ContentCardGroup';
import FooterCardGroup from './components/FooterCardGroup';
import history from '../../../../utils/history';

const CardGroup = props => {

  const goToPageDetails = () => {
    history.push({
      pathname: '/communaute',
      search: `?id_group=${props.item.id}`,
      state: {
        from: 'app',
      },
    });
  };
  
  return (
    <div
      onClick={goToPageDetails}
      className={css`
        width: 100%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background-color: white;
        border-radius: 4px;
        margin-top: 20px;
        box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.04);
      `}>
      <div
        className={`groupe-${
          props.item.id
        } ${css` background-repeat: no-repeat;background-size: cover;background-position: center;background-image:url('${server_img_public}${props.item.file_url}');height:200px;width:100%`}`}
      />
      <ContentCardGroup
        description={props.item.description}
        title={props.item.title}
      />
      <FooterCardGroup
        members={props.item.members}
        requested={props.item.requested}
        subscribed={props.item.subscribed}
      />
    </div>
  );
};

export default CardGroup;
