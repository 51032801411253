import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyComment = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
        viewBox="0 0 32 32">
        <path d="M16.077,2.999c3.024,0.021 6.091,0.815 8.611,2.554c2.818,1.944 4.932,5.312 4.147,8.897c-0.515,2.351 -1.977,4.367 -3.904,5.986c-1.689,1.42 -3.547,2.649 -5.086,4.222c-1.145,1.17 -2.133,2.484 -2.999,3.882c0,0 -0.037,0.087 -0.183,0.219c-0.4,0.358 -1.092,0.318 -1.444,-0.114c-0.293,-0.36 -0.252,-0.876 0.013,-1.3c1.679,-2.685 3.89,-4.944 6.431,-6.89c1.477,-1.129 3.043,-2.187 4.081,-3.767c1.241,-1.887 1.688,-4.256 0.585,-6.429c-1.441,-2.841 -4.596,-4.489 -7.795,-5.043c-3.857,-0.669 -8.069,0.087 -11.047,2.727c-1.464,1.299 -2.532,3.156 -2.498,5.161c0.04,2.364 1.637,4.516 3.645,5.837c2.154,1.417 4.788,2.062 7.363,2.068c0,0 0.094,-0.014 0.282,0.042c0.693,0.205 0.948,1.266 0.333,1.747c-0.4,0.313 -1.014,0.218 -1.597,0.185c-5.021,-0.287 -10.243,-2.863 -11.695,-7.713c-0.668,-2.233 -0.274,-4.726 1.029,-6.737c2.47,-3.813 7.395,-5.544 11.728,-5.534Zm3.92,10.006c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1.001 1,1.001c0.553,0 1.001,-0.449 1.001,-1.001c0,-0.552 -0.448,-1 -1.001,-1Zm-4.002,0c-0.552,0 -1.001,0.448 -1.001,1c0,0.552 0.449,1.001 1.001,1.001c0.552,0 1.001,-0.449 1.001,-1.001c0,-0.552 -0.449,-1 -1.001,-1Zm-4.002,0c-0.553,0 -1.001,0.448 -1.001,1c0,0.552 0.448,1.001 1.001,1.001c0.552,0 1,-0.449 1,-1.001c0,-0.552 -0.448,-1 -1,-1Z"
        fill={props.color}
        />
        </svg>
        </IconPattern>
    )
}

export default IconEasyComment;
