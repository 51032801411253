import React from 'react';
import {css} from 'emotion';

const LeftActionButton = props => {
  return (
    <div
      onClick={props.onPress}
      style={props.styles}
      className={css`
        cursor: pointer;
        background: ${!props.select ? 'white' : 'rgba(245, 93, 66,1)'};
        border: 1px solid rgba(245, 93, 66, 1);
        padding-left: 12px;
        padding-right: 8px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      `}>
      {props.icon}
    </div>
  );
};

export default LeftActionButton;
