import React from 'react';
import {css} from 'emotion';
import ButtonIcon from '../../button/ButtonIcon';
import IconEasyGeolocation from '../../../icons/IconEasyGeolocation';


const ControlMap = (props) =>{
  return(
    <div className={css`z-index:200;right:20px;bottom:50%;margin-bottom:-55px;position:absolute;display:flex;flex-direction:column;align-items;center;justify-content:center`}>
    <ButtonIcon
        onPress={props.onGeoLocUser}
        color={'rgba(255, 255,255,0.5)'}
        hoverColor={'rgba(255, 255,255,1.0)'}
        radius={'20%'}
        width={'30px'}
        height={'30px'}
        icon={<IconEasyGeolocation size={15} color={'#324259'}/>}
    />
    <div onClick={props.zoomIn} className={css` user-select: none;cursor:pointer;background-color:rgba(255, 255,255,0.5);border-radius:20%;width:30px;height:30px;margin-top:20;display:flex;align-items:center;justify-content:center;margin-top:10px;color:#324259;&:hover{background-color:rgba(255, 255,255,1)}`}>+</div>
        <div onClick={props.zoomOut} className={css` user-select: none;cursor:pointer;background-color:rgba(255, 255,255,0.5);border-radius:20%;width:30px;height:30px;margin-top:20;display:flex;align-items:center;justify-content:center;margin-top:10px;color:#324259;&:hover{background-color:rgba(255, 255,255,1)}`}>-</div>
    </div>
  )
}

export default ControlMap;
