import React from "react";
import Avatar from "../../common/avatar/Avatar";
import Button from "../../common/forms/buttons/Button";
import { css } from "emotion";
import UserAPI from "../../../api/UserAPI";
import { badgesTitles } from "../../../config/thematics";
import TokenTitle from "../../common/material-easy-ui/material/TokenTitle";
import { useSelector } from "react-redux";

const BasicInfoProfile = ({ onEdit, onEditPassword, onDeleteAccount }) => {
  const userInfo = useSelector((state) => state.user.info_user);

  const badgeSrcAlone = (id) => {
    switch (id) {
      case 1:
        return require("../../../image/badges/1alone.png");
      case 2:
        return require("../../../image/badges/2alone.png");
      case 3:
        return require("../../../image/badges/3alone.png");
      case 4:
        return require("../../../image/badges/4alone.png");
      case 5:
        return require("../../../image/badges/5alone.png");
      case 6:
        return require("../../../image/badges/6alone.png");
      case 7:
        return require("../../../image/badges/7alone.png");
      case 8:
        return require("../../../image/badges/8alone.png");
      case 9:
        return require("../../../image/badges/9alone.png");
      default:
        return require("../../../image/badges/1alone.png");
    }
  };

  const sendMail = () => {
    UserAPI.sendMail(userInfo.email)
      .then(() => {
        alert("L'email de confirmation a bien été envoyé !");
      })
      .catch((err) => console.log(err));
  };

  if (userInfo) {
    return (
      <div className="basic-info-profile-container">
        <div className="basic-info-profile-header"></div>

        <div style={{ position: "relative", width: "100%" }}>
          <div className="basic-info-profile-avatar">
            <Avatar id_avatar={userInfo.avatar} size={100} />
          </div>

          <div className="basic-info-profile-name">
            <div
              style={{
                color: "#2b2b2b",
                fontSize: 22,
                fontWeight: "bold",
                marginBottom: 10,
              }}>
              {userInfo.pseudo}
            </div>

            <TokenTitle
              colorText={"rgba(41, 53, 74,1)"}
              background={"#fff"}

              text={userInfo.points_nb + " point(s)"}
            />

            <TokenTitle
              colorText={"rgba(41, 53, 74,1)"}
              background={"#fff"}
              style={{ marginTop: 5 }}
              text={badgesTitles[userInfo.game_level]}
            />

            

          </div>

          <div style={{ marginTop: 30, paddingLeft: 20, paddingRight: 20 }}>
            {Array.from(Array(Object.keys(badgesTitles).length), (e, i) => {

              let thelevel = i + 1;

              if (thelevel <= userInfo.game_level) {
                return (
                  <img
                    key={"badgeSrcAlone" + i}
                    style={{
                      width: 60,
                      height: 60,
                      resizeMode: "contain",
                      alignSelf: "center",
                      margin : 10
                    }}
                    src={badgeSrcAlone(thelevel)}
                  />
                );
              } else {
                return (
                  <img
                    style={{
                      width: 60,
                      height: 60,
                      resizeMode: "contain",
                      alignSelf: "center",
                      opacity: 0.2,
                      margin : 10
                    }}
                    key={"badgeSrcAlone" + i}
                    src={badgeSrcAlone(thelevel)}
                  />
                );
              }
            })}
          </div>

          <div className="basic-info-profile-name">
            <TokenTitle
              colorText={"rgba(41, 53, 74,1)"}
              background={"#fff"}
              text={
                userInfo.points_next_level -
                userInfo.points_nb +
                1 +
                " point(s) avant le prochain niveau"
              }
            />
          </div>

          <div
            style={{
              marginTop: 30,
              marginBottom: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Button onClick={onEdit}>Modifier compte</Button>
          </div>

          <div
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Button onClick={onEditPassword}>Changer de mot de passe</Button>
          </div>

          {userInfo.account_blocked === 2 && (
            <div
              style={{
                marginBottom: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Button onClick={sendMail}>
                Renvoyer un mail de confirmation de compte
              </Button>
            </div>
          )}

          <div
            onClick={onDeleteAccount}
            style={{
              marginTop: 40,
              marginBottom: 30,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              color: "rgba(24, 62, 100, 0.5)",
              cursor: "pointer",
            }}>
            Supprimer mon compte
          </div>

          <a
            href="https://www.popvox.fr/cgu.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "rgba(24, 62, 100, 0.5)",
              marginBottom: 100,
            }}>
            CGU - Mentions légales
          </a>
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export default BasicInfoProfile;
