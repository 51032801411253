import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyLocation = (props) =>{
    return(
      <IconPattern size={props.size}>
    <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        `}
    version="1.1" viewBox="0 0 91 91">
  <g><path d="M68.396,39.138c0-12.045-9.8-21.845-21.845-21.845c-12.044,0-21.844,9.8-21.844,21.845   c0,9.825,6.735,18.549,16.145,21.093l1.697,4.465c-5.103,0.368-13.081,1.606-13.081,5.739c0,5.731,15.334,5.897,17.083,5.897   s17.083-0.166,17.083-5.897c0-4.157-8.07-5.385-13.169-5.746l1.615-4.413C61.589,57.792,68.396,49.052,68.396,39.138z    M60.112,70.435c-1.054,0.985-5.977,2.497-13.562,2.497c-7.584,0-12.507-1.512-13.562-2.497c0.922-0.86,4.798-2.122,10.819-2.427   l1.153,3.033c0.251,0.66,0.883,1.096,1.589,1.096c0.003,0,0.007,0,0.011,0c0.709-0.005,1.342-0.449,1.586-1.116l1.104-3.015   C55.297,68.307,59.188,69.572,60.112,70.435z M46.551,64.539c-0.099,0-0.245,0.001-0.423,0.003l-2.415-6.353   c-0.202-0.533-0.659-0.93-1.217-1.055c-8.337-1.87-14.389-9.438-14.389-17.997c0-10.17,8.273-18.444,18.443-18.444   s18.444,8.274,18.444,18.444c0,8.641-6.121,16.225-14.555,18.033c-0.57,0.122-1.039,0.529-1.24,1.078l-2.303,6.292   C46.757,64.54,46.634,64.539,46.551,64.539z" fill={props.color}/><path d="M56.458,39.138c0-5.463-4.444-9.908-9.907-9.908s-9.907,4.445-9.907,9.908s4.444,9.907,9.907,9.907   S56.458,44.601,56.458,39.138z M46.551,45.645c-3.588,0-6.507-2.919-6.507-6.507c0-3.589,2.919-6.508,6.507-6.508   c3.589,0,6.507,2.919,6.507,6.508C53.058,42.726,50.14,45.645,46.551,45.645z" fill={props.color}/>
  </g>
  </svg>
    </IconPattern>
    )
}

export default IconEasyLocation;
