import React, { useState, useEffect, useRef } from "react";
import { css } from "emotion";

import {
  getProjectDetails,
  likeProject,
  unlikeProject,
  unvoteProject,
  postComment,
  getMoreComment,
  removeComment,
} from "../../../../redux/actions/budgetPartActions";
import { useSelector, useDispatch } from "react-redux";
import FullModal from "../../../common/material-easy-ui/modal/FullModal";

import QueryString from "query-string";
import BudgetPartAPI from "../../../../api/BudgetPartAPI";
import SubscribeModal from "./components/subscribe/SubscribeModal";

import TokenTitle from "../../../common/material-easy-ui/material/TokenTitle";
import RubricText from "./components/RubricText";

import CommentInput from "../../../common/comment/comment-input/CommentInput";

import ListComment from "./components/ListComment";
import Connexion from "../../../connexion/Connexion";
import MessageAlerte from "../../../common/material-easy-ui/modal/MessageAlerte";
import history from "../../../../utils/history";
import BaseDetails from "./components/BaseDetails";
//import Extension from './components/extension/Extension';
import Footer from "./components/Footer";
import FullAdminResponse from "../../../participations/card/components/FullAdminResponse";
import AccountNotConfirmedAlert from "../../../common/alert/AccountNotConfirmedAlert";

import ProjectMap from "./components/ProjectMap";

const get = function (obj, key) {
  return key.split(".").reduce(function (o, x) {
    return typeof o === "undefined" || o === null ? o : o[x];
  }, obj);
};

// eslint-disable-next-line
const Details = ({ location, ...props }) => {


  const holder = {
    1: "Citoyen",
    2: "Collectif citoyen",
    3: "Association",
  };

  const dispatch = useDispatch();

  //selector
  const popkey = useSelector((state) => state.user.popkey);
  const page = useRef(0);
  const info_user = useSelector((state) => state.user.info_user);
  const details = useSelector((state) => state.budgetpart.project);

  const pubanswers = useSelector((state) => state.budgetpart.pubanswers);
  const comments = useSelector((state) => state.budgetpart.comments);
  const budget_part_session = useSelector(
    (state) => state.budgetpart.budget_part_session
  );

  const statusProject = useSelector((state) => state.budgetpart.statusProject);
  const loadingComment = useSelector(
    (state) => state.budgetpart.loadingComment
  );
  const endReachedComment = useSelector(
    (state) => state.budgetpart.endReachedComment
  );
  const nb_votes_user = useSelector((state) => state.budgetpart.nb_votes_user);
  const infoCity = useSelector((state) => state.city.infoCity);

  //state
  const [forbidenUserModal, setForbidenUserModal] = useState(false);
  const [notConfirmedUserModal, setNotConfirmedUserModal] = useState(false);
  const [maxVoteModal, setMaxVoteModal] = useState(false);
  const [userResponse, setUserResponse] = useState(null);
  const [connect, setConnect] = useState(false);
  const [subscribe, setSubcribe] = useState(false);

  const getMoreComments = () => {
    if (!loadingComment) {
      page.current++;
      return dispatch(
        getMoreComment(popkey, details.id_budget_part_project, page.current)
      );
    }
  };

  const getRubricName = () => {
    var name = "Autre";
    if (
      budget_part_session &&
      budget_part_session.rubric &&
      budget_part_session.rubric.rubric &&
      details
    ) {
      var tmp = budget_part_session.rubric.rubric.find((bp) => {
        return details.thematic == bp.id;
      });
      if (tmp) {
        name = tmp.name;
      }
    }
    return name;
  };

  const onBack = () => {
    //dispatch({type:'CLEAN_PARTICIPATION'})
    if (get(location, "state.modal")) {
      history.goBack();
      dispatch({ type: "CLEAN_PROJECT" });
    } else {
      history.push("/budget-participatif");
      dispatch({ type: "CLEAN_PROJECT" });
    }
  };

  const onBackConnect = () => {
    setConnect(false);
  };

  const toggleLike = () => {
    if (popkey) {
      if (info_user.account_blocked === 0) {
        if (!details.has_liked) {
          dispatch(likeProject(popkey, details.id_budget_part_project));
        } else {
          dispatch(unlikeProject(popkey, details.id_budget_part_project));
        }
      } else if (info_user.account_blocked === 1) setForbidenUserModal(true);
      else setNotConfirmedUserModal(true);
    } else {
      setConnect(true);
    }
  };

  const onValidSubscribe = () => {
    BudgetPartAPI.subscribeBdg(
      popkey,
      budget_part_session.id_budget_part_session
    )
      .then(() => {
        setSubcribe(false);
        //onSend();
        toggleVote();
      })
      .catch((error) => {
        setSubcribe(false);
        console.log(error);
        alert("Une erreur est survenue");
      });
  };

  const toggleVote = () => {
    if (popkey) {
      if (info_user.account_blocked === 0) {
        if (!details.has_voted) {
          if (budget_part_session.nbvote - nb_votes_user > 0) {
            BudgetPartAPI.voteProject(
              popkey,
              details.id_budget_part_project
            ).then((data) => {
              if (data && data.data.status === 1) {
                dispatch({
                  type: "VOTE_PROJECT",
                  payload: details.id_budget_part_project,
                });
              }

              if (data && data.data.status === 2) setSubcribe(true);
            });
          } else {
            setMaxVoteModal(true);
          }
        } else {
          dispatch(unvoteProject(popkey, details.id_budget_part_project));
        }
      } else if (info_user.account_blocked === 1) {
        setForbidenUserModal(true);
      } else if (info_user.account_blocked === 2) {
        setNotConfirmedUserModal(true);
      }
    } else setConnect(true);
  };

  const onSendComment = (text) => {
    if (popkey) {
      if (info_user.account_blocked === 0) {
        if (text.trim().length > 1) {
          const id_ref = userResponse ? userResponse.id_comment : null;
          dispatch(
            postComment(popkey, details.id_budget_part_project, id_ref, text)
          );
          if (id_ref === null) {
            setTimeout(() => {
              var element = document.getElementById("modalScroller");
              element.scrollTop = element.scrollHeight - element.clientHeight;
            }, 300);
          }

          setUserResponse(null);
        }
      } else if (info_user.account_blocked === 1) {
        setForbidenUserModal(true);
      } else if (info_user.account_blocked === 2) {
        setNotConfirmedUserModal(true);
      }
    } else {
      setConnect(true);
    }
  };

  const removeToken = () => {
    setUserResponse(null);
  };

  const onResponse = (pseudo, id_comment) =>
    setUserResponse({ pseudo: pseudo, id_comment: id_comment });

  const onDeleteComment = (id_comment, id_ref) => {
    dispatch(removeComment(id_comment, id_ref, details.id_budget_part_project));
  };

  const onReport = () => console.log("report");

  useEffect(() => {
    page.current = 0;
    dispatch(
      getProjectDetails(
        popkey,
        QueryString.parse(location.search).id_budget_part_project
      )
    );
  }, [popkey]);

  return (
    <FullModal
      onBack={onBack}
      id="modal_bdg_details"
      height={"97%"}
      textHeader="Projet de budget participatif"
      footer={
        <>
        <CommentInput
        onPress={onSendComment}
        removeToken={removeToken}
        userResponse={userResponse}
        avatar={info_user && info_user.avatar}
      />

      {connect && <Connexion onBack={onBackConnect} />}

      {forbidenUserModal && (
        <MessageAlerte
          close={() => setForbidenUserModal(false)}
          text={
            "Votre compte a été bloqué par la ville ou l'équipe popvox car vous ne respectiez pas les CGU du service. Pour plus d'informations, vous pouvez contacter les équipes popvox (contact@popvox.fr)."
          }
          textHeader={"Compte bloqué"}
        />
      )}

      {notConfirmedUserModal && (
        <AccountNotConfirmedAlert
          close={() => setNotConfirmedUserModal(false)}
        />
      )}

      {maxVoteModal && (
        <MessageAlerte
          close={() => setMaxVoteModal(false)}
          text={
            "Vous avez atteint le nombre maximum de votes pour cette session de budget participatif !"
          }
          textHeader={"Nombre de votes"}
        />
      )}

      {subscribe && (
        <SubscribeModal
          nom_comm={infoCity.nom_comm}
          age_limit={budget_part_session.age_limit}
          onBack={() => setSubcribe(false)}
          onValid={onValidSubscribe}
        />
      )}
      </>
      }>
        
      
        {details && <BaseDetails item={details} />}

        <div
          className={css`
            display: flex;
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
            margin-top: 20px;
          `}>
          {details && (
            <TokenTitle
              style={{ marginLeft: "10px" }}
              text={details.budget_part_session_title}
              colorText={"rgba(41, 53, 74,1)"}
              background={"#F5F8FA"}
            />
          )}

          {details && (
            <TokenTitle
              style={{ marginLeft: "10px" }}
              text={getRubricName()}
              colorText={"rgba(41, 53, 74,1)"}
              background={"#F5F8FA"}
            />
          )}

          {details && (
            <TokenTitle
              style={{ marginLeft: "10px" }}
              text={holder[details.project_holder]}
              colorText={"rgba(41, 53, 74,1)"}
              background={"#F5F8FA"}
            />
          )}
        </div>

        <div
          className={css`
            margin-top: 20px;
            box-sizing: border-box;
            padding-left: 30px;
            padding-right: 20px;
            color: #29354a;
            font-size: 18px;
            font-weight: 600;
          `}>
          {details.title}
        </div>

        {details && (
          <>
            <RubricText title="Objectif du Projet" text={details.objectiv} />

            <RubricText title="Description" text={details.synthese} />

            {details.budget !== 0 && (
              <RubricText
                title="Budget estimé"
                text={`${details.budget} euros`}
              />
            )}

            {details.lat && details.lat !== null && (
              <ProjectMap lon={details.lon} lat={details.lat} type={3} />
            )}

            <Footer
              toggleLike={toggleLike}
              has_liked={details ? details.has_liked : false}
              comments={details ? details.comments : 0}
              likes={details ? details.likes : 0}
              toggleVote={toggleVote}
              current_phase={statusProject ? statusProject : 1}
              votes={details.votes}
              has_voted={details.has_voted}
              project_status={details.project_status}
            />
          </>
        )}

        <div style={{ marginTop: "30px" }}>
          {pubanswers &&
            pubanswers.map((item, index) => {
              return (
                <FullAdminResponse
                  key={"ADMINRESP" + index}
                  logo_city={item.urlimg}
                  text={item.description}
                  file_url={item.file_url}
                  type_user={item.type_user}
                  nom_comm={item.nom_comm}
                  date_creation={item.date_creation}
                />
              );
            })}
        </div>

        {!endReachedComment && !loadingComment ? (
          <div
            className={css`
              cursor: pointer;
              color: rgb(242, 101, 35);
              font-size: 14px;
              margin-right: 20px;
              text-align: right;
              margin-top: 20px;
              &:hover {
                text-decoration: underline;
              }
            `}
            onClick={() => getMoreComments()}>
            Commentaires plus anciens
          </div>
        ) : (
          loadingComment && (
            <div style={{ textAlign: "center" }}>
              <img
                style={{ width: 70, height: 70, marginTop: 30 }}
                src={require("../../../../image/loading.gif")}
                alt="loading"
              />
            </div>
          )
        )}

        <ListComment
          onResponse={onResponse}
          onDelete={onDeleteComment}
          onReport={onReport}
          comments={comments}
        />
      

      
    </FullModal>
  );
};

export default Details;
