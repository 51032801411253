import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyVote = (props) =>{
    return(
      <IconPattern size={props.size}>
      <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
          `}viewBox="0 0 32 32">
        <path
          fill={props.color}
           d="M5,11l-1,0c-0.536,-0.025 -0.975,-0.46 -1,-1l0,-4c0.025,-0.537 0.451,-0.975 1,-1l24,0c0.031,0.001 0.062,0.003 0.092,0.004c0.5,0.07 0.884,0.483 0.908,0.996l0,4c-0.025,0.536 -0.46,0.975 -1,1l-21,0l0,14l18,0l0,-11c0.039,-0.882 0.48,-0.958 0.913,-0.996c0.491,-0.043 0.985,0.33 1.072,0.822c0.013,0.077 0.012,0.097 0.015,0.174l0,12c-0.023,0.537 -0.462,0.977 -1,1l-20,0c-0.537,-0.023 -0.977,-0.462 -1,-1l0,-15Zm15,4l-8,0c-0.862,-0.024 -1.434,-1.419 -0.434,-1.901c0.129,-0.062 0.204,-0.093 0.434,-0.099l8,0c0.019,0.001 0.037,0.001 0.056,0.002c0.229,0.019 0.302,0.053 0.428,0.123c0.739,0.408 0.554,1.846 -0.484,1.875Zm-15,-8l0,2l22,0l0,-2l-22,0Z"
           />
      </svg>
    </IconPattern>
    )
}

export default IconEasyVote;
