import React, {useRef} from 'react';
import ImageHome from './image-home/ImageHome';
import PublicationFlux from '../publication-flux/PublicationFlux';
import ParticipationFlux from '../participation-flux/ParticipationFlux';

const Home = props => {

  return (
    <>
      <div
        style={{
          overflowY: 'scroll',
        }}>
        <ImageHome />
        <PublicationFlux idCommunity={null} />
        <ParticipationFlux idCommunity={null} />

      </div>
    </>
  );
};

export default Home;
