import React from 'react';
import IconEasyStars from '../../../../common/icons/IconEasyStars';
import IconEasyHalfStars from '../../../../common/icons/IconEasyHalfStars';

const biggestProperty = obj => {
  let maxVal;
  let maxKey;
  for (let [key, value] of Object.entries(obj)) {
    if (!maxVal || value > maxVal) {
      maxVal = value;
      maxKey = key;
    }
  }
  return [maxKey, maxVal];
};

const arrayStar = [1, 2, 3, 4, 5];

const BlockEval = props => {
  const _renderStar = avg => {
    return (
      avg &&
      arrayStar.map((item, nb) => {
        return (
          <div key={'BE' + nb}>
            {item <= avg ? (
              <IconEasyStars color={'black'} size={10} />
            ) : 0 < item - avg && item - avg < 1 ? (
              <IconEasyHalfStars color={'black'} size={10} />
            ) : (
              <IconEasyStars color={'grey'} size={10} />
            )}
          </div>
        );
      })
    );
  };

  const _renderBar = myObject => {
    const bigProperty = biggestProperty(myObject);
    return Object.keys(myObject)
      .map((key, index) => {
        return (
          <div
            key={'bar' + index}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
            }}>
            <div style={{width: 15}}>
              <span style={{color: 'grey', fontSize: 16, textAlign: 'center'}}>
                {key}
              </span>
            </div>
            <div
              style={{
                width: `${(myObject[key] / bigProperty[1]) * 100}%`,
                height: 15,
                borderTopRightRadius: 3,
                borderBottomRightRadius: 3,
                backgroundColor:
                  parseInt(key,10) === 5
                    ? '#7cc8a2'
                    : parseInt(key,10) === 4
                    ? '#afd68c'
                    : parseInt(key,10) === 3
                    ? '#FED759'
                    : parseInt(key,10) === 2
                    ? '#FDB153'
                    : parseInt(key,10) === 1
                    ? '#FD8D63'
                    : false,
                marginLeft: 5,
              }}
            />
          </div>
        );
      })
      .reverse();
  };

  return (
    <div style={{flex: 1, flexDirection: 'row', display: 'flex'}}>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          width: 70,
        }}>
        <div style={{fontSize: 37, color: '#444444'}}>
          {props.item.avg ? Number(props.item.avg.toFixed(1)) : 0}
        </div>
        <div style={{flexDirection: 'row', display: 'flex'}}>
          {props.item.avg && _renderStar(props.item.avg)}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          paddingRight: 20,
          marginLeft: 30,
          display: 'flex',
          flexDirection: 'column',
        }}>
        {props.item.eval && _renderBar(props.item.eval)}
      </div>
    </div>
  );
};

export default BlockEval;
