import React from "react";
import { css } from "emotion";

import {
  participationTypesFr,
  participationSlogansFr,
} from "../../../../config/thematics";
import TypeIconParticipation from "../../../participations/TypeIconParticipation";

const ItemMenuParticipate = (props) => {
  if (participationTypesFr[props.id]) {
    return (
      <div
        onClick={() => {
          props.onPress(props.id);
        }}
        className={css`
          background: white;
          box-sizing: border-box;
          width: 100%;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          display: flex;
          &:hover {
            box-shadow: 3px 3px 14px 0px rgba(41, 53, 74, 0.1);
          }
        `}>
        <div
          style={{
            width: "100%",
            maxWidth: 450,
            padding: 25,
            flexDirection: "row",
            display: "flex",
            marginLeft: 10,
            marginRight: 10,
            borderBottom: "1px solid #f2f2f2",
          }}>

          <TypeIconParticipation size={60} type={props.id} />

          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 10%;
            `}>
            <div
              className={css`
                font-size: 18px;
                font-weight: 500;
              `}>
              {participationTypesFr[props.id]}
            </div>
            <div
              className={css`
                color: grey;
                font-size: 15px;
                height: 30px;
                line-height: 1.1;
                margin-top: 5px;
                display: flex;
                justify-content: center;
              `}>
              {participationSlogansFr[props.id]}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return false;
};

export default ItemMenuParticipate;
