import React from 'react';
import {css} from 'emotion';

const titleString = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const stripHtml = (html) =>
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

const ContentCardGroup = props => {
  return (
    <div
      className={css`
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
      `}>
      <div
        className={css`
          text-align: left;
          margin-top: 10px;
          font-weight: 600;
          color: #183e64;
          font-size: 18px;
        `}>
        {titleString(props.title)}
      </div>
      <div
        className={css`
          text-align: left;
          margin-top: 10px;
          color: #a0a6b0;
        `}>
        {stripHtml(props.description)}
      </div>
    </div>
  );
};

export default ContentCardGroup;
