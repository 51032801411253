import axios from "axios";
import { server_api_url } from "../config/constants";


class NotificationAPI{

  static getNotification(popkey,page) {
    return axios.get(server_api_url + "/notification/all" , {
      params: {
        popkey: popkey,
        page:page
      }
    });
  }

  static readNotifications(popkey) {
    return axios.post(server_api_url + "/notification/read", {
      popkey: popkey
    });
  }

  static readOneNotification(popkey,id_users_message) {
    return axios.post(server_api_url + "/notification/readonenotifaction",{
      popkey:popkey,
      id_users_message:id_users_message
    })
  }

  static deleteNotification(popkey, id_users_message) {
    return axios.post(server_api_url + "/notification/deletenotification",{
      popkey:popkey,
      id_users_message:id_users_message
    })
  }

}


export default NotificationAPI;
