import React from "react";

import AppLoader from "./components/app-start/AppLoader";

import {
  Router,
  Route,
} from "react-router-dom";
import history from "./utils/history";

function App() {

  history.listen((location) => {
    if (
      location &&
      location.state &&
      location.state.hasOwnProperty("from") &&
      location.state.from === "menu"
    ) {
      window.scrollTo(0, 0);
    }
  });
  
  return (
    <div className="App">
      <Router history={history}>
        <Route path="/" component={AppLoader} />
      </Router>
    </div>
  );
}

export default App;
