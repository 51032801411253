import React from "react";
import { css } from "emotion";
import IconPattern from "./IconPattern";

const IconEasyFeel = (props) => {
  return (
    <IconPattern size={props.size}>
      <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
        `}
        viewBox="0 0 100 100">
        <path
          fill={props.color}
          d="M32.893,19.639 C37.561,20.277 35.586,19.858 38.86,20.68 C43.416,22.003 46.86,24.938 49.775,28.575 C52.466,24.689 56.321,22.238 60.688,20.68 C69.173,17.988 78.226,20.52 84.031,28.125 L84.032,28.125 C90.366,36.423 90.45,47.725 84.548,58.771 C78.54,70.014 66.848,80.213 51.08,87.415 L49.774,88.012 L48.468,87.415 C32.7,80.213 21.008,70.014 15,58.772 C9.097,47.725 9.181,36.423 15.516,28.125 C19.833,22.661 24.155,20.646 30.856,19.682 L32.893,19.639 z M33.674,26.153 C32.517,26.183 31.359,26.178 30.201,26.167 C26.169,26.844 23.1,28.898 20.532,32.022 C7.254,52.579 33.62,73.286 49.774,80.976 C65.352,73.874 92.746,51.746 79.016,32.022 C74.598,26.542 69.446,25.124 62.587,26.773 C56.854,28.592 52.941,33.266 52.941,38.558 L46.61,38.558 C46.61,33.266 42.696,28.592 36.962,26.773 C36.16,26.621 35.384,26.316 34.575,26.206 C34.277,26.165 33.974,26.171 33.674,26.153 z"
        />
      </svg>
    </IconPattern>
  );
};

export default IconEasyFeel;
