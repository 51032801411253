import React from 'react';
import { css } from 'emotion';

const IconPattern = (props) =>{
  return(
    <div className={css`width:${props.size}px;height:${props.size}px;`}>
      <div className={css`
              display: flex;
              align-items:center;
              justify-content:center;
              position: relative;
          `}>
            {props.children}
      </div>
    </div>
  )
}


export default IconPattern
