import React from 'react';
import {css} from 'emotion';


const ButtonIcon = (props) =>{
  return(
    <div
      onClick={props.onPress}
      className={css`
        ${props.styles};
        border-radius:${props.radius};
        background:${props.color};
        height:${props.height};
        width:${props.width};
        display:flex;
        align-items:center;
        justify-content:center;
        cursor:pointer;
        &:hover{
          background:${props.hoverColor}
        }
      `}
    >
        {props.icon}
    </div>
  )
}
export default ButtonIcon
