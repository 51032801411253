import axios from 'axios';
import { server_api_url } from "../config/constants";

class ParticipationAPI {

  static getListParticipation(page, popkey, code_insee, order, types, getMyOnly, idCommunity) {
    return axios.get(server_api_url + '/participation/all', {
      params: {
          popkey:popkey,
          codeinsee:code_insee,
          page:page,
          order: order,
          toDisplay: types,
          getMyOnly: getMyOnly,
          idCommunity: idCommunity
      }
    });
  }

  static getMyParticipations(page, popkey) {
    return axios.get(server_api_url + '/participation/my', {
      params: {
          popkey:popkey,
          page:page,
      }
    });
  }


  static getDetailedCitizenpart(popkey, id_citizenpart, page) {
    return axios.get(server_api_url + "/participation/details", {
      params: {
        popkey: popkey,
        id_citizenpart: id_citizenpart,
        page: page
      }
    });
  }

  static getMoreComment(page,popkey,id_citizenpart){
    return axios.get(server_api_url + "/participation/comments", {
      params: {
        id_citizenpart:id_citizenpart,
        page: page,
        popkey: popkey
      }
    });
  }

  static postComment(popkey, id_citizenpart, ref_id_comment, description) {
  return axios.post(server_api_url + '/participation/postcomment',{
    popkey:popkey,
    id_citizenpart: id_citizenpart,
    ref_id_comment: ref_id_comment,
    description:description
  })
}

static deleteComment(id_comment,ref_id_comment) {
  return axios.post(server_api_url+ "/participation/deletecomment",{
    id_comment:id_comment,
    ref_id_comment:ref_id_comment
  })
}


static postSubscription(popkey,id_citizenpart){
  return axios.post(server_api_url+ "/participation/postsubscription",{
    popkey:popkey,
    id_citizenpart:id_citizenpart
  })
}

  static likeCitizenpart(popkey, id_citizenpart) {
    return axios.post(server_api_url + "/participation/like", {
      popkey: popkey,
      id_citizenpart: id_citizenpart
    });
  }

  static unlikeCitizenpart(popkey, id_citizenpart) {
    return axios.post(server_api_url + "/participation/unlike", {
      popkey: popkey,
      id_citizenpart: id_citizenpart
    });
  }

  static getHastagByTheme(id_theme,codeinsee) {
    return axios.get(server_api_url + "/participation/hastageproposal", {
      params: {
        id_theme: id_theme,
        codeinsee: codeinsee,
      }
    });

  }


  static sendParticipation(userKey, participation, insee_com) {
    return axios.post(server_api_url + "/participation/newparticipation", {
      popkey: userKey,
      participation: participation,
      codeinsee: insee_com
    });
  }

  static removeParticipation(popkey,id_citizenpart){
    return axios.post(server_api_url + "/participation/removeparticipation",{
      popkey:popkey,
      id_citizenpart:id_citizenpart
    })
  }



  }



export default ParticipationAPI;
