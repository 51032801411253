import PublicationAPI from '../../api/PublicationAPI';


export function getOnTheFrontPage(popkey, codeinsee, idCommunity) {
  return function(dispatch) {
    dispatch({type: 'GET_ON_THE_FRONT_LOADING'});
    return PublicationAPI.getOnTheFrontPage(popkey, codeinsee, idCommunity).then(data => {
      dispatch({
        type: 'GET_ON_THE_FRONT_SUCCESS',
        payload: data.data.consultations
      });
    });
  };
}

export function clearPublications() {
  return function(dispatch) {
    dispatch({type: 'CLEAR_PUBLICATIONS'});
  };
}


export function getNews(popkey, codeinsee, page, idCommunity) {

  return function(dispatch) {
    if (page === 0) {
      dispatch({type: 'GET_LIST_LOADING'});
    } else {
      dispatch({type: 'GET_MORE_LIST_LOADING'});
    }

    return PublicationAPI.getNews(popkey, codeinsee, page, idCommunity)
      .then(data => {

        if (page === 0) {
          return dispatch({
            type: 'GET_NEWS_LIST_SUCCESS',
            payload: {
              news: data.data.consultations,
              endReached: data.data.endReached,
            },
          });
        } else {
          return dispatch({
            type: 'GET_MORE_NEWS_LIST_SUCESS',
            payload: {
              news: data.data.consultations,
              endReached: data.data.endReached,
            },
          });
        }
      })
      .catch(() => console.log('error'));
  };
}

export function getEvents(popkey, codeinsee, page, idCommunity) {
  return function(dispatch) {
    if (page === 0) {
      dispatch({type: 'GET_LIST_LOADING'});
    } else {
      dispatch({type: 'GET_MORE_LIST_LOADING'});
    }
    return PublicationAPI.getEvents(popkey, codeinsee, page, idCommunity).then(data => {
      if (page === 0)
        dispatch({
          type: 'GET_EVENT_ALL_SUCCESS',
          payload: {events: data.data.consultations, endReached: data.data.endReached},
        });
      else {
        dispatch({
          type: 'GET_MORE_EVENT_ALL_SUCCESS',
          payload: {events: data.data.consultations, endReached: data.data.endReached},
        });
      }
    });
  };
}

export function getSurveys(popkey, codeinsee, page, idCommunity) {
  return function(dispatch) {
    if (page === 0) {
      dispatch({type: 'GET_LIST_LOADING'});
    } else {
      dispatch({type: 'GET_MORE_LIST_LOADING'});
    }
    return PublicationAPI.getSurveys(popkey, codeinsee, page, idCommunity).then(data => {

      if (page === 0) {
        dispatch({
          type: 'GET_SURVEY_LIST_SUCCESS',
          payload: {
            surveys: data.data.consultations,
            endReached: data.data.endReached,
          },
        });
      } else {
        dispatch({
          type: 'GET_MORE_SURVEY_LIST_SUCCESS',
          payload: {
            surveys: data.data.consultations,
            endReached: data.data.endReached,
          },
        });
      }
    });
  };
}

