import React, {useState} from 'react';
import {css} from 'emotion';
import HeaderCard from './components/HeaderCard';
import ContentCard from './components/ContentCard';
import FooterCard from './components/FooterCard';
import AdminResponse from './components/AdminResponse';
import history from '../../../utils/history';
import ModalMore from './components/ModalMore';
import CardOptions from './CardOptions';

const CardParticipation = props => {
  const [more, setMore] = useState(false);
  const [option, setOption] = useState(0);

  const goToPageDetails = () => {

    history.push({
      pathname: '/participation',
      search: `?id_citizenpart=${props.item.id_citizenpart}`,
      state: {
        modal: true,
      },
    });

  };

  const onMore = e => {
    e.stopPropagation();
    e.preventDefault();
    setMore(true);
  };

  const onHideModal = e => {
    e.stopPropagation();
    setMore(false);
  };

  const onOption = (e, option) => {
    e.stopPropagation();
    setMore(false);
    setOption(option);
  };

  const onBackOption = e => {
    e.stopPropagation();
    setOption(0);
  };

  const onSharing = e => {
    e.stopPropagation();
    setOption(4);
  };


  return (

    <div
      onClick={goToPageDetails}
      className={css`
        float: left;
        width: calc(50% - 20px);
        @media (max-width: 1510px) {
          width: calc(100% - 20px);
        }
        margin-left: 10px;
        margin-right: 10px;
        position: relative;
        background: white;
        margin-top: 20px;
        border-radius: 4px;
        box-sizing: border-box;
        &:first-child {
          margin-top: 0px;
        }
        box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.04);
        cursor: pointer;
        &:active {
          -webkit-transform: scale(1.01);
          transform: scale(1.01);
        }
        `}>

      {more && (
        <ModalMore
          id_citizenpart={props.item.id_citizenpart}
          is_my={props.item.is_my}
          onOption={onOption}
          onHideModal={onHideModal}
          private={props.item.private}
          community={props.item.community}
          participation={props.item}
        />
      )}

      {option !== 0 &&
        <CardOptions
          onSharing={onSharing}
          option={option}
          id_citizenpart={props.item.id_citizenpart}
          onBack={onBackOption}
        />
      }

      <HeaderCard
        avatar={props.item.avatar}
        nom_comm={props.item.nom_comm}
        type={props.item.type}
        onMore={onMore}
        pseudo={props.item.pseudo}
        date_creation={props.item.date_creation}
      />
      <ContentCard
        type={props.item.type}
        community={props.item.community}
        file_url={props.item.file_url}
        status={props.item.status}
        description={props.item.description}
        private_card={props.item.private}
      />
      
      {props.item.lastanswer && (
        <AdminResponse
          logo_city={props.item.urlimg}
          text={props.item.lastanswer}
        />
      )}
      <FooterCard
        file_url={props.item.file_url}
        has_liked={props.item.has_liked}
        comments={props.item.comments}
        likes={props.item.likes}
        status={props.item.status}
        private_card={props.item.private}
        community={props.item.community}
        item={props.item}
      />
    </div>

  );

};

export default CardParticipation;
