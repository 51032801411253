import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyLike = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          display:flex;
          top: 0;
          left: 0`}
        version="1.1" viewBox="0 0 32 32">
        <path
         fill={props.color}
         d="M21.369,5.033c2.123,0.045 4.109,1.388 4.993,3.431c1.064,2.458 0.662,5.304 -0.215,7.74c-1.645,4.568 -5.441,7.895 -9.528,10.598c-0.385,0.255 -0.915,0.226 -1.48,-0.152c-2.699,-1.815 -5.286,-3.883 -7.203,-6.454c-1.886,-2.53 -3.029,-5.712 -2.93,-8.788c0.064,-2.006 0.81,-4.113 2.502,-5.332c2.276,-1.639 5.659,-1.113 7.705,1.143c0.743,0.817 1.401,1.751 1.732,2.786c0.009,0.028 0.017,0.056 0.023,0.085c0.163,0.719 -0.699,1.486 -1.433,1.108c-0.155,-0.08 -0.288,-0.201 -0.382,-0.348c-0.283,-0.44 -0.899,-1.87 -1.859,-2.728c-0.912,-0.816 -2.185,-1.249 -3.359,-0.988c-1.495,0.333 -2.557,1.76 -2.832,3.346c-0.407,2.338 0.385,4.788 1.441,6.931c1.046,2.099 2.809,3.779 4.652,5.306c0.888,0.735 1.813,1.423 2.763,2.074l0.034,0.024c2.997,-2.044 6.03,-4.417 7.518,-7.546c1.169,-2.318 1.906,-4.995 1.242,-7.37c-0.593,-2.124 -3.2,-3.739 -5.486,-2.234c0,0 -0.638,0.314 -1.095,0.036c-0.591,-0.36 -0.607,-1.316 0.048,-1.74c0.898,-0.581 1.959,-0.917 3.031,-0.929c0.059,0 0.059,0 0.118,0.001Z"/>
        </svg>
        </IconPattern>
    )
}

export default IconEasyLike;
