import React, {useEffect, useState} from 'react';
import {css} from 'emotion';
import {useDispatch, useSelector} from 'react-redux';
import {getCommunity} from '../../redux/actions/groupActions';

import GroupInfo from './components/group-info/GroupInfo';

import PublicationFlux from "../publication-flux/PublicationFlux";
import ParticipationFlux from "../participation-flux/ParticipationFlux";

import GroupeNotSubscribe from './components/groupe-notsubcribe/GroupeNotSubscribe';
import {
  getUserHasSubscribeGroupe,
  unsubscribeUser,
  subscribeUser,
  sendRqst,
} from '../../redux/actions/groupActions';

import {
  add_user_community,
  remove_user_community,
} from '../../redux/actions/userActions';

import QueryString from 'query-string';
import HeaderPage from '../common/material-easy-ui/material/HeaderPage';
import history from '../../utils/history';
import GroupQuestion from './components/group-question/GroupQuestion';
import Alerte from '../common/material-easy-ui/modal/Alerte';

import Connexion from '../connexion/Connexion';

const GroupPage = props => {
  const dispatch = useDispatch();
  const [showQuestion, setShowQuestion] = useState(false);
  const [alert, setAlert] = useState(false);
  const popkey = useSelector(state => state.user.popkey);
  const userhasSubscribeGroupe = useSelector(
    state => state.group.userHasSubscribeGroupe,
  );
  const details = useSelector(state => state.group.community);
  const questions = useSelector(state => state.group.question);
  const infoCity = useSelector(state => state.city.infoCity);
  //const communities = useSelector(state => state.user.communities);
  const [connect, setConnect] = useState(false);

  const onBack = () => {
    setTimeout(() => {
      dispatch({ type: "CLEAN_GROUP_DETAILS" });
    }, 300);

    if (props.location.state && props.location.state.from === 'app') {
      history.goBack();
    }
    history.replace({pathname: '/communautes'});
  };




  useEffect(() => {
    dispatch(
      getCommunity(popkey, QueryString.parse(props.location.search).id_group),
    );
    dispatch(
      getUserHasSubscribeGroupe(
        popkey,
        QueryString.parse(props.location.search).id_group,
      ),
    );
    return () => {
      dispatch({type: 'CLEAN_COMMUNITY_PAGE'});
    };
  }, []);

  useEffect(() => {
    dispatch(
      getCommunity(popkey, QueryString.parse(props.location.search).id_group),
    );
    dispatch(
      getUserHasSubscribeGroupe(
        popkey,
        QueryString.parse(props.location.search).id_group,
      ),
    );
  }, [popkey]);

  const onSubscribed = (answer, photoSource, idQuestion) => {
    if (details.type === 2) {
      dispatch(
        sendRqst(popkey, details.id, idQuestion, answer, photoSource)
      ).then(() => {
        setShowQuestion(false);
      });
    } else {
      dispatch(
        subscribeUser(popkey, details.id, idQuestion, answer, photoSource)
      ).then(() => {
        setShowQuestion(false);
      });
    }
  };

  const onBackQuestion = () => {
    setShowQuestion(false);
  };

  const onSubscribe = () => {
    if (popkey) {
      if (details.subscribed) setAlert(true);
      else if (details.subscribed === 0 && details.requested === 1) {
        setAlert(true);
      } else {
        if (details.has_questions) {
          setShowQuestion(true);
        } else {
          if (details.type !== 2) {
            dispatch(subscribeUser(popkey, details.id, null, null, null));
            let myNewCommunity = { id: details.id, title : details.title, code_insee : infoCity.insee_com};
            dispatch(add_user_community(myNewCommunity));
          } else  {
            dispatch(sendRqst(popkey, details.id, null, null, null));
          }
        }
      }
    } else {
      setConnect(true);
    }
  };

  const removeAlert = () => {
    setAlert(false);
  };

  const onChoose = value => {
    setAlert(false);
    if (value) {
      dispatch(unsubscribeUser(popkey, details.id));
      let myNewCommunity = { id: details.id, title : details.title, code_insee : infoCity.insee_com};
      dispatch(remove_user_community(myNewCommunity));
    }
  };

  const onBackConnect = () => {
    setConnect(false);
  };

  return (
    <div
      className={css`
        width: inherit;
        height: 100%;
      `}>
      <HeaderPage title="Communauté" onBack={onBack} />

      {details &&
            <GroupInfo
            onSubscribe={onSubscribe}
            details={details}
          />
      }


      {userhasSubscribeGroupe ? (
        <>
          <PublicationFlux idCommunity={QueryString.parse(props.location.search).id_group} />
          <ParticipationFlux idCommunity={QueryString.parse(props.location.search).id_group} /> 
          <br/><br/><br/>
        </>
      ) : (
        <GroupeNotSubscribe />
      )}
      {alert && (
        <Alerte
          btnText={"Désinscrire"}
          onChoose={onChoose}
          onBack={removeAlert}
          text={'Êtes-vous certain de vouloir vous désincrire ?'}
          textHeader={'Attention'}
        />
      )}
      {showQuestion && (
        <GroupQuestion
          question={questions}
          onSubscribed={onSubscribed}
          onBackQuestion={onBackQuestion}
        />
      )}
      {connect && <Connexion onBack={onBackConnect} />}
    </div>
  );
};

export default GroupPage;
