import React, {useState} from 'react';
import FullModal from '../../../common/material-easy-ui/modal/FullModal';

import history from '../../../../utils/history';
import axios from 'axios';

import InputTextArea from '../../../budgetpart/components/post/components/InputTextArea';
import InputFile from '../../../budgetpart/components/post/components/InputFile';
import {server_api_url} from '../../../../config/constants.js';

import {useSelector, useDispatch} from 'react-redux';
import { updateParticipation } from '../../../../redux/actions/participationActions';

const get = function(obj, key) {
  return key.split('.').reduce(function(o, x) {
    return typeof o === 'undefined' || o === null ? o : o[x];
  }, obj);
};

export default function CardParticipation(location, ...props) {
  const dispatch = useDispatch();

  const popkey = useSelector(state => state.user.popkey);

  const [description, setDescription] = useState(location.location.state.participation.description);
  const [uri, setUri] = useState(location.location.state.participation.file_url);

  const onBack = () => {
    if (get(location, 'state.modal')) {
      history.goBack();
      setTimeout(() => { dispatch({type: 'CLEAN_PARTICIPATION'}); }, 300);
    } else {
      history.push('/');
      setTimeout(() => { dispatch({type: 'CLEAN_PARTICIPATION'}); }, 300);
    }
  };

  const sendModifications = () => {
    var clone_participation = {...location.location.state.participation};
    clone_participation = {
      ...clone_participation,
      description: description,
      file_url: uri
    };

    axios
      .post(server_api_url + '/participation/editparticipation', {
        popkey: popkey,
        id_citizenpart: location.location.state.participation.id_citizenpart,
        description: description,
        file_url: uri
      })
      .then((res) => res.data)
      .then(() => {
        dispatch(updateParticipation(clone_participation));
        onBack();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <FullModal 
      onBack={onBack} 
      textHeader="Modifier une participation" 
      height={'97%'}>

      <div style={{margin: 20}}>
        <InputTextArea
          style={{ marginTop: 30 }}
          value={description}
          onChange={(e) => {setDescription(e.target.value)}}
          title={'Description libre'}
        />

        <InputFile
          onClearFileUrl={() => {setUri(null)}}
          url={server_api_url + "/fileandimageuploads/participationimage"}
          onPost={(value) => {setUri(value.pathName)}}
          title={'Modifier l\'image'}
          style={{ marginTop: 30, width: '100%' }}
          fileUrl={uri}
        />

      </div>

      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
        <div onClick={sendModifications}
          style={{border: "2px solid #f55d42", cursor: "pointer", padding: (8, 15, 8, 15), borderRadius: 20, marginRight: 20, marginBottom : 20}}>
          Envoyer
        </div>
      </div>

    </FullModal>
  )
}