import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyGeolocation = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
        version="1.1" viewBox="0 0 100 100">
          <path d="M50,33.636c-9.018,0-16.333,7.31-16.333,16.331c0,9.018,7.315,16.33,16.333,16.33   c9.02,0,16.333-7.313,16.333-16.33C66.333,40.946,59.02,33.636,50,33.636z" fill={props.color}/>
          <path d="M99.966,45.815H84.64c-1.901-15.93-14.559-28.59-30.488-30.486V0h-8.301v15.33   C29.918,17.226,17.26,29.886,15.36,45.815H0.035v8.3H15.36c1.899,15.933,14.558,28.59,30.49,30.485v15.327h8.301V84.601   c15.93-1.896,28.587-14.553,30.488-30.485h15.326V45.815z M50,76.576c-14.673,0-26.61-11.937-26.61-26.609   c0-14.673,11.937-26.61,26.61-26.61c14.673,0,26.609,11.937,26.609,26.61C76.609,64.64,64.673,76.576,50,76.576z" fill={props.color}/>
         </svg>
    </IconPattern>
    )
}

export default IconEasyGeolocation;
