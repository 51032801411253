import React, {useState, useEffect} from 'react';
import {css} from 'emotion';
import IconEasyRespond from '../../icons/IconEasyRespond';
import IconEasyCross from '../../icons/IconEasyCross';

const TokenResponse = ({userResponse, ...props}) => {
  const [userResponseState, setUserResponse] = useState(null);

  useEffect(() => {
    setUserResponse(userResponse);
  }, [userResponse]);

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        padding-left: 80px;
        align-items: center;
        flex: 1;
        justify-conter: center;
        margin-top: 2px;
      `}>
      <IconEasyRespond size={15} color={'grey'} />
      <div
        className={css`
          font-size: 13px;
          font-style: italic;
          color: grey;
          margin-left: 10px;
          margin-right: 10px;
        `}>{`répondre à ${userResponseState}...`}</div>
      <IconEasyCross
        onPress={props.removeToken}
        pointer
        size={25}
        color={'#f55d42'}
      />
    </div>
  );
};

export default TokenResponse;
