import React from 'react';
import {css} from 'emotion';
import {Marker} from 'react-mapbox-gl';
import IconEasyParticipate from '../../../../../common/icons/IconEasyParticipate';
import IconEasyWarning from '../../../../../common/icons/IconEasyWarning';
import IconEasyAppreciate from '../../../../../common/icons/IconEasyAppreciate';
import IconEasyImagine from '../../../../../common/icons/IconEasyImagine';
import IconEasyQuestion from '../../../../../common/icons/IconEasyQuestion';
import IconEasyMore from '../../../../../common/icons/IconEasyMore';


// const typeToColor = type => {
//   switch (type) {
//     case 1:
//       return 'rgba(204, 49, 67,1)';
//     case 2:
//       return 'rgba(50, 155, 168,1)';
//     case 3:
//       return 'rgba(52, 207, 106,1)';
//     case 4:
//       return 'rgba(209, 107, 160,1)';
//     case 6:
//       return 'rgba(252, 186, 3,1)';
//     default:
//       return '#999999';
//   }
// };


const typeToIcon = type => {
  switch (type) {
    case 1:
      return <IconEasyWarning size={20} color="#FFF" />;
    case 2:
      return <IconEasyAppreciate size={20} color="#FFF" />;
    case 3:
      return <IconEasyImagine size={20} color="#FFF" />;
    case 4:
      return <IconEasyQuestion size={20} color="#FFF" />;
    case 6:
      return <IconEasyParticipate size={20} color="#FFF" />;
    default:
      return <IconEasyMore size={20} color="#FFF" />;
  }
};

const MarkerMap = (props) =>{
  return(
    <Marker coordinates={props.coord}>
      <div className={css`
          background-color:rgb(242, 101, 35);
          padding:5px;
          border-radius:50%;
          -moz-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74,0.1);
          -webkit-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74,0.1);
          -o-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74,0.1);
          box-shadow: 3px 3px 14px 0px rgba(41, 53, 74,0.1);
      `}>
        {
          typeToIcon(props.type)
        }
      </div>
    </Marker>
  )
}

export default MarkerMap
