import React from 'react';
import {css} from 'emotion';

const AdminResponse = props => {

  let replacedText = props.text;
  /*replacedText = reactStringReplace(replacedText, /(https?:\/\/\S+)/g, (match, i) => (
    <a key={match + i} href={match}>{match}</a>
  ));*/

  return (
    <div
      className={css`
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
      `}>


      <div
        className={css`
          background: #F5F8FA;
          border: 1px solid rgba(24,62,100,0.05);
          border-radius: 5px;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
        `}>

        <div
          className={css`
            color: #394861;
            line-height: 1.3;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 16px !important;
            -webkit-text-size-adjust: none;
          `}>
          {replacedText}
        </div>
      </div>
    </div>
  );
};

export default AdminResponse;
