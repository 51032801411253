import React from "react";
import { css } from "emotion";

import { participationRubricsFR } from "../../../../config/thematics";

const ItemRubrics = (props) => {
  // eslint-disable-next-line

  return (
    <div
      onClick={() => {
        props.onChooseRubric(props.id);
      }}
      className={css`
        color: ${props.rubric === props.id ? "rgba(245, 93, 66,1)" : "#333437"};
        background-color: #fff;
        cursor: pointer;
        border: ${props.rubric === props.id
          ? "2px solid rgba(245, 93, 66,1);"
          : "2px solid #e8e8e8;"};
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 5px;
        padding-bottom: 5px;
        box-sizing: border-box;
        color: #5c5c5c;
        margin: 5px;
        border-radius: 10px;
        &:hover {
          opacity: 0.8;
        }
      `}>
      <div style={{ textAlign: "center", fontSize: 13 }}>
        {participationRubricsFR[props.id]}
      </div>
    </div>
  );
};

export default ItemRubrics;
