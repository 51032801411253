import React, { useEffect, useState, useRef } from "react";
import { css } from "emotion";
import Portal from "./components/Portal";
import HeaderModal from "./components/HeaderModal";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import ButtonEX from "../button/ButtonEX";
import { useSelector } from "react-redux";
import axios from "axios";
import { server_api_url } from "../../../../config/constants";
import cadeau from "../../../../image/gift.png";
import "./styles.css";

import Avatar from "../../../common/avatar/Avatar";

const PointsNotification = (props) => {
  const [pointDetails, setPointDetails] = useState(null);
  const popkey = useSelector((state) => state.user.popkey);
  const user_infos = useSelector((state) => state.user.info_user);
  //pointDetails.level_up (1===1)
  const onChooseFalse = () => {
    props.close();
  };

  const targetRef = useRef(null);

  useEffect(() => {
    targetRef.current && disableBodyScroll(targetRef.current);

    axios
      .post(server_api_url + "/points/addpoints", {
        popkey: popkey,
        type: props.type,
      })
      .then((res) => res.data)
      .then((data) => {
        setPointDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => clearAllBodyScrollLocks();
  }, [popkey, props.type]);

  const clickModal = () => {
    props.close();
  };

  const clickChildren = (e) => {
    e.stopPropagation();
  };

  const badgeSrc = (id) => {
    switch (id) {
      case 1:
        return require("../../../../image/badges/1alone.png");
      case 2:
        return require("../../../../image/badges/2alone.png");
      case 3:
        return require("../../../../image/badges/3alone.png");
      case 4:
        return require("../../../../image/badges/4alone.png");
      case 5:
        return require("../../../../image/badges/5alone.png");
      case 6:
        return require("../../../../image/badges/6alone.png");
      case 7:
        return require("../../../../image/badges/7alone.png");
      case 8:
        return require("../../../../image/badges/8alone.png");
      case 9:
        return require("../../../../image/badges/9alone.png");
      default:
        return require("../../../../image/badges/1alone.png");
    }
  };

  return (
    <Portal id="PointsNotification">
      <div
        onClick={clickModal}
        ref={targetRef}
        className={css`
          width: 100%;
          height: 100%;
          position: fixed;
          z-index: 2001;
          top: 0px;
          left: 0px;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          justify-content: center;
          align-items: center;
        `}>
        <div
          onClick={clickChildren}
          className={css`
            background: "white";
            box-sizing: border-box;
            border-radius: 5px;
            max-height: 100%;
            @media screen and (max-width: 678px) {
              height: 100%;
            }
            width: 550px;
            max-width: 100%;
            background: #fff;
          `}>
          <HeaderModal onBack={props.close} text="" />

          {pointDetails && user_infos && (
            <div
              className={css`
                height: calc(100% - 60px);
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-text: center;
                justify-content: center;
                padding-left: 20px;
                padding-right: 20px;
                background: #f5f8fa;
                position: relative;
              `}>
              <div
                className={css`
                  height: 100%;
                  overflow-y: scroll;
                `}>
                <div style={{ height: 90, position: "relative" }}>
                  <div style={{ backgroundColor: "#F5F8FA", height: 90 }}>
                    <div
                      style={{
                        backgroundColor: "#F5F8FA",
                        flexDirection: "row",
                        paddingTop: 25,
                        paddingRight: 20,
                        justifyContent: "flex-end",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: 20,
                      left: 20,
                      fontSize: 22,
                      fontWeight: "bold",
                      zIndex: 10,
                      color: "#333",
                    }}>
                    {pointDetails.new_points} pts
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: 48,
                      left: 20,
                      fontSize: 12,
                      fontWeight: "bold",
                      zIndex: 13,
                      color: "grey",
                    }}>
                    Niv. {pointDetails.new_level}
                  </div>
                </div>

                <div
                  style={{
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    backgroundColor: "#FFF",
                    width: "100%",
                    position: "relative",
                  }}>
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      top: -50,
                      left: 0,
                      width: "100%",
                      textAlign: "center",
                    }}>
                    <Avatar size={100} id_avatar={user_infos.avatar} />
                  </div>

                  <div
                    style={{
                      height: 350,
                      width: "100%",
                      position: "relative",
                    }}>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        top: 0,
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {!pointDetails.level_up ? (
                        <img
                          className={css`
                            margin-top: 10px;
                            width: 300px;
                            max-width: 100%;
                            animation-duration: 2s;
                            animation-name: bounceIn;
                            align-self: center;
                          `}
                          src={cadeau}
                          alt="Cadeau"
                        />
                      ) : (
                        <img
                          className={css`
                            margin-top: 100px;
                            width: 180px;
                            animation-duration: 2s;
                            animation-name: bounceIn;
                            align-self: center;
                          `}
                          src={badgeSrc(user_infos.game_level)}
                          alt=""
                        />
                      )}
                    </div>
                  </div>

                  {!pointDetails.level_up ? (
                    <div style={{}}>
                      <div
                        style={{
                          color: "#20cdff",
                          fontWeight: "700",
                          fontSize: 40,
                          textShadowColor: "rgba(255,255, 255, 1)",
                          textShadowOffset: { width: -3, height: 3 },
                          textShadowRadius: 10,
                          textAlign: "center",
                        }}>
                        {"+" + pointDetails.add_points}
                      </div>
                      <div
                        style={{
                          color: "#20cdff",
                          fontWeight: "700",
                          fontSize: 28,
                          textAlign: "center",
                        }}>
                        {pointDetails.add_points === 1 ? "Point" : "Points"}
                      </div>
                    </div>
                  ) : (
                    <div style={{}}>
                      <div
                        style={{
                          color: "#20cdff",
                          fontWeight: "700",
                          fontSize: 22,
                          textAlign: "center",
                        }}>
                        {"+" + pointDetails.add_points}{" "}
                        {pointDetails.add_points === 1 ? " point" : " points"}
                      </div>
                      <div
                        style={{
                          color: "#20cdff",
                          fontWeight: "700",
                          fontSize: 35,
                          lineHeight: 1,
                          textShadowColor: "rgba(255,255, 255, 1)",
                          textShadowOffset: { width: -3, height: 3 },
                          textShadowRadius: 10,
                          textAlign: "center",
                          marginLeft: 20,
                          marginRight: 20,
                          marginTop: 10,
                        }}>
                        Niveau {pointDetails.new_level} !!
                      </div>
                    </div>
                  )}

                  {!pointDetails.level_up && (
                    <div
                      style={{
                        flexDirection: "column",
                        width: "100%",
                      }}>
                      <div
                        style={{
                          color: "#20cdff",
                          fontWeight: "700",
                          marginTop: 30,
                          marginLeft: 20,
                          marginRight: 20,
                          fontSize: 23,
                          textAlign: "center",
                        }}>
                        {pointDetails.next_level} points avant le prochain
                        niveau !
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className={css`
                    display: flex;
                    flex-direction: row;
                    padding-top: 30px;
                    padding-bottom: 20px;
                    background: #fff;
                  `}>
                  <div
                    className={css`
                      flex: 1;
                      text-align: center;
                      display: flex;
                      justify-content: flex-end;
                      box-sizing: border-box;
                      padding-right: 10px;
                    `}></div>
                  <div
                    className={css`
                      flex: 1;
                      text-align: center;
                      display: flex;
                      justify-content: flex-start;
                      box-sizing: border-box;
                      padding-right: 10px;
                    `}>
                    <ButtonEX onPress={onChooseFalse} width={"75"} text="Ok" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};

export default PointsNotification;
