import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyParticipate = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
          version="1.1" viewBox="0 0 32 32">
      <path
      fill={props.color}
      d="M11.795,10l13.811,-5.92c0.124,-0.046 0.168,-0.097 0.504,-0.074c0.494,0.076 0.869,0.492 0.89,0.994l0,20c-0.029,0.669 -0.741,1.166 -1.394,0.919l-12.606,-5.403l0,6.484c-0.005,0.176 -0.021,0.225 -0.052,0.319c-0.121,0.359 -0.461,0.634 -0.84,0.675c-0.048,0.005 -0.06,0.005 -0.108,0.006l-4,0c-0.2,-0.005 -0.26,-0.027 -0.37,-0.071c-0.336,-0.134 -0.585,-0.46 -0.624,-0.821c-0.006,-0.048 -0.005,-0.06 -0.006,-0.108l0,-4c0.005,-0.179 0.021,-0.229 0.054,-0.325c0.131,-0.381 0.512,-0.664 0.918,-0.675c0.107,-0.003 0.131,0.006 0.165,0.009c0.167,0.028 0.194,0.044 0.265,0.075c0.319,0.14 0.553,0.456 0.592,0.806c0.005,0.049 0.005,0.061 0.006,0.11l0,3l2,0l0,-6l-5,0c-0.183,-0.005 -0.233,-0.022 -0.33,-0.056c-0.353,-0.124 -0.622,-0.457 -0.664,-0.832c-0.006,-0.05 -0.005,-0.062 -0.006,-0.112l0,-8c0.005,-0.183 0.022,-0.234 0.056,-0.331c0.123,-0.352 0.456,-0.621 0.832,-0.663c0.049,-0.006 0.062,-0.005 0.112,-0.006l5.795,0Zm1.205,1.659l0,3.341c-0.021,0.755 -1.044,1.301 -1.666,0.745c-0.182,-0.162 -0.301,-0.392 -0.328,-0.633c-0.005,-0.05 -0.005,-0.062 -0.006,-0.112l0,-3l-4,0l0,6l4.945,0c0.123,-0.012 0.22,-0.006 0.449,0.081l12.606,5.402l0,-16.967l-12,5.143Z"/>
         </svg>
    </IconPattern>
    )
}

export default IconEasyParticipate;
