import axios from "axios";
import { server_api_url } from "../config/constants";

class NewsAPI {
  static getNews(popkey, codeinsee, page) {
    return axios.get(server_api_url + "/news/all" , {

      params: {
        popkey: popkey,
        codeinsee: codeinsee,
        page: page
      }
    });
  }
static getNewsDetails(popkey,id_news) {
    return axios.get(server_api_url + "/news/details", {
      params: {
        popkey: popkey,
        id_news: id_news
      }
    });
  }

static getNewsComments(id_news,page,popkey){
  return axios.get(server_api_url + "/news/comments",{
    params:{
      id_news:id_news,
      page:page,
      popkey:popkey
    }
  })
}



static postComment(popkey,id_news,ref_id_comment,description){
  return axios.post(server_api_url + '/news/postcomment',{
    popkey:popkey,
    id_news: id_news,
    ref_id_comment: ref_id_comment,
    description:description
  })
}

static hasLike(popkey,id_news){
  return axios.get(server_api_url + "/news/hasLike", {
    params: {
      popkey: popkey,
      id_news: id_news
    }
  });
}

static postLike(popkey,id_news){
  return axios.post(server_api_url + "/news/postLike",{
      popkey: popkey,
      id_news: id_news

  })
}

static disLike(popkey,id_news){
  return axios.post(server_api_url + "/news/disLike",{
      popkey: popkey,
      id_news: id_news

  })
}

static deleteComment(id_comment,ref_id_comment){
  return axios.post(server_api_url+ "/news/deleteComment",{
    id_comment:id_comment,
    ref_id_comment:ref_id_comment
  })
}


}

export default NewsAPI;
