import React from 'react';
import BlockEval from './BlockEval';
import BlockDiagram from './BlockDiagram';
import BlockFreeAnswer from './BlockFreeAnswer';

const ResultSurvey = (props) => {
  return (
    <div
      style={{
        flex: 1,
        paddingLeft: 20,
        paddingRight: 20,
        display: 'flex',
        flexDirection: 'column',
      }}>
      {props.resultSurvey.map((i, nb) => {
        return (
          <div
            key={'D' + nb}
            style={{
              flex: 1,
              marginTop: 30,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div style={{fontSize: 17, marginBottom: 5, fontWeight: 'bold'}}>
              {i.question.entitled}
            </div>
            <div>
              {(i.question.type === 1 || i.question.type === 2) && (
                <BlockDiagram item={i.answers} />
              )}
              {i.question.type === 3 && (
                <BlockEval colorStar="#444444" item={i} />
              )}
              {i.question.type === 4 && <BlockFreeAnswer item={i} />}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ResultSurvey;
