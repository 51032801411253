import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasySurvey = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
        version="1.1" viewBox="0 0 32 32">
        <path d="M24.001,3c0.01,0 0.021,0 0.033,0c2.586,0.025 4.94,2.273 4.966,4.966l0,10.034c0,0 -0.222,0.971 -1,1l-4,0c-0.582,-0.022 -1.077,-0.565 -0.989,-1.149c0.071,-0.473 0.396,-0.829 0.989,-0.851l3,0c0,-3.02 0.047,-6.041 -0.001,-9.06c-0.034,-1.553 -1.389,-2.935 -2.999,-2.94c0,0 -0.018,-0.004 -0.05,-0.012c-0.054,0.009 -0.109,0.016 -0.165,0.019c-1.494,0.109 -2.78,1.444 -2.785,2.993l0,20c-0.025,0.536 -0.46,0.975 -1,1l-16,0c-0.782,-0.037 -1.298,-1.029 -0.756,-1.655c0.182,-0.21 0.33,-0.325 0.756,-0.345l15,0c0,-6.355 -0.06,-12.711 0,-19.066c0.017,-1.056 0.372,-2.086 0.993,-2.927l0.006,-0.007c-4.012,0 -8.024,-0.025 -12.036,0c-0.208,0.004 -0.25,0.011 -0.37,0.028c-1.411,0.193 -2.584,1.495 -2.593,2.975l0,15.997c-0.031,0.646 -0.33,0.793 -0.585,0.91c-0.6,0.274 -1.381,-0.206 -1.415,-0.91l0,-16.003c0.016,-2.592 2.241,-4.98 4.997,-4.997l16.003,0l0.001,0Zm-13.001,15.586l1.293,-1.293c0.195,-0.188 0.39,-0.29 0.717,-0.293c0.013,0 0.026,0.001 0.04,0.001c0.77,0.054 1.258,1.081 0.657,1.706l-1.293,1.293l1.293,1.293c0.684,0.712 -0.349,2.438 -1.414,1.414l-1.293,-1.293l-1.293,1.293c-0.72,0.692 -2.438,-0.349 -1.414,-1.414l1.293,-1.293l-1.293,-1.293c-0.545,-0.567 -0.17,-1.681 0.697,-1.707c0.013,0 0.027,0 0.04,0c0.257,0.013 0.452,0.076 0.677,0.293l1.293,1.293Zm3.036,-9.585c0.781,0.047 1.269,1.078 0.671,1.706l-4,4c-0.127,0.121 -0.176,0.147 -0.27,0.192c-0.375,0.183 -0.792,0.144 -1.144,-0.192l-2,-2c-0.682,-0.715 0.349,-2.429 1.414,-1.414l1.293,1.293l3.293,-3.293c0,0 0.401,-0.296 0.743,-0.292Z" fill={props.color}/>

        </svg>
    </IconPattern>
    )
}

export default IconEasySurvey;
