import React, {useState} from 'react';
import {css} from 'emotion';

import IconEasyMap from '../../../../common/icons/IconEasyMap';
import ModalMap from '../../../../common/material-easy-ui/modal/ModalMap';

const InputMap = props => {
  const [map, setMap] = useState(false);

  const onValid = (marker, adress) => {
    props.onSendData(marker, adress);
    setMap(false);
  };

  const onBack = () => {
    setMap(false);
  };
  return (
    <>
      <div
        className={css`
          margin-bottom: 10px;
          font-weight: 600;
          ${props.style}
        `}>
        {props.title}{' '}
        {props.facultatif ? (
          <span
            className={css`
              font-size: 12px;
            `}>
            (facultatif)
          </span>
        ) :
        <span
          className={css`
            font-size: 12px;
          `}>
           *
        </span>
      }
      </div>
      <div
        className={css`
          margin-top: 20px;
          flex-direction: row;
          display: flex;
        `}>
        <div
          className={css`
            flex: 1;
            background-color: #f5f8fa;
            height: 40px;
            border-radius: 10px;
            box-shadow: inset 0px 0px 0px 1px #ededed;
            padding-left: 10px;
            display: flex;
            align-items: center;
            font-size: 14px;
          `}>
          {props.adress}
        </div>
        <div
          onClick={() => {
            setMap(!map);
          }}
          className={css`
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            cursor: pointer;
            width: 60px;
            background: rgb(242,101,35);
            margin-left: 10px;
            border-radius: 5px;
            &:hover {
              background: rgb(242,101,35);
            }
          `}>
          <IconEasyMap size={25} color="white" />
        </div>
      </div>
      {map && (
        <ModalMap
          marker={props.marker}
          zoomLevel={props.zoomLevel}
          adress={props.adress}
          id="modal-budgetpart-map"
          height={'97%'}
          onValid={onValid}
          onBack={onBack}
        />
      )}
    </>
  );
};

export default InputMap;
