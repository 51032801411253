import React from 'react';
import {css} from 'emotion';

import TimeLine from '../time-line/TimeLine';

const EmptySubHeader = (props) => {
  return (
    <>
      <div
        className={css`
          box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.04);
          border-radius: 3px;
          padding: 20px;
          margin-left: 10px;
          margin-right: 10px;
          position: relative;
          z-index: 1;
          background-color: white;
          margin-top: -20px;
          box-sizing: border-box;
        `}>

        <div
          className={css`
            margin-top: 20px;
          `}>

          {props.budget_part_session && (
            <TimeLine budget_part_session={props.budget_part_session} />
          )}

        </div>


        <p style={{textAlign: 'center', fontWeight: 'bold'}}>Participez prochainement au budget participatif de votre commune !</p>
        <p style={{textAlign: 'center'}}>Cela vous permettra de proposer des projets, de commenter, ou de voter pour les idées des autres citoyens.</p>
        <p style={{textAlign: 'center'}}>Les meilleures idées seront sélectionnées et réalisées par la collectivité, dans la limite du budget défini.</p>
        <p style={{textAlign: 'center', fontWeight: 'bold'}}>À très bientôt !</p>

      </div>
    </>
  );
};

export default EmptySubHeader;
