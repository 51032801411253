import React, {useState, useEffect, useRef} from 'react';
import {css} from 'emotion';
import './stylesHold.css';

const SelectInput = props => {
  const refText = useRef(null);
  const refIcon = useRef(null);

  const [defaultSelectText, setDefaultSelectText] = useState('');
  const [showOptionList, setShowOptionList] = useState(false);
  const [optionList, ] = useState(props.options);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    setDefaultSelectText(props.defaultText);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.defaultText]);

  useEffect(() => {
    props.showList !== props.id && setShowOptionList(false);
  }, [props.showList, props.id]);

  const handleClickOutside = e => {
    if (
      !e.target.classList.contains('custom-select-option') &&
      !e.target.classList.contains('selected-text') &&
      !e.target.classList.contains('select-text-option')
    ) {
      setShowOptionList(false);
    }
  };

  const handleListDisplay = () => {
    setShowOptionList(!showOptionList);
    props.onUse && props.onUse();
  };

  const handleOptionClick = e => {
    setDefaultSelectText(
      JSON.parse(e.currentTarget.getAttribute('data-name')).name,
    );
    props.onChoose(JSON.parse(e.currentTarget.getAttribute('data-name')).id);
    setShowOptionList(false);
  };

  return (
    <>
      {props.title && (
        <div
          className={css`
            margin-bottom: 10px;
            font-weight: 600;
            ${props.style}
          `}>
          {props.title} {' '}
          <span
            className={css`
              font-size: 12px;
            `}>
             *
          </span>
        </div>
      )}

      <div
        className={css`
          display: inline-block;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          text-align: left;
          position: relative;
          width: ${props.width};
          ${showOptionList &&
            'box-shadow: 3px 3px 3px 0px rgba(41, 53, 74, 0.1)'}
        `}>

        <div
          className={showOptionList ? 'selected-text active' : 'selected-text'}
          onClick={handleListDisplay}>
          {defaultSelectText}
        </div>
        {showOptionList && (
          <ul className="select-options">
            {optionList.map(option => {
              return (
                <li
                  style={{display: 'flex'}}
                  className="custom-select-option"
                  data-name={JSON.stringify(option)}
                  key={option.id}
                  onClick={e => handleOptionClick(e)}>
                  <div
                    ref={refIcon}
                    className={css`
                      width: 20px;
                    `}>
                    {option.id === props.selected ? '\u2714 ' : ''}
                  </div>

                  <div className="select-text-option" ref={refText}>
                    {option.name}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
};

export default SelectInput;
