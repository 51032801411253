import React from 'react';
import {css} from 'emotion';
import IconEasyComment from '../../common/icons/IconEasyComment';
import IconEasyLike from '../../common/icons/IconEasyLike';

const FooterParticipationDetails = props => {

  if (Number.isNaN(props.likes))
    return <></>;

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        border-top: 1px solid rgba(232, 232, 232, 0.8);
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
      `}>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}>
        <div
          className={css`
            cursor: pointer;
          `}
          onClick={props.onLike}>
          <IconEasyLike
            color={props.has_liked ? '#ff006a' : '#29354a'}
            size={30}
          />
        </div>
        <div
          className={css`
            color: ${props.has_liked ? '#ff006a' : '#29354a'};
          `}>
          {props.likes}
        </div>
      </div>
      <div
        className={css`
          flex: 1;
        `}></div>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}>
        <IconEasyComment size={30} />
        <div>{props.comments}</div>
      </div>
    </div>
  );
};

export default FooterParticipationDetails;
