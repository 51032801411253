import React, { useEffect, useState } from 'react';
import './style/ConfirmEmail.css'

import QueryString from 'query-string';
import UserAPI from '../../api/UserAPI';

import {getUserInfo} from '../../redux/actions/userActions';
import {useDispatch, useSelector} from 'react-redux';
import history from '../../utils/history';

export default function ConfirmEmail({location}) {
  const dispatch = useDispatch();

  const code = QueryString.parse(location.search).code
  const popkey = useSelector(state => state.user.popkey);

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [succeded, setSucceded] = useState(false);

  useEffect(() => {
    UserAPI.whatisguys(parseInt(code)).then(data => {
      if (data && data.data.email) {
        UserAPI.confirmAccount(data.data.email)
          .then(data2 => {
            if (data2.data.error)
              setErrorMessage("Une erreur s'est produite lors de la vérification du compte. Vous avez peut-être déjà vérifié votre compte.");
            else
              setSucceded(true);
          })
          .then(() => {
            if (popkey)
              dispatch(getUserInfo(popkey))
          })
          .catch((err) => {
            setErrorMessage("Une erreur s'est produite lors de la vérification du compte. Vous avez peut-être déjà vérifié votre compte.")
            console.log(err)
          });
      } else if (data && data.data.error)
        setErrorMessage("Une erreur s'est produite lors de la vérification du compte. Vous avez peut-être déjà vérifié votre compte.")
      setLoading(false);
    });
  }, [])

  return (
    <div className="emailMessagesContainer">

      <img src="https://www.popvox.fr/img/popvoxlogo.png" />

      {loading && (
        <div className="emailMessages">
          Confirmation du compte en cours...
        </div>
      )}

      {succeded && (
        <div className="emailMessages">
          Votre compte a bien été confirmé !
        </div>
      )}

      {errorMessage && (
        <div className="emailMessages">
          {errorMessage}
        </div>
      )}

<div
            style={{
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid rgb(245, 93, 66)",
              color: "rgb(245, 93, 66)",
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 9,
              paddingBottom: 9,
              borderRadius: 20,
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              fontSize: 18,
              lineHeight: 1,
              alignSelf: "center",
              background: "#FFF",
            }}
            onClick={() => history.push({pathname: '/', state: {from: 'menu'}}) }>
              Accueil
          </div>



    </div>
  );
}
