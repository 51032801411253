import React from 'react';
import {css} from 'emotion';

const ButtonEX = props => {
  return (
    <div
      onClick={props.onPress}
      className={css`
        margin: 0 auto;
        cursor: pointer;
        margin-top: 10px;
        text-align: center;
        width: ${props.width}px;
        max-width: 100%;
        height: ${props.height}px;
        line-height: ${props.height}px;
        background-color: ${props.background ? props.background : '#F5F8FA'};
        color: ${props.textColor ? props.textColor : '#29354a'};
        border-radius: 10px;
        border: ${props.border
          ? props.border
          : '1px solid rgba(41, 53, 74,0.1);'};
        padding: 5px;
        &:hover {
          background-color: #f55d42;
          color: white;
        }
      `}>
      {props.text}
    </div>
  );
};

export default ButtonEX;
