import React from 'react';
import IconEasyComment from '../../../../common/icons/IconEasyComment';

const BlockFreeAnswer = props => {
  if (
    props.item &&
    props.item.answers &&
    props.item.answers[0] &&
    props.item.answers[0].nb
  ) {
    return (
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <span
          style={{color: '#333', fontSize: 20, marginLeft: 25, marginRight: 5}}>
          {props.item.answers[0].nb}
        </span>
        <IconEasyComment color={'#29354a'} size={20} />
      </div>
    );
  }

  return false;
};

export default BlockFreeAnswer;
