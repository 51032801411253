import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyValid = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
        version="1.1" viewBox="0 0 100 100">
      <path d="M93.8,20.9c-1.7-1.7-4.4-1.7-6,0L38.7,70c-1.3,1.3-3.3,1.3-4.6,0L12.2,48.1c-1.7-1.7-4.3-1.7-6,0   c-1.7,1.7-1.7,4.4,0,6l26.9,26.9c0.9,0.9,2.1,1.3,3.2,1.2c1.2,0.1,2.4-0.3,3.2-1.2L93.8,27C95.4,25.3,95.4,22.6,93.8,20.9z" fill={props.color}/>

        </svg>
    </IconPattern>
    )
}

export default IconEasyValid;
