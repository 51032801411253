import React from 'react';
import IconEasyConnexion from '../../common/icons/IconEasyConnexion';
import {css} from 'emotion';
import {useSelector} from 'react-redux';

// eslint-disable-next-line
const ConnexionAccountMenu = React.forwardRef((props, ref) => {
  const popkey = useSelector(state => state.user.popkey);

  return (
    <div
      ref={ref}
      className={css`
        flex-direction: row;
        width: 100%;
        display: flex;
        padding-left: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        &:hover{
            background-color: rgba(245, 93, 66,0.1);
        }
        overflow: hidden;
        cursor: pointer;
      `}>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <IconEasyConnexion
          size={18}
          color={'rgba(245, 93, 66,1)'}
        />
      </div>
      <div
        className={css`
          margin-left: 10px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover{
              color: rrgba(245, 93, 66,1);
          }
        `}>
        {popkey ? 'Deconnexion' : 'Connexion'}
      </div>
    </div>
  );
});

export default ConnexionAccountMenu;
