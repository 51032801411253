import React from "react";
import { css } from "emotion";
import IconPattern from "./IconPattern";

const IconEasyFullPage = (props) => {
  return (
    <IconPattern size={props.size}>
      <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
        `}
        version="1.1"
        viewBox="0 0 402 402">
        <path
          fill={props.color}
          d="M392.996,1.985H258.33c-5.523,0-10,4.477-10,10V31.01c0,5.523,4.477,10,10,10h78.046L224.955,152.431
          c-3.905,3.905-3.905,10.237,0,14.142l13.453,13.453c1.953,1.953,4.512,2.929,7.07,2.929c2.56,0,5.119-0.977,7.071-2.929
          L363.972,68.604v78.047c0,5.523,4.478,10,10,10h19.024c5.523,0,10-4.477,10-10V11.985C402.996,6.462,398.52,1.985,392.996,1.985z"
        />
        <path
          fill={props.color}
          d="M66.62,41.01h78.046c5.523,0,10-4.477,10-10V11.985c0-5.523-4.477-10-10-10H10c-5.523,0-10,4.477-10,10v134.666
          c0,5.523,4.477,10,10,10h19.024c5.523,0,10-4.477,10-10V68.604l111.422,111.421c1.952,1.953,4.512,2.929,7.071,2.929
          s5.119-0.977,7.071-2.929l13.453-13.453c3.905-3.905,3.905-10.237,0-14.142L66.62,41.01z"
        />
        <path
          fill={props.color}
          d="M392.996,246.344h-19.024c-5.522,0-10,4.478-10,10v78.047L252.55,222.97c-3.903-3.905-10.237-3.905-14.142,0
          l-13.453,13.453c-3.905,3.904-3.905,10.236,0,14.142l111.421,111.421H258.33c-5.523,0-10,4.477-10,10v19.023
          c0,5.523,4.477,10,10,10h134.666c5.523,0,10-4.477,10-10V256.344C402.996,250.821,398.52,246.344,392.996,246.344z"
        />
        <path
          fill={props.color}
          d="M164.588,222.97c-3.905-3.905-10.238-3.905-14.142,0L39.024,334.392v-78.047c0-5.523-4.477-10-10-10H10
          c-5.523,0-10,4.477-10,10v134.666c0,5.523,4.477,10,10,10h134.666c5.523,0,10-4.477,10-10v-19.023c0-5.523-4.477-10-10-10H66.62
          l111.421-111.422c3.905-3.904,3.905-10.236,0-14.143L164.588,222.97z"
        />
      </svg>
    </IconPattern>
  );
};

export default IconEasyFullPage;
