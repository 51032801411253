import React, { useEffect, useState, useRef } from "react";
import HorizontalScroll from "../common/horizontalScroll/HorizontalScroll";
import { useDispatch, useSelector } from "react-redux";
import {
  getOnTheFrontPage,
  getSurveys,
  getNews,
  getEvents,
} from "../../redux/actions/publicationActions";
import CardPublication from "../publications/card/CardPublication";
import { css } from "emotion";
import MenuPublication from "../publications/components/MenuPublication";
import CardLoader from "../publications/card/CardLoader";

const PublicationFlux = (props) => {
  /* REF SCROLL */
  const scrollRef = useRef(null);
  const page = useRef(0);
  /*STATE REACT */


  const [categorie, setCategorie] = useState(0);
  const idCommunity = props.idCommunity ? props.idCommunity : null;

  /* STATE REDUX */
  const listPublication = useSelector(
    (state) => state.publication.listConsultation
  );
  const popkey = useSelector((state) => state.user.popkey);
  const insee_com = useSelector((state) => state.city.infoCity.insee_com);

  const endReached = useSelector((state) => state.publication.endReached);
  const isLoading = useSelector((state) => state.publication.loading);

  /* DISPATCH */
  const dispatch = useDispatch();

  /* GET PUBLICATION */
  useEffect(() => {
    setCategorie(0);
    page.current = 0;
    dispatch(getOnTheFrontPage(popkey, insee_com, idCommunity));
  }, [popkey, insee_com]);

  const fetchMorePublication = () => {

    if (!isLoading && !endReached) {
      page.current++;
      if (categorie === 1) {
        dispatch(getNews(popkey, insee_com, page.current, idCommunity));
      } else if (categorie === 3) {
        dispatch(getEvents(popkey, insee_com, page.current, idCommunity));
      } else if (categorie === 2) {
        dispatch(getSurveys(popkey, insee_com, page.current, idCommunity));
      }
    }

    return false;
  };

  const chooseCategorie = (categorie) => {
    setCategorie(categorie);
    page.current = 0;

    if (categorie === 0) {
      dispatch(getOnTheFrontPage(popkey, insee_com, idCommunity)).then(() => {
        scrollRef.current.StartScrollView();
      });
    } else if (categorie === 1) {
      dispatch(getNews(popkey, insee_com, 0, idCommunity)).then(() => {
        scrollRef.current.StartScrollView();
      });
    } else if (categorie === 3) {
      dispatch(getEvents(popkey, insee_com, 0, idCommunity)).then(() => {
        scrollRef.current.StartScrollView();
      });
    } else if (categorie === 2) {
      dispatch(getSurveys(popkey, insee_com, 0, idCommunity)).then(() => {
        scrollRef.current.StartScrollView();
      });
    }

    return false;
  };

  if (categorie === 0 && listPublication.length === 0 && !isLoading) {
    return false;
  }

  return (
    <>
      <MenuPublication onChoose={chooseCategorie} currentSelect={categorie} />

      <div style={{ width: "100%", height: 325 }}>
        <HorizontalScroll
          onScrollEndLeft={fetchMorePublication}
          ref={scrollRef}>
          {listPublication.length > 0 ? (
            listPublication.map((item, key) => {
              if (item === "loading") {
                return <CardLoader key={`${key}loading`} />;
              } else {
                return <CardPublication item={item} key={item.id_publicpart} />;
              }
            })
          ) : (
            <div
              className={css`
                height: 285px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgb(41, 53, 74);
                font-size: 15px;
              `}>
              {" "}
              Il n&apos;y a pas de contenu
            </div>
          )}
        </HorizontalScroll>
      </div>

    </>
  );
};

export default PublicationFlux;
