import React from 'react';
import {css} from 'emotion';
import {timeSince} from '../../../../../utils/date';
import Avatar from '../../../../common/avatar/Avatar';


const formatText = text => {
  return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
};


const HeaderDetails = (props) =>{

  if (props.nom_comm === undefined)
    return <></>;

  return(
    <div
      className={css`
        display: flex;
        margin-top: 20px;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
      `}>
      <div
        className={css`
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        `}>
        <Avatar
          size={30}
          id_avatar={props.avatar}
        />
      </div>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          justify-content: center;
        `}>
        <div
          className={css`
            display: flex;
            flex-direction: row;
          `}>
          <div
            className={css`
              color: #29354a;
              font-weight: 600;
            `}>
            {props.pseudo}
          </div>
          <div
            className={css`
              margin-left: 5px;
              color:orange;
            `}>
            à déposé
          </div>
        </div>
        <div
          className={css`
            color: #bfbfbf;
            font-size: 13px;
            display: flex;
          `}>
          <div>{formatText(props.nom_comm)},</div>
          <div
            className={css`
              margin-left: 5px;
            `}>
            il y a {timeSince(props.date_creation)}
          </div>
        </div>
      </div>
    </div>
  )
}
export default HeaderDetails
