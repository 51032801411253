import React from "react";
import { css } from "emotion";

const ThematicIconParticipation = (props) => {

  const imagesSrcs = {
    1: require('../../image/icons_participate/themes/1.png'),
    2: require('../../image/icons_participate/themes/2.png'),
    3: require('../../image/icons_participate/themes/3.png'),
    4: require('../../image/icons_participate/themes/4.png'),
    5: require('../../image/icons_participate/themes/5.png'),
    6: require('../../image/icons_participate/themes/6.png'),
    7: require('../../image/icons_participate/themes/7.png'),
    8: require('../../image/icons_participate/themes/8.png'),
    9: require('../../image/icons_participate/themes/9.png'),
    10: require('../../image/icons_participate/themes/10.png'),
    11: require('../../image/icons_participate/themes/11.png'),
    12: require('../../image/icons_participate/themes/12.png'),
    13: require('../../image/icons_participate/themes/13.png'),
    14: require('../../image/icons_participate/themes/14.png'),
    15: require('../../image/icons_participate/themes/15.png'),
    16: require('../../image/icons_participate/themes/16.png'),
    17: require('../../image/icons_participate/themes/17.png'),
    18: require('../../image/icons_participate/themes/18.png'),
    19: require('../../image/icons_participate/themes/19.png'),
    22: require('../../image/icons_participate/themes/22.png'),
    21: require('../../image/icons_participate/themes/21.png'),
    29: require('../../image/icons_participate/themes/29.png'),
    26: require('../../image/icons_participate/themes/26.png'),
    25: require('../../image/icons_participate/themes/25.png'),
    32: require('../../image/icons_participate/themes/32.png'),
    33: require('../../image/icons_participate/themes/33.png'),
    28: require('../../image/icons_participate/themes/28.png'),
    30: require('../../image/icons_participate/themes/30.png'),
    23: require('../../image/icons_participate/themes/23.png'),
    24: require('../../image/icons_participate/themes/24.png'),
    27: require('../../image/icons_participate/themes/27.png'),
    31: require('../../image/icons_participate/themes/31.png'),
    34: require('../../image/icons_participate/themes/34.png'),
    39: require('../../image/icons_participate/themes/39.png'),
    42: require('../../image/icons_participate/themes/42.png'),
    41: require('../../image/icons_participate/themes/41.png'),
    49: require('../../image/icons_participate/themes/49.png'),
    46: require('../../image/icons_participate/themes/46.png'),
    45: require('../../image/icons_participate/themes/45.png'),
    52: require('../../image/icons_participate/themes/52.png'),
    53: require('../../image/icons_participate/themes/53.png'),
    48: require('../../image/icons_participate/themes/48.png'),
    50: require('../../image/icons_participate/themes/50.png'),
    43: require('../../image/icons_participate/themes/43.png'),
    44: require('../../image/icons_participate/themes/44.png'),
    47: require('../../image/icons_participate/themes/47.png'),
    51: require('../../image/icons_participate/themes/51.png'),
    54: require('../../image/icons_participate/themes/54.png'),
    55: require('../../image/icons_participate/themes/55.png'),
    56: require('../../image/icons_participate/themes/56.png'),
    57: require('../../image/icons_participate/themes/57.png'),
    58: require('../../image/icons_participate/themes/58.png'),
    59: require('../../image/icons_participate/themes/59.png'),
    62: require('../../image/icons_participate/themes/62.png'),
    61: require('../../image/icons_participate/themes/61.png'),
    69: require('../../image/icons_participate/themes/69.png'),
    66: require('../../image/icons_participate/themes/66.png'),
    65: require('../../image/icons_participate/themes/65.png'),
    72: require('../../image/icons_participate/themes/72.png'),
    73: require('../../image/icons_participate/themes/73.png'),
    68: require('../../image/icons_participate/themes/68.png'),
    70: require('../../image/icons_participate/themes/70.png'),
    63: require('../../image/icons_participate/themes/63.png'),
    64: require('../../image/icons_participate/themes/64.png'),
    67: require('../../image/icons_participate/themes/67.png'),
    71: require('../../image/icons_participate/themes/71.png'),
    74: require('../../image/icons_participate/themes/74.png'),
    75: require('../../image/icons_participate/themes/75.png'),
    76: require('../../image/icons_participate/themes/76.png'),
    77: require('../../image/icons_participate/themes/77.png'),
    78: require('../../image/icons_participate/themes/78.png'),
    78001: require('../../image/icons_participate/themes/78001.png'),
    78002: require('../../image/icons_participate/themes/78002.png'),
    79: require('../../image/icons_participate/themes/79.png'),
    82: require('../../image/icons_participate/themes/82.png'),
    81: require('../../image/icons_participate/themes/81.png'),
    89: require('../../image/icons_participate/themes/89.png'),
    86: require('../../image/icons_participate/themes/86.png'),
    85: require('../../image/icons_participate/themes/85.png'),
    92: require('../../image/icons_participate/themes/92.png'),
    93: require('../../image/icons_participate/themes/93.png'),
    88: require('../../image/icons_participate/themes/88.png'),
    90: require('../../image/icons_participate/themes/90.png'),
    99: require('../../image/icons_participate/themes/99.png'),
    83: require('../../image/icons_participate/themes/83.png'),
    84: require('../../image/icons_participate/themes/84.png'),
    87: require('../../image/icons_participate/themes/87.png'),
    91: require('../../image/icons_participate/themes/91.png'),
    94: require('../../image/icons_participate/themes/94.png'),
    101: require('../../image/icons_participate/themes/101.png'),
    102: require('../../image/icons_participate/themes/102.png'),
    119: require('../../image/icons_participate/themes/119.png'),
    125: require('../../image/icons_participate/themes/125.png'),
    126: require('../../image/icons_participate/themes/126.png'),
    127: require('../../image/icons_participate/themes/127.png'),
    128: require('../../image/icons_participate/themes/128.png'),
    129: require('../../image/icons_participate/themes/129.png'),
    130: require('../../image/icons_participate/themes/130.png'),
    139: require('../../image/icons_participate/themes/139.png'),
  }

  return (
    <img
    alt="Thème"
    className={css`
      width: 70px;
      height: 70px;
    `}
    src={imagesSrcs[props.id]}
  />
  );
};

export default ThematicIconParticipation;
