import React from 'react';
import {Marker} from 'react-mapbox-gl';
import TypeIconParticipation from '../../participations/TypeIconParticipation';

const MarkerMap = (props) =>{
  return(
    <Marker coordinates={props.coord}>
      <TypeIconParticipation size={20} type={props.type} />
    </Marker>
  )
}

export default MarkerMap
