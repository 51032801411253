import React from 'react';
import InputPassword from '../../common/material-easy-ui/input/InputPassword';
import ButtonEasy from '../../common/material-easy-ui/button/ButtonEasy';
import {css} from 'emotion';
import Input from '../../common/forms/inputs/Input';

import logo from '../../../image/logo/popvox.png';
import history from '../../../utils/history';

const SignInStepPassword = props => {

  return (
    <div
      className={css`
        flex-direction: column;
        display: flex;
        align-items: center;
        height: calc(100% - 50px);
      `}>
      <div
        className={css`
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
        `}>
          <div style={{width: '100%', textAlign: 'center', paddingTop: 30}}>
            <img
              alt="popvox"
              style={{width: 'auto', height: 80}}
              src={logo}
            />
          </div>
        <div
          className={css`
            font-weight: 600;
            text-align: center;
            margin-top: 20px;
          `}>
          Veuillez indiquer votre adresse email et assigner un mot de passe à votre compte
        </div>

        <Input
          onBlur={props.onBlurEmail}
          width={'80%'}
          style={{
            width: '80%',
            height: 40,
            marginTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
            display: 'flex',
            alignItems: 'center',
            paddingTop: 5,
            paddingBottom: 5,
          }}
          inputStyle={{fontSize: 18}}
          labelStyle={{color: '#cccccc'}}
          clearInput={props.clearInput}
          onChangeText={props.onChangeEmail}
          placeholder={'Votre adresse mail'}
          text={props.email}
          error={props.error_email}
          onKeyDown={props._handleKeyDown}
        />

        <InputPassword
          width={'80%'}
          style={{
            marginTop: 30,
            height: 40,
            paddingLeft: 20,
            paddingRight: 20,
            display: 'flex',
            alignItems: 'center',
            paddingTop: 5,
            paddingBottom: 5,
          }}
          // onKeyDown={(e) => {
          //   if (e.key === 'Enter') {
          //     props.sendPassword();
          //   }
          // }}
          inputStyle={{fontSize: 18}}
          labelStyle={{color: '#cccccc'}}
          label={'Mot de passe'}
          placeholder={'Choisir un mot de passe'}
          value={props.password}
          onChange={props.onChangePassword}
          onBlur={props.onBlurPassword}
          // error={props.error_password}
        />

        <InputPassword
          width={'80%'}
          style={{
            marginTop: 20,
            marginBottom: 30,
            height: 40,
            paddingLeft: 20,
            paddingRight: 20,
            display: 'flex',
            alignItems: 'center',
            paddingTop: 5,
            paddingBottom: 5,
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              props.sendPassword();
            }
          }}
          inputStyle={{fontSize: 18}}
          labelStyle={{color: '#cccccc'}}
          label={'Mot de passe'}
          placeholder={'Choisir un mot de passe'}
          value={props.secondPassword}
          onChange={props.setSecondPassword}
          // onBlur={props.onBlurPassword}
          error={props.error_password}
        />
      </div>

      <div
        className={css`
          height: 100px;
          width: 100%;
          margin-top: 20px;
          text-align: center;
        `}>
        <ButtonEasy
          onPress={props.sendPassword}
          width={'200px'}
          text={'Valider'}
        />
      </div>

      <div
        style={{
          marginBottom: 20,
          fontSize: 15,
          textAlign: 'center',
          color: '#29354a',
        }}>
        Déjà un compte ?{' '}
        <span
          style={{
            color: 'rgb(245,93,66)',
            marginLeft: 7,
            cursor: 'pointer'
          }}
          onClick={() => history.replace('/connexion')}>
          {' '}
          Je me connecte à PopVox
        </span>
      </div>

      <div style={{border: "2px solid #f55d42", padding: 10, textAlign: 'center', borderRadius: 20, marginBottom: 20}}>
        Des difficultés à créer votre compte PopVox ? Contactez-nous par mail à l&apos;adresse <span style={{color: "#f55d42"}}>contact@setavoo.fr </span>
        ou directement par téléphone : <span style={{color: "#f55d42"}}>06 24 72 11 86</span>.
      </div>
    </div>
  );
};

export default SignInStepPassword;
