import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasySearch = (props) =>{
    return(
      <IconPattern size={props.size}>
      <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
          `}
       viewBox="0 0 32 32">
    <path
    fill={props.color}
    d="M17.601,18.911c-0.871,0.702 -1.868,1.246 -2.936,1.58c-3.629,1.132 -7.895,-0.282 -10.107,-3.508c-1.893,-2.761 -2.095,-6.567 -0.419,-9.533c0.77,-1.363 1.904,-2.519 3.248,-3.319c0.57,-0.339 1.426,-0.082 1.574,0.6c0.1,0.46 -0.185,0.9 -0.621,1.165c-2.135,1.315 -3.473,3.797 -3.356,6.31c0.157,3.41 3.075,6.534 6.722,6.684c2.665,0.109 5.318,-1.437 6.532,-3.826c1.103,-2.17 0.966,-4.92 -0.354,-6.974c-0.927,-1.442 -2.396,-2.52 -4.059,-2.974c0,0 -0.633,-0.248 -0.742,-0.766c-0.114,-0.538 0.301,-1.129 0.851,-1.2c0.149,-0.02 0.186,-0.006 0.235,-0.003c0.522,0.076 1.015,0.289 1.502,0.507c3.877,1.731 6.188,6.384 5.002,10.646c-0.324,1.166 -0.899,2.253 -1.656,3.194l1.467,1.467c0.389,-0.187 0.817,-0.294 1.251,-0.301c0.036,-0.001 0.036,-0.001 0.072,0c0.77,0.013 1.525,0.326 2.074,0.865c1.428,1.419 2.851,2.842 4.27,4.27c0.911,0.929 1.124,2.463 0.468,3.604c-0.902,1.572 -3.325,2.011 -4.717,0.646c-1.428,-1.419 -2.852,-2.842 -4.27,-4.27c-0.858,-0.874 -1.101,-2.287 -0.573,-3.405l-1.458,-1.459Zm4.158,1.752c-0.654,0.011 -1.169,0.758 -0.917,1.379c0.049,0.121 0.123,0.232 0.215,0.325c1.411,1.42 2.831,2.831 4.246,4.247c0.57,0.569 1.742,0.134 1.71,-0.745c-0.009,-0.245 -0.112,-0.485 -0.283,-0.661c-1.403,-1.431 -2.834,-2.834 -4.251,-4.251c-0.19,-0.191 -0.431,-0.296 -0.72,-0.294Z"/>
    </svg>
  </IconPattern>
    )
}

export default IconEasySearch;
