import React, { useEffect, useState } from "react";
import { server_img_headers } from "../../../config/constants";

import { css } from "emotion";
import { useSelector, useDispatch } from "react-redux";
import Connexion from "../../connexion/Connexion";

import { server_img_logos } from "../../../config/constants";
import IconEasySubscribe from "../../common/icons/IconEasySubscribe";
import IconEasyBeware from "../../common/icons/IconEasyBeware";
import ModalMessage from "../../common/material-easy-ui/modal/ModalMessage";

import { addfavoritecity, removefavoritecity } from "../../../redux/actions/cityActions";

const ImageHome = () => {
  const dispatch = useDispatch();
  const info_city = useSelector((state) => state.city.infoCity);
  const popkey = useSelector((state) => state.user.popkey);
  const [showAlert, setShowAlert] = useState(false);
  const [connect, setConnect] = useState(false);

  const toggleFavoriseCity = () => {
    if (popkey && popkey !== null) {

      if(info_city && info_city.favorite > 0) {
        dispatch(removefavoritecity(popkey, info_city.insee_com));
      } else {
        dispatch(addfavoritecity(popkey, info_city.insee_com));
      }

    } else {
      setConnect(true);
    }
  };

  const onBackDetails = () => {
    setConnect(false);
  };

  useEffect(() => {
    if (info_city.premium === 0) {
      setShowAlert(true);
    }
  }, [info_city]);

  return (
    <>
      {connect && <Connexion onBack={onBackDetails} />}
      <div
        className={css`
          padding-bottom: 2px;
        `}>
        <div
          className={css`
            width: 100;
            box-sizing: border-box;
            position: relative;
          `}>
          {info_city.premium === 0 && (
            <div
              onClick={() => setShowAlert(true)}
              className={css`
                background-color: rgb(245, 93, 66);
                width: 100%;
                height: 28px;
                color: #fff;
                font-size: 14px;
                line-height: 28px;
                font-weight: bold;
                padding-top: 1px;
                text-align: center;
                &:hover {
                  opacity: 0.8;
                  cursor: pointer;
                }
              `}>
              <IconEasyBeware color="#fff" size={14} /> &nbsp;&nbsp;Mairie non
              adhérente...{" "}
              <span style={{ textDecoration: "underline", marginLeft: 10 }}>
                En savoir plus
              </span>{" "}
              ?
            </div>
          )}

          {showAlert && (
            <ModalMessage
              close={() => setShowAlert(false)}
              text1={"Votre ville n'est pas encore adhérente à PopVox... 😢😳"}
              text2={
                "En attendant, retrouvez l'actualité sur les villes en transition, participez à nos sondages, intégrez des communautés sur les sujets qui vous intéressent, visualisez les messages des citoyens aux mairies des alentours et participez à votre tour à l'amélioration de votre commune !"
              }
              text3={
                "... En espérant que votre mairie se dote rapidement de PopVox pour vous répondre !"
              }
              textHeader={"Malheureusement..."}
            />
          )}

          <div
            className={css`
              position: relative;
              width: 100%;
              height: 180px;
              background-image: linear-gradient(
                  rgba(0, 0, 0, 0.3),
                  rgba(0, 0, 0, 0.3)
                ),
                url(${server_img_headers + info_city.url_header});

              background-repeat: no-repeat;
              background-origin: content-box;
              background-position: center;
              background-size: cover;
            `}>
            <div
              className={css`
                left: 20px;
                bottom: 50%;
                margin-bottom: -50px;
                position: absolute;
                background-color: white;
                border-radius: 50%;
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 1px;
                padding-right: 1px;
              `}>
              <img
                style={{ maxWidth: "70px", maxHeight: "70px" }}
                src={server_img_logos + info_city.urlimg}
                alt=""
              />
            </div>

            <div
              className={css`
                line-height: 40px;
                position: absolute;
                top: 65px;
                left: 140px;
                font-size: 20px;
                color: white;
                font-weight: 600;
                text-shadow: 0 2px 3px rgba(0, 0, 0, 0.8);
                line-height: 1.1;
                @media (max-width: 1000px) {
                  top: 25px;
                }
              `}>
              {info_city.nom_comm}

              {info_city.fullname && info_city.fullname !== "" && (
                <div
                  className={css`
                    font-size: 16px;
                    opacity: 0.8;
                    padding-top: 5px;
                    font-weight: 400;
                  `}>
                  {info_city.fullname}
                </div>
              )}
              
            </div>

            <div
              className={css`
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding-bottom: 10px;
                position: relative;
                top: calc(150px - 70px);
                padding-right: 20px;
                box-sizing: border-box;
              `}>
              <div
                style={{
                  border: info_city.favorite
                    ? "1px solid #b51847"
                    : "1px solid #f5f9fa",
                }}
                onClick={toggleFavoriseCity}
                className={css`
                  cursor: pointer;
                  color: white;
                  background-color: #fff;
                  border-radius: 10px;
                  padding-top: 6px;
                  padding-bottom: 6px;
                  width: 140px;
                  box-sizing: border-box;
                  &:active {
                    background: #9c4130;
                  }
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}>

                <IconEasySubscribe
                  color={info_city.favorite ? "rgb(245,93,66)" : "#29354a"}
                  size={22}
                />

                <div
                  style={{
                    marginLeft: 10,
                    width: 87,
                    textAlign: "left",
                    color: info_city.favorite ? "rgb(245,93,66)" : "#29354a",
                  }}>
                  {info_city.favorite ? " Abonné " : "S'abonner"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageHome;
