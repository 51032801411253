import React from 'react';
import {css} from 'emotion';
import CloseButtonEasy from '../../button/CloseButtonEasy';

const HeaderModal = ({text, ...props}) => {
  return (
    <div
      className={css`
        height: 50px;
        width: 100%;
        display: flex;
        background: white;
        flex-direction: row;
        border-bottom: 1px solid #ededed;
      `}>
      <div
        className={css`
          flex: 1;
        `}></div>
      <div
        className={css`
          justify-content: center;
          align-items: center;
          display: flex;
        `}>
        <div
          className={css`
            text-align: center;
            line-height: 16px;
            font-size: 16px;
            font-weight: bold;
            color: rgb(41, 53, 74);
          `}>
        {text}
        </div>
      </div>
      <div
        className={css`
          flex: 1;
          display: flex;
          align-items: center;
          padding-right: 20px;
        `}>
        <div
          className={css`
            flex: 1;
          `}></div>
        <div
          className={css`
            display: flex;
            justify-content: center;
            min-width: 40px;
          `}>
          <CloseButtonEasy size={20} color="primary" onPress={props.onBack} />
        </div>
      </div>
    </div>
  );
};

export default HeaderModal;
