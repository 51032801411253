import React from 'react';
import {css} from 'emotion';

const checkmark = css`
  position: absolute;
   top: 0.5px;
   left: 0;
   height: 20px;
   width: 20px;
   background-color: #eee;
   border-radius: 50%;
   &:after{
     content: "";
     position: absolute;
     display: none;
   }`;


 const inpout = css`
       position: absolute;
       opacity: 0;
       cursor: pointer;
        &:checked ~ .${checkmark}{
           background-color: #29354a;
        };
       `

const container = css`
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover .${checkmark}{
      background: #ccc
    };
    & .${checkmark}:after{
      border-radius:50%;
    top: 7px;
      left: 7px;
      width: 6px;
      height: 6px;
        background: white;
    };
    & .${inpout}:checked ~ .${checkmark}{
         background-color: #29354a;
    };
    &
    .${inpout}:checked ~ .${checkmark}:after{
      display: block;
    }
    `




const RadioButton = (props) =>{

  const onChange=()=>{
      props.onChange(props.id)
  }

  return(
    <label className={container}>{props.label}
      <input
        onChange={onChange}
        id={props.id}
        className={inpout}
        type='radio' checked={props.checked} name="radio"/>
       <div className={checkmark}></div>
   </label>
  )
}


export default RadioButton;
