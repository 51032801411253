import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyHalfStars = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
          version="1.1" viewBox="0 0 32 32">
            <path
             fill={props.color}
             d="M16.072,3.002c0.513,0.082 0.904,0.51 0.928,1.038l0,12.534c-0.038,0.825 -1.122,1.342 -1.748,0.772c-0.167,-0.152 -0.282,-0.359 -0.323,-0.58c-0.016,-0.085 -0.014,-0.107 -0.018,-0.192l0,-5.939l-0.776,2.388c0,0 -0.329,0.692 -0.993,0.722l-5.892,0l4.766,3.463c0,0 0.56,0.513 0.38,1.168l-1.821,5.603l4.767,-3.463l0.08,-0.053c0.076,-0.04 0.094,-0.053 0.174,-0.083c0.66,-0.241 1.48,0.359 1.398,1.097c-0.032,0.287 -0.196,0.547 -0.425,0.729l-7.367,5.353c-0.265,0.174 -0.363,0.184 -0.541,0.197c-0.671,0.046 -1.256,-0.671 -1.066,-1.365l2.58,-7.939l-6.753,-4.906c0,0 -0.258,-0.215 -0.355,-0.454c-0.228,-0.565 0.15,-1.3 0.775,-1.418c0.085,-0.016 0.107,-0.014 0.193,-0.018l8.348,0l2.579,-7.938c0,0 0.356,-0.765 1.11,-0.716Z"/>
         </svg>
    </IconPattern>
    )
}

export default IconEasyHalfStars;
