import produce from 'immer';

const intitalState = {
  news: [],
  loadingNews: true,
  endOfNewsReached: false,
  nbOfNews: 0,
  newsDetails: false,
  loading: false,
  newsComments: [],
  commentsNb: 0,
  likesNb: 0,
  likeLoading: false,
  loadingComments: false,
  endReachedComment: false,
};

export const newsReducers = (state = intitalState, action) => {
  switch (action.type) {

    case 'CLEAN_PUBLICATION': {
      return {
        ...state,
        newsDetails: false,
        newsComments: [],
        commentsNb: 0,
        likesNb: 0,
        endReachedComment: false,
      };
    }

    case 'GET_NEWS_SUCCESS':
      return {
        ...state,
        news: action.news,
        loadingNews: false,
        nbOfNews: action.nbOfNews,
        endOfNewsReached: action.endOfNewsReached,
      };

    case 'GET_MORE_NEWS_SUCCESS':
      let news = state.news.concat(action.news);
      return {
        ...state,
        news: news,
        loadingNews: false,
        nbOfNews: action.nbOfNews,
        endOfNewsReached: action.endOfNewsReached,
      };

    case 'INITIALISE_NEWS_DETAILS':
      return {
        ...state,
        newsDetails: action.payload,
        newsComments: [],
        commentsNb: action.payload.commentsnb,
        likesNb: action.payload.like,
      };

    case 'GET_NEWS_DETAILS_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'GET_NEWS_DETAILS_ERROR':
      return {
        ...state,
        loading: false,
      };

    case 'GET_NEWS_DETAILS_SUCCCES':
      return {
        ...state,
        loading: false,
        newsDetails: action.payload.newsDetails,
      };

    case 'GET_NEWS_LOADING':
      return {
        ...state,
        loadingNews: true,
        endOfNewsReached: false,
      };

    case 'GET_NEWS_COMMENT_LOADING':
      return {
        ...state,
        loadingComments: true,
      };
    case 'GET_NEWS_COMMENT_ERROR':
      return {
        ...state,
        loadingComments: false,
      };

    case 'GET_NEWS_COMMENT_SUCCES':
      return {
        ...state,
        loadingComments: false,
        newsComments: action.payload.newsComment,
        endReachedComment: action.payload.endReached,
      };

    case 'GET_NEWS_MORE_COMMENT_SUCCES':
      return produce(state, draftState => {
        draftState.loadingComments = false;
        draftState.newsComments = [...action.payload.newsComment, ...state.newsComments];
        draftState.endReachedComment = action.payload.endReached;
      });

    case 'POST_NEW_COMMENT_ERROR':
      return {
        ...state,
      };

    case 'POST_NEW_COMMENT_SUCCESS':
      const newsCommentNbstate = state.newsDetails.commentsnb;
      const newNewsComment = {...action.payload, newComment: true};

      return {
        ...state,
        newsComments: [...state.newsComments, newNewsComment],
        newsDetails: {
          ...state.newsDetails,
          commentsnb: parseInt(newsCommentNbstate,10) + 1,
        },
      };

    case 'POST_NEW_COMMENT_REPLY_SUCCESS':
      const newsCommentNbstate2 = state.newsDetails.commentsnb;

      var indexComment = state.newsComments.findIndex(i => {
        return i.id_comment === action.payload.ref_id_comment;
      });
      var count_ref_id = state.newsComments.filter(i => {
        return i.ref_id_comment === action.payload.ref_id_comment;
      }).length;
      return {
        ...state,
        newsComments: [
          ...state.newsComments.slice(0, indexComment + count_ref_id + 1),
          {
            ...action.payload,
            newComment: true,
          },
          ...state.newsComments.slice(indexComment + count_ref_id + 1),
        ],
        newsDetails: {
          ...state.newsDetails,
          commentsnb: parseInt(newsCommentNbstate2, 10) + 1,
        },
      };
    case 'POST_LIKE_NEWS_LOADING':
      return {
        ...state,
        likeLoading: true,
      };

    case 'POST_LIKE_NEWS_ERROR':
      return {
        ...state,
        likeLoading: false,
      };

    case 'POST_LIKE_NEWS_SUCCES':
      return {
        ...state,
        newsDetails: {
          ...state.newsDetails,
          has_liked: true,
          likes: parseInt(state.newsDetails.likes,10) + 1,
        },
        likeLoading: false,
      };
    case 'DIS_LIKE_NEWS_LOADING':
      return {
        ...state,
        likeLoading: true,
      };

    case 'DIS_LIKE_NEWS_ERROR':
      return {
        ...state,
        likeLoading: false,
      };

    case 'DIS_LIKE_NEWS_SUCCES':
      return {
        ...state,
        newsDetails: {
          ...state.newsDetails,
          has_liked: false,
          likes: parseInt(state.newsDetails.likes,10) - 1,
        },
        likeLoading: false,
      };
    case 'DIS_LIKE_NEWS_SUCCES_WITHOUT_LIST':
      return {
        ...state,
        newsDetails: {
          ...state.newsDetails,
          has_liked: false,
          likes: parseInt(state.newsDetails.likes) - 1,
        },
        likeLoading: false,
      };

    case 'DELETE_COMMENT_NEWS_LOADING':
      return {
        ...state,
        loadingDeleteComment: true,
      };
    case 'DELETE_COMMENT_NEWS_ERROR':
      return {
        ...state,
        loadingDeleteComment: false,
      };

    case 'DELETE_COMMENT_NEWS_SUCCES':
      const indexCommentDelete = state.newsComments.findIndex(i => {
        return parseInt(i.id_comment, 10) === parseInt(action.payload, 10);
      });
      const mess = {
        ...state.newsComments[indexCommentDelete],
        avatar: null,
        date_creation: Date.now(),
        description: 'Message supprimé',
        id_user: null,
        mycomment: false,
        pseudo: '',
      };
      return {
        ...state,
        loadingDeleteComment: false,
        newsComments: [
          ...state.newsComments.slice(0, indexCommentDelete),
          mess,
          ...state.newsComments.slice(indexCommentDelete + 1),
        ],
        newsDetails: {
          ...state.newsDetails,
          commentsnb: parseInt(state.newsDetails.commentsnb) - 1,
        },
      };

    default:
      return state;
  }
};
