import React, {useEffect, useRef, useState} from 'react';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';


var thescrollview = null;

const ScrollView = props => {
  const refScrolview = useRef(null);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    thescrollview = refScrolview.current;
    thescrollview && disableBodyScroll(thescrollview);
    return () => enableBodyScroll(thescrollview);
  }, []);

  useEffect(() => {
    props.infiniteScroll &&
      thescrollview.addEventListener('scroll', scrollEndBottom);
  }, []);

  const scrollEndBottom = () => {
    if (
      thescrollview.scrollTop + 10 >=
      thescrollview.scrollHeight - thescrollview.offsetHeight
    ) {
      setIsFetching(true);
    }
  };

  useEffect(() => {
    if (!isFetching) return;
    !props.endReached &&
      props.onScrollEndBottom().then(() => {
        setIsFetching(false);
      });
  }, [isFetching]);

  return (
    <div ref={refScrolview} style={{...props.style}}>
      {props.children}
    </div>
  );
};

export default ScrollView;
