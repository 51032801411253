import React from 'react';
import {css} from 'emotion';
import ButtonEasy from '../../common/material-easy-ui/button/ButtonEasy';
import Input from '../../common/material-easy-ui/input/Input';


const SigninStepPersonnalData = props => {
  return (
    <div
      className={css`
        flex-direction: column;
        display: flex;
        align-items: center;
        height: calc(100% - 50px);
      `}>
      <div
        className={css`
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
        `}>
        <div
          className={css`
            font-weight: 600;
            text-align: center;
            margin-top: 20px;
          `}>
          Quelques informations pour mieux vous connaitre...
        </div>
        
        <Input
          onChangeText={props.onChangeFirstname}
          inputStyle={{fontSize: 15}}
          label={'Prénom'}
          onBlur={props.onBlurFirstname}
          error={props.error_firstname}
          width="80%"
          placeholder="Votre prénom"
          style={{
            marginTop: 40,
            height: 30,
            paddingLeft: 20,
            paddingRight: 20,
            display: 'flex',
            alignItems: 'center',
            paddingTop: 5,
            paddingBottom: 5,
          }}
        />

        <Input
          onChangeText={props.onChangeLastname}
          inputStyle={{fontSize: 15}}
          label={'Nom'}
          onBlur={props.onBlurLastname}
          error={props.error_lastname}
          width="80%"
          placeholder="Votre nom"
          style={{
            marginTop: 40,
            height: 30,
            paddingLeft: 20,
            paddingRight: 20,
            display: 'flex',
            alignItems: 'center',
            paddingTop: 5,
            paddingBottom: 5,
          }}
        />

        <Input
          onChangeText={props.onChangePseudo}
          inputStyle={{fontSize: 15}}
          label={'Pseudo'}
          width="80%"
          placeholder="Votre pseudo (choix libre, unique !)"
          error={props.error_pseudo}
          onBlur={props.onBlurPseudo}
          style={{
            marginTop: 40,
            height: 30,
            paddingLeft: 20,
            paddingRight: 20,
            display: 'flex',
            alignItems: 'center',
            paddingTop: 5,
            paddingBottom: 5,
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              props.goCreateAccount()
            }
          }}
        />

      </div>
      
      <div
        className={css`
          height: 100px;
          width: 100%;
          margin-top: 20px;
          text-align: center;
        `}>
        <ButtonEasy
          onPress={props.goCreateAccount}
          width={'200px'}
          text={'Valider'}
        />
      </div>

      <div style={{border: "2px solid #f55d42", padding: 10, textAlign: 'center', borderRadius: 20}}>
        Des difficultés à créer votre compte PopVox ? Contactez-nous par mail à l&apos;adresse <span style={{color: "#f55d42"}}>contact@setavoo.fr </span>
         ou directement par téléphone : <span style={{color: "#f55d42"}}>06 24 72 11 86</span>.
      </div>
    </div>
  );
};

export default SigninStepPersonnalData;
