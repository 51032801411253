import React from 'react';
import {css} from 'emotion';

const ButtonEasy = props => {
  return (
    <div
      onClick={props.onPress}
      className={css`
        background-color: ${props.color ? props.color : 'rgba(81, 181, 99,1)'};
        display: inline-block;
        width: ${props.width};
        height: ${props.height}px;
        padding: 10px;
        color: white;
        border-radius: 10px;
        cursor: pointer;
        ${props.style};
        &:hover {
          background-color: ${props.hoverColor
            ? props.hoverColor
            : 'rgba(81, 181, 99,0.8)'};
        }
      `}>
      {props.text}
    </div>
  );
};

export default ButtonEasy;
