//import {history_url} from '../config/constants';
import { createBrowserHistory } from "history";

/*const history_url = window.location.pathname
  .replace("/communautes", "")
  .replace("/communautes", "")
  .replace("/notification", "")
  .replace("/budget-participatif", "")
  .replace("/details", "")
  .replace("/poster", "")
  .replace("/signinstep", "")
  .replace("/connexion", "")
  .replace("/participez", "")
  .replace("/confirm_email", "")
  .replace("/alerte", "")
  .replace("/actu", "")
  .replace("/sondage", "")
  .replace("/evenement", "")
  .replace("/participation", "")
  .replace("/geolocation", "")
  .replace("/edit-participation", "")
  .replace("/profile", "")
  .replace("/edit", "")
  .replace("/new-password", "")
  .replace("//", "/");*/

let indexofapp =
  window.location &&
  window.location.pathname &&
  window.location.pathname.indexOf("/app") > 0
    ? window.location.pathname.indexOf("/app") + 4
    : -1;

let history_url = "/app/";

if (indexofapp > 0) {
  history_url = window.location.pathname.substring(0, indexofapp) + "/";
}

export default createBrowserHistory({ basename: history_url });

//export default createBrowserHistory();
