import React from 'react';
import {css} from 'emotion';
import IconEasyFb from '../../../common/icons/IconEasyFb';
import IconEasyTwitter from '../../../common/icons/IconEasyTwitter';
import {server_api_url} from '../../../../config/constants';

const ModalMore = props => {

  const shareOnFb = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var windowReference = window.open();
    let theurl = server_api_url + "/publications/share/" + props.id_publicpart;
    windowReference.location = "https://www.facebook.com/sharer/sharer.php?u="+theurl;
  }

  const shareOnTwitter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var windowReference = window.open();
    let theurl = server_api_url + "/publications/share/" + props.id_publicpart;
    windowReference.location = "https://twitter.com/share?url="+theurl;
  }


  return (
    <>
      <div
        className={css`
          background-color: white;
          position: absolute;
          z-index: 999;
          right: 15px;
          top: 15px;
          border-radius: 10px;
          border: 1px solid rgba(24, 62, 100, 0.3);
          box-sizing: border-box;
          overflow: hidden;
          -moz-box-shadow: 3px 3px 14px 0px rgba(24, 62, 100, 0.1);
          -webkit-box-shadow: 3px 3px 14px 0px rgba(24, 62, 100, 0.1);
          -o-box-shadow: 3px 3px 14px 0px rgba(24, 62, 100, 0.1);
          box-shadow: 3px 3px 14px 0px rgba(24, 62, 100, 0.1);
        `}>

        {
          !props.private &&
          <>
            <div
            onClick={event => {
              shareOnFb(event);
            }}
            className={css`
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            &:hover {
              background-color: #ffeeed;
            }
            `}>
              <div>
                <IconEasyFb color={'rgba(24,62,100,1)'} size="20" />
              </div>
              <div
                className={css`
                margin-left: 10px;
                font-size: 13px;
                color: rgba(24, 62, 100, 1);
                `}>
                Partager sur Facebook
              </div>
            </div>


            <div
            onClick={event => {
              shareOnTwitter(event);
            }}
            className={css`
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            &:hover {
              background-color: #ffeeed;
            }
            `}>
              <div>
                <IconEasyTwitter color={'rgba(24,62,100,1)'} size="20" />
              </div>
              <div
                className={css`
                margin-left: 10px;
                font-size: 13px;
                color: rgba(24, 62, 100, 1);
                `}>
                Partager sur Twitter
              </div>
            </div>
          </>
        }
        
      </div>

      <div
      onClick={props.onHideModal}
        className={css`
          position: fixed;
          top: 0px;
          left: 0px;
          z-index: 10;
          width: 100vw;
          height: 100vh;
        `}></div>
    </>
  );
};

export default ModalMore;
