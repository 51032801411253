import React from 'react';
import {css} from 'emotion';

import {timeSpentFromSeconds} from '../../../utils/date';

const HeaderCardNotification = props => {
  return (
    <div
      className={css`
        padding-top: 10px;
        padding-right: 10px;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        color: grey;
        font-size: 13px;
      `}>
      {timeSpentFromSeconds(props.time)}
    </div>
  );
};

export default HeaderCardNotification;
