import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyConnexion = (props) =>{
    return(
      <IconPattern size={props.size}>
            <svg
            className={css`
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
                `}
              data-name="Layer 1"
              id="Layer_1"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg">
            <title/>
              <path d="M12.9443,11.2359a1,1,0,0,0-.8906-1.7911,8.88,8.88,0,1,0,7.8926,0,1,1,0,0,0-.8906,1.7911,6.88,6.88,0,1,1-6.1114,0Z" fill={props.color}/>
              <path d="M17,13.9654v-7.25a1,1,0,1,0-2,0v7.25a1,1,0,1,0,2,0Z" fill={props.color}/>
            </svg>
        </IconPattern>
    )
}

export default IconEasyConnexion;
