import React, {useEffect, useRef} from 'react';
import './styles.css';
import InfoUserAccountMenu from './components/InfoUserAccountMenu';
import ConnexionAccountMenu from './components/ConnexionAccountMenu';
import CreateAccountMenu from './components/CreateAccountMenu';
import ProfileMenuAccount from './components/ProfileMenuAccount';

import history from '../../utils/history';
import {useSelector, useDispatch} from 'react-redux';

const MenuAccount = props => {
  //DISPATCH
  const dispatch = useDispatch();
  // REF
  const body_menu_account = useRef();
  const profil_menu_account = useRef();
  const connexion_menu_account = useRef();
  const create_menu_account = useRef();
  const info_user = useSelector(state => state.user.info_user);

  const onCloseMenuAccount = e => {

    if (
      body_menu_account.current &&
      body_menu_account.current.contains(e.target)
    ) {
      if (
        profil_menu_account.current &&
        profil_menu_account.current.contains(e.target)
      ) {
        history.push('/profile');
        props.onCloseMenuAccount();
      }

      else if (
        connexion_menu_account.current &&
        connexion_menu_account.current.contains(e.target)
      ) {
        if (info_user) {
          dispatch({type: 'DISCONNECT'});
          localStorage.setItem('popvox@popkey', '');
          props.onCloseMenuAccount();
          history.push('/');
          
        } else {
          history.push('/connexion');
          props.onCloseMenuAccount();
        }
      } else if (
        create_menu_account.current &&
        create_menu_account.current.contains(e.target)
      ) {
        history.push('/signinstep');
        props.onCloseMenuAccount();
      } else {
        return;
      }
    } else
      props.onCloseMenuAccount();

  };

  useEffect(() => {
    window.addEventListener('click', onCloseMenuAccount);
    return () => {
      window.removeEventListener('click', onCloseMenuAccount, false);
    };
  }, []);

  return (
    <div ref={body_menu_account} className="container-menu-account">
      {info_user && (
        <>
          <InfoUserAccountMenu />
          <ProfileMenuAccount ref={profil_menu_account} />
        </>
      )}
      <ConnexionAccountMenu ref={connexion_menu_account} />
      {!info_user &&
        <CreateAccountMenu ref={create_menu_account} />
      }

    </div>
  );
};

export default MenuAccount;
