import React, {useState} from 'react';
import {css} from 'emotion';
import ReactMapboxGl, {ZoomControl} from 'react-mapbox-gl';

import MarkerMap from './MarkerMap';
import Button from '../../common/forms/buttons/Button';

//import IconEasyGeoloc from '../../common/icons/IconEasyGeoloc';

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoianVzcHJpZXQiLCJhIjoiY2o5cHRzMjB1MnVxMTJ4czJoaTdhZjVjbCJ9.3LZxp2Lf97TcCYWPAgsyyA',
});

const ParticipationMap = props => {
  const [mapVisible, setMapVisible] = useState(false);

  const _toggleVisibility = () => {
    setMapVisible(!mapVisible);
  };

  return (
    <>
      {mapVisible ? (
        <div
          className={css`
            height: 200px;
            flex: 1;
            padding: 20px;
          `}>
          <Map
            center={[props.lon, props.lat]}
            zoom={[14]}
            // eslint-disable-next-line
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
              height: '100%',
              width: '100%',
            }}>
            <ZoomControl />

            <MarkerMap type={props.type} coord={[props.lon, props.lat]} />
          </Map>
        </div>
      ) : (
        <div
          style={{
            paddingTop: 20,
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          <Button style={{marginRight: 20}} onClick={_toggleVisibility}>
            {/*<IconEasyGeoloc color={'#f29483'} size={20} />*/}
            <div >Carte</div>
          </Button>
        </div>
      )}
    </>
  );
};

export default ParticipationMap;
