import React from 'react';
import BpPresentationBlock from './components/BpPresentationBlock';
import WelcomeBlock from './components/WelcomeBlock';
import FreemiumBlock from './components/FreemiumBlock';

import {useSelector} from 'react-redux';
import './RightMenu.css'

const NotificationRightMenu = () => {
const infoCity = useSelector(state => state.city.infoCity);

  return (
    <div className="rightMenuContainer">
      {infoCity && infoCity.premium !== 1 ? <FreemiumBlock /> : <WelcomeBlock />}
      <BpPresentationBlock infoCity={infoCity} />

    </div>
  );
};

export default NotificationRightMenu;
