import React, {useState} from 'react';
import {css} from 'emotion';
import  { Popup, Marker } from 'react-mapbox-gl';

import IconEasyWarning from '../../common/icons/IconEasyWarning';

//import IconEasyGeoloc from '../../common/icons/IconEasyGeoloc';

const MarkerMap = props => {
  const [popupVisible, setPopupVisible] = useState(false);

  const _toggleVisibility = () => {
    setPopupVisible(!popupVisible);
  };

  return (
    <>
      <Marker onClick={_toggleVisibility} coordinates={[props.point.lng, props.point.lat]}>
        <div className={css`
            background-color:rgba(204, 49, 67,1);
            padding:5px;
            border-radius:50%;
            -moz-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74,0.1);
            -webkit-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74,0.1);
            -o-box-shadow: 3px 3px 14px 0px rgba(41, 53, 74,0.1);
            box-shadow: 3px 3px 14px 0px rgba(41, 53, 74,0.1);
        `}>
          <IconEasyWarning size={20} color="#FFF" />
        </div>
      </Marker>
      {
        popupVisible &&
        <Popup
            offset={{
             'bottom-left': [12, -38],  'bottom': [0, -38], 'bottom-right': [-12, -38]
           }}
          coordinates={[props.point.lng, props.point.lat]}>
          <h1>{props.point.description}</h1>
        </Popup>
      }

      </>
  );
};

export default MarkerMap;
