import React, {useState} from 'react';
import {css} from 'emotion';
import IconEasyArrowBack from '../../icons/IconEasyArrowBack';

const HeaderPage = (props) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={css`
        position: fixed;
        width: inherit;
        z-index: 10;
        display: flex;
        flex-direction: row;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        align-items: center;
        background-color: white;
        height: 50px;
        border-bottom: 1px solid rgba(24, 62, 100, 0.1);
      `}>
      <div
        className={css`
          min-width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}>
        <div
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          className={css`
            cursor: pointer;
            display: inline-block;
            border-radius: 50%;
            padding: 2px;
            transition: 0.2s;
            &:hover {
              background-color: rgba(245, 93, 66, 0.1);
              padding: 8px;
            }
          `}
          onClick={props.onBack}>
          <IconEasyArrowBack
            size={25}
            color={hover ? 'rgb(245, 93, 66)' : 'rgb(41, 53, 74)'}
          />
        </div>
      </div>
      <div
        className={css`
          margin-left: 20px;
          color: rgb(41, 53, 74);
        `}>
        {props.title}
      </div>
    </div>
  );
};

export default HeaderPage;
