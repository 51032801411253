import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyPageEmpty = (props) =>{
    return(
      <IconPattern size={props.size}>
      <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
          `}
      viewBox="0 0 32 32">
      <path d="M23.012,24.586l1.293,-1.293c0.194,-0.186 0.389,-0.29 0.717,-0.293c0.013,0.001 0.026,0.001 0.039,0.001c0.752,0.053 1.248,1.069 0.685,1.678c-0.009,0.009 -0.018,0.019 -0.027,0.028l-1.293,1.293l1.293,1.293c0.535,0.557 0.164,1.665 -0.658,1.706c-0.278,0.014 -0.557,-0.1 -0.756,-0.292l-1.293,-1.293l-1.293,1.293c-0.531,0.51 -1.597,0.209 -1.699,-0.578c-0.039,-0.302 0.071,-0.614 0.285,-0.836l1.292,-1.293l-1.292,-1.293c-0.532,-0.553 -0.197,-1.663 0.657,-1.706c0.026,-0.001 0.053,-0.001 0.08,0c0.122,0.006 0.142,0.012 0.196,0.025c0.13,0.03 0.252,0.086 0.36,0.164c0.043,0.031 0.083,0.066 0.121,0.103l1.293,1.293Zm-5.996,-21.586c0.007,0 0.014,0 0.022,0c0.106,0.005 0.123,0.01 0.171,0.02c0.124,0.025 0.243,0.073 0.349,0.143c0.053,0.034 0.08,0.052 0.161,0.13l2.998,2.998c0.595,0.616 0.015,1.98 -1.004,1.662c-0.11,-0.034 -0.214,-0.087 -0.306,-0.157c-0.037,-0.028 -0.071,-0.059 -0.104,-0.09l-1.291,-1.292l0,2.586l5,0c0,0 0.492,0.056 0.739,0.326c0.499,0.548 0.139,1.633 -0.739,1.674l-6,0c-0.536,-0.025 -0.975,-0.46 -1,-1l0,-5l-8,0l0,20l9,0c0,0 0.726,0.107 0.932,0.639c0.211,0.543 -0.169,1.236 -0.749,1.344c-0.08,0.015 -0.101,0.013 -0.183,0.017l-10,0c-0.536,-0.025 -0.975,-0.46 -1,-1l0,-22c0.024,-0.536 0.46,-0.975 1,-1l10,0l0.004,0Zm6.068,10.003c0.157,0.015 0.199,0.031 0.282,0.062c0.346,0.131 0.605,0.467 0.641,0.839c0.004,0.042 0.004,0.053 0.005,0.096l0,6c-0.015,0.597 -0.611,1.11 -1.216,0.977c-0.44,-0.098 -0.773,-0.517 -0.784,-0.977l0,-6c0.004,-0.178 0.021,-0.232 0.056,-0.332c0.129,-0.364 0.483,-0.637 0.871,-0.665c0.094,-0.007 0.115,-0.002 0.145,0Z"
      fill={props.color}/>
      </svg>
    </IconPattern>
    )
}

export default IconEasyPageEmpty;
