import React from 'react';
import WelcomeBlock from './components/WelcomeBlock';
import FreemiumBlock from './components/FreemiumBlock';
import GroupBlock from './components/GroupBlock';

import FavoriteCitiesBlock from './components/FavoriteCitiesBlock';
import {useSelector} from 'react-redux';

const HomeRightMenu = () => {
  const infoCity = useSelector(state => state.city.infoCity);

  return (
    <div className="rightMenuContainer">
      {infoCity && infoCity.premium !== 1 ? <FreemiumBlock /> : <WelcomeBlock />}
      <GroupBlock />
      <FavoriteCitiesBlock />
    </div>
  );
};

export default HomeRightMenu;
