import React, {useState, useEffect} from 'react';
import FullModal from '../common/material-easy-ui/modal/FullModal';
import Input from '../common/material-easy-ui/input/Input';
import InputPassword from '../common/material-easy-ui/input/InputPassword';
import PlainButton from '../common/forms/buttons/PlainButton';

import logo from '../../image/logo/popvox.png';

import './styles.css';
import Alerte from '../common/material-easy-ui/modal/Alerte';

import {useDispatch, useSelector} from 'react-redux';
import {goConnect, forgotPwd} from '../../redux/actions/userActions';
import history from '../../utils/history';

const Connexion = props => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const [error_pwd, setError_pwd] = useState(null);
  const [alert, setAlert] = useState(false);

  const popkey = useSelector(state => state.user.popkey);
  const signupError = useSelector(state => state.user.signupError);
  const new_pwd_sent = useSelector(state => state.user.new_pwd_sent);
  const error_new_pwd_sent = useSelector(state => state.user.error_new_pwd_sent,);

  const onChangeEmail = e => setEmail(e.target.value.toLowerCase().trim());

  const onChangePassword = e => setPassword(e.target.value);

  const removeAlert = () => {
    setAlert(false);
  };

  const onChoose = value => {
    setAlert(false);
    if (value) {
      if (email.length > 0) {
        dispatch(forgotPwd(email));
      }
    }
  };

  useEffect(() => {
    if (popkey !== null) {
      localStorage.setItem('popvox@popkey', popkey);
      if (typeof props.onBack === "function") {
        props.onBack();
      } else {
        history.goBack();
      }
    }
  }, [popkey]);

  useEffect(() => {
    if (error_new_pwd_sent === 1) {
      setError_pwd('Email non existant...');
    }
  }, [error_new_pwd_sent]);

  const onSend = () => {
    dispatch(goConnect(email, password));
  };

  const goToSignIn = () => {
    history.replace('/signinstep');
  };

  const verifyEmail = () => {
    setError_pwd('');
    if (email.length > 1 && email.indexOf('@' > 0)) {
      setAlert(true);
    } else {
      setError_pwd('Merci de renseigner un email !');
    }
  };

  const onBlurPassword = () => {};

  return (
    <FullModal
      textHeader="Connectez vous"
      id="modal-doyouwantconnect"
      onBack={() => {
        if (typeof props.onBack === "function") {
          props.onBack();
        } else {
            if (document.referrer === "") {
              history.replace('/');
            } else {
              if(history.length > 0) {
                history.go(-1);
              } else {
                history.replace('/');
              }
            }
        }
      }}>
        
      <div className="modal-connexion-container">

        <div style={{width: '100%', textAlign: 'center', paddingTop: 30}}>
          <img
            alt="popvox"
            style={{width: 'auto', height: 80}}
            src={logo}
          />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 10,
          }}>
          <Input
            width={'80%'}
            style={{
              width: '80%',
              height: 40,
              paddingLeft: 20,
              paddingRight: 20,
              display: 'flex',
              alignItems: 'center',
              paddingTop: 5,
              paddingBottom: 5,
            }}
            inputStyle={{fontSize: 15}}
            labelStyle={{color: '#cccccc'}}
            onChangeText={onChangeEmail}
            placeholder={'Adresse email'}
            text={email}
          />

          <InputPassword
            width={'80%'}
            style={{
              marginTop: 10,
              height: 40,
              paddingLeft: 20,
              paddingRight: 20,
              display: 'flex',
              alignItems: 'center',
              paddingTop: 5,
              paddingBottom: 5,
            }}
            inputStyle={{fontSize: 15}}
            labelStyle={{color: '#cccccc'}}
            placeholder={'Mot de passe'}
            value={password}
            onBlur={onBlurPassword}
            onChange={onChangePassword}
          />
        </div>

        {alert && (
          <Alerte
            btnText={'Envoyer'}
            onChoose={onChoose}
            onBack={removeAlert}
            text={'Voulez vous recevoir un mot de passe temporaire par email ?'}
            textHeader={'Attention'}
          />
        )}

        <div
          style={{
            margin: '0 auto',
            marginTop: '20px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <PlainButton size={'80%'} onClick={onSend}>Connexion</PlainButton>
        </div>

        <div style={{textAlign: 'center', fontSize: 14, color: 'red'}}>
          {signupError}
        </div>

        {new_pwd_sent ? (
          <div className="modal-description">
            Un nouveau mot-de-passe temporaire vous a été envoyé par mail....
          </div>
        ) : (
          <div onClick={verifyEmail} className="modal-link">
            Mot de passe oublié ?{' '}
            <span style={{color: 'red'}}>
              {' ' + error_pwd ? error_pwd : ''}
            </span>
          </div>
        )}

        <div
          style={{
            marginTop: 60,
            fontSize: 15,
            textAlign: 'center',
            color: '#29354a',
          }}>
          Pas de compte ?{' '}
          <span
            style={{
              color: 'rgb(245,93,66)',
              marginLeft: 7,
              cursor: 'pointer'
            }}
            onClick={goToSignIn}>
            {' '}
            Je m&apos;inscris à PopVox
          </span>
        </div>

        <div style={{height: 50, width: '100%'}} />
      </div>

    </FullModal>
  );
};

export default Connexion;
