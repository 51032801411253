import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyLoad = (props) =>{
    return(
      <IconPattern size={props.size}>
    <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        `}
    version="1.1" viewBox="0 0 15.979 15.989">
  <path d="M1.997,7.947c0.022-3.146,2.423-5.708,5.5-5.94  c0.275-0.021,0.5-0.241,0.5-0.518v-1c0-0.276-0.224-0.504-0.499-0.488C3.48,0.222,0.288,3.415,0.016,7.433  c-0.019,0.275-0.021,0.725-0.007,1c0.229,4.188,3.682,7.526,7.933,7.557c4.251,0.029,7.749-3.299,8.037-7.484  c0.019-0.275-0.205-0.497-0.481-0.493l-1,0.013c-0.276,0.004-0.499,0.231-0.524,0.506c-0.275,3.078-2.872,5.48-6.017,5.459  C4.642,13.966,1.974,11.261,1.997,7.947z" fill={props.color}/>
    </svg>
    </IconPattern>
    )
}

export default IconEasyLoad;
