import React, {useEffect, useRef} from 'react';
import {css} from 'emotion';

import IconEasyLike from '../../../common/icons/IconEasyLike';

import {getFavoriteCities, setCityInfo} from '../../../../redux/actions/cityActions';
import history from "../../../../utils/history";

import {useSelector, useDispatch} from 'react-redux';

const FavoriteCities = () => {
  const childRef = useRef(null);
  const dispatch = useDispatch();

  const favoriteCities = useSelector(state => state.city.favoriteCities);
  const popkey = useSelector(state => state.user.popkey);


  const onChoose = item => {
    history.push({
      pathname: "/?code_insee=" + item.insee_com
    })
    history.go(0);
  };

  useEffect(() => {
    dispatch(
      getFavoriteCities(popkey)
    );
  }, [popkey]);

    if(favoriteCities && favoriteCities.length > 0) {

      return (
        <div
          className={css`
            width: 100%;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 15px;
            margin-top: 30px;
            margin-bottom: 20px;
            background: #fff;
            border: 1px solid rgba(24,62,100,0.05);
          `}>
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            `}>
            <div
              className={css`
                color: #29354a;
                font-size: 17px;
                text-align: left;
                flex: 1;
                line-height: 1;
                font-weight: 600;
              `}>
              Villes favorites
            </div>
            <div>
              <IconEasyLike color={'#29354a'} size={20} />
            </div>
          </div>
          <div
            ref={childRef}
            className={css`
              border-top: 1px solid #ebebeb;
              margin-top: 10px;
            `}>
            {favoriteCities.map((items, keys) => {

              return items.data.map((item, key) => {

                return (
                  <div
                    onClick={() => onChoose(item)}
                    key={key}>
                    <div
                      style={{
                        cursor: 'pointer',
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderTop: (key + keys !== 0) && '1px solid #e8e8e8',
                        display: 'flex',
                        flexDirection: 'row',
                      }}>
                      <div
                        style={{paddingLeft: 10, flex: 1}}
                        key={key}>
                        {item.nom_comm}
                      </div>
                      
                    </div>
                  </div>
                );
              });
            })}
          </div>

        </div>
      );
    }

  return false;
};

export default FavoriteCities;
