import React from 'react';
import {css} from 'emotion';

import ItemMenuParticipate from './components/ItemMenuParticipate';
import FooterParticipateStep from '../participate-step/components/FooterParticipateStep';

const ParticipateMenu = props => {
  const thematics = props.thematics ? props.thematics : [];

  return (
    <div style={{paddingBottom: 100}}>
      <div
        className={css`
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 20px;
      `}>

      {thematics && thematics.types && thematics.types.map((item, i) => {
        return (
          <ItemMenuParticipate
            key={i}
            onPress={props.onSelect}
            id={item.id}
          />
        );
      })}
      </div>

      <FooterParticipateStep>
        <div
          onClick={props.onBackStep}
          className={css`
            flex: 1;
            text-align: center;
            cursor: pointer;
            &:hover {
              background-color: #f55d42;
              color: white;
            }
            transition: 0.3s;
          `}>
          Précédent
        </div>
        <div
          className={css`
            flex: 1;
            color: rgba(41, 53, 74, 0.1);
            text-align: center;
            border-left: 1px solid rgba(41, 53, 74, 0.1);
            &:hover {
              background-color: rgba(41, 53, 74, 0.1);
              color: white;
            }
            transition: 0.3s;
          `}>
          Suivant
        </div>
      </FooterParticipateStep>

    </div>
  );
};

export default ParticipateMenu;
