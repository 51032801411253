import React from 'react';
import {css} from 'emotion';
import Input from '../../../../common/material-easy-ui/input/Input';

const InputTitle = props => {
  return (
    <>
      <div
        className={css`
          margin-bottom: props.subtitle ? 0px : 10px;
          font-weight: 600;
          ${props.style}
        `}>
        {props.title}{' '}
        {props.facultatif ? (
          <span
            className={css`
              font-size: 12px;
            `}>
            (facultatif)
          </span>
        ) :
        <span
          className={css`
            font-size: 12px;
          `}>
           *
        </span>
        }
      </div>

{
  props.subtitle &&
  <div
    className={css`
      margin-bottom: 10px;
      font-size: 12px;
      color: grey;
    `}>
    {props.subtitle}
  </div>
}





      <Input
        style={{
          width: '100%',
          height: 40,
          paddingLeft: 20,
          paddingRight: 20,
          display: 'flex',
          alignItems: 'center',
          paddingBottom: 5,
        }}
        type={props.type}
        inputStyle={{fontSize: 15}}
        labelStyle={{color: '#cccccc'}}
        onChangeText={props.onChangeText}
        text={props.text}
      />
    </>
  );
};

export default InputTitle;
