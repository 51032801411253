import React, {useEffect, useState} from 'react';
import {css, keyframes} from 'emotion';
import './styles.css';

var timerHandle;

const bounce = keyframes`
  0%{
    transform: scale(0.9);
  }
  10% {
    box-shadow: 0 0 0 25px rgba(245,93,66,0.1);
    transform: scale(1);
  }
  40% {
    box-shadow: 0 0 0 0 rgba(245,93,66,0.1);
    transform: scale(0.9);
  }
  20% {
    box-shadow: 0 0 0 0 rgba(245,93,66,0.1);
    transform: scale(0.9);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(245,93,66,0.1);
    transform: scale(0.9);
  }
`;

const BudgetpartButton = props => {

  const [showBubble, setShowBubble] = useState(true);

  useEffect(() => {
    timerHandle = setTimeout(() => {
      setShowBubble(false);
      timerHandle = 0;
    }, 5000);

    return cancelTimer;
  }, []);

  const cancelTimer = () => {
    if (timerHandle) {
      // ***
      // Yes, clear it                     // ***
      clearTimeout(timerHandle); // ***
      timerHandle = 0; // ***
    }
  };

  return (
    <>
      {showBubble && (
        <div
          className={css`
            cursor: pointer;
            position: fixed;
            z-index: 100;
            bottom: 135px;
            left: calc(50% + 210px);

            @media (max-width: 1510px) {
              left: calc(70% - 120px);
              @media (max-width: 1200px) {
                left: calc(60% - 120px);
                @media (max-width: 1100px) {
                  left: calc(85% - 120px);
                  @media (max-width: 678px) {
                    left: calc(75% - 120px);
                    bottom: 185px;
                  }
                }
              }
            }
          `}>
          <div className="bubble animated fadeOutDown delay-3s ">
            <div style={{paddingTop: 18, paddingLeft: 10, paddingRight: 10 }}>{props.name}</div>
          </div>
        </div>
      )}

      <div
        onClick={props.onPress}
        className={css`
          cursor: pointer;
          position: fixed;
          z-index: 100;
          bottom: 45px;
          left: calc(50% + 330px);
          display: flex;
          flex-direction: row;
          border-radius: 30px;
          background-color: rgb(245, 93, 66);
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          transition: 0.2s;
          box-shadow: 3px 3px 14px 0px rgba(245, 93, 66, 0.5);
          animation: ${bounce} 5s infinite ease-in-out;

          &:hover {
            box-shadow: 3px 3px 14px 0px #ed826f;
            animation: none;
          }

          @media (max-width: 1510px) {
            left: 70%;
            @media (max-width: 1200px) {
              left: 60%;
              @media (max-width: 1100px) {
                left: 85%;
                @media (max-width: 678px) {
                  left: 75%;
                  bottom: 95px;
                }
              }
            }
          }
        `}>
        <div style={{fontSize: 30, color: '#fff', marginTop: 3, fontWeight: 'bold', textAlign: 'center'}}>+</div>
      </div>
    </>
  );
};

export default BudgetpartButton;
