import React, { useState } from "react";
import FullModal from "../../../common/material-easy-ui/modal/FullModal";
import history from "../../../../utils/history";
import { css } from "emotion";

import ScrollView from "../../../common/scrollView/ScrollView";
import InputTitle from "./components/InputTitle";
import InputFile from "./components/InputFile";
import InputTextArea from "./components/InputTextArea";
import InputMap from "./components/InputMap";
import SelectInput from "./components/SelectInput";
import InputFileBasic from "./components/InputFileBasic";
import ButtonEasy from "../../../common/material-easy-ui/button/ButtonEasy";
import { server_api_url } from "../../../../config/constants.js";
import { useDispatch, useSelector } from "react-redux";
import Connexion from "../../../connexion/Connexion";
import BudgetPartAPI from "../../../../api/BudgetPartAPI";

import SubscribeModal from "./components/SubscribeModal";

import PointsNotification from "../../../common/material-easy-ui/modal/PointsNotification";
import Alerte from "../../../common/material-easy-ui/modal/Alerte";

const Post = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [synthese, setSynthese] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [adress, setAdress] = useState(null);
  const [marker, setMarker] = useState([null, null]);
  const [projectHolder, setProjectHolder] = useState(1);
  const [objectif, setObjectif] = useState("");
  const [phonenb, setPhoneNb] = useState("");
  const [budget, setBudget] = useState(0);
  const [rubric, setRubric] = useState(1);
  const [annex, setAnnex] = useState([]);
  const [connect, setConnect] = useState(false);
  const [subscribe, setSubcribe] = useState(false);
  const [pointNotification, setPointNotification] = useState(false);

  const popkey = useSelector((state) => state.user.popkey);
  const budget_part_session = useSelector(
    (state) => state.budgetpart.budget_part_session
  );
  const infoCity = useSelector((state) => state.city.infoCity);
  const zoomLevel = infoCity.zoom_level ? infoCity.zoom_level : 8;

  const [errorModal, setErrorModal] = useState(false);

  const onBackErrorModal = () => {
    setErrorModal(false);
  };

  const onSend = () => {
    if (
      title === "" ||
      synthese === "" ||
      image === null ||
      objectif === "" ||
      phonenb === ""
    ) {
      setErrorModal(true);
      return false;
    }

    if (!popkey) {
      setConnect(true);
    }

    var project = {
      title: title,
      synthese: synthese,
      objectiv: objectif,
      img_project: image,
      description: description,
      coord: marker,
      project_holder: projectHolder,
      budget: parseInt(budget),
      thematic: rubric,
      phonenb: phonenb,
      adress: adress,
    };

    BudgetPartAPI.postProject(
      budget_part_session.id_budget_part_session,
      popkey,
      project,
      annex,
      budget_part_session.code_insee
    )
      .then((response) => {
        if (response && response.data.status === 1) {
          dispatch({
            type: "POST_PROJECT_SUCCESS",
            payload: {
              project: response.data.project,
              marker: response.data.marker,
            },
          });
          setPointNotification(true);
        }

        if (response && response.data.status === 2) {
          setSubcribe(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeTitle = (e) => setTitle(e.target.value);
  const onChangeSynthese = (e) => setSynthese(e.target.value);
  const onChangeDescription = (e) => setDescription(e.target.value);

  const onPostImage = (file) => {
    setImage(file.pathName);
  };

  const onClearImage = () => setImage(null);

  const onPostMap = (marker, adress) => {
    setMarker(marker);
    setAdress(adress);
  };

  const onChooseProjectHolder = (id) => {
    setProjectHolder(id);
  };

  const onChangeObjectif = (e) => {
    setObjectif(e.target.value);
  };

  const onChangeBudget = (e) => {
    setBudget(e.target.value);
  };

  const onChangePhoneNb = (e) => {
    setPhoneNb(e.target.value);
  };

  const onChooseRubric = (id) => {
    setRubric(id);
  };

  const addFileAnnex = (text) => {
    setAnnex((prevState) => [...prevState, text]);
  };

  const removeFiles = (index) => {
    setAnnex((prevState) => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ]);
  };

  const onValidSubscribe = () => {
    BudgetPartAPI.subscribeBdg(
      popkey,
      budget_part_session.id_budget_part_session
    )
      .then(() => {
        setSubcribe(false);
        onSend();
      })
      .catch((error) => {
        setSubcribe(false);
        console.log(error);
        alert("Une erreur est survenue");
      });
  };

  const onBack = () => history.goBack();
  const onBackConnect = () => setConnect(false);

  return (
    <FullModal
      id="modal-budgetpart-post"
      textHeader="Déposer un projet"
      onBack={onBack}
      height={"97%"}
      footer={
        <>
          {errorModal && (
            <Alerte
              onChoose={onBackErrorModal}
              textHeader="Attention"
              text="Veuillez renseigner tous les champs obligatoires"
              btnText="Ok"
              onBack={onBackErrorModal}
            />
          )}
          {connect && <Connexion onBack={onBackConnect} />}

          {subscribe && (
            <SubscribeModal
              nom_comm={infoCity.nom_comm}
              age_limit={budget_part_session.age_limit}
              onBack={() => setSubcribe(false)}
              onValid={onValidSubscribe}
            />
          )}

          {pointNotification && (
            <PointsNotification type={201} close={() => history.goBack()} />
          )}
        </>
      }>

        <div
          className={css`
            padding: 20px;
            box-sizing: border-box;
            width: 100%;
          `}>
          {/* indispensable */}
          <InputTitle
            title={"Titre du projet"}
            text={title}
            subtitle={"(Max 100 caractères)"}
            onChangeText={onChangeTitle}
          />

          {budget_part_session && (
            <SelectInput
              onChoose={onChooseRubric}
              selected={rubric}
              width={"100%"}
              style={{ marginTop: 40 }}
              title={"Rubrique"}
              defaultText={budget_part_session.rubric.rubric[0].name}
              options={budget_part_session.rubric.rubric}
            />
          )}
          <InputTextArea
            style={{ marginTop: 40 }}
            value={objectif}
            onChange={onChangeObjectif}
            title={"Objectif du projet"}
          />

          <InputTextArea
            style={{ marginTop: 40 }}
            value={synthese}
            onChange={onChangeSynthese}
            title={"Description du projet"}
          />

          <InputFile
            onClearFileUrl={onClearImage}
            url={server_api_url + "/fileandimageuploads/participationimage"}
            onPost={onPostImage}
            title={"Illustration du projet (photo)"}
            style={{ marginTop: 40, width: "100%" }}
            fileUrl={image}
          />

          <SelectInput
            width={"100%"}
            onChoose={onChooseProjectHolder}
            style={{ marginTop: 40 }}
            title={"Porteur de projet"}
            selected={projectHolder}
            defaultText={"Citoyens"}
            options={[
              { id: 1, name: "Citoyen" },
              { id: 2, name: "Collectif citoyen" },
              { id: 3, name: "Association" },
            ]}
          />

          <InputTitle
            type={"number"}
            style={{ marginTop: 40 }}
            title={"Votre numéro de téléphone"}
            subtitle={"(Nous vous contacterons si votre projet est retenu)"}
            text={phonenb}
            onChangeText={onChangePhoneNb}
          />

          {/* facultatif */}
          <InputMap
            facultatif
            zoomLevel={zoomLevel}
            marker={marker}
            style={{ marginTop: 40 }}
            adress={adress}
            onSendData={onPostMap}
            title={"Localisation du projet"}
          />

          <InputTitle
            facultatif
            type={"number"}
            style={{ marginTop: 40 }}
            title={"Budget Estimé (€)"}
            text={budget}
            onChangeText={onChangeBudget}
          />
          <InputTextArea
            facultatif
            style={{ marginTop: 40 }}
            value={description}
            onChange={onChangeDescription}
            title={"Infos complémentaires"}
          />
          <InputFileBasic
            facultatif
            removeFiles={removeFiles}
            nameFiles={annex}
            onChangeFiles={addFileAnnex}
            url={server_api_url + "/fileandimageuploads/participationfiles"}
            style={{ marginTop: 40 }}
            title="Fichiers annexes"
          />

          {/*  ACTION SEND BACK */}

          <div
            className={css`
              margin-top: 60px;
              padding-top: 20px;
              display: flex;
              border-top: 1px solid #ededed;
              justify-content: flex-end;
              align-items: center;
            `}>
            <ButtonEasy
              onPress={onBack}
              color={"#F5F8FA"}
              hoverColor={"#d1d1d1"}
              style={{
                marginRight: 20,
                color: "black",
                border: "1px solid #ededed",
              }}
              text="Annuler"
            />
            <ButtonEasy
              onPress={onSend}
              color={"rgb(242,101,35)"}
              hoverColor={"#ed9282"}
              text="Déposer"
            />
          </div>
        </div>
      
    </FullModal>
  );
};

export default Post;
