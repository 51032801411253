import React from 'react';
import {css} from 'emotion';
import TokenTitle from '../../common/material-easy-ui/material/TokenTitle';

const typeToData = {
  1: {
    entitled: 'Alerte',
    colorText: '#454545',
    color: '#fff',
  },
  2: {
    entitled: 'Actualité',
    colorText: '#454545',
    color: '#fff',
  },
  3: {
    entitled: 'Sondage',
    colorText: '#454545',
    color: '#fff',
  },
  6: {
    entitled: 'Evenement',
    colorText: '#454545',
    color: '#fff',
  },
};

const HeaderPublication = props => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
      `}>
      <TokenTitle
        text={props.type && typeToData[props.type].entitled}
        background='#fff'
        colorText='#454545'
      />
      <TokenTitle
        style={{marginLeft: '10px'}}
        text={props.community ? props.community : 'Public'}
        background='#fff'
        colorText='#454545'
      />
    </div>
  );
};

export default HeaderPublication;
