const initialState = {
  eventDetails: false,
  loading: false,
  listEvent: [],
  endReached: false,
};

export function eventReducers(state = initialState, action) {
  switch (action.type) {
    case 'INITIALISE_EVENT_DETAILS':
      return {
        ...state,
        eventDetails: action.payload,
      };
    case 'GET_EVENT_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'GET_EVENT_SUCCESS':
      return {
        ...state,
        loading: true,
        eventDetails: action.payload.eventDetails,
      };

    case 'POST_SUBSCRIBE_EVENT':
      const newEvent = {
        ...state.eventDetails,
        hasparticipate: action.payload,
        nbparticipate: !action.payload
          ? state.eventDetails.nbparticipate - 1
          : state.eventDetails.nbparticipate + 1,
      };
      //const indexEvent = state.listEvent.findIndex(i=> {return i.id_publicpart === state.event.id_publicpart});

      return {
        ...state,
        loading: true,
        eventDetails: newEvent,
        //listEvent:[...state.listEvent.slice(0,indexEvent),newEvent,...state.listEvent.slice(indexEvent+1)]
      };

    case 'GET_LIST_EVENT_LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'GET_LIST_EVENT_SUCCESS':
      return {
        ...state,
        listEvent: action.payload.events,
        nbEvents: action.payload.nbEvents,
        endReached: action.payload.endReached,
        isLoading: false,
      };
    case 'GET_MORE_LIST_EVENT_SUCCESS':
      return {
        ...state,
        listEvent: [...state.listEvent, ...action.payload.events],
        endReached: action.payload.endReached,
        isLoading: false,
      };

    case 'CLEAN_EVENT':
      return {
        ...state,
        eventDetails: false,
      };

    default:
      return state;
  }
}
