import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyNotification = (props) =>{
    return(
      <IconPattern size={props.size}>
      <svg
      className={css`
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
          `}
       viewBox="0 0 32 32">
    <path
     fill={props.color}
     d="M6.988,17.001l0,-5.008c0.012,-3.662 2.442,-7.216 5.965,-8.482c0.994,-0.357 2.048,-0.531 3.105,-0.528c3.675,0.035 7.236,2.517 8.462,6.069c0.327,0.945 0.489,1.946 0.492,2.941l0,5.008c0.017,0 0.033,0.001 0.05,0.001c1.587,0.024 3.1,1.069 3.678,2.54c0.621,1.581 0.099,3.538 -1.236,4.6c-0.694,0.553 -1.574,0.861 -2.466,0.87c-2.011,0.006 -4.023,0.01 -6.034,0.013l0,0.991c-0.009,1.522 -1.323,2.922 -2.855,2.998c-1.602,0.079 -3.142,-1.298 -3.153,-2.998l0,-0.991c-2.011,-0.003 -4.022,-0.007 -6.034,-0.013c-1.584,-0.015 -3.103,-1.047 -3.692,-2.514c-0.639,-1.593 -0.111,-3.576 1.245,-4.642c0.698,-0.548 1.578,-0.852 2.472,-0.855l0.001,0Zm7.98,8.025c0.054,0.649 -0.092,1.441 0.477,1.82c0.59,0.394 1.551,-0.034 1.556,-0.836l0,-0.984c-0.678,0 -1.356,0 -2.033,0Zm8.782,-6.057c-0.369,-0.103 -0.668,-0.43 -0.728,-0.81c-0.011,-0.069 -0.01,-0.086 -0.013,-0.156c0,-2.018 0.007,-4.036 0,-6.054c-0.028,-2.973 -2.13,-5.818 -5.007,-6.673c-2.352,-0.7 -5.056,-0.054 -6.838,1.645c-1.368,1.305 -2.167,3.183 -2.173,5.075l0,5.005l11.014,0c0.085,0.004 0.107,0.002 0.19,0.019c0.786,0.151 1.117,1.436 0.269,1.873c-0.141,0.072 -0.298,0.103 -0.459,0.111c-4.347,0 -8.695,-0.041 -13.043,0c-1.281,0.021 -2.337,1.494 -1.833,2.748c0.294,0.732 1.042,1.25 1.846,1.257c6.021,0.019 12.042,0.058 18.063,0c1.286,-0.02 2.34,-1.502 1.829,-2.757c-0.298,-0.731 -1.056,-1.245 -1.857,-1.248l-0.999,0c-0.012,0 -0.022,-0.001 -0.031,-0.001c-0.016,-0.001 -0.033,-0.001 -0.05,-0.002c-0.032,-0.002 -0.059,-0.005 -0.109,-0.015c-0.024,-0.005 -0.048,-0.01 -0.071,-0.017Z"/>
      </svg>
        </IconPattern>
    )
}

export default IconEasyNotification;
