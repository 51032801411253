import React from 'react';
import {css} from 'emotion';
const ItemNavMobile = props => {
  return (
    <div
      onClick={() => {
        props.onPress(props.pathname);
      }}
      className={css`
        flex: 1;
        display: flex;
        flex-diection: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      `}>
      <div
        className={css`
          border-radius: 50%;
          height: 40px;
          width: 40px;
          background-color: ${props.location.pathname === props.pathname
            ? 'rgba(245,93,66,0.1)'
            : 'none'};
          display: flex;
          align-items: center;
          justify-content: center;
        `}>
        {props.icon}
      </div>
    </div>
  );
};

export default ItemNavMobile;
