import React from 'react';
import {css} from 'emotion';
import IconEasyNotification from '../../../common/icons/IconEasyNotification';
import {useSelector} from 'react-redux';

const NotificationPresentationBlock = () => {
  const infoCity = useSelector(state => state.city.infoCity);
  return (
    <div
      className={css`
        width: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 15px;
        margin-top: 30px;
        margin-bottom: 20px;
        background: #fff;
        border: 1px solid rgba(24, 62, 100, 0.05);
      `}>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        `}>
        <div
          className={css`
            color: #29354a;
            font-size: 17px;
            text-align: left;
            flex: 1;
            font-weight: 600;
            line-height: 1;
          `}>
          Vos notifications
        </div>
        <div>
          <IconEasyNotification color={'#29354a'} size={20} />
        </div>
      </div>
      <div
        className={css`
          border-top: 1px solid #ebebeb;
          margin-top: 10px;
          padding: 10px;
        `}>
        { 'Retrouvez ici toutes vos notifications et messages de ' + infoCity.nom_comm + ' et autres !'}
      </div>


    </div>
  );
};

export default NotificationPresentationBlock;
