import React from 'react';
import {css} from 'emotion';
import IconEasyGroup from '../../../../common/icons/IconEasyGroup';
import TokenMembers from './TokenMembers';

const FooterInfoGroup = props => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        flex: 1;
        box-sizing: border-box;
        padding-bottom: 20px;
        align-items: center;
        margin-top: 40px;
        padding-top: 20px;
        border-top: 1px solid rgba(24, 62, 100, 0.1);
      `}>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          flex: 1;
          justify-content: center;
          alignItems: center;

        `}>
        <TokenMembers
          onSubscribe={props.onSubscribe}
          subscribed={props.subscribed}
          requested={props.requested}
        />
      </div>
      <div
        className={css`

          display: flex;
          flex-direction: row;
          flex: 1;
          justify-content: center;
          align-items: center;
          padding-right: 20px;
        `}>
        <IconEasyGroup color={'grey'} size={30} />
        <div
          className={css`
            color: grey;
          `}>
          {props.members}
        </div>
      </div>
    </div>
  );
};

export default FooterInfoGroup;
