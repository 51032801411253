import React from 'react';
import {css} from 'emotion';
import SupportPicture from './SupportPicture';



const PicturePreview = (props) =>{

  return(
    <div className={css`
       width:100%;
       border-radius:10px;
       height:300px;
       background-size:contain;
       background-position:center;
       background-repeat:no-repeat;
       background-color:black;
       background-image:url(${props.uri})`}>
        <SupportPicture onClose={props.onClose} nameFile={props.nameFile} load={props.load}/>
    </div>
  )
}

export default PicturePreview;
