import GroupAPI from '../../api/GroupAPI';

// Gets communities list
export function getListCommunities(userKey, codeinsee) {
  return function(dispatch) {
    dispatch({type: 'GET_COMMUNITIES_LOADING'});
    return GroupAPI.getCommunities(userKey, codeinsee)
      .then(data => {
        if (data.error) {
          dispatch({type: 'GET_COMMUNITIES_ERROR', error: data});
        } else {
          dispatch({
            type: 'GET_COMMUNITIES_SUCCESS',
            city_communities: data.data.communities,
          });
        }
      })
      .catch(error => {
        dispatch({type: 'GET_COMMUNITIES_ERROR'});
        throw error;
      });
  };
}

// Get community
export function getCommunity(popkey, id_community) {
  return function(dispatch) {
    dispatch({type: 'GET_COMMUNITY_LOADING'});
    return GroupAPI.getCommunity(popkey, id_community)
      .then(data => {
        if (data.error) {
          dispatch({type: 'GET_COMMUNITY_ERROR', error: data});
        } else {
          dispatch({
            type: 'GET_COMMUNITY_SUCCESS',
            community: data.data.community,
            question: data.data.question,
          });
        }
      })
      .catch(error => {
        console.log(error);
        dispatch({type: 'GET_COMMUNITY_ERROR'});
        throw error;
      });
  };
}

// Subscribe a user to a community
export function subscribeUser(
  userKey,
  commID,
  questionID,
  answer,
  photoSource,
) {
  return function(dispatch) {
    dispatch({type: 'SUBSCRIBE_COMMUNITY_LOADING'});
    return GroupAPI.subscribeUser(
      userKey,
      commID,
      questionID,
      answer,
      photoSource,
    )
      .then(data => {
        if (data && data.error) {
          dispatch({type: 'SUBSCRIBE_COMMUNITY_ERROR', error: data});
        } else {
          dispatch({type: 'SUBSCRIBE_COMMUNITY_SUCCESS'});
        }
      })
      .catch(error => {
        dispatch({type: 'SUBSCRIBE_COMMUNITY_ERROR'});
        throw error;
      });
  };
}
// Subscribe a user to a community
export function unsubscribeUser(userKey, commID) {
  return function(dispatch) {
    dispatch({type: 'SUBSCRIBE_COMMUNITY_LOADING'});
    return GroupAPI.unsubscribeUser(userKey, commID)
      .then(() => {
        dispatch({type: 'UNSUBSCRIBE_COMMUNITY_SUCCESS'});
      })
      .catch(error => {
        dispatch({type: 'SUBSCRIBE_COMMUNITY_ERROR'});
        throw error;
      });
  };
}

// Updates user's communities susbscription locally
export function updateCommunities(community) {
  return function(dispatch) {
    dispatch({type: 'UPT_COMMUNITIES', community: community});
  };
}

// Sends community request
export function sendRqst(userKey, commID, questionID, answer, fileurl) {
  return function(dispatch) {
    dispatch({type: 'SEND_RQST_LOADING'});
    return GroupAPI.sendRqst(userKey, commID, questionID, answer, fileurl)
      .then(data => {
        if (data && data.error) {
          dispatch({type: 'SEND_RQST_ERROR', error: data});
        } else {
          dispatch({type: 'SEND_RQST_SUCCESS'});
        }
      })
      .catch(error => {
        dispatch({type: 'SEND_RQST_ERROR'});
        throw error;
      });
  };
}

export function getUserHasSubscribeGroupe(popkey, id_groupe) {
  return function(dispatch) {
    return GroupAPI.getUserHasSubscribeGroupe(popkey, id_groupe).then(data => {
      dispatch({
        type: 'USER_HAS_SUBSCRIBE_GROUPE',
        payload: data.data.hasSubscribe,
      });
    });
  };
}

export function getForUserGroup(popkey, code_insee) {
  return function(dispatch) {
    dispatch({type: 'GET_FOR_USER_GROUP_LOADING'});
    return GroupAPI.getForUserGroup(popkey, code_insee).then(data => {
      dispatch({type: 'GET_FOR_USER_GROUP_SUCCESS', payload: data.data.groupe});
    });
  };
}
