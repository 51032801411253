import axios from "axios";
import { server_api_url } from "../config/constants";

export default class PublicationAPI {

  static getOnTheFrontPage(popkey, codeinsee, idCommunity) {
    return axios.get(server_api_url + "/publications/onthefront", {
      params: {
        popkey: popkey,
        codeinsee: codeinsee,
        idCommunity: idCommunity,
      },
    });
  }

  static getNews(popkey, codeinsee, page, idCommunity) {
    return axios.get(server_api_url + "/news/all", {
      params: {
        popkey: popkey,
        codeinsee: codeinsee,
        page: page,
        idCommunity: idCommunity
      },
    });
  }

  static getEvents(popkey, codeinsee, page, idCommunity) {
    return axios.get(server_api_url + "/events/all", {
      params: {
        popkey: popkey,
        codeinsee: codeinsee,
        page: page,
        idCommunity: idCommunity
      },
    });
  }



  static getSurveys(popkey, codeinsee, page, idCommunity) {
    return axios.get(server_api_url + "/surveys/all", {
      params: {
        popkey: popkey,
        codeinsee: codeinsee,
        page: page,
        idCommunity: idCommunity
      },
    });
  }
}
