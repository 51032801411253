import React, {useState} from 'react';
import BasicInfoProfile from './components/BasicInfoProfile';
import Alerte from '../common/material-easy-ui/modal/Alerte';
import './styles.css';
import history from '../../utils/history';
import {deleteUser} from '../../redux/actions/userActions';
import {useDispatch, useSelector} from 'react-redux';

const Profile = () => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const popkey = useSelector(state => state.user.popkey);

  const onEdit = () => {
    history.push({pathname: '/profile/edit'});
  };

  const onEditPassword = () => {
    history.push({pathname: '/profile/new-password'});
  };

  const onDeleteAccount = todelete => {
    if (todelete) {
      dispatch(deleteUser(popkey));
      history.push({pathname: '/'});
    } else {
      setAlert(false);
    }
  };
  return (
    <>
      <div style={{overflowY: 'scroll', width: '100%', height: '100%', paddingBottom: 100, background: "#fff"}}>
        {alert && (
          <Alerte
            btnText={'Supprimer'}
            onChoose={onDeleteAccount}
            onBack={() => {
              setAlert(false);
            }}
            text={
              'Êtes-vous certain de vouloir supprimer définitivement votre compte popvox ? Toutes vos participations seront anonymisées ! '
            }
            textHeader={'Attention'}
          />
        )}

        <BasicInfoProfile
          onDeleteAccount={() => setAlert(true)}
          onEditPassword={onEditPassword}
          onEdit={onEdit}
        />
      </div>
    </>
  );
};

export default Profile;
