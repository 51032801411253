import React from 'react';
import Avatar from '../../common/avatar/Avatar';
import {useSelector} from 'react-redux';

const InfoUserAccountMenu = () => {
  const info_user = useSelector(state => state.user.info_user);

  return (
    <div className="contains_info_user_account_menu">
      <Avatar
        size={40}
        id_avatar={info_user.avatar}
      />
      <div style={{fontSize: '14px', marginTop: '5px', marginLeft: '5px'}}>
        {info_user.firstname} {info_user.lastname}
      </div>
      <div
        style={{
          color: '#c9c9c9',
          marginLeft: '5px',
          fontSize: '13px',
        }}>
        {info_user.pseudo}
      </div>
    </div>
  );
};

export default InfoUserAccountMenu;
