import produce from 'immer';

const intitalState = {
  listNotifications: [],
  nbNotification: 0,
  loading: false,
  endReached: false,
};

export const notificationReducers = (state = intitalState, action) => {
  switch (action.type) {
    case 'GET_NOTIFICATION_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'GET_NOTIFICATION_SUCCESS':
      return {
        ...state,
        listNotifications: action.payload.notifications,
        loading: false,
        nbNotification: action.payload.nb,
        endReached: action.payload.endReached,
      };
    case 'GET_MORE_NOTIFICATION_SUCCESS':
      return {
        ...state,
        listNotifications: [
          ...state.listNotifications,
          ...action.payload.notifications,
        ],
        loading: false,
        nbNotification: action.payload.nb,
        endReached: action.payload.endReached,
      };

    case 'READ_ONE_MESSAGE':
      const listNotifications = [...state.listNotifications];
      const indexMessage = listNotifications.findIndex(i => {
        return parseInt(i.id_users_message,10) === parseInt(action.payload.id_users_message, 10);
      });
      const newNotificationList = produce(listNotifications, draft => {
        draft[indexMessage].click = true;
      });

      return {
        ...state,
        listNotifications: newNotificationList,
      };


      case 'DELETE_NOTIFICATION':
      const indexNotifDelete = state.listNotifications.findIndex(i => {
        return parseInt(i.id_users_message, 10) === parseInt(action.payload.id_users_message, 10);
      });
        return {
          ...state,
          listNotifications: [
            ...state.listNotifications.slice(0, indexNotifDelete),
            ...state.listNotifications.slice(indexNotifDelete + 1),
          ],

        };

    default:
      return state;
  }
};
