import React from 'react';
import { css } from 'emotion';


const IconEasyWinner = (props) =>{
    return(
      <div className={css`width:${props.size}px;height:${props.size}px; display: inline-block; position: relative; top: 2px;`}>
        <div className={css`
                display: flex;
                align-items:center;
                justify-content:center;
                position: relative;
            `}>
            <svg
            className={css`
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
                `}
              version="1.1" viewBox="0 0 32 32">
            <path
             fill={props.color}
             d="M15.362,15.033l5.738,-11.475c0.18,-0.33 0.516,-0.54 0.894,-0.553l5.998,0c0.023,0.001 0.045,0.002 0.068,0.003c0.459,0.047 0.486,0.146 0.614,0.266c0.318,0.298 0.436,0.707 0.206,1.191l-5.411,10.44c-0.124,0.22 -0.189,0.27 -0.311,0.356c-0.706,0.499 -1.938,-0.281 -1.464,-1.276l4.654,-8.98l-3.736,0l-5.092,10.182c2.606,0.631 4.832,2.8 5.348,5.489c0.835,4.351 -3.189,9.123 -8.152,8.204c-2.837,-0.526 -5.223,-2.992 -5.644,-5.86c-0.247,-1.689 0.212,-3.411 1.157,-4.824l-7.117,-13.731c-0.102,-0.216 -0.106,-0.287 -0.111,-0.421c-0.021,-0.538 0.343,-1.015 0.999,-1.039l5.998,0c0.024,0.001 0.049,0.002 0.074,0.003c0.346,0.039 0.649,0.237 0.82,0.55l3.999,7.997c0.421,0.928 -1.11,2.133 -1.789,0.895l-3.722,-7.445l-3.736,0l6.013,11.602c1.029,-0.857 2.3,-1.437 3.705,-1.574Zm0.601,1.968c-2.267,0.021 -4.405,1.749 -4.864,3.991c-0.458,2.236 0.835,4.716 2.953,5.613c2.332,0.987 5.336,-0.131 6.45,-2.441c1.418,-2.941 -0.775,-7.082 -4.409,-7.162c-0.043,-0.001 -0.086,-0.001 -0.13,-0.001Z"/>
            </svg>
        </div>
      </div>

    )
}

export default IconEasyWinner;
