import React from 'react';
import { css } from 'emotion';
import IconPattern from './IconPattern';

const IconEasyPin = (props) =>{
    return(
      <IconPattern size={props.size}>
        <svg
        className={css`
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
            `}
          version="1.1" viewBox="113.03099822998047 96.56199645996094 23.938003540039062 23.938003540039062">
<path d="M125,96.562c-3.857,0-6.986,3.17-6.986,7.082c0,1.01,0.213,1.97,0.588,2.84l6.52,10.954l6.277-10.954   c0.377-0.87,0.588-1.83,0.588-2.84C131.984,99.732,128.857,96.562,125,96.562z M125,108.666c-2.809,0-5.083-2.295-5.083-5.125   s2.274-5.125,5.083-5.125c2.807,0,5.083,2.295,5.083,5.125S127.807,108.666,125,108.666z M127.958,103.518   c0,1.623-1.326,2.936-2.959,2.936s-2.957-1.312-2.957-2.936c0-1.619,1.324-2.934,2.957-2.934S127.958,101.898,127.958,103.518z" fill={props.color}/>
<path d="M120.95,114.456c-2.589,0.545-3.945,1.573-3.945,2.376c0,1.15,3.041,2.756,7.995,2.756    c4.953,0,7.995-1.605,7.995-2.756c0-0.801-1.347-1.874-3.901-2.441v-0.828c2.811,0.548,4.906,1.646,4.906,3.291    c0,2.369-4.639,3.646-9,3.646c-4.363,0-9-1.277-9-3.646c0-1.646,2.107-2.766,4.938-3.294L120.95,114.456z" fill={props.color}/>
        </svg>
    </IconPattern>
    )
}

export default IconEasyPin;
