import React from 'react';
import {css} from 'emotion';

const CardInfoGroup = (props) => {
  return (
    <div
      className={css`
        border-radius: 4px;
        background-color: white;
        position: relative;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -15px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 10px;
      `}>
      <div
        className={css`
          text-align: left;
          font-weight: 600;
          color: #29354a;
          font-size: 17px;
        `}>
        {props.title}
      </div>

      <div
        className={css`
          text-align: left;
          margin-top: 10px;
          margin-bottom: 10px;
        `}
        dangerouslySetInnerHTML={{
          __html: props.description,
        }}></div>

      {props.footer}
    </div>
  );
};

export default CardInfoGroup;
