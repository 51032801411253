import React from 'react';
import {css} from 'emotion';

const InfoPopvox = () => {
  return (
    <div
      className={css`
        font-size: 14px;
        text-align: left;
        box-sizing: border-box;
        padding-left: 40px;
        margin-top: 40px;
        padding-bottom: 20px;
        text-align: center;
      `}>
      <div>

      <span
        className={css`
          color: rgba(24, 62, 100, 1);
        `}>
        {'Popvox © 2019'}
      </span>

        <a
          href='https://www.popvox.fr/cgu.html'
          target='_blank'
          rel="noopener noreferrer"
          className={css`
            color: rgba(24, 62, 100, 0.5);
            margin-left: 10px;
          `}>
          {'Confidentialité'}
        </a>

      </div>

    </div>
  );
};

export default InfoPopvox;
