import React, {useState, useEffect} from 'react';
import {css} from 'emotion';
import logo from '../../../../image/logo/popvox.png';
import {server_api_url} from '../../../../config/constants';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios';

const FreemiumBlock = () => {
  const dispatch = useDispatch();
  const infoCity = useSelector(state => state.city.infoCity);
  const popkey = useSelector(state => state.user.popkey);
  const citySuggestAppByUser = useSelector(
    state => state.city.citySuggestAppByUser,
  );
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (citySuggestAppByUser.includes(infoCity.insee_com)) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [infoCity]);

  useEffect(() => {
    localStorage.setItem(
      'popvox@inseeSuggest',
      JSON.stringify(citySuggestAppByUser),
    );
  }, [citySuggestAppByUser]);

  const onSuggestApp = () => {
    axios
      .post(server_api_url + '/participation/suggestbyuser', {
        popkey: popkey,
        code_insee: infoCity.insee_com,
      })
      .then(() => {
        if (
          !citySuggestAppByUser.includes(
            infoCity.insee_com || citySuggestAppByUser.length === 0,
          )
        ) {
          dispatch({
            type: 'ADD_CITY_TO_APP_SUGGEST',
            payload: infoCity.insee_com,
          });
          setShow(false);
        }
      });
  };

  return (
    <>
      {!show ? (
        <>
          <div
            className={css`
              width: 100%;
              border-radius: 4px;
              box-sizing: border-box;
              padding: 15px;
              margin-top: 30px;
              margin-bottom: 20px;
              background: #fff;
              border: 1px solid rgba(24,62,100,0.05);
            `}>
            <img
              alt='Ville'
              className={css`
                width: 80%;
              `}
              src={logo}
            />

            <div
              className={css`
                margin-top: 10px;
                line-height: 1.1;
                font-size: 15px;
                color: grey;
              `}>
              Votre sollicitation à bien été envoyée à la Mairie...
            </div>

            <div
              className={css`
                margin-top: 15px;
                line-height: 1.1;
                font-weight: bold;
              `}>
              Merci de votre confiance !
            </div>


          </div>
        </>
      ) : (
        <>
          <div
            className={css`
              width: 100%;
              border-radius: 4px;
              box-sizing: border-box;
              padding: 15px;
              margin-top: 30px;
              margin-bottom: 20px;
              background: #fff;
              border: 1px solid rgba(24,62,100,0.05);
            `}>
            <img
              alt='Ville'
              className={css`
                width: 80%;
              `}
              src={logo}
            />
            <div
              className={css`
                margin-top: 10px;
                line-height: 1.1;
              `}>
              Je souhaite que ma ville utilise l&apos;application !
            </div>
            <div
              onClick={onSuggestApp}
              className={css`
                border: 1px solid rgb(245, 93, 66);
                color: rgb(245, 93, 66);
                border-radius: 4px;
                padding-top: 5px;
                padding-bottom: 5px;
                margin-top: 30px;
                margin-bottom: 10px;
                cursor: pointer;
                text-align: center;
                &:hover {
                  background-color: rgba(245, 93, 66, 0.8);
                  color: #FFF;
                }
              `}>
              Envoyer une demande
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default FreemiumBlock;
