import React from 'react';
import {css} from 'emotion';
import IconEasyStars from '../../../common/icons/IconEasyStars';

const groupeStars = css`
  display: flex;
  flex-direction: row;
`;

const arrayStar = [1, 2, 3, 4, 5];

const GroupEvaluation = props => {

  const select = item => {
    if (item !== props.answers[0].result) {
      props.setAnswers(
        [{...props.answers[0], result: item}],
        props.id_question,
      );
    } else {
      props.setAnswers(
        [{...props.answers[0], result: item - 1}],
        props.id_question,
      );
    }
  };

  return (
    <div
      className={css`
        padding: 15px;
        border-top: 1px solid rgb(237, 237, 237);
      `}>
      <div
        className={css`
          margin-top: 10px;
        `}>
        {props.question.charAt(0).toUpperCase() +
          props.question.substring(1).toLowerCase()}
      </div>
      <div className={groupeStars}>
        {arrayStar.map((item, i) => {
          return (
            <div
              className={css`
                cursor: pointer;
              `}
              key={i}
              onClick={() => {
                select(item);
              }}>
              <IconEasyStars
                size={30}
                color={
                  item <= props.answers[0].result
                    ? 'rgb(245, 93, 66)'
                    : 'rgb(41, 53, 74)'
                }
              />
            </div>
          );
        })}

        {
          props.answers[0].result === 0 &&
          <div style={{color: 'grey', fontSize: 11, marginTop: 10, marginLeft: 10}}>  (entre 1 et 5 étoiles)</div>
        }
      </div>
    </div>
  );
};

export default GroupEvaluation;
