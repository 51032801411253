import React, { useEffect } from "react";

import { css } from "emotion";
import logo from "../../image/logo/popvox.png";
import mobile_logo from "../../image/logo/popvox_mini.png";

import Media from "react-media";
import { isMobile } from "../../config/constants";
import SearchBar from "./searchbar/SearchBar";
import { getUserInfo } from "../../redux/actions/userActions";
import { getCityInfo } from "../../redux/actions/cityActions";

import Avatar from "../common/avatar/Avatar";

import { useDispatch, useSelector } from "react-redux";

import IconEasyNotification from "../common/icons/IconEasyNotification";

import history from "../../utils/history";

const HeaderApp = (props) => {
  const popkey = useSelector((state) => state.user.popkey);
  const info_user = useSelector((state) => state.user.info_user);
  const infoCity = useSelector((state) => state.city.infoCity);
  const messages = useSelector((state) => state.user.messages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCityInfo(popkey, infoCity.insee_com));
    if (popkey !== null) {
      dispatch(getUserInfo(popkey));
    }
  }, [popkey]);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        height: 60,
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1000,
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid #ededed",
        boxShadow: "0 0 10px rgba(0,0,0,0.2)",
      }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 15,
          paddingTop: 12,
          paddingBottom: 12,
          paddingRight: 15,
        }}>
        <Media query={`(max-width: ${isMobile})`}>
          {(matches) =>
            matches ? (
              <img
                style={{
                  height: "100%",
                  width: 40
                }}
                src={mobile_logo}
                alt="Logo"
              />
            ) : (
              <img
                style={{
                  height: "100%",
                  width: 100
                }}
                src={logo}
                alt="Logo"
              />
            )
          }
        </Media>
      </div>

      <div style={{ flex: 1 }}>
        <SearchBar />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingRight: 10,
          paddingLeft: 5,
          alignItems: "flex-start",
        }}>
        {popkey !== null && (
          <div
            onClick={() => {
              history.push("/notification");
            }}
            className={css`
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 50px;
              align-items: center;
              position: relative;
              cursor: pointer;
            `}>
            <div
              style={{
                height: 30,
                width: 30,
                background: "rgba(0, 0, 0, 0.03)",
                borderRadius: 15,
                padding: 3,
                border:
                  props.location.pathname === "/notification"
                    ? "2px solid rgb(245, 93, 66)"
                    : "none",
              }}>
              <IconEasyNotification
                size={30}
                color={
                  props.location.pathname === "/notification"
                    ? "rgb(245, 93, 66)"
                    : "grey"
                }
              />
            </div>

            {messages !== null && parseInt(messages, 10) !== 0 && (
              <div
                className={css`
                  font-size: 14px;
                  position: absolute;
                  color: white;
                  background-color: red;
                  border-radius: 10px;
                  top: 10px;
                  right: 2px;
                  width: 20px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}>
                {messages}
              </div>
            )}
          </div>
        )}

        <div
          onClick={props.onMenuAccount}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}>
          <Avatar size={30} id_avatar={info_user ? info_user.avatar : null} />
        </div>
      </div>
    </div>
  );
};

export default HeaderApp;
