import React from 'react';

import IconEasyValid from '../../common/icons/IconEasyValid';
import IconEasyClose from '../../common/icons/IconEasyClose';

export default function FilterElement(props) {

  if (props.selected === true)
    return (
      <div style={{padding: 8, border: '2px solid rgb(245, 93, 66)', borderRadius: 10, margin: 8, cursor: 'pointer', color: "rgb(245, 93, 66)", display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={props.onClick}>
        <div style={{marginRight: 10}}>
          <IconEasyValid size={20} color="rgb(245, 93, 66)"/>
        </div>
        {props.title}
      </div>
    );
  return (
    <div style={{padding: 8, border: '2px solid grey', borderRadius: 10, margin: 8, cursor: 'pointer', color: "grey", display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={props.onClick}>
      <div style={{marginRight: 10}}>
        <IconEasyClose size={20} color="grey"/>
      </div>
      {props.title}
    </div>
  )
}