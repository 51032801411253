import React, {useState, useEffect} from 'react';
import FullModal from '../common/material-easy-ui/modal/FullModal';
import InputEasy from '../common/material-easy-ui/input/Input';
import Button from '../common/forms/buttons/Button';
import {useSelector, useDispatch} from 'react-redux';

import AvatarPickerButton from '../common/avatar/AvatarPickerButton';
import UserAPI from '../../api/UserAPI';
import history from '../../utils/history';
import {updateInfo} from '../../redux/actions/userActions';

const EditProfile = () => {
  const dispatch = useDispatch();
  const info_user = useSelector(state => state.user.info_user);
  const popkey = useSelector(state => state.user.popkey);

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pseudo, setPseudo] = useState('');
  const [, setError] = useState(null);
  const [error_pseudo, setError_pseudo] = useState(null);
  const [error_name, setError_name] = useState(null);
  const [error_lastName, setError_lastName] = useState(null);
  const [avatar, setAvatar] = useState(0);

  useEffect(() => {
    if (info_user) {
      setName(info_user.firstname);
      setLastName(info_user.lastname);
      setPseudo(info_user.pseudo);
      setAvatar(info_user.avatar);
    }
  }, [info_user]);

  useEffect(() => {
    if (!pseudo.match(/^([a-zA-Z0-9-_ @.]{1,36})$/) && !pseudo === '')
      setError_pseudo({
        error: true,
        message: "Votre pseudo n'est pas au bon format",
      });
    else {
      setError_pseudo(null);
    }
  }, [pseudo]);

  const onChangeName = e => setName(e.target.value);
  const onChangeLastName = e => setLastName(e.target.value);

  const onChangePseudo = e => {
    setPseudo(e.target.value);
  };

  const onUpdate = () => {
    if (name === '')
      setError_name({error: true, message: 'Votre prénom est vide'});
    else if (lastName === '')
      setError_lastName({error: true, message: 'Votre nom est vide'});
    else if (pseudo === '')
      setError_pseudo({error: true, message: 'Votre pseudo est vide'});
    else if (pseudo.includes(' '))
      setError_pseudo({error: true, message: 'Votre pseudo ne doit pas contenir d\'espace !'});
    else if (error_pseudo && error_pseudo.error === true)
      setError({eror: true, message: error_pseudo});
    else
      dispatch(updateInfo(popkey, name, lastName, pseudo, avatar)).then(() => {
        history.goBack();
      });
  };

  const pseudoTesting = () => {
    if (!error_pseudo && pseudo !== info_user.pseudo) {
      UserAPI.testPseudo(pseudo).then(data => {
        if (data.data.pseudoExist) {
          setError_pseudo({error: true, message: 'le pseudo est déjà pris'});
        } else {
          setError_pseudo({error: false, message: 'le pseudo est valide'});
        }
      });
    }
  };

  const testName = () => {
    if (name === '')
      setError_name({error: true, message: 'Votre prénom est vide'});
    else setError_name(null);
  };

  const testLastName = () => {
    if (lastName === '')
      setError_lastName({error: true, message: 'Votre nom est vide'});
    else setError_lastName(null);
  };

  const chooseAvatar = item => {
    setAvatar(item);
  };

  const onBack = () => {
    history.goBack();
  };

  return (
    <FullModal
      id={'modal-edit-profile'}
      textHeader="Modifier vos informations"
      onBack={onBack}>
      

          {info_user && (
            <AvatarPickerButton chooseAvatar={chooseAvatar} avatar={avatar} />
          )}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}>

            <InputEasy
              width={'80%'}
              style={{
                width: '80%',
                height: 40,
                paddingLeft: 20,
                paddingRight: 20,
                display: 'flex',
                alignItems: 'center',
                paddingTop: 5,
                paddingBottom: 5,
              }}
              inputStyle={{fontSize: 15}}
              labelStyle={{color: '#cccccc'}}
              onChangeText={onChangeName}
              placeholder={'Ecrivez votre prénom'}
              text={name}
              label={'Prénom'}
              error={error_name}
              onBlur={testName}
            />

            <InputEasy
              width={'80%'}
              style={{
                marginTop: 20,
                height: 40,
                paddingLeft: 20,
                paddingRight: 20,
                display: 'flex',
                alignItems: 'center',
                paddingTop: 5,
                paddingBottom: 5,
              }}
              inputStyle={{fontSize: 15}}
              labelStyle={{color: '#cccccc'}}
              onChangeText={onChangeLastName}
              placeholder={'Ecrivez votre Nom'}
              text={lastName}
              label={'Nom'}
              error={error_lastName}
              onBlur={testLastName}
            />

            <InputEasy
              width={'80%'}
              style={{
                marginTop: 20,
                height: 40,
                paddingLeft: 20,
                paddingRight: 20,
                display: 'flex',
                alignItems: 'center',
                paddingTop: 5,
                paddingBottom: 5,
              }}
              inputStyle={{fontSize: 15}}
              labelStyle={{color: '#cccccc'}}
              onChangeText={onChangePseudo}
              placeholder={'Ecrivez votre Pseudo'}
              text={pseudo}
              label={'pseudo'}
              error={error_pseudo}
              onBlur={pseudoTesting}
            />



            <Button
              style={{marginTop: 20, marginBottom: 20}}
              onClick={onUpdate}>
              Envoyer
            </Button>


          </div>
        

    </FullModal>
  );
};

export default EditProfile;
