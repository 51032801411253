import React from 'react';
import {css} from 'emotion';
import {Popup} from 'react-mapbox-gl';
import history from '../../../../../utils/history';

const PopupMap = props => {
  
  const goToPageDetails = () => {
    history.push({
      pathname: '/budget-participatif/details',
      search: `?id_budget_part_project=${props.item.id_budget_part_project}`,
      state: {
        modal: true,
      },
    });
  };

  return (
    <Popup
      coordinates={JSON.parse(props.item.marker).coordinates}
      offset={{
        'bottom-left': [-200, -50],
        bottom: [0, -50],
        'bottom-right': [-200, -50],
      }}>
      <div
        className={css`
          box-sizing: border-box;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
        `}>
        <div
          className={css`
            text-align: center;
          `}>
          <span
            className={css`
              color: grey;
            `}>
            {props.item.pseudo}
          </span>
          <span
            className={css`
              margin-left: 5px;
              color: rgb(242,101,35);
            `}>
            a déposé
          </span>
        </div>
        <div
          className={css`
            margin-top: 5px;
            text-align: center;
            font-size: 18px;
            color: rgb(41, 53, 74);
          `}>
          {props.item.title}
        </div>
        <div
          onClick={goToPageDetails}
          className={css`
            margin-top: 10px;
            text-align: center;
            color: rgb(242,101,35);
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          `}>
          Voir le projet
        </div>
      </div>
    </Popup>
  );
};

export default PopupMap;
