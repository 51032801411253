import React from 'react';
import './styles.css';
import IconEasyArrowRight from '../icons/IconEasyArrowRight';
import IconEasyArrowLeft from '../icons/IconEasyArrowLeft';

class HorizontalScroll extends React.Component {

  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.isDown = false;
    this.startX = 0;
    this.scrollLeft = 0;
    this.scrollStart = 0;
    this.scrollEnd = 0;
    this.clientXStart = 0;
    this.clientXEnd = 0;
  }

  state = {
    active: false,
    has_moove: true,
  };

  scrollToTheLeft = () => {
    let content_scoll_left = this.slider.current.scrollLeft;
    content_scoll_left -= 430;
    if (content_scoll_left >= this.slider.current.scrollWidth) {
      content_scoll_left = this.slider.current.scrollWidth;
    }
    this.slider.current.scrollTo({
      left: content_scoll_left,
      behavior: 'smooth'
    });
  };

  scrollToTheRight = () => {
    let content_scoll_left = this.slider.current.scrollLeft;
    content_scoll_left += 430;
    if (content_scoll_left >= this.slider.current.scrollWidth) {
      content_scoll_left = this.slider.current.scrollWidth;
    }
    this.slider.current.scrollTo({
      left: content_scoll_left,
      behavior: 'smooth'
    });
  };

  componentDidMount() {

    this.props.onScrollEndLeft &&
      this.slider.current.addEventListener('scroll', this.onScrollEndLeft);

    this.slider.current.addEventListener('mousedown', e => {
      this.isDown = true;
      this.setState({active: true});
      this.startX = e.pageX - this.slider.current.offsetLeft;
      this.scrollLeft = this.slider.current.scrollLeft;
      this.scrollStart = this.slider.current.scrollLeft;
      this.clientXStart = e.clientX;
      this.clientXEnd = 0;
    });

    this.slider.current.addEventListener('mouseleave', () => {
      this.isDown = false;
      this.setState({active: false});
    });

    this.slider.current.addEventListener('mouseup', e => {
      this.isDown = false;
      this.setState({active: false});
      this.scrollEnd = this.slider.current.scrollLeft;
      this.clientXEnd = e.clientX;

      /*this.setState({
        has_moove:
          this.scrollStart === this.scrollEnd &&
          this.clientXStart === this.clientXEnd,
      });*/

      this.setState({
        has_moove:
          (this.scrollStart >= this.scrollEnd - 20) && (this.scrollStart <= this.scrollEnd + 20)
      });

    });

    this.slider.current.addEventListener('mousemove', e => {
      if (!this.isDown)
        return;
      e.preventDefault();

      const x = e.pageX - this.slider.current.offsetLeft;
      const walk = (x - this.startX) * 3;
      this.slider.current.scrollLeft = this.scrollLeft - walk;
    });
  }

  onScrollEndLeft = () => {
    if (this.slider.current.scrollLeft >= this.slider.current.scrollWidth - this.slider.current.offsetWidth) {
      this.props.onScrollEndLeft();
    }
  };

  StartScrollView = () => {
    this.slider.current.scrollLeft = 0;
    this.isDown = false;
    this.startX = 0;
    this.scrollLeft = 0;
    this.scrollStart = 0;
    this.scrollEnd = 0;
    this.clientXStart = 0;
    this.clientXEnd = 0;
  };

  render() {

    const children = React.Children.map(this.props.children, (child, index) => {

      return React.cloneElement(child, {
        has_moove: this.state.has_moove.toString(),
      });

    });

    // console.log(children)

    return (
      <div className="horizontal_container">

        <div
          ref={this.slider}
          className={`item_container ${
            this.state.active ? 'active_slider' : ''
          }`}>
          {children}
        </div>

        <div
          className="btn_left_scrollto"
          onClick={this.scrollToTheLeft}>
            <IconEasyArrowLeft size={15} color='#d5dadb'/>
        </div>


        <div
          className="btn_right_scrollto"
          onClick={this.scrollToTheRight}>
            <IconEasyArrowRight size={15} color='#d5dadb'/>
        </div>

      </div>
    );
  }
}

export default HorizontalScroll;
