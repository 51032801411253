import React from 'react';
import {css} from 'emotion';

import IconEasyWinner from '../../../../../common/icons/IconEasyWinner';

const LaureatReward = props => {


  if(props.project_status === 4) {
    return (
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}>
            <IconEasyWinner color={'#69bf7d'} size={38} />
            <div style={{background: '#69bf7d', color: '#fff', padding: '3px 10px 3px 10px', borderRadius: 10 ,fontSize: 16, fontWeight: 'bold', marginLeft: 10}}>Projet lauréat</div>
        </div>
    );
  }

  return (
      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}>
          <div style={{color: '#a6a6a6', fontSize: 16, padding: '3px 10px 3px 10px', borderRadius: 10 , border: '2px solid #cacaca', fontWeight: 'bold'}}>Non retenu</div>
      </div>
  );


};

export default LaureatReward;
